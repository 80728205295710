import React, { useState, useEffect, useRef } from 'react'
import { Row, Col, Progress, Button, Card, Spin } from "antd"
import ChooseProduct from './screens/chooseProduct';
import Covid from "../create-quote/screens/covid";
import AdditionalBenefit from './screens/additionalBenefit';
import OrderSummary from './screens/orderSummary';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory,useLocation } from "react-router-dom"
import rootIndex from "../../store/root_index";
import { toast } from 'react-toastify';
import { useLanguage } from '../../config/LanguageContext';
import CustomerHeader from '../../CustomerHeader/CustomerHeader';
import axiosRequest from '../../axios-request/request.methods'
import { useSelector } from 'react-redux';
const { store } = rootIndex;

function ChooseProductTravel(props) {
    
    const ref = useRef();
    const childRef = useRef();
    const _store = store.getState();
    const { translate } = useLanguage()
    const fetchingWholeDatafromStore=useSelector((state)=>state?.cipherlinkreducer?.quote)
    console.log(fetchingWholeDatafromStore,"fetchingWholeDatafromStore")
    const history = useHistory()
    const location=useLocation()
    const [current, setCurrent] = useState(0);
    const [width, setWidth] = useState(window.innerWidth);
    const [planCode, setPlanCode] = useState('')
    const [planName, setPlanName] = useState('')
    const [premium, setPremium] = useState('')
    const [loading, setLoading] = useState(false)
    const [addbnftarr, setAddBnftArr] = useState([])
    const [addbnftcalc, setAddBnftCalc] = useState(0)
    const [covid, setCovid] = useState(false)
    const [agecal, setAgeCal] = useState(false)
    const [tvca, setTvca] = useState('')
    const [triptype, setTriptype] = useState('')
    const [schegan, setSchegan] = useState(false)
    const [covidpopup, setCovidPopup] = useState(false)
    const [addData, setAddData] = useState('')
    const [selectButton,setSelectButton] = useState(true)
    console.log(selectButton,"selectButton")

    const breakpoint = 600;

    useEffect(() => {
        if(fetchingWholeDatafromStore?.quoteId !== ""){
        // console.log(props.location, 'create quote=======>');
        // if (fetchingWholeDatafromStore?.age !== undefined) {
        //     setAgeCal(fetchingWholeDatafromStore?.age)
        // }
        // if (fetchingWholeDatafromStore?.covid) {
        //     setCovid(fetchingWholeDatafromStore?.covid)
        // }
        // if (fetchingWholeDatafromStore?.tcaid !== null && fetchingWholeDatafromStore?.tcaid !== undefined) {
        //     setTvca(fetchingWholeDatafromStore?.tcaid)
        // }
        // if (fetchingWholeDatafromStore?.triptype !== null && fetchingWholeDatafromStore?.triptype !== undefined) {
        //     setTriptype(fetchingWholeDatafromStore?.triptype)
        // }
        getQuotes()
    }
       
    }, [fetchingWholeDatafromStore]);

    useEffect(()=>{
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);

        return () => window.removeEventListener("resize", handleWindowResize);

    },[width])



    const next = () => {
        console.log(current, tvca, agecal, schegan, 'schegan next----->');
        setCovidPopup(true)
        if (current === 0) {
            ref.current.log()
            console.log('yes inside 1------->');
        } else if (current === 0 && tvca != 3 && agecal === false) {
            console.log('yes inside 2------->');
            setCovidPopup(true)
        } else if (current === 0 && tvca != 3 && agecal === true) {
            console.log('yes inside 3------->');
            history.push({
                pathname: "/additionalbenefittravel",
                // state: {
                //     data:location?.state?.data,
                //     quoteId: props?.location?.state?.quoteId,
                //     tca: props?.location?.state?.tca,
                //     phone: props?.location?.state?.phone,


                // }
            })
        } else if (current === 0 && tvca === 3 && agecal === false && schegan === true) {
            console.log('yes inside 4------->');
            // setCurrent(1)
            setCovidPopup(true)
        } 
        else if (current === 0 && tvca === 3 && agecal === true) {
            console.log('yes inside 6------->');
            history.push({
                pathname: "/additionalbenefittravel",
                // state: {
                //     data:location?.state?.data,
                //     quoteId: props?.location?.state?.quoteId,
                //     tca: props?.location?.state?.tca,
                //     phone: props?.location?.state?.phone,

                // }
            })
        } else if (current === 2) {
            childRef.current.log2();
        } else {
            setCovidPopup(true)
        }
    };



    const prev = () => {
        console.log(current - 1);
        if (current === 3) {
            if (triptype === 1 || triptype === '1') {
                setCurrent(0)
            } else {
                setCurrent(current - 1);
            }
        } else if (current === 2) {
            setCurrent(0);
        } else {
            setCurrent(current - 1);
        }

    };

    const getQuotes = () => {

        var fromReferral = ""
        if(fetchingWholeDatafromStore?.resumeType === "referalJourney" || fetchingWholeDatafromStore?.resumeType === "quoteSearch"){
          fromReferral=`user/get-quotes?id=${fetchingWholeDatafromStore?.quoteId}&logkey=${fetchingWholeDatafromStore?.resumeType}`
        }
        else{
          fromReferral=`user/get-quotes?id=${fetchingWholeDatafromStore?.quoteId}`
        }


        axiosRequest
      .get(
        `${fromReferral}`,
              )
            .then((data) => {
                setTvca(data.errMsg.data[0].travelCoverageArea)
                setTriptype(data.errMsg.data[0].tripType)
                setAddData(data.errMsg.data[0].planName)
                var today = new Date();
                var birthDate = new Date(data.errMsg.data[0].insuredDob);
                var age = today.getFullYear() - birthDate.getFullYear();
                var m = today.getMonth() - birthDate.getMonth();
                if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                    age--;
                }
                if (age > 70) {
                    setAgeCal(true)
                } else {
                    setAgeCal(false)
                }

               
                if (data.errMsg.data[0].stage === 'Plan Selected' && data.errMsg.data[0].travelCoverageArea != '3' && age <= 70) {
                    console.log('yes---2');
                    setCovidPopup(false);
                   
                } else if (data.errMsg.data[0].stage === 'Lead Created') {
                    setCovidPopup(false)
                } else {
                    setCovidPopup(false)
                }

            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

            });
    }

    const planSubmit = (planCode, planName, premium) => {
        console.log(planCode, planName, premium, 'values in Travel==========>')

        if (addData === planName) {
            var formData = {
                planCode: planCode,
                premium: premium,
                planName: planName,
            }
        }
        else {
            var formData = {
                planCode: planCode,
                premium: premium,
                planName: planName,
                additionalBenefits: [],
            }
        }

        var formData = {
            planCode: planCode,
            premium: premium,
            planName: planName,
            additionalBenefits: [],
        }



        console.log(formData, 'form data--------->');

        axiosRequest
            .patch(
                `user/update-quote/${fetchingWholeDatafromStore?.quoteId}`,
                formData,

            )
            .then((data) => {
                if (triptype === 2 || triptype === '2') {
                    if (tvca === '3' && agecal === false && schegan === true) {
                        console.log('first-----1');
                        setCovidPopup(true);
                    } 
                    else if (tvca === '3' && agecal === true) {
                        console.log('first-----3');
                        history.push({
                            pathname: "/additionalbenefittravel",
                            // state: {
                            //     data:location?.state?.data,
                            //     quoteId: props?.location?.state?.quoteId,
                            //     tca: props?.location?.state?.tca,
                            //     phone: props?.location?.state?.phone,
                            // }
                        });
                    } else if (tvca != '3' && agecal === false) {
                        console.log('first-----4');
                        setCovidPopup(true);
                    } else if (tvca != '3' && agecal === true) {
                        console.log('first-----5');
                        history.push({
                            pathname: "/additionalbenefittravel",
                            // state: {
                            //     data:location?.state?.data,
                            //     quoteId: props?.location?.state?.quoteId,
                            //     tca: props?.location?.state?.tca,
                            //     phone: props?.location?.state?.phone,
                            // }
                        });
                    }
                } else {
                    console.log('first-----6');
                    history.push({
                        pathname: "/ordersummarytravel",
                        // state: {
                        //     data:location?.state?.data,
                        //     quoteId: props?.location?.state?.quoteId,
                        //     tca: props?.location?.state?.tca

                        // }
                    })
                }
                toast.success(`${translate("home")["Quote Updated Successfully"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })

            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
                toast.error(`${translate("home")["Network Request Failed"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
            });
    }

    const scheganPlan = (value) => {
        console.log(value, 'schegan--------->');
        setSchegan(value)
        setSelectButton(false)
    }


    const addplanSubmit = (addbnft, calc, planName, premium, planCode) => {
        setAddBnftArr(addbnft)
        setAddBnftCalc(calc)
        console.log(addbnft, 'values of addbnft==========>')
        if (addbnft.length > 0) {


            let formData = {
                planCode: planCode,
                premium: premium,
                planName: planName,
                additionalBenefits: addbnft,
                //proposalNo: ""
            }


            console.log(formData, 'form data--------->');

            axiosRequest
                .patch(
                    `user/update-quote/${fetchingWholeDatafromStore?.quoteId}`,
                    formData,

                )
                .then((data) => {


                    // history.push("/createquote")
                    history.push({
                        pathname: "/ordersummarytravel",
                        // state: {
                        //     data:location?.state?.data,
                        //     quoteId: props?.location?.state?.quoteId,
                        //     tca: props?.location?.state?.tca

                        // }
                    })
                    toast.success(`${translate("home")["Quote Updated Successfully"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })


                }).catch((error) => {
                    console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
                    toast.error(`${translate("home")["Network Request Failed"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })

                });
        } else {
            history.push({
                pathname: "/ordersummarytravel",
                // state: {
                //     data:location?.state?.data,
                //     quoteId: props?.location?.state?.quoteId,
                //     tca: props?.location?.state?.tca,

                // }
            })

        }
    }

    const handleCovid = (value) => {
        console.log(value, 'covid value---->')
        if (value === false) {
            setCovid(false)
            setCovidPopup(false)
            // setCurrent(current + 1);
            history.push({
                pathname: "/additionalbenefittravel",
                // state: {
                //     data:location?.state?.data,
                //     quoteId: props?.location?.state?.quoteId,
                //     tca: props?.location?.state?.tca,
                //     covid: false,
                //     phone: props?.location?.state?.phone,

                // }
            });
        } else {
            setCovid(true)
            setCovidPopup(false)
            // setCurrent(current + 1);
            history.push({
                pathname: "/additionalbenefittravel",
                // state: {
                //     data:location?.state?.data,
                //     quoteId: props?.location?.state?.quoteId,
                //     tca: props?.location?.state?.tca,
                //     covid: true,
                //     phone: props?.location?.state?.phone,

                // }
            });
        }

    }


    const handleLoading = (value) => {
        setLoading(value)
    }



    const handleBack = () => {
        if (_store?.cipherlinkreducer?.formdata?.cipherkeyavilable === true) {
            history.push({
                pathname: "/oonatravel",
                // state: {
                //     data:location?.state?.data,
                //     quoteId: props?.location?.state?.quoteId,
                //     tca: props?.location?.state?.tca
                // }
            })
        } else {
            history.push({
                pathname: "/oonatravel",
                // state: {
                //     data:location?.state?.data,
                //     quoteId: props?.location?.state?.quoteId,
                //     tca: props?.location?.state?.tca

                // }
            })
        }

    }

    // const steps = [
    //     {
    //         title: 'Second',
    //         content: <><ChooseProduct scheganPlan={scheganPlan} index={current} handleLoading={handleLoading} planSubmit={planSubmit} ref={ref} next={next} prev={prev} tca={props?.location?.state?.tca} quoteId={props?.location?.state?.quoteId} /></>,
    //     },
    //     {
    //         title: 'third',
    //         content: <><Covid handleCovid={handleCovid} ref={childRef} handleLoading={handleLoading} next={next} prev={prev} quoteId={props?.location?.state?.quoteId} /></>,
    //     },
    //     {
    //         title: 'fourth',
    //         content: <><AdditionalBenefit covid={covid} handleLoading={handleLoading} addplanSubmit={addplanSubmit} next={next} prev={prev} ref={childRef} quoteId={props?.location?.state?.quoteId} planCode={planCode} planName={planName} premium={premium} /></>
    //     },
    //     {
    //         title: 'fifth',
    //         content: <><OrderSummary quote={props?.location?.state?.quote} addbnft={addbnftarr} bnftcalc={addbnftcalc} handleLoading={handleLoading} next={next} prev={prev} quoteId={props?.location?.state?.quoteId} planCode={planCode} planName={planName} premium={premium} ref={childRef} phone={props?.location?.state?.phone} /></>
    //     },
    // ];

    const sendEmail = () => {
        window.open("mailto:noreply@oona-insurance.co.id?subject=SendMail&body=Description");
    };

    return (
        <>
            <CustomerHeader />
            <div className="row-travel">
                <Spin tip="Loading" spinning={loading} >
                    <Row>
                        <Col xl={7} md={7} lg={7} sm={24} xs={24}>
                            {width > breakpoint ? (
                                <>
                                    <h5 className="heading-content">{translate("travel")["Quick quote"]}</h5>
                                    <Card className="card-progress">
                                        <Row>
                                            <Col md={3}>
                                                <Progress className="progress-content"
                                                    percent={30} showInfo={false}
                                                />
                                            </Col>
                                            <Col className="col-content" md={10}>
                                                <div className='thick-font'>{translate("travel")["Create Quote"]}</div>
                                                <div className='thick-font'>{translate("travel")["Customer Details"]}</div>
                                                <div className='thick-font'>{translate("travel")["Confirmation"]}</div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </>
                            ) : (
                                <>
                                    <Card className="card-mobile">
                                        <div className="justify-space">
                                            <div className="mob-header">
                                                {translate("travel")["Quick quote"]}
                                            </div>
                                            {props?.location?.state?.quote != undefined && props?.location?.state?.quote === true ? null :
                                                <div style={{ cursor: "pointer" }} onClick={sendEmail} className='need-content'>
                                                    {translate("onboarding")["Need Help"]} ?
                                                </div>
                                            }
                                        </div>
                                    </Card>
                                    <Card className="card-mobile">
                                        <Row>
                                            <Col xs={3}>
                                                <div className="number-mob-selected">
                                                    1
                                                </div>
                                            </Col>
                                            <Col xs={14}>
                                                <div className='thick-font'>
                                                    {translate("travel")["Create Quote"]}
                                                </div>
                                            </Col>
                                            <Col xs={3}>
                                                <div className="number-mob-non-selected">
                                                    2
                                                </div>
                                            </Col>
                                            <Col xs={3}>
                                                <div className="number-mob-non-selected">
                                                    3
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </>
                            )}
                        </Col>
                        <Col xl={14} md={14} lg={14} sm={24} xs={24}>
                            <Card className='travel-back-card-mob'>
                                    <div className='travel-row-1 back-content'>
                                        {/* {current === 0 && ()}
                                        {current > 0 && (<div className='arrow-back-font' style={{ cursor: 'pointer' }} onClick={() => prev()}><ArrowLeftOutlined />{translate("onboarding")["Back"]}</div>
                                        )} */}
                                        <><div className='arrow-back-font' style={{ cursor: 'pointer' }} onClick={() => handleBack()}><ArrowLeftOutlined />{translate("onboarding")["Back"]}</div></>
                                        <div style={{ cursor: "pointer" }} onClick={sendEmail} className='need-content'>{translate("onboarding")["Need Help"]} ?</div>
                                    </div>
                                
                                <div className="steps-content"><ChooseProduct setSelectButton={setSelectButton} scheganPlan={scheganPlan} index={current} handleLoading={handleLoading}  planSubmit={planSubmit} ref={ref} next={next} prev={prev} tca={props?.location?.state?.tca} quoteId={props?.location?.state?.quoteId} /></div>
                                <div className="steps-action">
                                   
                                        <Button disabled={selectButton} type="primary" className="next-button-travel" onClick={() => next()}>
                                           {translate("home")["Next"]}
                                        </Button>
                                 
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    {covidpopup === true ?
                        <Covid handleCovid={handleCovid} ref={childRef} handleLoading={handleLoading} next={next} prev={prev}  />
                        : null}
                </Spin>
            </div>
        </>
    )
}

export default ChooseProductTravel