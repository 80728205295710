import React, { useState, useEffect, useRef } from 'react'
import { Row, Col, Progress, Card,Spin } from "antd"
import OrderSummary from './screens/orderSummary';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory,useLocation } from "react-router-dom"
import rootIndex from "../../store/root_index";
import { useLanguage } from '../../config/LanguageContext';
import axiosRequest from '../../axios-request/request.methods'
import CustomerHeader from '../../CustomerHeader/CustomerHeader';
import { useSelector } from 'react-redux';

const { store } = rootIndex;

function OrderSummaryTravel(props) {
    const ref = useRef();
    const childRef = useRef();
    const fetchingWholeDatafromStore=useSelector((state)=>state?.cipherlinkreducer?.quote)
    const _store = store.getState();
    const { translate } = useLanguage()
    const history = useHistory()
    const location=useLocation()
    console.log(location,"location in Order Summary")
    const [current, setCurrent] = useState(2);
    const [width, setWidth] = useState(window.innerWidth);
    const [planCode, setPlanCode] = useState('')
    const [planName, setPlanName] = useState('')
    const [premium, setPremium] = useState('')
    const [loading, setLoading] = useState(false)
    const [addbnftarr, setAddBnftArr] = useState([])
    const [addbnftcalc, setAddBnftCalc] = useState(0)
    const [covid, setCovid] = useState(false)
    const [agecal, setAgeCal] = useState(false)
    const [tvca, setTvca] = useState('')
    const [triptype, setTriptype] = useState('')
    const [schegan, setSchegan] = useState(false)
    const [covidpopup, setCovidPopup] = useState(false)

    const breakpoint = 600;

    useEffect(() => {
        console.log(props.location, 'create quote=======>');
        // if (props?.location?.state?.age != undefined) {
        //     setAgeCal(props?.location?.state?.age)
        // }
        // if (props?.location?.state?.covid != undefined) {
        //     setCovid(props?.location?.state?.covid)
        // }
        // if (props?.location?.state?.tcaid != undefined) {
        //     setTvca(props?.location?.state?.tcaid)
        // }
        // if (props?.location?.state?.triptype != undefined) {
        //     setTriptype(props?.location?.state?.triptype)
        // }
      
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);

        return () => window.removeEventListener("resize", handleWindowResize);
    }, [width]);

    useEffect(()=>{
        if(fetchingWholeDatafromStore?.quoteId !== ""){
            getQuotes()
        }
       
    },[fetchingWholeDatafromStore])



    const next = () => {
        console.log(current, tvca, agecal, schegan, 'schegan next----->');
        setCovidPopup(true)
        if (current === 0) {
            ref.current.log()
        } else if (current === 0 && tvca != 3 && agecal === false) {
            setCovidPopup(true)
        } else if (current === 0 && tvca != 3 && agecal === true) {
            history.push({
                pathname: "/additionalbenefittravel",
                // state: {
                //     data:location?.state?.data,
                //     quoteId: props?.location?.state?.quoteId,
                //     tca: props?.location?.state?.tca

                // }
            })
        } else if (current === 0 && tvca === 3 && agecal === false && schegan === true) {
            setCovidPopup(true)
        } else if (current === 0 && tvca === 3 && agecal === false && schegan === false) {
            history.push({
                pathname: "/additionalbenefittravel",
                // state: {
                //     data:location?.state?.data,
                //     quoteId: props?.location?.state?.quoteId,
                //     tca: props?.location?.state?.tca

                // }
            })
        } else if (current === 0 && tvca === 3 && agecal === true) {
            history.push({
                pathname: "/additionalbenefittravel",
                // state: {
                //     data:location?.state?.data,
                //     quoteId: props?.location?.state?.quoteId,
                //     tca: props?.location?.state?.tca

                // }
            })
        } else if (current === 2) {
            childRef.current.log2();
        } else {
            setCovidPopup(true)
        }
    };



    const prev = () => {
        console.log(current - 1);
        if (current === 3) {
            if (triptype === 1 || triptype === '1') {
                history.push({
                    pathname: "/chooseprooducttravel",
                    // state: {
                    //     data:location?.state?.data,
                    //     quoteId: props?.location?.state?.quoteId,
                    //     tca: props?.location?.state?.tca

                    // }
                })
            } else {
                history.push({
                    pathname: "/additionalbenefittravel",
                    // state: {
                    //     data:location?.state?.data,
                    //     quoteId: props?.location?.state?.quoteId,
                    //     tca: props?.location?.state?.tca

                    // }
                })
            }
        } else if (current === 2) {
           
            history.push({
                pathname: "/chooseproducttravel",
                // state: {
                //     quoteId: props?.location?.state?.quoteId,
                //     tca: props?.location?.state?.tca,
                    
                // }
            })
        } else {
            setCurrent(current - 1);
        }

    };

    const getQuotes = () => {
      
            var fromReferral = ""
            if(fetchingWholeDatafromStore?.resumeType === "referalJourney" || fetchingWholeDatafromStore?.resumeType === "quoteSearch"){
              fromReferral=`user/get-quotes?id=${fetchingWholeDatafromStore?.quoteId}&logkey=${fetchingWholeDatafromStore?.resumeType}`
            }
            else{
              fromReferral=`user/get-quotes?id=${fetchingWholeDatafromStore?.quoteId}`
            }


       axiosRequest
      .get(
        `${fromReferral}`,
              )
            .then((data) => {
                    setTvca(data.errMsg.data[0].travelCoverageArea)
                    setTriptype(data.errMsg.data[0].tripType)
                    var today = new Date();
                    var birthDate = new Date(data.errMsg.data[0].insuredDob);
                    var age = today.getFullYear() - birthDate.getFullYear();
                    var m = today.getMonth() - birthDate.getMonth();
                    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                        age--;
                    }
                    if (age > 70) {
                        setAgeCal(true)
                    } else {
                        setAgeCal(false)
                    }

                  
                
            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

            });
    }

   

    const handleLoading = (value) => {
        setLoading(value)
    }

    const handleBack = () => {
        if (_store?.cipherlinkreducer?.formdata?.cipherkeyavilable === true) {
            history.push({
                pathname: "/chooseproducttravel",
                // state: {
                //     data:location?.state?.data,
                //     quoteId: props?.location?.state?.quoteId,
                //     tca: props?.location?.state?.tca,
                    

                // }
            })
        } else {
            history.push({
                pathname: "/chooseproducttravel",
                // state: {
                //     data:location?.state?.data,
                //     quoteId: props?.location?.state?.quoteId,
                //     tca: props?.location?.state?.tca,
                    
                // }
            })
        }

    }

    

    console.log(props?.location?.state?.cipher, "aks")

    const sendEmail = () => {
        window.open("mailto:noreply@oona-insurance.co.id?subject=SendMail&body=Description");
    };

    return (
        <>


                <CustomerHeader/>
            <div className="row-travel">
                <Spin tip="Loading" spinning={loading} >
                    <Row>
                        <Col xl={7} md={7} lg={7} sm={24} xs={24}>
                            {width > breakpoint ? (
                                <>
                                    <h5 className="heading-content">{translate("travel")["Quick quote"]}</h5>
                                    <Card className="card-progress">
                                        <Row>
                                            <Col md={3}>
                                                <Progress className="progress-content"
                                                    percent={30} showInfo={false}
                                                />
                                            </Col>
                                            <Col className="col-content" md={10}>
                                                <div className='thick-font'>{translate("travel")["Create Quote"]}</div>
                                                <div className='thick-font'>{translate("travel")["Customer Details"]}</div>
                                                <div className='thick-font'>{translate("travel")["Confirmation"]}</div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </>
                            ) : (
                                <>
                                    <Card className="card-mobile">
                                        <div className="justify-space">
                                            <div className="mob-header">
                                                {translate("travel")["Quick quote"]}
                                            </div>
                                            {props?.location?.state?.quote != undefined && props?.location?.state?.quote === true ? null :
                                                <div style={{ cursor: "pointer" }} onClick={sendEmail} className='need-content'>
                                                    {translate("onboarding")["Need Help"]} ?
                                                </div>
                                            }
                                        </div>
                                    </Card>
                                    <Card className="card-mobile">
                                        <Row>
                                            <Col xs={3}>
                                                <div className="number-mob-selected">
                                                    1
                                                </div>
                                            </Col>
                                            <Col xs={14}>
                                                <div className='thick-font'>
                                                    {translate("travel")["Create Quote"]}
                                                </div>
                                            </Col>
                                            <Col xs={3}>
                                                <div className="number-mob-non-selected">
                                                    2
                                                </div>
                                            </Col>
                                            <Col xs={3}>
                                                <div className="number-mob-non-selected">
                                                    3
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </>
                            )}
                        </Col>
                        <Col xl={14} md={14} lg={14} sm={24} xs={24}>
                            <Card className='travel-back-card-mob'>
                               
                                    <div className='travel-row-1 back-content'>
                                        {/* {current === 0 && (
                                           )}
                                        {current > 0 && (<div className='arrow-back-font' style={{ cursor: 'pointer' }} onClick={() => prev()}><ArrowLeftOutlined />{translate("onboarding")["Back"]}</div>
                                        )} */}
                                         <><div className='arrow-back-font' style={{ cursor: 'pointer' }} onClick={() => handleBack()}><ArrowLeftOutlined />{translate("onboarding")["Back"]}</div></>
                                        <div style={{ cursor: "pointer" }} onClick={sendEmail} className='need-content'>{translate("onboarding")["Need Help"]} ?</div>
                                    </div>
                                
                                <div className="steps-content">
                                    <OrderSummary   handleLoading={handleLoading}  next={next} prev={prev}  />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Spin>
            </div>
        </>
    )
}

export default OrderSummaryTravel