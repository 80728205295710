import { message } from "antd";
import { toast } from "react-toastify";
import axiosRequest from '../../axios-request/request.methods'

export  const sdk=(id,setfetchingtheHypervergeData)=>{
        let options = { secure: false, multipart: false }
        axiosRequest.get(`auth/hyperverge/product-token/${id}`,options)
            .then((res, error) => {
                    let token = res.errMsg.accessToken
                    let transactionId = res.errMsg.transactionId
                    let workFlow = res.errMsg.workflow
                    LaunchSDK(token, workFlow, transactionId)
            });
    
    const LaunchSDK = async (token, workFlow, transactionId) => {

        const hyperKycConfigs = new window.HyperKycConfig(token, workFlow, transactionId);
        window.HyperKYCModule.launch(hyperKycConfigs, ResultsHandler);



    }
    const ResultsHandler = (KYCResult) => {
       
       
        switch (KYCResult.status) {
            case "user_cancelled":
                message.error("user Cancelled")
                setfetchingtheHypervergeData(KYCResult)
               break;
            case "error":
                message.error("Failed Transaction")
                setfetchingtheHypervergeData(KYCResult)

                break;

            case "auto_approved":
                message.success("Approved")
                setfetchingtheHypervergeData(KYCResult)
                break;
                
            case "auto_declined":
                toast.success("Auto Declined", { position: "top-center", hideProgressBar: true, autoClose: 1000 })
                setfetchingtheHypervergeData(KYCResult)
                break;
            case "needs_review":
                // workflow success
                message.warning("needs_review")
                setfetchingtheHypervergeData(KYCResult)
                break;
                default: message.warning("Auto Declined")
        }
    }


}
