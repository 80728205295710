import React, { useEffect, useState } from 'react'
import { Card, Row, Col, Form, Image, Radio, Spin, Input, Button, Progress, DatePicker, Select, Modal } from 'antd'
import { ArrowLeftOutlined, ArrowRightOutlined, EnvironmentOutlined, RightOutlined, UploadOutlined } from '@ant-design/icons'
import { useHistory, useLocation } from "react-router-dom"
import { LoadingOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import Browse from "../../oonaImages/browse.png"
import moment from "moment";
//import rootIndex from "../../../store/root_index";
import rootIndex from '../../store/root_index'
import { Base_URL } from '../../config/BaseUrl';
import { ToastContainer, toast } from 'react-toastify';
import Feather from "../../oonaImages/Feather.png"
import MapContainer from './MapContainer';
import CorrMapsContainer from './CorrMapsContainer';
import { useLanguage } from "../../config/LanguageContext"
import * as action from '../../store/actions/index'
import { useDispatch, useSelector } from 'react-redux';
import axiosRequest from '../../axios-request/request.methods'
import CustomerHeader from '../../CustomerHeader/CustomerHeader';
import { error } from 'jquery';


const { store } = rootIndex;
const { TextArea } = Input;

function CustomerInformationHome(props) {
    const location = useLocation();
    const [form] = Form.useForm();
    const _store = store.getState();
    const fetchingIDfromStore=useSelector((state)=>state?.cipherlinkreducer?.quote?.quoteId)
    console.log(fetchingIDfromStore,"fetchingIDfromStore")
    const checkingResumeType=useSelector((state)=>state?.cipherlinkreducer?.quote?.resumeType);
    const [customerName, setcustomerName] = useState("")
    const [policyInceptionstartDate, setpolicyInceptionstartDate] = useState("")
    const [policyInceptionendDate, setpolicyInceptionendDate] = useState("")
    const [insuredPropoertyAddress, setinsuredPropoertyAddress] = useState("")
    const [customerprovince, setcustomerprovince] = useState("")
    const [imageUrl, setimageUrl] = useState("")
    const [customerCity, setcustomerCity] = useState("")
    const [customerDist, setcustomerDist] = useState("")
    const [customersubDist, setcustomersubDist] = useState("")
    const [customerpostalCode, setcustomerpostalCode] = useState("")
    const [loader, setLoader] = useState(false)
    const [checkingcorrespondenceAddress, setcheckingcorrespondenceAddress] = useState("no")
    const [insuredPhoneNUmber, setinsuredPhoneNUmber] = useState("")
    const [insuredEmailAddress, setinsuredEmailAddress] = useState("")
    const [homeProvinceResponse, sethomeProvinceResponse] = useState([])
    const [homecityResponse, sethomeCityResponse] = useState([])
    const [customerDistResponse, setcustomerDistResponse] = useState([])
    const [correspondencedistResponse, setCorrespondenceDistResponse] = useState([])
    const [customersubDistrictResponse, setcustomersubDistrictResponse] = useState("")
    const [customercorrespondencesubdistrictresponse, setcustomerCorrespondencesubDistrictResponse] = useState("")
    const [postalCodeSelected, setSelectedPostal] = useState("")
    const [selectedcorrespincode, setSelectedPostalCardCoress] = useState("")
    const [quoteDetails, setgetQuotedetails] = useState("")
    const [addressmodalPopup, setaddressmodalpopup] = useState(false)
    const [addresscorrespondencemodalPopup, setaddresscorrespondencemodalPopup] = useState(false)
    const [correspondenceInsuredPropertyAddress, setcorrespondenceInsuredPropertyAddress] = useState("")
    const [correspondencePronvine, setcorrespondencePronvine] = useState("")
    const [correspondenceCity, setcorrespondenceCity] = useState("")
    const [correspondenceDistrict, setcorrespondenceDistrict] = useState("")
    const [correspondencesubDistrict, setcorrespondencesubDistrict] = useState("")
    const [correspondencePostalCode, setcorrespondencePostalCode] = useState("")
    const [customerInformationquotedetails, setCustomerInformQuotedetails] = useState("")
    const [correspondenceInsuredPropertyAddressYes, setcorrespondenceInsuredPropertyAddressYes] = useState("")
    const [correspondencePronvineYes, setcorrespondencePronvineYes] = useState("")
    const [correspondenceCityYes, setcorrespondenceCityYes] = useState("")
    const [correspondenceDistrictYes, setcorrespondenceDistrictYes] = useState("")
    const [correspondencesubDistrictYes, setcorrespondencesubDistrictYes] = useState("")
    const [correspondencepostalCodeYes, setcorrespondencePostalCodeYes] = useState("")
    const dispatch = useDispatch()
    const [idcardNumber, setidcardNumber] = useState("")
    const [correspondenceProvinceresponse, sethomecorrespondenceProvinceResponse] = useState([])
    const [homeCorrespondenceCityResponse, sethomecorrespondenceCityResponse] = useState([])
    const [homeDistrictOptionsmaps, sethomeDistrictOptionsMaps] = useState([])
    const [homesubDistrictOptionsResponse, sethomesubDistrictOptionsmaps] = useState([])
    const [enabledistrict, setEnabledistrict] = useState(false)
    const [enablesubdistrict, setEnablesubDistrict] = useState(false)
    const [cords, setCords] = useState({ lat: 0, long: 0 })
    const [corrrespondencecords, setcorrescords] = useState({ lat: 0, long: 0 })
    const [findingDistrictarr, setFindingDistrictarr] = useState("")
    const [findingCorresdonceDistrict, setFindingCorrespondenceDistrictarr] = useState("")
    const [resultsHandlerobj, setResultsHandler] = useState({})
    const [shownhardCopyFields, setShownhardCopyFields] = useState(false)
    const [copy, setCopy] = useState("2")
    const [addressForHardCopy, setaddressForHardCopy] = useState("")
    const [settingduePremium, setduePremium] = useState("")

const[homeCorrespondencProvinceeOptions,sethomeCorrespondencProvinceeOptions]=useState([])
console.log(homeCorrespondencProvinceeOptions,"homeCorrespondencProvinceeOptions")
const[homeCorresCityOptions,sethomeCorresCityOptions]=useState([])
console.log(homeCorresCityOptions,"homeCorresCityOptions")
const[homecorrespondenceDistrictOptions,sethomecorrespondenceDistrictOptions]=useState([])
console.log(homecorrespondenceDistrictOptions,"homecorrespondenceDistrictOptions")
const[homecorrespondencesubDistrictOptions,sethomecorrespondencesubDistrictOptions]=useState([])
console.log(homecorrespondencesubDistrictOptions,"homecorrespondencesubDistrictOptions")

    const [InsuredDefaultEmail, setInsuredDefaultEmail] = useState("issuing@oona-insurance.co.id")
    console.log(shownhardCopyFields, "shownhardCopyFields")
    const searchString = "Opted"
    const custObJDetailsOpted = Object.keys(customerInformationquotedetails).length > 0 && Object.keys(customerInformationquotedetails).filter(key => key.endsWith(searchString))
    const customerbenefitsObj = custObJDetailsOpted !== false && custObJDetailsOpted
        .filter(key => customerInformationquotedetails.hasOwnProperty(key))
        .reduce((obj, key) => {
            obj[key] = customerInformationquotedetails[key];
            return obj;
        }, {});

    const customerbenefitsObjarray = customerbenefitsObj !== false && Object.keys(customerbenefitsObj).map(key => ({ key, value: customerbenefitsObj[key] }));
    const mappedcustomerbenefitsObjarraylength = customerbenefitsObjarray !== false && customerbenefitsObjarray.filter(obj => obj.value === "Y").map(obj => obj.value);
    const [imagedetails, setImageDetails] = useState("")
    const [getImage, setGetImage] = useState(false)
    const getQuoteId = quoteDetails?.data?._id
    const getleadId = quoteDetails?.data?.leadId?._id
    /*finding the district,city,subdistrict*/
    const [loading, setLoading] = useState(false);
    const [mapsaddress, setmapsAddress] = useState("")
    const [correspondencemapsaddress, setcorrespondencemapsaddress] = useState("")

    useEffect(() => {
        form.setFieldsValue({
            idCardNumber: resultsHandlerobj?.details?.idNumber
        })
        setidcardNumber(resultsHandlerobj?.details?.idNumber)

    }, [resultsHandlerobj])




    useEffect(() => {
        /*getquoteAPI*/
        var fromReferral = ""
        if (checkingResumeType === "referalJourney" || checkingResumeType === "quoteSearch") {
            fromReferral = `home/getQuote/${fetchingIDfromStore}?logkey=${checkingResumeType}`
        }
        else {
            fromReferral = `home/getQuote/${fetchingIDfromStore}`
        }

        axiosRequest
            .get(
                `${fromReferral}`,
            )
            .then((data) => {
                if (data?.errCode === -1) {
                    // setCopy(data?.errMsg?.data?.printOption)
                    setgetQuotedetails(data?.errMsg)
                    console.log(data?.errMsg, "bsjdu=jsgd==")
                    console.log(data?.errMsg?.data, "details====>")
                    console.log(data?.errMsg?.data?.ocrDetails, "Geethhha===>")
                    if (data?.errMsg?.data?.ocrDetails !== "No OCR details found") {
                        setidcardNumber(data?.errMsg?.data?.ocrDetails?.idNumber)
                        setGetImage(true)
                        const response = `${Base_URL}auth/user/getLinkUsingKey?key=${data?.errMsg?.data?.ocrDetails?.s3Url}`
                        setImageDetails(response)
                    }
                }
            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

            });

    }, [fetchingIDfromStore])

    const history = useHistory();
    const [width, setWidth] = useState(window.innerWidth);
    const breakpoint = 620;

    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);

        return () => window.removeEventListener("resize", handleWindowResize);
    }, [width]);

    useEffect(() => {
        form.setFieldsValue({
            customerName: customerInformationquotedetails?.proposerName,
            policyInceptionendDate: moment(policyInceptionstartDate, "DD/MM/YYYY").isValid() === true && moment(moment(policyInceptionstartDate, "DD/MM/YYYY").add(1, "years")),
            correspondenceInsuredPropertyAddressYes: insuredPropoertyAddress,
            correspondencePronvineYes: customerprovince,
            correspondenceCityYes: customerCity,
            correspondenceDistrictYes: customerDist,
            correspondencesubDistrictYes: customersubDist,
            correspondencepostalCodeYes: postalCodeSelected?.PostalCode,
            customerpostalCode: postalCodeSelected?.PostalCode,
            correspondencepostalCode: selectedcorrespincode?.PostalCode,
            customerprovince: customerInformationquotedetails?.addrProvince,
            customerCity: customerInformationquotedetails?.city
        })
        setcustomerName(customerInformationquotedetails?.proposerName)
        setpolicyInceptionendDate(moment(policyInceptionstartDate, "DD/MM/YYYY").isValid() === true && moment(moment(policyInceptionstartDate, "DD/MM/YYYY").add(1, "years")))

        setcorrespondenceInsuredPropertyAddressYes(insuredPropoertyAddress)
        setcorrespondencePronvineYes(customerprovince)
        setcorrespondenceCityYes(customerCity)
        setcorrespondenceDistrictYes(customerDist)
        setcorrespondencesubDistrictYes(customersubDist)
        setcorrespondencePostalCodeYes(customerpostalCode)
        setcustomerpostalCode(postalCodeSelected?.PostalCode)
        setcorrespondencePostalCode(selectedcorrespincode?.PostalCode)
        setcustomerprovince(customerInformationquotedetails?.addrProvince)
        setcustomerCity(customerInformationquotedetails?.city)


    }, [customerInformationquotedetails, selectedcorrespincode, policyInceptionstartDate, correspondencemapsaddress, insuredPropoertyAddress, customerprovince, customerCity, customerDist, customersubDist, postalCodeSelected])


    useEffect(() => {
        if (props?.location?.state?.data !== "homecustomer" || _store?.cipherlinkreducer?.quote?.quoteId !== ""
        ) {
            form.setFieldsValue({
                insuredPropoertyAddress: mapsaddress?.data,
            })
            setinsuredPropoertyAddress(mapsaddress?.data)
        }
    }, [mapsaddress])


    useEffect(() => {
        if (props?.location?.state?.data !== "homecustomer" || _store?.cipherlinkreducer?.quote?.quoteId !== "") {
            form.setFieldsValue({
                correspondenceInsuredPropertyAddress: correspondencemapsaddress?.data,
            })
            setcorrespondenceInsuredPropertyAddress(correspondencemapsaddress?.data)
        }
    }, [correspondencemapsaddress])

    useEffect(() => {
        const findingDistrict = homecityResponse?.length > 0 && homecityResponse?.find((item) => {
            return item?.Id === Number(customerCity)
        })
        setFindingDistrictarr(findingDistrict)
    }, [homecityResponse, customerCity, customerDist, customerDistResponse])

    useEffect(() => {
        const findingDistrict = homeCorrespondenceCityResponse?.length > 0 && homeCorrespondenceCityResponse?.find((item) => {
            return item?.Id === Number(correspondenceCity)
        })
        setFindingCorrespondenceDistrictarr(findingDistrict)

    }, [homeCorrespondenceCityResponse, correspondenceCity, correspondenceDistrict, correspondencedistResponse])

    useEffect(() => {

        /*getProvinces*/
        axiosRequest
            .get(
                `user/provinces`,
            )
            .then((data) => {
                if (data?.errCode === -1) {
                    sethomeProvinceResponse(data.errMsg)
                }
            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
            });

        if (homeProvinceResponse?.length > 0) {
            /*getCity*/
            axiosRequest
                .get(
                    `user/${customerprovince}/cities`,
                )
                .then((data) => {
                    sethomeCityResponse(data.errMsg)
                }).catch((error) => {
                    console.log(error, "bhjcbc")
                    console.log(error.response, "bjdgu")
                    console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
                });

        }


    }, [customerprovince])

    /*getCorrespondenceCityandProvinde*/

    useEffect(() => {

        /*getProvinces*/
        axiosRequest
            .get(
                `user/provinces`,
            )
            .then((data) => {
                if (data?.errCode === -1) {
                    sethomecorrespondenceProvinceResponse(data.errMsg)
                }

            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

            });
       
    }, [])


    useEffect(()=>{
        if (correspondenceProvinceresponse?.length > 0) {

            /*getCity*/
            axiosRequest
                .get(
                    `user/${correspondencePronvine}/cities`,
                )
                .then((data) => {
                    if (data?.errCode === -1) {
                        sethomecorrespondenceCityResponse(data.errMsg)
                    }
                }).catch((error) => {
                    console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
                });
        }

    },[correspondencePronvine])






    useEffect(() => {
        /*getDistricts*/
        axiosRequest
            .get(
                `user/${findingDistrictarr?.RegionalLevel}/districts?ProvinceId=${quoteDetails?.data?.addrProvince}`,
            )
            .then((data) => {
                if (data?.errCode === -1) {
                    setcustomerDistResponse(data?.errMsg)
                }

            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
            });


    }, [findingDistrictarr])


    useEffect(() => {
        /*getDistricts*/
        axiosRequest
            .get(
                `user/${findingCorresdonceDistrict?.RegionalLevel}/districts?ProvinceId=${correspondencePronvine}`,

            )
            .then((data) => {
                if (data?.errCode === -1) {
                    setCorrespondenceDistResponse(data?.errMsg)
                }

            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
            });


    }, [findingCorresdonceDistrict, correspondencePronvine])

    useEffect(() => {
        const findingpincodeEG = customersubDistrictResponse?.length > 0 && customersubDistrictResponse?.find((item) => item?.SubDistrict === customersubDist)
        setSelectedPostal(findingpincodeEG)
    }, [customersubDistrictResponse, customersubDist])


    useEffect(() => {
        const findingcorrespincodeEG = customercorrespondencesubdistrictresponse?.length > 0 && customercorrespondencesubdistrictresponse?.find((item) => item?.SubDistrict === correspondencesubDistrict)
        setSelectedPostalCardCoress(findingcorrespincodeEG)
    }, [customercorrespondencesubdistrictresponse, correspondencesubDistrict])

    useEffect(() => {
        /*getSubdistricts*/
        if (props?.location?.state?.data === "homecustomer" || _store?.cipherlinkreducer?.quote?.quoteId !== "") {
            axiosRequest
                .get(
                    `user/${customerDist}/sub-districts?ProvinceId=${quoteDetails?.data?.addrProvince}`,
                )
                .then((data) => {
                    if (data.errCode === -1) {
                        // console.log(data.data.errMsg, 'Cities');
                        setcustomersubDistrictResponse(data?.errMsg)
                    }
                }).catch((error) => {
                    console.log(error?.response, "bhjgdyu")
                    console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

                });
        }
    }, [customerDist])


    useEffect(() => {
        /*getSubdistricts*/
        if (props?.location?.state?.data === "homecustomer" || _store?.cipherlinkreducer?.quote?.quoteId !== "" || customerDistResponse.length > 0) {
            axiosRequest
                .get(
                    `user/${correspondenceDistrict}/sub-districts?ProvinceId=${correspondencePronvine}`,
                )
                .then((data) => {
                    if (data?.errCode === -1) {
                        // console.log(data.data.errMsg, 'Cities');
                        setcustomerCorrespondencesubDistrictResponse(data?.errMsg)
                    }
                }).catch((error) => {
                    console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
                });
        }
    }, [correspondenceDistrict, correspondencePronvine])

    useEffect(() => {
        var fromReferral = ""
        if (checkingResumeType === "referalJourney" || checkingResumeType === "quoteSearch") {
            fromReferral = `home/getQuote/${fetchingIDfromStore}?logkey=${checkingResumeType}`
        }
        else {
            fromReferral = `home/getQuote/${fetchingIDfromStore}`
        }

        const getOCRDetails = async () => {
            setLoader(true)
            if (props?.location?.state?.data === "homecustomer" || fetchingIDfromStore || _store?.cipherlinkreducer?.quote?.quoteId !== "") {
                await axiosRequest
                    .get(
                        `${fromReferral}`,
                    )
                    .then((data) => {
                        if (data?.errCode === -1) {
                            setLoader(false)
                            setCustomerInformQuotedetails(data?.errMsg?.data)
                        }
                    }).catch((error) => {
                        console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

                    });

            }
        }
        getOCRDetails()
    }, [])

    const homeProvinceOptions = homeProvinceResponse.length > 0 && homeProvinceResponse.sort(function (a, b) {
        var nameA = a.Description.toLowerCase(), nameB = b.Description.toLowerCase()
        if (nameA < nameB)
            return -1
        if (nameA > nameB)
            return 1
        return 0
    }).map((homep) => {
        return {
            label: homep.Description,
            value: homep.Id
        }
    })

    useEffect(()=>{
        const fetchingCorrespondencProvinceeOptions = correspondenceProvinceresponse?.length > 0 && correspondenceProvinceresponse.sort(function (a, b) {
            var nameA = a.Description.toLowerCase(), nameB = b.Description.toLowerCase()
            if (nameA < nameB)
                return -1
            if (nameA > nameB)
                return 1
            return 0
        }).map((homep) => {
            return {
                label: homep.Description,
                value: homep.Id
            }
        })
        sethomeCorrespondencProvinceeOptions(fetchingCorrespondencProvinceeOptions)

    },[correspondenceProvinceresponse])





    const homeCityOptions = (homecityResponse?.length > 0) && homecityResponse.sort(function (a, b) {
        var nameA = a.RegionalLevel.toLowerCase(), nameB = b.RegionalLevel.toLowerCase()
        if (nameA < nameB)
            return -1
        if (nameA > nameB)
            return 1
        return 0
    }).map((homep) => {
        return {
            label: homep.RegionalLevel,
            value: homep.Id.toString()
        }
    })

    useEffect(()=>{
        const fetchinghomeCorresCityOptions = homeCorrespondenceCityResponse?.length > 0 && homeCorrespondenceCityResponse.sort(function (a, b) {
            var nameA = a.RegionalLevel.toLowerCase(), nameB = b.RegionalLevel.toLowerCase()
            if (nameA < nameB)
                return -1
            if (nameA > nameB)
                return 1
            return 0
        }).map((homep) => {
            return {
                label: homep.RegionalLevel,
                value: homep.Id.toString()
            }
        })
        sethomeCorresCityOptions(fetchinghomeCorresCityOptions)

    },[homeCorrespondenceCityResponse])




   




    useEffect(() => {
        if (mapsaddress) {
            const mapsResponse = homeDistrictOptionsmaps?.find((item) => {
                return item.value === mapsaddress.district
            })

            if (mapsResponse?.hasOwnProperty("value")) {
                setEnabledistrict(true)
            }
            console.log(mapsResponse, "mapsResponse")
            form.setFieldsValue({
                customerDist: mapsResponse?.value
            })
            setcustomerDist(mapsResponse?.value)
        }
    }, [mapsaddress, homeDistrictOptionsmaps])

    useEffect(() => {
        const homeDistrictOptions = customerDistResponse?.length > 0 && customerDistResponse.sort(function (a, b) {
            var nameA = a.District.toLowerCase(), nameB = b.District.toLowerCase()
            if (nameA < nameB)
                return -1
            if (nameA > nameB)
                return 1
            return 0
        }).map((homep) => {
            return {
                label: homep.District,
                value: homep.District
            }
        })
        console.log(homeDistrictOptions, "homeDistrictOptions")

        sethomeDistrictOptionsMaps(homeDistrictOptions)
    }, [customerDistResponse])




useEffect(()=>{
    const correspondenceDistrictOptions = correspondencedistResponse?.length > 0 && correspondencedistResponse.sort(function (a, b) {
        var nameA = a.District.toLowerCase(), nameB = b.District.toLowerCase()
        if (nameA < nameB)
            return -1
        if (nameA > nameB)
            return 1
        return 0
    }).map((homep) => {
        return {
            label: homep.District,
            value: homep.District
        }
    })
    sethomecorrespondenceDistrictOptions(correspondenceDistrictOptions)

},[correspondencedistResponse])
  



    useEffect(() => {
        const homesubDistrictOptions = customersubDistrictResponse?.length > 0 && customersubDistrictResponse.sort(function (a, b) {
            var nameA = a.SubDistrict.toLowerCase(), nameB = b.SubDistrict.toLowerCase()
            if (nameA < nameB)
                return -1
            if (nameA > nameB)
                return 1
            return 0
        }).map((homep) => {
            return {
                label: homep.SubDistrict,
                value: homep.SubDistrict
            }
        })
        sethomesubDistrictOptionsmaps(homesubDistrictOptions)

    }, [customersubDistrictResponse])



    useEffect(() => {
        if (mapsaddress) {
            console.log(homesubDistrictOptionsResponse, "homesubDistrictOptionsResponsein USeEFFECT")
            const subdistrictaddressresponse = homesubDistrictOptionsResponse && homesubDistrictOptionsResponse?.find((item) => {
                return item.value === mapsaddress?.subDistrict
            })
            if (subdistrictaddressresponse?.hasOwnProperty("value")) {
                setEnablesubDistrict(true)
            }
            console.log(subdistrictaddressresponse, "subdistrictaddressresponse")
            form.setFieldsValue({
                customersubDist: subdistrictaddressresponse?.value
            })
            setcustomersubDist(subdistrictaddressresponse?.value)
        }

    }, [mapsaddress, homesubDistrictOptionsResponse])



useEffect(()=>{
    const correspondencesubDistrictOptions = customercorrespondencesubdistrictresponse?.length > 0 && customercorrespondencesubdistrictresponse.sort(function (a, b) {
        var nameA = a.SubDistrict.toLowerCase(), nameB = b.SubDistrict.toLowerCase()
        if (nameA < nameB)
            return -1
        if (nameA > nameB)
            return 1
        return 0
    }).map((homep) => {
        return {
            label: homep.SubDistrict,
            value: homep.SubDistrict
        }
    })
    sethomecorrespondencesubDistrictOptions(correspondencesubDistrictOptions)

},[customercorrespondencesubdistrictresponse])



useEffect(()=>{
    if(correspondencemapsaddress){
       const fetchingcorresProvince=homeCorrespondencProvinceeOptions?.find((item)=>{
        return item?.label === correspondencemapsaddress?.province
       }) 
       console.log(fetchingcorresProvince,"fetchingcorresProvince")

       form.setFieldsValue({
        correspondencePronvine:fetchingcorresProvince?.value
       })
       setcorrespondencePronvine(fetchingcorresProvince?.value)
    }

},[homeCorrespondencProvinceeOptions,correspondencemapsaddress])


useEffect(()=>{
    if(correspondencemapsaddress){
       const fetchingcorreCity=(homeCorresCityOptions && homeCorresCityOptions.length > 0) && homeCorresCityOptions?.find((item)=>{
        return (correspondencemapsaddress?.city.includes(item?.label) || item?.label?.includes(correspondencemapsaddress?.city))
       }) 
       console.log(fetchingcorreCity,"fetchingcorresCity===>")

       form.setFieldsValue({
        correspondenceCity:fetchingcorreCity?.value
       })
       setcorrespondenceCity(fetchingcorreCity?.value)
    }

},[correspondencemapsaddress,homeCorresCityOptions])



useEffect(()=>{
    if(correspondencemapsaddress){
       const fetchingcorredist=(homecorrespondenceDistrictOptions && homecorrespondenceDistrictOptions.length > 0) && homecorrespondenceDistrictOptions?.find((item)=>{
        return (correspondencemapsaddress?.district.includes(item?.label) || item?.label?.includes(correspondencemapsaddress?.district))
       }) 
       console.log(fetchingcorredist,"fetchingcorredist===>")

       form.setFieldsValue({
        correspondenceDistrict:fetchingcorredist?.value
       })
       setcorrespondenceDistrict(fetchingcorredist?.value)
    }

},[correspondencemapsaddress,homecorrespondenceDistrictOptions])


useEffect(()=>{
    if(correspondencemapsaddress){
       const fetchingcorresubdist=(homecorrespondencesubDistrictOptions && homecorrespondencesubDistrictOptions.length > 0) && homecorrespondencesubDistrictOptions?.find((item)=>{
        return (correspondencemapsaddress?.subDistrict.includes(item?.label) || item?.label?.includes(correspondencemapsaddress?.subDistrict))
       }) 
       console.log(fetchingcorresubdist,"fetchingcorresubdist===>")

       form.setFieldsValue({
        correspondencesubDistrict:fetchingcorresubdist?.value
       })
       setcorrespondencesubDistrict(fetchingcorresubdist?.value)
    }

},[correspondencemapsaddress,homecorrespondencesubDistrictOptions])






  

    const handlepolicyInceptionstartDate = (date, dateString) => {
        setpolicyInceptionstartDate(dateString)
    }
    const handlepolicyInceptionendDate = (date, dateString) => {
        setpolicyInceptionendDate(dateString)
    }

    const handleCustomerPage = () => {
        if (policyInceptionstartDate.format("YYYY-MM-DD") === "Invalid date") {
            toast.warning(`${translate("home")["Start Date is required"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
        }
        else if (copy === "1" && addressForHardCopy === "") {
            toast.warning("Enter address to send the Hard Copy", { position: "top-center", hideProgressBar: true, autoClose: 1000 })
        }

        else if (checkingcorrespondenceAddress === "no") {
            if (correspondencePronvine === "") {
                toast.warning("Correspondence Province is required", { position: "top-center", hideProgressBar: true, autoClose: 1000 })
            }
            else if (correspondenceCity === "") {
                toast.warning("Correspondence City is required", { position: "top-center", hideProgressBar: true, autoClose: 1000 })
            }
            else if (correspondenceDistrict === "") {
                toast.warning("Correspondence District is required", { position: "top-center", hideProgressBar: true, autoClose: 1000 })
            }
            else if (correspondencesubDistrict === "") {
                toast.warning("Correspondence subdistrict is required", { position: "top-center", hideProgressBar: true, autoClose: 1000 })
            }
            else if (correspondencePostalCode === "") {
                toast.warning("Correspondence POstalCode is required", { position: "top-center", hideProgressBar: true, autoClose: 1000 })
            }

            else {
                var axiosConfig = ""
                if (location?.state?.cipher != undefined || location?.state?.cipher != null) {
                    axiosConfig = {
                        headers: {
                            "Content-Type": "application/json",
                            'cipher': location?.state?.cipher
                        },
                    };
                } else {
                    axiosConfig = {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': 'Bearer ' + _store.login.token
                        },
                    };
                }

                let formdata = {
                    "email": insuredEmailAddress,
                    "primaryMobile": insuredPhoneNUmber,
                    "idCard": resultsHandlerobj?.details?.idNumber || quoteDetails?.data?.ocrDetails?.idNumber
                }


                const updateQuotePayload = {
                    "insuredName": customerName,
                    "homeEffectiveDate": moment(policyInceptionstartDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
                    "homeExpiryDate": moment(policyInceptionendDate, "YYYY-MM-DD").format("YYYY-MM-DD"),
                    "insuredMobile": insuredPhoneNUmber,
                    "insuredEmail": insuredEmailAddress,
                    "district": customerDist,
                    "subDistrict": customersubDist,
                    "postalCode": customerpostalCode,
                    "address": insuredPropoertyAddress,
                    "insuredPlaceOfBirth": resultsHandlerobj?.details?.address,
                    "insuredDob": "",
                    "insuredIdCard": resultsHandlerobj?.details?.idNumber || quoteDetails?.data?.ocrDetails?.idNumber,
                    "insuredPassportNo": "",
                    "correspondenceAddress": correspondenceInsuredPropertyAddress || correspondenceInsuredPropertyAddressYes,
                    "correspondenceProvince": correspondencePronvine || correspondencePronvineYes,
                    "correspondenceCity": correspondenceCity || correspondenceCityYes,
                    "correspondenceDistrict": correspondenceDistrict || correspondenceDistrictYes,
                    "correspondenceSubDistrict": correspondencesubDistrict || correspondencesubDistrictYes,
                    "correspondencePostalCode": correspondencePostalCode || customerpostalCode,
                    "addrProvince": customerprovince,
                    "city": customerCity,
                    "isCorrespondenceChecked": checkingcorrespondenceAddress === "no" ? false : true,
                    "addressForHardCopy": addressForHardCopy !== "" && addressForHardCopy
                }

                axiosRequest
                    .patch(
                        `home/updatelead/${getleadId}`,
                        formdata,

                    )
                    .then((data) => {
                        if (data?.errCode === -1) {
                            toast.success(`${translate("home")["lead updated successfully"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
                            const payload = {
                                quoteId: getQuoteId,
                                 printOption: copy === "1" ? "hard" : "soft"
                           }
                            axiosRequest
                                .patch(
                                    `user/update-printpolicy`,
                                    payload
                                )
                                .then((data) => {

                                    if (data?.errCode === -1) {

                                        axiosRequest
                                            .patch(
                                                `home/updateQuote/${getQuoteId}`,
                                                updateQuotePayload,

                                            )
                                            .then((data) => {
                                                if (data.errCode === -1) {
                                                    toast.success(`${translate("home")["Quote updated SuccessFully"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
                                                    // let Data = {
                                                    //     quoteId: data?.errMsg?.data?.quoteDetails?._id,
                                                    //     isHome: true
                                                    // }
                                                    // dispatch(action.set_quote_id(Data))
                                                    history.push(
                                                        {
                                                            pathname: "/customerconfirmationhome",
                                                            // state: {
                                                            //     data: location?.state?.data,
                                                            //     quoteId: props?.history?.location?.state?.quoteId,
                                                            //     provinces: homeProvinceResponse,
                                                            //     city: homecityResponse,
                                                            //     dist: customerDistResponse,
                                                            //     subdist: customersubDistrictResponse,
                                                            //     //cipher: location?.state?.cipher != undefined || location?.state?.cipher != null ? location?.state?.cipher : null,
                                                            // },
            
                                                        }
                                                    )

                                                }
                                            }).catch((error) => {
                                                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
                                                console.log(error.response, "error")
                                            })


                                      

                                    }
                                })
                        }
                    }).catch((error) => {
                        console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
                        toast.error(error?.response?.data?.errMsg?.errors[0]?.msg, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
                    })

            }
        }
        else {

            let formdata = {
                "email": insuredEmailAddress,
                "primaryMobile": insuredPhoneNUmber,
                "idCard": resultsHandlerobj?.details?.idNumber || quoteDetails?.data?.ocrDetails?.idNumber
            }


            const updateQuotePayload = {
                "insuredName": customerName,
                "homeEffectiveDate": moment(policyInceptionstartDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
                "homeExpiryDate": moment(policyInceptionendDate, "YYYY-MM-DD").format("YYYY-MM-DD"),
                "insuredMobile": insuredPhoneNUmber,
                "insuredEmail": insuredEmailAddress,
                "district": customerDist,
                "subDistrict": customersubDist,
                "postalCode": customerpostalCode,
                "address": insuredPropoertyAddress,
                "insuredPlaceOfBirth": resultsHandlerobj?.details?.address,
                "insuredDob": "",
                "insuredIdCard": resultsHandlerobj?.details?.idNumber || quoteDetails?.data?.ocrDetails?.idNumber,
                "insuredPassportNo": "",
                "correspondenceAddress": correspondenceInsuredPropertyAddress || correspondenceInsuredPropertyAddressYes,
                "correspondenceProvince": correspondencePronvine || correspondencePronvineYes,
                "correspondenceCity": correspondenceCity || correspondenceCityYes,
                "correspondenceDistrict": correspondenceDistrict || correspondenceDistrictYes,
                "correspondenceSubDistrict": correspondencesubDistrict || correspondencesubDistrictYes,
                "correspondencePostalCode": correspondencePostalCode || customerpostalCode,
                "addrProvince": customerprovince,
                "city": customerCity,
                "isCorrespondenceChecked": checkingcorrespondenceAddress === "no" ? false : true,
                "addressForHardCopy": addressForHardCopy
            }

            axiosRequest
                .patch(
                    `home/updatelead/${getleadId}`,
                    formdata,
                )
                .then((data) => {
                    if (data?.errCode === -1) {
                        toast.success(`${translate("home")["lead updated successfully"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })

                        const payload = {
                            quoteId: getQuoteId,
                            printOption: copy === "1" ? "hard" : "soft"
                        }

                        axiosRequest
                            .patch(
                                `user/update-printpolicy`,
                                payload
                            )
                            .then((data) => {
                                if (data?.errCode === -1) {


                                    axiosRequest
                                        .patch(
                                            `home/updateQuote/${getQuoteId}`,
                                            updateQuotePayload,
                                        )
                                        .then((data) => {
                                            if (data?.errCode === -1) {
                                                toast.success(`${translate("home")["Quote updated SuccessFully"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
                                                // let Data = {
                                                //     quoteId: data?.errMsg?.data?.quoteDetails?._id,
                                                //     isHome: true
                                                // }
                                                // dispatch(action.set_quote_id(Data))
                                                history.push(
                                                    {
                                                        pathname: "/customerconfirmationhome",
                                                        // state: {
                                                        //     data: location?.state?.data,
                                                        //     quoteId: props?.history?.location?.state?.quoteId,
                                                        //     provinces: homeProvinceResponse,
                                                        //     city: homecityResponse,
                                                        //     dist: customerDistResponse,
                                                        //     subdist: customersubDistrictResponse,
            
                                                        // },
            
                                                    }
                                                )
                                            }
                                        }).catch((error) => {
                                            console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
                                            console.log(error.response, "error")
                                        })

                                 

                                }

                            })




                    }
                }).catch((error) => {
                    console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

                    toast.error(error?.response?.data?.errMsg?.errors[0]?.msg, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
                })

        }

    }

    useEffect(() => {

        if (customerInformationquotedetails?.stage === "Customer Information Updated") {
            const calculatingDuePremium = customerInformationquotedetails?.grossPremium + customerInformationquotedetails?.adminCost - customerInformationquotedetails?.discountAmount
            setduePremium(calculatingDuePremium)

        }
        else {
            setduePremium(customerInformationquotedetails?.duePremium)
        }


    }, [customerInformationquotedetails])


    useEffect(() => {
        if (resultsHandlerobj?.details?.idNumber !== undefined) {
            setLoading(true)
            setTimeout(gettingOCRDetails, 5000);

        }

    }, [resultsHandlerobj])

    useEffect(() => {
        if (resultsHandlerobj?.details?.idNumber !== undefined) {
            setTimeout(displayingIdCardnumber, 5000);
        }

    }, [resultsHandlerobj])



    const displayingIdCardnumber = () => {
        form.setFieldsValue({
            idcardNumber: resultsHandlerobj?.details?.idNumber
        })
        setidcardNumber(resultsHandlerobj?.details?.idNumber)
    }


    const gettingOCRDetails = () => {
        let options = { secure: false, multipart: false }
        axiosRequest
            .get(
                `auth/hyperverge/ocr-details?transactionId=${resultsHandlerobj?.transactionId}`,
                options
            )
            .then((data) => {
                if (data?.errCode === -1) {
                    setLoading(false)
                    setidcardNumber(data?.errMsg?.details?.ocrDetails?.idNumber)
                    setGetImage(true)
                    setLoading(false)
                    // console.log(data?.errMsg,"Geetha===>")
                    // console.log(data?.errMsg?.data,"bjsbjh5%%%==>")
                    // console.log(data?.errMsg?.data?.ocrDetails?.s3Url,"chjfguyd===>")
                    const response = `${Base_URL}auth/user/getLinkUsingKey?key=${data?.errMsg?.details?.ocrDetails?.s3Url}`

                    setImageDetails(response)
                }
            }).catch((error) => {
                toast.error("Verification service is not responding as expected. Please try after sometime")
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
                setLoading(true)
            });


    }

    const { translate ,language} = useLanguage()

    const onBoardHandler = async () => {
        let options = { secure: false, multipart: false }
        axiosRequest.get(`auth/hyperverge/product-token/${fetchingIDfromStore}`, options)
            .then((res, error) => {
                let token = res.errMsg.accessToken
                let transactionId = res.errMsg.transactionId
                let workFlow = res.errMsg.workflow
                LaunchSDK(token, workFlow, transactionId)
            });
    };
    const LaunchSDK = async (token, workFlow, transactionId) => {

        const hyperKycConfigs = new window.HyperKycConfig(token, workFlow, transactionId);
        window.HyperKYCModule.launch(hyperKycConfigs, ResultsHandler);



    }
    const ResultsHandler = (KYCResult) => {

        setResultsHandler(KYCResult)
        switch (KYCResult.status) {
            case "user_cancelled":
                message.error("user Cancelled")

                break;
            case "error":
                message.error("Failed Transection")

                break;
            case "auto_approved":
                message.success("Approved")

                break;
            case "auto_declined":
                toast.success(`${translate("home")["Auto Declined"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })

                break;
            case "needs_review":
                // workflow success
                message.warning("needs_review")

                break;
        }
    }
    const handleAddressPropertysearch = () => {
        setaddressmodalpopup(true)

    }

    const handleOk = () => {
        setaddressmodalpopup(false)
    };
    const handleCancel = () => {
        setaddressmodalpopup(false)
    };



    const handlecorrespondenceAddressPropertysearch = () => {
        setaddresscorrespondencemodalPopup(true)

    }

    const handlecorrespondenceOk = () => {
        setaddresscorrespondencemodalPopup(false)
    };
    const handlecorrespondenceCancel = () => {
        setaddresscorrespondencemodalPopup(false)
    };


    const handleInsuredAddress = (data) => {
        console.log(data, "data in MAps")
        var latitudemaps = ""
        var longitudemaps = ""
        navigator.geolocation.getCurrentPosition((position) => {
            latitudemaps = position.coords.latitude
            longitudemaps = position.coords.longitude
            setCords({
                lat: position.coords.latitude, long: position.coords.longitude

            })

        });



        const mapspayload = {
            "latitude": data?.lat || latitudemaps,
            "longitude": data?.long || longitudemaps
        }
        axiosRequest
            .post(
                `home/get-address?city=${customerInformationquotedetails?.city}&province=${customerInformationquotedetails?.addrProvince}&langId=${language === "en" ? "EN" : "BH"}`,
                mapspayload,
            )
            .then((data) => {
                if (data?.errCode === -1) {
                    setmapsAddress(data?.errMsg)
                }
            }).catch((error) => {
                // console.log(error?.response?.data?.errMsg?.error, "messagwErytf")
                toast.error(`${translate("home")["Invalid Address / Address not found"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

            });

    }

    const handleCorrespondenceAddress = (data) => {
        var latitudemaps = ""
        var longitudemaps = ""

        navigator.geolocation.getCurrentPosition((position) => {
            latitudemaps = position.coords.latitude
            longitudemaps = position.coords.longitude
            setcorrescords({
                lat: position.coords.latitude, long: position.coords.longitude

            })

        });

        const mapspayload = {
            "latitude": data?.lat || latitudemaps,
            "longitude": data?.long || longitudemaps
        }
        axiosRequest
            .post(
                `home/get-address?isCorrespondAddress=true`,
                mapspayload,
            )
            .then((data) => {
                if (data?.errCode === -1) {
                    //console.log(data.data.errMsg, 'Province');
                    setcorrespondencemapsaddress(data?.errMsg)
                }

            }).catch((error) => {
                console.log(error?.response?.data?.errMsg?.error, "messagwErytf")
                toast.error(`${translate("home")["Invalid Address / Address not found"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

            });

    }


    const sendEmail = () => {
        window.open("mailto:noreply@oona-insurance.co.id?subject=SendMail&body=Description");
    };

    useEffect(() => {

        if (props?.location?.state?.data === "homecustomer" || _store?.cipherlinkreducer?.quote?.quoteId !== "") {



            form.setFieldsValue({
                customerName: quoteDetails?.data?.proposerName,
                // policyInceptionstartDate: moment(quoteDetails?.data?.homeEffectiveDate, "YYYY-MM-DD"),
                policyInceptionstartDate: moment(quoteDetails?.data?.homeEffectiveDate, "YYYY-MM-DD").isValid() === true && moment(moment(quoteDetails?.data?.homeEffectiveDate, "YYYY-MM-DD")),
                policyInceptionendDate: moment(quoteDetails?.data?.homeExpiryDate, "YYYY-MM-DD"),
                insuredPropoertyAddress: quoteDetails?.data?.address,
                customerprovince: quoteDetails?.data?.addrProvince,
                customerCity: quoteDetails?.data?.city,
                customerDist: quoteDetails?.data?.district,
                customersubDist: quoteDetails?.data?.subDistrict,
                customerpostalCode: quoteDetails?.data?.postalCode,
                insuredPhoneNUmber: quoteDetails?.data?.insuredMobile,
                insuredEmailAddress: quoteDetails?.data?.insuredEmail,
                idCardNumber: quoteDetails?.data?.ocrDetails?.idNumber,
                checkingcorrespondenceAddress: quoteDetails?.data?.isCorrespondenceChecked === true ? "yes" : "no",
                copy: quoteDetails?.data?.printOption,
            })
            setidcardNumber(quoteDetails?.data?.ocrDetails?.idNumber)
            setcustomerName(quoteDetails?.data?.proposerName)
            setpolicyInceptionstartDate(moment(quoteDetails?.data?.homeEffectiveDate, "YYYY-MM-DD"))
            setpolicyInceptionendDate(moment(quoteDetails?.data?.homeExpiryDate, "YYYY-MM-DD"))
            setinsuredPropoertyAddress(quoteDetails?.data?.address)
            setcustomerprovince(quoteDetails?.data?.addrProvince)
            setcustomerCity(quoteDetails?.data?.city)
            setcustomerDist(quoteDetails?.data?.district)
            setcustomersubDist(quoteDetails?.data?.subDistrict)
            setcustomerpostalCode(quoteDetails?.data?.postalCode)
            setinsuredPhoneNUmber(quoteDetails?.data?.insuredMobile)
            setinsuredEmailAddress(quoteDetails?.data?.insuredEmail)
            setcheckingcorrespondenceAddress(quoteDetails?.data?.isCorrespondenceChecked === true ? "yes" : "no")
            setCopy(quoteDetails?.data?.printOption)

            if (quoteDetails?.data?.printOption === "1") {
                form.setFieldsValue({
                    addressForHardCopy: quoteDetails?.data?.addressForHardCopy
                })

                setaddressForHardCopy(quoteDetails?.data?.addressForHardCopy)
                setShownhardCopyFields(true)
            }




            if (quoteDetails?.data?.isCorrespondenceChecked === true) {


                form.setFieldsValue({
                    correspondenceInsuredPropertyAddressYes: quoteDetails?.data?.correspondenceAddress,
                    correspondencePronvineYes: quoteDetails?.data?.correspondenceProvince,
                    correspondenceCityYes: quoteDetails?.data?.correspondenceCity,
                    correspondenceDistrictYes: quoteDetails?.data?.correspondenceDistrict,
                    correspondencesubDistrictYes: quoteDetails?.data?.correspondenceSubDistrict,
                    correspondencepostalCodeYes: quoteDetails?.data?.correspondencePostalCode,
                })

                setcorrespondenceInsuredPropertyAddressYes(quoteDetails?.data?.correspondenceAddress)
                setcorrespondencePronvineYes(quoteDetails?.data?.correspondenceProvince)
                setcorrespondenceCityYes(quoteDetails?.data?.correspondenceCity)
                setcorrespondenceDistrictYes(quoteDetails?.data?.correspondenceDistrict)
                setcorrespondencesubDistrictYes(quoteDetails?.data?.correspondenceSubDistrict)
                setcorrespondencePostalCodeYes(quoteDetails?.data?.correspondencePostalCode)

            }


            if (quoteDetails?.data?.isCorrespondenceChecked === false && quoteDetails?.data?.isCorrespondenceChecked !== undefined) {


                form.setFieldsValue({
                    correspondenceInsuredPropertyAddress: quoteDetails?.data?.correspondenceAddress,
                    correspondencePronvine: quoteDetails?.data?.correspondenceProvince,
                    correspondenceCity: quoteDetails?.data?.correspondenceDistrict,
                    correspondenceDistrict: quoteDetails?.data?.correspondenceDistrict,
                    correspondencesubDistrict: quoteDetails?.data?.correspondenceSubDistrict,
                    correspondencepostalCode: quoteDetails?.data?.correspondencePostalCode,
                })

                setcorrespondenceInsuredPropertyAddress(quoteDetails?.data?.correspondenceAddress)
                setcorrespondencePronvine(quoteDetails?.data?.correspondenceProvince)
                setcorrespondenceCity(quoteDetails?.data?.correspondenceCity)
                setcorrespondenceDistrict(quoteDetails?.data?.correspondenceDistrict)
                setcorrespondencesubDistrict(quoteDetails?.data?.correspondenceSubDistrict)
                setcorrespondencePostalCode(quoteDetails?.data?.correspondencePostalCode)

            }
        }
    }, [quoteDetails])

    const searchHomeProvince = () => {

        axiosRequest
            .get(
                `user/provinces`,
            )
            .then((data) => {
                if (data?.errCode === -1) {
                    sethomeProvinceResponse(data?.errMsg)
                }
            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

            });

    }

    const handleCustomerProvince = (value) => {
        setcustomerprovince(value)
        setcustomerCity("")
        setcustomerDist("")
        setcustomersubDist("")
        setcustomerpostalCode("")

    }

    const searchHomeCity = () => {


        axiosRequest
            .get(
                `user/${customerprovince}/cities`,
            )
            .then((data) => {

                if (data?.errCode === -1) {

                    sethomeCityResponse(data?.errMsg)
                }

            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

            });

    }


    const searchHomeDistrict = () => {



        /*getDistricts*/
        axiosRequest
            .get(
                `user/${findingDistrictarr?.RegionalLevel}/districts?ProvinceId=${quoteDetails?.data?.addrProvince}`,

            )
            .then((data) => {
                if (data?.errCode === -1) {
                    setcustomerDistResponse(data?.errMsg)
                }
            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
            });


    }


    const searchHomeSubDistrict = () => {
        axiosRequest
            .get(
                `user/${customerDist}/sub-districts?ProvinceId=${quoteDetails?.data?.addrProvince}`,

            )
            .then((data) => {
                if (data?.errCode === -1) {
                    setcustomersubDistrictResponse(data?.errMsg)
                }

            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

            });

    }


    const searchCorrespondenceHomeProvince = () => {

        axiosRequest
            .get(
                `user/provinces`,
            )
            .then((data) => {
                if (data?.errCode === -1) {
                    sethomecorrespondenceProvinceResponse(data?.errMsg)
                }
            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
            });

    }

    const searchCorrespondenceHomeCity = () => {
        axiosRequest
            .get(
                `user/${customerprovince}/cities`,
            )
            .then((data) => {
                if (data?.errCode === -1) {
                    sethomecorrespondenceCityResponse(data?.errMsg)
                }

            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

            });

    }

    const searchHomeCorrespondenceDistrict = () => {



        /*getDistricts*/
        axiosRequest
            .get(
                `user/${findingDistrictarr?.RegionalLevel}/districts?ProvinceId=${quoteDetails?.data?.addrProvince}`,

            )
            .then((data) => {
                if (data?.errCode === -1) {
                    setCorrespondenceDistResponse(data?.errMsg)
                }

            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
            });


    }

    const searchCorrespondenceHomeSubDistrict = () => {




        axiosRequest
            .get(
                `user/${customerDist}/sub-districts?ProvinceId=${quoteDetails?.data?.addrProvince}`,

            )
            .then((data) => {
                if (data?.errCode === -1) {
                    // console.log(data.data.errMsg, 'Cities');
                    setcustomerCorrespondencesubDistrictResponse(data?.errMsg)
                }
            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

            });

    }

    const onChangeCopy = (e) => {
        if (e.target.value === "1") {
            setShownhardCopyFields(true)
            setCopy(e.target.value)
        }
        else {
            setShownhardCopyFields(false)
            setCopy(e.target.value)
        }
       
    }


    const toCurrencyConvertor = (value) => {
        const currencyVal = new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 0,
        }).format(value)

        return currencyVal
    }

    return (
        <>
            <Spin spinning={loader} size='large' >
                <CustomerHeader />
                <div className="row-travel">
                    <Row>
                        <Col xl={8} md={8} lg={8} sm={24} xs={24}>
                            {width > breakpoint ? (
                                <>
                                    <h5 className="heading-content">{translate("motor")["Great just need a few more details"]}</h5>
                                    <Card className="card-progress">
                                        <Row>
                                            <Col md={3}>
                                                <Progress className="progress-content"
                                                    percent={60} showInfo={false}
                                                />
                                            </Col>
                                            <Col className="col-content" md={10}>
                                                <div className='thick-font'>{translate("travel")["Create Quote"]}</div>
                                                <div className='thick-font'>{translate("travel")["Customer Details"]}</div>
                                                <div className='thick-font'>{translate("travel")["Confirmation"]}</div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </>
                            ) : (
                                <>
                                    <Card className="card-mobile">
                                        <div className="justify-space">
                                            <div className="mob-header">
                                                {translate("travel")["Just need your confirmation now"]}
                                            </div>
                                            <div style={{ cursor: "pointer" }} onClick={sendEmail} className='need-content'>
                                                {translate("onboarding")["Need Help"]} ?
                                            </div>
                                        </div>
                                    </Card>
                                    <Card className="card-mobile">
                                        <Row>
                                            <Col xs={3}>
                                                <div className="number-mob-selected">
                                                    2
                                                </div>
                                            </Col>
                                            <Col xs={14}>
                                                <div className='thick-font'>
                                                    {translate("travel")["Customer Information"]}
                                                </div>
                                            </Col>
                                            <Col xs={3}>
                                                <div className="number-mob-non-selected">
                                                    1
                                                </div>
                                            </Col>
                                            <Col xs={3}>
                                                <div className="number-mob-non-selected">
                                                    3
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </>
                            )}
                        </Col>
                        <Col xl={15} md={15} lg={15} sm={24} xs={24}>
                            <Form layout="vertical" form={form} onFinish={handleCustomerPage} className='home__policy'>
                                <Card>
                                    <div className='travel-row-1 back-content '>
                                        <div className='arrow-back-font' onClick={() => history.push({
                                            pathname: "/home-order-summary",
                                            // state: {
                                            //     data: location?.state?.data,
                                            //     quouteId: props?.location?.state?.quoteId,
                                            //     num: props?.location?.state?.num,
                                            //     promo: props?.location?.state?.promo,
                                            //     cipher: props?.location?.state?.cipher
                                            // }
                                        })}><ArrowLeftOutlined />{translate("onboarding")["Back"]}</div>
                                        <div style={{ cursor: "pointer" }} onClick={sendEmail} className='need-content'>{translate("onboarding")["Need Help"]} ?</div>
                                    </div>
                                    <h3 className='header-card-content'>
                                        {translate("travel")["Customer Information"]}
                                    </h3>
                                    <b>
                                        {translate("travel")["Please fill in your details to proceed."]}
                                    </b>

                                    <Card className='add-benefit-card  back-img-1 '>
                                        <Row style={{ alignItems: "center" }}>
                                            <Col md={18} xl={18} xs={18} sm={18} lg={18}>
                                                <div>
                                                    <div>Subtotal</div>
                                                    <div style={{ fontSize: 23 }}><b className='thick-font'>
                                                        {toCurrencyConvertor(Math.floor(customerInformationquotedetails?.grossPremium) - (Number(customerInformationquotedetails?.discountAmount)))}
                                                    </b></div>
                                                    <div>{customerInformationquotedetails?.typeofProduct} + {mappedcustomerbenefitsObjarraylength?.length + `${translate('travel')["Add Ons"]}`} </div>
                                                </div>
                                            </Col>
                                            <Col md={6} xl={6} sm={6} lg={6} xs={6}>
                                                <div style={{ alignItems: "center", display: "flex", flexDirection: "row", gap: 5, inlineSize: "max-content" }}>
                                                    {/* <div>view detail</div>
                                            <RightOutlined /> */}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                    <div className='justify-space'>
                                        <div className='thick-font'><b>{translate("motor")["Personal Information"]}</b></div>
                                    </div>

                                    <Col xl={24} xs={24} sm={24} lg={24} md={24}>
                                        <Form.Item
                                            name="customerName"
                                            label={translate("travel")["Insured Name"]}
                                            rules={[
                                                // {
                                                //     required: true,
                                                //     message: 'Please select Name',
                                                // },

                                                {
                                                    message: `${translate("home")["Only Alphabets are Allowed"]}`,
                                                    pattern: new RegExp(/^[a-zA-Z ]+$/)
                                                },
                                            ]}
                                        >
                                            <Input placeholder={translate("travel")["Enter Insured Name"]} disabled value={customerName} onChange={(e) => setcustomerName(e.target.value)}></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col xl={24} xs={24} sm={24} lg={24} md={24}>
                                        <div className='thick-font'>
                                            <b>{translate("motor")["ID Card (Optional)"]}</b>
                                        </div>
                                        <Form.Item
                                            label=""
                                            name="IdCard"
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         message: 'ID card is required',
                                        //     },
                                        // ]}
                                        >



                                            {

                                                getImage ? (<><Card className='avatar-uploader__customerInformation'>
                                                    <div>
                                                        <>
                                                            <img src={imagedetails}
                                                                alt="avatar"
                                                                object-fit="contain"
                                                                crossOrigin="anonymous"
                                                                style={{
                                                                    width: '100%',
                                                                    height: "15vh",
                                                                    textAlign: "center",
                                                                    objectFit: "cover",
                                                                }}


                                                            />


                                                            {/* <img 
                                                    src="https://oonanode-id-dev.salesdrive.app/sdx-api/auth/user/getLinkUsingKey?key=1698753721400-cropped.jpeg"
                                                     alt="Image Description"
                                                     crossorigin="anonymous"
                                                     /> */}
                                                        </>
                                                    </div>

                                                </Card>
                                                    <button onClick={() => onBoardHandler()} style={{ color: "#482C77", display: "flex", width: "100%", justifyContent: "Center", outline: "none", alignContent: "center", border: "none", backgroundColor: "white" }}>{translate("home")["ReUpload"]}</button>
                                                    <Col xl={24} xs={24} sm={24} lg={24} md={24}>
                                                        {/* <Form.Item
                                    name="idCardNumber"
                                    label={translate("home")["iD card Number"]}
                                    rules={[
                                        // {
                                        //     message: "Only Alphabets are Allowed",
                                        //     pattern: new RegExp(/^[a-zA-Z ]+$/)
                                        // }
                                    ]}
                                >
                                    <Input value={idcardNumber} onChange={(e) => setidcardNumber(e.target.value)}></Input>
                                </Form.Item> */}
                                                    </Col>


                                                </>) : (<><Card className='avatar-uploader__customerInformation' onClick={() => onBoardHandler()} >
                                                    <Spin tip="Loading" spinning={loading} >
                                                        <div
                                                            src={imageUrl}
                                                            alt="avatar"
                                                            style={{
                                                                width: '100%',
                                                                textAlign: "center"
                                                            }}
                                                        ><UploadOutlined /></div></Spin>



                                                </Card>
                                                    <button onClick={() => onBoardHandler()} style={{ color: "#482C77", display: "flex", width: "100%", outline: "none", justifyContent: "Center", alignContent: "center", border: "none", backgroundColor: "white" }}>{translate("home")["upload"]}</button>


                                                </>)


                                            }


                                        </Form.Item>

                                    </Col>
                                    <Col xl={24} xs={24} sm={24} lg={24} md={24} >
                                        <Form.Item

                                            label={translate("home")["Maximum file size 2Mb (PNG or JPG files only)"]}>
                                        </Form.Item>
                                    </Col>

                                    <Col xl={24} xs={24} sm={24} lg={24} md={24} >
                                        <Form.Item
                                            name="idCardNumber"
                                            label={translate("home")["iD card Number"]}
                                            rules={[
                                                // {
                                                //     message: "Only Alphabets are Allowed",
                                                //     pattern: new RegExp(/^[a-zA-Z ]+$/)
                                                // }
                                            ]}
                                        >
                                            <Input value={idcardNumber} onChange={(e) => setidcardNumber(e.target.value)}></Input>
                                        </Form.Item>
                                    </Col>
                                </Card>
                                <Card>
                                    <Row>
                                        <Col xl={24} xs={24} sm={24} lg={24} md={24}>
                                            <div style={{ padding: "12px 0px" }} className='thick-font'>{translate("home")["Insured Property Address"]}</div>
                                        </Col>
                                        <Col xl={12} xs={24} sm={24} lg={12} md={12}>
                                            <Form.Item
                                                label={translate("motor")["Policy Inception"]}
                                                name="policyInceptionstartDate"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: `${translate("home")["Start Date is required"]}`,
                                                    },


                                                ]}
                                            >
                                                <DatePicker disabledDate={(current) => current.isBefore(moment().subtract(1, "day"))} placeholder={translate("home")["Start Dateplacehoolder"]} style={{ width: "98%" }} format="DD/MM/YYYY" value={policyInceptionstartDate} onChange={handlepolicyInceptionstartDate} />
                                            </Form.Item>
                                        </Col>

                                        <Col xl={12} xs={24} sm={24} lg={12} md={12}>
                                            <Form.Item
                                                label={<></>}
                                                name="policyInceptionendDate"

                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'End Date is required',
                                                    },
                                                ]}
                                            >
                                                <DatePicker disabled disabledDate={(current) => current.isBefore(moment().subtract(1, "day"))} placeholder={translate("home")["End Dateplacehoolder"]} format="DD/MM/YYYY" value={policyInceptionendDate} onChange={handlepolicyInceptionendDate} />
                                            </Form.Item>
                                        </Col>

                                        <Col xl={24} xs={24} sm={24} lg={24} md={24}>
                                            <Form.Item
                                                label={translate("motor")["Insured Property Address"]}
                                                name="insuredPropoertyAddress"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Insured Property Address is required',
                                                    },


                                                ]}
                                            >
                                                <Input suffix={<div><Image src={Feather} preview={false} onClick={handleAddressPropertysearch} /></div>} value={insuredPropoertyAddress} onChange={(e) => setinsuredPropoertyAddress(e.target.value)} placeholder={translate("motor")["Enter Insured Property Address"]}></Input>
                                            </Form.Item>
                                        </Col>
                                        <Col xl={12} xs={24} sm={24} lg={12} md={12}>
                                            <Form.Item
                                                label={translate("home")["Province"]}
                                                name="customerprovince"

                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Province is required',
                                                    },


                                                ]}
                                            ><Select disabled value={customerprovince}
                                                // onChange={(e) => setcustomerprovince(e)}
                                                onChange={handleCustomerProvince}
                                                placeholder={translate("home")["Choose Province"]}
                                                options={homeProvinceOptions}
                                                style={{ width: "98%" }}
                                                onSearch={searchHomeProvince}
                                                onSelect={(e) => setcustomerprovince(e)}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? "")
                                                        .toLowerCase()
                                                        .includes(input.toLowerCase())
                                                }
                                                showSearch

                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col xl={12} xs={24} sm={24} lg={12} md={12}>
                                            <Form.Item
                                                label={translate("home")["City/Kabupaten"]}
                                                name="customerCity"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please select City/Kabupaten',
                                                    },

                                                ]}
                                            >
                                                <Select disabled value={customerCity}
                                                    options={homeCityOptions}
                                                    onChange={(e) => setcustomerCity(e)}
                                                    placeholder={translate("home")["City/Kabupaten"]}
                                                    onSearch={searchHomeCity}
                                                    onSelect={(e) => setcustomerCity(e)}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        (option?.label ?? "")
                                                            .toLowerCase()
                                                            .includes(input.toLowerCase())
                                                    }
                                                    showSearch
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col xl={12} xs={24} sm={24} lg={12} md={12}>
                                            <Form.Item
                                                label={translate("home")["District"]}
                                                name="customerDist"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: `${translate("home")["Please select District"]}`,
                                                    },
                                                ]}
                                            >
                                                <Select value={customerDist}
                                                    options={homeDistrictOptionsmaps}
                                                    onChange={(e) => setcustomerDist(e)}
                                                    placeholder={translate("home")["Choose District"]}
                                                    style={{ width: "98%" }}
                                                    onSearch={searchHomeDistrict}
                                                    onSelect={(e) => setcustomerDist(e)}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        (option?.label ?? "")
                                                            .toLowerCase()
                                                            .includes(input.toLowerCase())
                                                    }
                                                    showSearch

                                                />
                                            </Form.Item>
                                        </Col>


                                        <Col xl={12} xs={24} sm={24} lg={12} md={12}>
                                            <Form.Item
                                                label={translate("home")["Sub-District"]}
                                                name="customersubDist"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: `${translate("home")["Please select Sub-District"]}`,
                                                    },


                                                ]}
                                            >
                                                <Select placeholder={translate("home")["Choose Sub-District"]}
                                                    options={homesubDistrictOptionsResponse}
                                                    value={customersubDist}
                                                    onChange={(e) => setcustomersubDist(e)}
                                                    onSearch={searchHomeSubDistrict}
                                                    onSelect={(e) => setcustomersubDist(e)}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        (option?.label ?? "")
                                                            .toLowerCase()
                                                            .includes(input.toLowerCase())
                                                    }
                                                    showSearch

                                                />
                                            </Form.Item>
                                        </Col>


                                        <Col xl={24} xs={24} sm={24} lg={24} md={24}>
                                            <Form.Item
                                                label={translate("home")["Postal Code"]}
                                                name="customerpostalCode"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: `${translate("home")["Please select Postal Code"]}`,
                                                    },
                                                    {
                                                        message: `${translate("home")["Only Numbers are Allowed in"]}`,
                                                        pattern: new RegExp(/^[0-9]*$/),
                                                    },
                                                ]}
                                            >
                                                <Input maxLength={6} disabled value={customerpostalCode} onChange={(e) => setcustomerpostalCode(e.target.value)} placeholder={translate("home")["Enter Postal Code"]} />
                                            </Form.Item>
                                        </Col>
                                        <Col xl={24} xs={24} sm={24} lg={24} md={24}>
                                            <div style={{ padding: "10px 0px" }} className='thick-font'>{translate("home")["Correspondence Address Information"]}</div>
                                        </Col>
                                        <Col xl={24} xs={24} sm={24} lg={24} md={24}>
                                            <div>
                                                <b className='thick-font'>{translate("home")["Is your correspondence address the same as your insured address?"]}</b>
                                            </div>
                                        </Col>
                                        <Col xl={24} xs={24} sm={24} lg={24} md={24}>
                                            <Form.Item
                                                label=""
                                                name="checkingcorrespondenceAddress"
                                            >
                                                <Radio.Group value={checkingcorrespondenceAddress} onChange={(e) => setcheckingcorrespondenceAddress(e.target.value)}>
                                                    <Radio value="yes">{translate("commercial sales")["yes"]}</Radio>
                                                    <Radio value="no">{translate("commercial sales")["no"]}</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>



                                        {
                                            (checkingcorrespondenceAddress === "no") ? (<>
                                                <Col xl={24} xs={24} sm={24} lg={24} md={24}>
                                                    <Form.Item
                                                        label={translate("motor")["Insured Property Address"]}
                                                        name="correspondenceInsuredPropertyAddress"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Insured Property Address is required',
                                                            },
                                                        ]}
                                                    >
                                                        <Input value={correspondenceInsuredPropertyAddress} suffix={<div><Image src={Feather} preview={false} onClick={handlecorrespondenceAddressPropertysearch} /></div>} onChange={(e) => setcorrespondenceInsuredPropertyAddress(e.target.value)} />
                                                    </Form.Item>
                                                </Col>

                                                <Col xl={12} xs={24} sm={24} lg={12} md={24}>
                                                    <Form.Item
                                                        label={translate("home")["Province"]}
                                                        name="correspondencePronvine"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'correspondenceProvince is required',
                                                            },
                                                        ]}
                                                    >
                                                        <Select style={{ width: "98%" }}
                                                            value={correspondencePronvine}
                                                            onChange={(e) => setcorrespondencePronvine(e)}
                                                            options={homeCorrespondencProvinceeOptions}
                                                            onSearch={searchCorrespondenceHomeProvince}
                                                            onSelect={(e) => setcorrespondencePronvine(e)}
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                (option?.label ?? "")
                                                                    .toLowerCase()
                                                                    .includes(input.toLowerCase())
                                                            }
                                                            showSearch
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col xl={12} xs={24} sm={24} lg={12} md={24}>
                                                    <Form.Item
                                                        label={translate("home")["City/Kabupaten"]}
                                                        name="correspondenceCity"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please select correspondenceCity/Kabupaten',
                                                            },
                                                        ]}
                                                    >
                                                        <Select style={{ width: "98%" }}
                                                            value={correspondenceCity}
                                                            onChange={(e) => setcorrespondenceCity(e)}
                                                            options={homeCorresCityOptions}

                                                            onSearch={searchCorrespondenceHomeCity}
                                                            onSelect={(e) => setcorrespondenceCity(e)}
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                (option?.label ?? "")
                                                                    .toLowerCase()
                                                                    .includes(input.toLowerCase())
                                                            }
                                                            showSearch


                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col xl={12} xs={24} sm={24} lg={12} md={24}>
                                                    <Form.Item
                                                        label={translate("home")["District"]}
                                                        name="correspondenceDistrict"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please select correspondenceDistrict',
                                                            },
                                                        ]}
                                                    >
                                                        <Select style={{ width: "98%" }}
                                                            value={correspondenceDistrict}
                                                            onChange={(e) => setcorrespondenceDistrict(e)}
                                                            options={homecorrespondenceDistrictOptions}
                                                            onSearch={searchHomeCorrespondenceDistrict}
                                                            onSelect={(e) => setcorrespondenceDistrict(e)}
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                (option?.label ?? "")
                                                                    .toLowerCase()
                                                                    .includes(input.toLowerCase())
                                                            }
                                                            showSearch

                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col xl={12} xs={24} sm={24} lg={12} md={24}>
                                                    <Form.Item
                                                        label={translate("home")["Sub-District"]}
                                                        name="correspondencesubDistrict"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please select correspondencesubDistrict',
                                                            },
                                                        ]}
                                                    >
                                                        <Select style={{ width: "98%" }}
                                                            value={correspondencesubDistrict}
                                                            onChange={(e) => setcorrespondencesubDistrict(e)}
                                                            options={homecorrespondencesubDistrictOptions}
                                                            onSearch={searchCorrespondenceHomeSubDistrict}
                                                            onSelect={(e) => setcorrespondencesubDistrict(e)}
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                (option?.label ?? "")
                                                                    .toLowerCase()
                                                                    .includes(input.toLowerCase())
                                                            }
                                                            showSearch

                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col xl={24} xs={24} sm={24} lg={24} md={24}>
                                                    <Form.Item
                                                        label={translate("home")["Postal Code"]}
                                                        name="correspondencepostalCode"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please select correspondencepostalCode',
                                                            },
                                                            {
                                                                message: `${translate("home")["Only Numbers are Allowed in"]}`,
                                                                pattern: new RegExp(/^[0-9]*$/),
                                                            },
                                                        ]}
                                                    >
                                                        <Input value={correspondencePostalCode} onChange={(e) => setcorrespondencePostalCode(e.target.value)} />
                                                    </Form.Item>
                                                </Col>
                                            </>) :
                                                (
                                                    <>
                                                        <Col xl={24} xs={24} sm={24} lg={24} md={24}>
                                                            <Form.Item
                                                                label={translate("motor")["Insured Property Address"]}
                                                                name="correspondenceInsuredPropertyAddressYes"
                                                                rules={[
                                                                    // {
                                                                    //     required: true,
                                                                    //     message: 'Please select Insured Phone Number',
                                                                    // },
                                                                ]}
                                                            >
                                                                <Input disabled value={correspondenceInsuredPropertyAddressYes} suffix={<EnvironmentOutlined />} onChange={(e) => setcorrespondenceInsuredPropertyAddressYes(e.target.value)} />
                                                            </Form.Item>
                                                        </Col>

                                                        <Col xl={12} xs={24} sm={24} lg={12} md={24}>
                                                            <Form.Item
                                                                label={translate("home")["Province"]}
                                                                name="correspondencePronvineYes"
                                                                rules={[
                                                                    // {
                                                                    //     required: true,
                                                                    //     message: 'Please select Insured Phone Number',
                                                                    // },
                                                                    // {
                                                                    //     message: `${translate("home")["Only Numbers are Allowed in"]}`,
                                                                    //     pattern: new RegExp(/^[0-9]*$/),
                                                                    //   },
                                                                ]}
                                                            >
                                                                <Select disabled style={{ width: "98%" }} value={correspondencePronvineYes} onChange={(e) => setcorrespondencePronvineYes(e)} options={homeProvinceOptions} />
                                                            </Form.Item>
                                                        </Col>

                                                        <Col xl={12} xs={24} sm={24} lg={12} md={24}>
                                                            <Form.Item
                                                                label={translate("home")["City/Kabupaten"]}
                                                                name="correspondenceCityYes"
                                                                rules={[
                                                                    // {
                                                                    //     required: true,
                                                                    //     message: 'Please select Insured Phone Number',
                                                                    // },
                                                                    // {
                                                                    //     message: `${translate("home")["Only Numbers are Allowed in"]}`,
                                                                    //     pattern: new RegExp(/^[0-9]*$/),
                                                                    //   },
                                                                ]}
                                                            >
                                                                <Select disabled style={{ width: "98%" }} value={correspondenceCityYes} onChange={(e) => setcorrespondenceCityYes(e)} options={homeCityOptions} />
                                                            </Form.Item>
                                                        </Col>

                                                        <Col xl={12} xs={24} sm={24} lg={12} md={24}>
                                                            <Form.Item
                                                                label={translate("home")["District"]}
                                                                name="correspondenceDistrictYes"
                                                                rules={[
                                                                    // {
                                                                    //     required: true,
                                                                    //     message: 'Please select Insured Phone Number',
                                                                    // },
                                                                    // {
                                                                    //     message: `${translate("home")["Only Numbers are Allowed in"]}`,
                                                                    //     pattern: new RegExp(/^[0-9]*$/),
                                                                    //   },
                                                                ]}
                                                            >
                                                                <Select disabled style={{ width: "98%" }} value={correspondenceDistrictYes} onChange={(e) => setcorrespondenceDistrictYes(e)} options={homeDistrictOptionsmaps} />
                                                            </Form.Item>
                                                        </Col>

                                                        <Col xl={12} xs={24} sm={24} lg={12} md={24}>
                                                            <Form.Item
                                                                label={translate("home")["Sub-District"]}
                                                                name="correspondencesubDistrictYes"
                                                                rules={[
                                                                    // {
                                                                    //     required: true,
                                                                    //     message: 'Please select Insured Phone Number',
                                                                    // },
                                                                    // {
                                                                    //     message: `${translate("home")["Only Numbers are Allowed in"]}`,
                                                                    //     pattern: new RegExp(/^[0-9]*$/),
                                                                    //   },
                                                                ]}
                                                            >
                                                                <Select disabled style={{ width: "98%" }} value={correspondencesubDistrictYes} onChange={(e) => setcorrespondencesubDistrictYes(e)} options={homesubDistrictOptionsResponse} />
                                                            </Form.Item>
                                                        </Col>

                                                        <Col xl={24} xs={24} sm={24} lg={24} md={24}>
                                                            <Form.Item
                                                                label={translate("home")["Postal Code"]}
                                                                name="correspondencepostalCodeYes"
                                                                rules={[
                                                                    // {
                                                                    //     required: true,
                                                                    //     message: 'Please select Insured Phone Number',
                                                                    // },
                                                                    {
                                                                        message: `${translate("home")["Only Numbers are Allowed in"]}`,
                                                                        pattern: new RegExp(/^[0-9]*$/),
                                                                    },
                                                                ]}
                                                            >
                                                                <Input disabled value={correspondencepostalCodeYes} onChange={(e) => setcorrespondencePostalCodeYes(e.target.value)} />
                                                            </Form.Item>
                                                        </Col>
                                                    </>
                                                )
                                        }

                                    </Row>
                                </Card>
                                <Card>
                                    <div className='justify-space'>
                                        <div className='thick-font'><b>{translate("motor")["Contact Information"]}</b></div>
                                    </div>

                                    <Col xl={24} xs={24} sm={24} lg={24} md={24}>
                                        <Form.Item
                                            label={translate("motor")["Insured Phone Number"]}
                                            name="insuredPhoneNUmber"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: `${translate("home")["Insured Phone Number is required"]}`,
                                                },
                                                {
                                                    message: `${translate("home")["Only Numbers are Allowed in"]}`,
                                                    pattern: new RegExp(/^[0-9]*$/),
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (value && (value.length < 9 || value.length > 13)) {
                                                            return Promise.reject(
                                                                new Error(`${translate("home")["Please enter 9 until 13 digits Mobile Number"]}`)
                                                            );
                                                        } else {
                                                            return Promise.resolve();
                                                        }
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input maxLength={13} minLength={9} placeholder={translate("motor")["Enter Insured Phone Number"]} value={insuredPhoneNUmber} onChange={(e) => setinsuredPhoneNUmber(e.target.value)} />
                                        </Form.Item>
                                    </Col>


                                    <Col xl={24} xs={24} sm={24} lg={24} md={24}>
                                        <Form.Item
                                            label={translate("motor")["Insured Email Address"]}
                                            name="insuredEmailAddress"
                                            rules={
                                                [
                                                    {
                                                        required: true,
                                                        message: `${translate("home")["Insured Email Address is required"]}`
                                                    },
                                                    {
                                                        type: "email",
                                                        message: `${translate("home")["The input is not valid E-mail"]}`,
                                                    }
                                                ]
                                            }
                                        >
                                            <Input placeholder={translate("motor")["Enter Insured Email Address"]} value={insuredEmailAddress} onChange={(e) => setinsuredEmailAddress(e.target.value)} />
                                        </Form.Item>
                                    </Col>
                                    <label style={{ margin: 5, fontWeight: 800 }}>Print Policy</label>
                                    <Col xl={24} xs={24} sm={24} lg={24} md={24}>
                                        <Form.Item name="copy">
                                            <Radio.Group value={copy} onChange={onChangeCopy}>
                                                <Radio value="1">Hard Copy</Radio>
                                                <Radio value="2">Soft Copy</Radio>
                                            </Radio.Group>
                                            <Col xl={6} xs={15} sm={10} lg={6} md={6} style={{ display: "flex", justifyContent: "space-between" }}>
                                                <div>IDR 40,000</div>
                                                <div> IDR 15,000</div>
                                            </Col>
                                        </Form.Item>
                                    </Col>


                                    {
                                        shownhardCopyFields && (<>
                                            <Col xl={24} xs={24} sm={24} lg={24} md={24} style={{ marginTop: "1em" }}>
                                                <Form.Item name="addressForHardCopy">
                                                    <TextArea placeholder='Enter address' value={addressForHardCopy} onChange={(e) => setaddressForHardCopy(e.target.value)} />
                                                </Form.Item>
                                            </Col>

                                            {/* <Col xl={24} xs={24} sm={24} lg={24} md={24} style={{ marginTop : "1em"}}>
                                 <Form.Item>
                                    <Input placeholder='Email address' value={InsuredDefaultEmail} disabled/>
                                    </Form.Item>
                                 </Col> */}

                                        </>)
                                    }
                                    <Button htmlType='submit' className='next-button-travel'>{translate("home")["Next"]}</Button>
                                </Card>
                            </Form>
                        </Col>
                    </Row>

                    <><Modal closable={true} visible={addressmodalPopup} width={1000} onOk={handleOk} onCancel={handleCancel}>
                        <div style={{ width: "100%", height: "30rem", marginTop: "50px" }}>
                            <MapContainer cords={cords} setLocation={setCords} handleInsuredAddress={handleInsuredAddress} mapsaddress={mapsaddress} />
                            {/* <MyGoogleMap /> */}

                        </div>
                    </Modal></>


                    <><Modal closable={true} visible={addresscorrespondencemodalPopup} width={1000} onOk={handlecorrespondenceOk} onCancel={handlecorrespondenceCancel}>
                        <div style={{ width: "100%", height: "30rem", marginTop: "50px" }}>
                            <CorrMapsContainer cords={corrrespondencecords} setLocation={setcorrescords} handleInsuredAddress={handleCorrespondenceAddress} mapsaddress={correspondencemapsaddress} />
                            {/* <MyGoogleMap /> */}
                        </div>
                    </Modal></>
                </div >
            </Spin>
        </>

    )
}

export default CustomerInformationHome



