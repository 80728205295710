import React, { useEffect, useState } from "react";
import "./oona-aob.css";
import { Row, Col, Image, Form, Input, Button } from "antd";
//import ErrorLogo from "../../aobImages/ErrorLogo.png";
//import badgePlaystore from "../../aobImages/badgePlaystore.png";
//import DownloadAppStore from "../../aobImages/DownloadAppStore.png";
import { useHistory } from "react-router-dom";
import errorLogo from '../oonaImages/GRFX Error.png'
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Header } from "antd/lib/layout/layout"
import oonaLogo from "../images/oona-logo@2x.png"
import LangSwitch from "../config/LangSwitch";
import CustomerHeader from '../CustomerHeader/CustomerHeader'
import { useLanguage } from "../config/LanguageContext";

const AOBCORError = (props) => {
  console.log(props, "autoooo")
  const history = useHistory();
  const location=useLocation()
  const {translate}=useLanguage()
  return (
    <>
    <CustomerHeader/>
    <div className="row__aob oona_aob_font">
      <Row>
        <Col xl={12} md={12} lg={12}>
          <Image preview={false} src={errorLogo} />
        </Col>
        <Col xl={12} md={12} lg={12}>
          <div className="oonaError__kahoona">
            <div className="oonaSuccess__kahoona__header oona_aob_font">
            {translate("onboarding")["We’re Sorry"]}
            </div>
            <div className="oonaError__kahoona__header__cenetered oona_aob_font">
              <div className="oona_aob_font">
              {translate("onboarding")["Your registration cannot be proceeded.Your ID card photo is not in the correct format.Please retake the photo or proceed without an ID card photo."]}

              </div>
              {/* <div className="oona_aob_font">
                Your ID card photo is not in the correct format.

              </div>
              <div className="oona_aob_font">
                Please retake the photo or proceed without
                an ID card photo.
              </div> */}
            </div>


            <div className="oonna_success_buuton">
              <Button className="oonna_success_buuton__home oona_aob_font" onClick={() => history.push({
                pathname: '/AOBmitraRegistrationForm',
                state: {
                  data: "declinedeoorCor",
                  phonenumber:props?.location?.state?.phonenumber,
                  userId:props?.location?.state?.userId,
                  activekey:"cor",
                  corPoratetaxpayer : true,

                },
              })}>
               {translate("onboarding")["Skip Upload"]}
              </Button>
              <Button className="oonna_success_buuton__Invite oona_aob_font" onClick={() => history.push({
                pathname: '/AOBmitraRegistrationForm',
                state: {
                  data: "declinedeoorCorRetake",
                  phonenumber: props?.location?.state?.phonenumber,
                  userId: props?.location?.state?.userId,
                  activekey:"cor"
                },
              })}>
               {translate("onboarding")["Retake Photo"]}
              </Button>
            </div>
          </div>
        </Col>
      </Row>

    </div>
    </>

  )
}

export default AOBCORError
