import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import { Row, Col, Card, Button, Input, message } from "antd"
import { useHistory, useLocation } from "react-router-dom"
import rootIndex from '../../store/root_index'
import { Base_URL } from '../../config/BaseUrl'
import { toast } from 'react-toastify';
import { useLanguage } from '../../config/LanguageContext';
import axiosRequest from '../../axios-request/request.methods'
import { useSelector } from 'react-redux'

const { store } = rootIndex;

const OrderSummary = (props, ref) => {

  const fetchingIDfromStore=useSelector((state)=>state?.cipherlinkreducer?.quote?.quoteId)
  console.log(fetchingIDfromStore,"fetchingIDfromStore")
  const checkingResumeType=useSelector((state)=>state?.cipherlinkreducer?.quote?.resumeType);
  
  const location = useLocation()
    const history = useHistory();
  const _store = store.getState();
  const [num, setNum] = useState(0);
    const [show, setShow] = useState(true);
  const [quotationDetails, setQuotationDetails] = useState("")
  
  const [showDiscount, setshowDiscount] = useState("")
  const [discounedValue, setsubDiscountedValue] = useState("")
  const [diserr, setDisErr] = useState(false)
  const [disnumerr, setdisNumErr] = useState(false)
  const [promo, setPromo] = useState("")
  const [discountpercent, setDiscountPercent] = useState('')
  console.log(discountpercent,"discountpercent")
  const[discountP,setDiscountPercentagehome]=useState('')
  const [total, setTotal] = useState('')
  console.log(total,"totalInOrdeSummaryyy")
  const [disable, setDisable] = useState(false)
  const [promoarr, setPromoArr] = useState(false)
  const [subtotalfromAdditionalBenefits,setSubtotalAmount]=useState("")
  const [promocodevalidation,setpromoCodeValiadtionState]=useState(true)
  console.log(promocodevalidation,"promocodevalidation")
  console.log(subtotalfromAdditionalBenefits,"subtotalfromAdditionalBenefits")


  useEffect(() => {

    const totalSum = quotationDetails?.data?.additionalBenefits?.reduce(function (acc, obj) { return Number(acc) + Number(obj.Premium); }, 0);
    console.log(totalSum, "totalSum")
    if(totalSum !== undefined){
    const viewDetailTS = Number(quotationDetails?.data?.premium) + Number(totalSum)
console.log(viewDetailTS,"viewDetailTS")
    setSubtotalAmount(viewDetailTS)
  }

}, [quotationDetails])

  const nextBtn = async() => {
   
    if (_store?.cipherlinkreducer?.formdata?.cipherkeyavilable === true) {

        if(promocodevalidation === true){
        promocodeSubmit()
        }
        else{
          toast.error(`${translate("home")["Invalid promo code"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
        }
        
      } else {
        discountSubmit()
      }
    }
   
  


  const promocodeSubmit = () => {
    if (promoarr === true) {
      quickQuote()
    } else {
      
      console.log(discountpercent?.discount,"discountpercent?.discount")

      let formData = {
        "discountPercent":discountP?.discount === undefined ? 0 : discountP?.discount,
        "promoCode": promo
      }
  

      axiosRequest
        .patch(
          `home/updateQuote/${fetchingIDfromStore}`,
          formData,
                  )
        .then((data) => {
          if (data?.errCode === -1) {
            quickQuote()
            toast.success(`${translate("home")["Quote Updated Successfully"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
          }
        }).catch((error) => {
          console.log(
            "error:2---forgotpass-----> ",
            typeof error,
            error.Error,
            error.message
          );
        });
    }
  }



  const discountSubmit = () => {
    
    let formData = {
      "discountPercent": num,
    }

    axiosRequest
      .patch(
        `home/updateQuote/${fetchingIDfromStore}`,
        formData
      )
      .then((data) => {
        if(data?.errCode === -1){
          quickQuote()
          toast.success(`${translate("home")["Quote Updated Successfully"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
        }
       else{
          if (_store?.cipherlinkreducer?.formdata?.cipherkeyavilable === true) {
            history.push({
              pathname: '/oonaerror',
              // state: {
              //  data:location?.state?.data,
              //   quoteId: props.quoteId,
                
              // },
            })
          } else {
            history.push({
              pathname: '/oonaerror',
              // state: {
              //   data:location?.state?.data,
              //   quoteId: props.quoteId,
              // },
            })
          }
        }
      }).catch((error) => {
          console.log(
            "error:2---forgotpass-----> ",
            typeof error,
            error.Error,
            error.message
          );
      });

  }


  const quickQuote = () => {


    axiosRequest
      .get(
        `user/home-quick-quote/${fetchingIDfromStore}`,
              )
      .then((data) => {
        if(data?.errCode === -1){

if (_store?.cipherlinkreducer?.formdata?.cipherkeyavilable === true) {
            toast.success(`${translate("home")["Quick Quote Created successfully"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
            history.push({
              pathname: '/customerinformationhome',
              state: {
                quoteId: location?.state?.quouteId,
                data: 'home',
                quoteCode: quotationDetails?.data?.quote_Id,
                
              
              }
            })
          } 
          
          else {
            toast.success(`${translate("home")["Quick Quote Created successfully"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
            history.push({
              pathname: '/home-quick-quote-success-screen',
              state: {
                // quoteId: location?.state?.quouteId,
                // data: location?.state?.data,
                // quoteCode: quotationDetails?.data?.quote_Id,
               
              }
            })
          }
          
          
        }
          else{
            if (_store?.cipherlinkreducer?.formdata?.cipherkeyavilable === true) {
            history.push({
              pathname: '/oonaerror',
              state: {
                data: 'travel',
                quoteId: props.quoteId,
                
              },
            })
          } else {
            history.push({
              pathname: '/oonaerror',
              state: {
                data: 'travel',
                quoteId: props.quoteId,
              },
            })
          }
        }
      
      }).catch((error) => {
        console.log(
          "error:2---forgotpass-----> ",
          typeof error,
          error.Error,
          error.message
        );
      });

  }


  let incNum = () => {
    if (diserr === false && disnumerr === false) {
      if (num < 15) {
        if ((Number(num) + 0.5) <= 15) {
          setNum(Number(num) + 0.5);
         

          const calculatingDiscountAmount=(Math.round((subtotalfromAdditionalBenefits) * (Number(num) + 0.5) / 100))
          console.log(calculatingDiscountAmount,"calculatingDiscountAmount")

          const calculatingTotalAmount=Math.floor((subtotalfromAdditionalBenefits) - (calculatingDiscountAmount))
            console.log(calculatingTotalAmount,"calculatingTotalAmount")

          setDiscountPercent(parseInt(calculatingDiscountAmount))
          setTotal((calculatingTotalAmount))
        } else {
          message.error('Discount will be increse in the interval of 0.5, maximum discount is 15% only')
        }
      } else {

        message.error('Discount is upto 15% only')
      }
    } else {
      if (diserr === true) {
        message.error(`${translate("home")["Please Enter Only Numbers"]}`)
      }
      if (disnumerr === true) {
        message.error('Please enter numbers between 0 to 15')
      }
    }
  };
  let decNum = () => {
    if (diserr === false && disnumerr === false) {
      if (num >= 0.5) {
        if ((Number(num) - 0.5) >= 0) {
          setNum(num - 0.5);
         

          const calculatingDiscountAmount=(Math.round((subtotalfromAdditionalBenefits) * (Number(num) - 0.5) / 100))
          console.log(calculatingDiscountAmount,"calculatingDiscountAmount")

          const calculatingTotalAmount=Math.floor(subtotalfromAdditionalBenefits) - parseInt(calculatingDiscountAmount)
          

          setDiscountPercent(parseInt(calculatingDiscountAmount))
          setTotal(calculatingTotalAmount)
        } else {
          message.error('Discount will be decrease in the interval of 0.5, discount cannot be negative')
        }
      } else {
        toast.error(`${translate("home")["Discount cannot be negative"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
      }
    } else {
      if (diserr === true) {
        message.error(`${translate("home")["Please Enter Only Numbers"]}`)
      }
      if (disnumerr === true) {
        message.error('Please enter numbers between 0 to 15')
      }
    }
  }


  

  const handlePromo = async (value) => {
    console.log(value?.length,"dfssgatg")
    setPromo(value)
    if (value?.length === 17) {
      promocodeValidate(value)
     setpromoCodeValiadtionState(true)
    }
    else{
      toast.error(`${translate("home")["Invalid promo code"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
      setpromoCodeValiadtionState(false)
    }

  }


  let handleChange = (val) => {
   
    console.log(val,"handlechnage valuee")
    const reg = new RegExp('^(0(\\.\\d{1,2})?|[1-9][0-9]?|15)$');
    console.log(reg.test(val), typeof (val));
    if (reg.test(val) === true) {
      if (val.length <= 4) {
        if (Number(val) <= 15 && Number(val) >= 0) {
          console.log('dis 5----->')
          //alert("greater than 30")
          console.log(val,"valvalval")
          //console.log(val,"vallll")
          setNum(val);
          setDisErr(false)
          setdisNumErr(false)

          const calculatingDiscountAmount=(Math.round((subtotalfromAdditionalBenefits) * (Number(val)) / 100))
          console.log(calculatingDiscountAmount,"calculatingDiscountAmount")

          const calculatingTotalAmount=Math.floor((subtotalfromAdditionalBenefits) - (calculatingDiscountAmount))
            console.log(calculatingTotalAmount,"calculatingTotalAmount")

          setDiscountPercent(parseInt(calculatingDiscountAmount))
          setTotal(calculatingTotalAmount)
          
         
        } else {
          setNum(15);
          setdisNumErr(false)
          setDisErr(false)
         
          const calculatingDiscountAmount=(Math.round((subtotalfromAdditionalBenefits) * (Number(15)) / 100))
          console.log(calculatingDiscountAmount,"calculatingDiscountAmount")

          const calculatingTotalAmount=Math.floor((subtotalfromAdditionalBenefits) - (calculatingDiscountAmount))
            console.log(calculatingTotalAmount,"calculatingTotalAmount")

          setDiscountPercent(parseInt(calculatingDiscountAmount))
          setTotal(calculatingTotalAmount)


          toast.error(`${translate("home")["Enter only numbers between 0 to 15"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
        }
      } else {
        console.log('dis 4----->')
        toast.error(`${translate("home")["Enter only numbers between 0 to 15"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
      }
    } else {
      if (val.length <= 4 && val.length > 0) {
        setNum(val);
        console.log('dis 2----->')
        toast.error(`${translate("home")["Enter Only Numbers"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
        setDisErr(true)
      } else if (val.length === 0) {
        setNum(val);
        console.log(parseInt(subtotalfromAdditionalBenefits),Math.floor((parseInt(subtotalfromAdditionalBenefits)) * ((0) / 100)),'dis------>');
        setDisErr(true)
        toast.error(`${translate("home")["enter any number, minimum is 0"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
        

        const calculatingDiscountAmount=(Math.round((subtotalfromAdditionalBenefits) * (Number(0)) / 100))
        console.log(calculatingDiscountAmount,"calculatingDiscountAmount")

        const calculatingTotalAmount=Math.floor((subtotalfromAdditionalBenefits) - (calculatingDiscountAmount))
          console.log(calculatingTotalAmount,"calculatingTotalAmount")

        setDiscountPercent(parseInt(calculatingDiscountAmount))
        setTotal(calculatingTotalAmount)
      } else {
        console.log('dis 3----->')
        toast.error(`${translate("home")["Enter only numbers between 0 to 15"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
      }
    }
  }
  


 

 



  const promocodeValidate = (value) => {

    let formData = {
      promoCode: value
    }

    axiosRequest
      .post(
        `home/validate-promoCode-home`,
        formData,
        
      )
      .then((data) => {
        if(data?.errCode === -1){
          toast.success(`${translate("home")["PromoCode validated Successfully"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
       console.log(data?.errMsg,"bsjdgy")
          setDisable(true)
          setDiscountPercent(data?.errMsg)
          setDiscountPercentagehome(data?.errMsg)
         

          const calculatingDiscountAmount=(Math.round((subtotalfromAdditionalBenefits) * (Number(data.errMsg.discount)) / 100))
          console.log(calculatingDiscountAmount,"calculatingDiscountAmount")

          const calculatingTotalAmount=Math.floor((subtotalfromAdditionalBenefits) - (calculatingDiscountAmount))
            console.log(calculatingTotalAmount,"calculatingTotalAmount")

          setDiscountPercent(parseInt(calculatingDiscountAmount))
          setTotal(calculatingTotalAmount)

                  }

      }).catch((error) => {
        console.log(
          "error:2---forgotpass-----> ",
          typeof error,
          error.Error,
          error.message
        );
              });
  }



  useEffect(() => {
    console.log(quotationDetails?.data?.policyLobList?.length, "hdjkwhu")
    console.log(quotationDetails?.data?.policyLobList.length, "dwgduwuyg")
    if ((quotationDetails?.data?.policyLobList?.length === 1)) {
      //alert("Hellloo")
      const flexasPremiumEg = parseInt(quotationDetails?.data?.policyLobList?.[0]?.policyRiskList?.[0]?.flexasCoverPremium)
      const eqpremiumEg = parseInt(quotationDetails?.data?.policyLobList[0]?.policyRiskList[0]?.eQCoverPremium)
      const rsmdccEg = parseInt(quotationDetails?.data?.policyLobList[0]?.policyRiskList[0]?.rsmdccCoverPremium)
      const floodcoverEg = parseInt(quotationDetails?.data?.policyLobList[0]?.policyRiskList[0]?.floodCoverPremium)

      const parpremiumEg = parseInt(quotationDetails?.data?.grossPremium) - (parseInt(quotationDetails?.data?.policyLobList[0]?.policyRiskList[0]?.eQCoverPremium || 0))

      if (quotationDetails?.data?.typeofProduct === "FLEXAS") {


        const calculatingDiscount = (Number(num) / 100) * Number(flexasPremiumEg + eqpremiumEg + rsmdccEg + floodcoverEg)
        setshowDiscount(calculatingDiscount.toFixed(2))
        const subDiscountedValue = Number(flexasPremiumEg + eqpremiumEg + rsmdccEg + floodcoverEg) - Number(showDiscount)
        setsubDiscountedValue(subDiscountedValue.toFixed(2))
      }



      if (quotationDetails?.data?.typeofProduct === "PAR") {
        //alert("Entered into Flexas")
        const calculatingDiscount = (Number(num) / 100) * Number(flexasPremiumEg + eqpremiumEg + rsmdccEg + floodcoverEg)
        setshowDiscount(calculatingDiscount.toFixed(2))
        const subDiscountedValue = Number(parpremiumEg + eqpremiumEg + rsmdccEg + floodcoverEg) - Number(showDiscount)
        setsubDiscountedValue(subDiscountedValue.toFixed(2))
      }
    }
    else {
      //alert("nottHellloo")
      const calculatingDiscount = (Number(num) / 100) * Number(subtotalfromAdditionalBenefits)
      setshowDiscount(calculatingDiscount.toFixed(2))
      const subDiscountedValue = Number(subtotalfromAdditionalBenefits) - Number(showDiscount)
      setsubDiscountedValue(subDiscountedValue.toFixed(2))
    }


  }, [num, showDiscount, quotationDetails])

  useEffect(() => {

    if (_store?.cipherlinkreducer?.formdata?.cipherkeyavilable === true) {
      setShow(false)
    } else {
      setShow(true)
    }

    

if(fetchingIDfromStore !== ""){
    /*getUoteByQuoteId*/

    var fromReferral = ""
    if(checkingResumeType === "referalJourney" || checkingResumeType === "quoteSearch"){
      fromReferral=`home/getQuote/${fetchingIDfromStore}?logkey=${checkingResumeType}`
    }
    else{
      fromReferral=`home/getQuote/${fetchingIDfromStore}`
    }


    axiosRequest
      .get(
        `${fromReferral}`,
              )
      .then((data) => {
       
        if (data?.errCode === -1) {
          console.log(data?.data, "hommmmm")
          setQuotationDetails(data?.errMsg)
        }
      }).catch((error) => {
        console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

      });
    }


  }, [fetchingIDfromStore,checkingResumeType])



  const toCurrencyConvertor = (value) => {
    const currencyVal = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    }).format(value)

    return currencyVal
  }


  console.log(quotationDetails?.data?.policyLobList?.[0]?.policyRiskList?.[0]?.eQCoverPremium, "EETEYTy")

  const { translate } = useLanguage()
  const [width, setWidth] = useState(window.innerWidth);

  const breakpoint = 600;

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, [width]);





  useEffect(()=>{
    if(quotationDetails?.data?.duePremium !== undefined){
      toCurrencyConvertor(setTotal(quotationDetails?.data?.duePremium-quotationDetails?.data?.adminCost))
     
    }

    if(quotationDetails?.data?.discountPercent !== undefined){
      toCurrencyConvertor(setNum(quotationDetails?.data?.discountPercent))
    }

    if(quotationDetails?.data?.discountAmount !== undefined){
      toCurrencyConvertor(setDiscountPercent(Math.floor(quotationDetails?.data?.discountAmount)))
    }

    if(quotationDetails?.data?.duePremium === undefined){
    
      toCurrencyConvertor(setTotal((subtotalfromAdditionalBenefits)))
    
     
    }

    if(quotationDetails?.data?.promoCode !== undefined){
      toCurrencyConvertor(setPromo(quotationDetails?.data?.promoCode))
     
    }



  },[quotationDetails,subtotalfromAdditionalBenefits])

  return (

<div>
<h3 className='header-card-content'>
    {translate("travel")["Order Summary"]}
</h3>
<div><b>{translate("travel")["Please check again your quote summary"]}</b></div>
<br />


{width > breakpoint ? (
    <>
        <Row style={{ gap: "15px" }}>
            <Col md={12} lg={12} xl={12} xs={24} sm={12}>
                <div className='accordian-inner'>
                    <div className='additional-font'>
                        {quotationDetails?.data?.planName}
                    </div>
                    <div className='additional-font'>
                        {new Intl.NumberFormat('id-ID', {
                            style: 'currency',
                            currency: 'IDR',
                            minimumFractionDigits: 0,
                        }).format(Math.floor(parseInt(quotationDetails?.data?.premium)))}
                    </div>
                </div>
                <br />
                {quotationDetails?.data?.additionalBenefits.length > 0 ? quotationDetails?.data?.additionalBenefits.map(item => {
                    return (
                        <>
                            <div className='accordian-inner'>
                                <div className='additional-font'>
                                    {item.BenefitDesc}
                                </div>
                                <div className='additional-font'>
                                    {new Intl.NumberFormat('id-ID', {
                                        style: 'currency',
                                        currency: 'IDR',
                                        minimumFractionDigits: 0,
                                    }).format(Math.floor(parseInt(item.Premium)))}
                                </div>
                            </div>
                            <br />
                        </>
                    )
                }) : null}

       
                <div className='accordian-inner'>
                    <div className='additional-font'>
                    {translate("motor")["Discount"]}
                    </div>
                    <div style={{ color: "red" }}>
                       -{toCurrencyConvertor(discountpercent)}
                    </div>
                </div>
                <hr />
                <div className='accordian-inner'>
                    <div>
                        <b style={{ color: "#482c77" }}>{translate("motor")["Total"]}</b>
                        <p style={{ fontSize: 12, fontStyle: "italic" }}>
                            *{translate("travel")["Premium amount shown excludes admin cost"]}
                        </p>
                    </div>
                    <div className='thick-font' style={{ fontSize: 18 }}>
                        {toCurrencyConvertor(total)}
                    </div>
                </div>
            </Col>
            <Col md={11} sm={11} xs={24} lg={11} xl={11}>
                {props.quote != undefined && props.quote == true ? null :
                    <Card className='card-background'>

                        {
                            show === true ?
                                <>
                                    <div className='thick-font'>{translate("travel")["Discount (Optional)"]}</div>
                                    <div style={{ margin: "10px 10px 10px 0px" }} className='additional-font'>{translate("travel")["Enter discount you will give to the customer"]}
                                    </div>

                                    <Input style={{ borderColor: diserr === true || disnumerr === true ? 'red' : '#818F99', color: diserr === true || disnumerr === true ? 'red' : 'black' }} value={num} onChange={(e) => handleChange(e.target.value)} prefix={<><div onClick={decNum} style={{ cursor: 'pointer' }}>-</div></>} suffix={<><div onClick={incNum} style={{ cursor: 'pointer' }}>+</div></>} />
                                    <div className='justify-space'>
                                        <div className='font-inner'>
                                            {translate("home")["Min."]} 0%
                                        </div>
                                        <div className='font-inner'>
                                            {translate("home")["Max."]} 15%
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <b>{translate("home")["PromoCodeHome"]}</b>
                                    <div>
                                    {translate("home")["Apply to get the discounted price"]}
                                    </div>
                                    <br />
                                    <Input defaultValue={promo} value={promo} disabled={disable} placeholder={translate("home")["promoCodePlaceHolder"]} onChange={(e) => handlePromo(e.target.value)} />
                                </>
                        }

                    </Card>
                }
            </Col>
        </Row>

        {props.quote != undefined && props.quote === true ? null :
            <Button disabled={diserr || disnumerr} onClick={() => nextBtn()} className='next-button-travel'>{translate("home")["next"]}</Button>}
    </>
) : (
    <>
        <Row style={{ gap: "15px" }}>
            <Col md={11} sm={11} xs={24} lg={11} xl={11}>
                {props.quote != undefined && props.quote === true ? null :
                    <Card style={{ marginBottom: 20 }} className='card-background'>
                        {
                            show === true ?
                                <>
                                    <div className='thick-font'>{translate("travel")["Discount (Optional)"]}</div>
                                    <div style={{ margin: "10px 10px 10px 0px" }} className='additional-font'>{translate("travel")["Enter discount you will give to the customer"]}
                                    </div>

                                    <Input style={{ borderColor: diserr === true || disnumerr === true ? 'red' : '#818F99', color: diserr === true || disnumerr === true ? 'red' : 'black' }} value={num} onChange={(e) => handleChange(e.target.value)} prefix={<><div onClick={decNum} style={{ cursor: 'pointer' }}>-</div></>} suffix={<><div onClick={incNum} style={{ cursor: 'pointer' }}>+</div></>} />
                                    <div className='justify-space'>
                                        <div className='font-inner'>
                                            {translate("home")["Min."]}1%
                                        </div>
                                        <div className='font-inner'>
                                            {translate("home")["max."]}15%
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <b>{translate("home")["PromoCodeHome"]}</b>
                                    <div>
                                    {translate("travel")["Apply to get the discounted price"]}
                                    </div>
                                    <br />
                                    <Input defaultValue={promo} value={promo} disabled={disable} placeholder={translate("home")["promoCodePlaceHolder"]} onChange={(e) => handlePromo(e.target.value)} />
                                </>
                        }

                    </Card>
                }
            </Col>
            <Col md={12} lg={12} xl={12} xs={24} sm={12}>
                <div className='accordian-inner'>
                    <div className='additional-font'>
                        {quotationDetails?.data?.planName}
                    </div>
                    <div className='additional-font'>
                        {toCurrencyConvertor(quotationDetails?.data?.premium)}
                    </div>
                </div>
                <br />
                {quotationDetails?.data?.additionalBenefits.length > 0 ? quotationDetails?.data?.additionalBenefits.map(item => {
                    return (
                        <>
                            <div className='accordian-inner'>
                                <div className='additional-font'>
                                    {item.BenefitDesc}
                                </div>
                                <div className='additional-font'>
                                    {toCurrencyConvertor(item.Premium)}
                                </div>
                            </div>
                            <br />
                        </>
                    )
                }) : null}

             
                <div className='accordian-inner'>
                    <div className='additional-font'>
                    {translate("motor")["Discount"]}
                    </div>
                    <div style={{ color: "red" }}>
                        -{toCurrencyConvertor(discountpercent)}
                    </div>
                </div>
                <hr />
                <div className='accordian-inner'>
                    <div>
                        <b style={{ color: "#482c77" }}>{translate("motor")["Total"]}</b>
                        <p style={{ fontSize: 12, fontStyle: "italic" }}>
                            *{translate("travel")["Premium amount shown excludes admin cost"]}
                        </p>
                    </div>
                    <div className='thick-font' style={{ fontSize: 18 }}>
                        {toCurrencyConvertor(Math.floor(total))}
                    </div>
                </div>
            </Col>

            {props.quote != undefined && props.quote === true ? null :
                <Button disabled={diserr || disnumerr} onClick={() => nextBtn()} className='next-button-travel'>{translate("home")["Next"]}</Button>}
        </Row>
    </>
)}
</div>


  )
}

export default OrderSummary