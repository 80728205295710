import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom"
import Footer from "../components/Footer/Footer"
import { SearchOutlined, ArrowLeftOutlined,RightOutlined} from "@ant-design/icons"
import { Tabs, Select,Col, Row, Badge, Button, Radio,Input,Card,Space, Image, Modal, Spin } from 'antd'
import "./oonaLeads.css"
import Car from '../assets/car-product.png'
import homeProd from '../assets/Product Icons.png'
import travelProd from '../assets/travel-prod.png'
import moment from "moment";
import ButtonImage from "../oonaImages/buttonIcon.png"
import Filled from "../oonaImages/Filled.png"
import callIcon from "../oonaImages/callIcon.png"
import emailIcon from "../oonaImages/emailIcon.png"
import rootIndex from "../store/root_index";
import { Base_URL } from '../config/BaseUrl'
import { useLanguage } from '../config/LanguageContext'
import axiosRequest from "../axios-request/request.methods";

const { Search } = Input;
const { store } = rootIndex;


function CommercialLeads() {
  const { Option } = Select

  const history = useHistory()
  const _store = store.getState();

  const [width, setWidth] = useState(window.innerWidth);
  const [leaddata, setLeadData] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filtersel, setFilterSel] = useState('All')
  const [sort, setSort] = useState('oldest');
  const [leadIp, setLeadIP] = useState([])
  const [inprogressarr, setInprogressArr] = useState([])
  const [completedarr, setCompletedArr] = useState([])
  const [expiredarr, setExpiredArr] = useState([])
  const [rejectedarr, setRejectedArr] = useState([])
  const [active, setActive] = useState('1')
  const [loading, setLoading] = useState(false)
  const [agent, setAgent] = useState('All');
  const [agentarr, setAgentArr] = useState([]);
  const [search, setSearch] = useState('');

  const onChange = (e) => {
    console.log('radio checked', e.target.value);
    setSort(e.target.value);
  };

  const handleAgents = () => {
    // let axiosConfig = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     'Authorization': 'Bearer ' + _store.login.token
    //   },
    // };

    var apiConfig = `sales/getAgents`

    axiosRequest
      .get(
        apiConfig
      )
      .then((data) => {
        console.log('datttaa',data);
        // console.log("Final response: of new lead", data.errMsg.result_valid_mobile[0]);
        if (data.errCode === -1) {
          console.log(data.errMsg, 'My performance API Response----->');
          let arr = data.errMsg
          setAgentArr(data.errMsg)
          // setCollPrefArr(arr)

          // if (myperfarr.length == 0) {
          //     setCollPrefArr(data.data.errMsg)
          // } else {
          //     setCollPrefArr([...data.data.errMsg])
          // }


        } else {
          setAgentArr([])
        }
      }).catch((error) => {
        console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

      });
  }


  const ResetFilter = () => {
    setFilterSel('All')
    setSort('')
    handleNext()
  }

  const ApplyFilter = () => {
    setLoading(true)
    console.log('yes======>')

    if (filtersel != '' && sort == '') {
      var apiConfig = `${Base_URL}secure/user/filterLeads?productId=${filtersel}`
    } else if (sort != '' && filtersel == '') {
      var apiConfig = `${Base_URL}secure/user/filterLeads?sort=${sort}`
    } else {
      var apiConfig = `${Base_URL}secure/user/filterLeads?productId=${filtersel}&sort=${sort}`
    }

    // let axiosConfig = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     'Authorization': 'Bearer ' + _store.login.token
    //   },
    // };

    // let formData = {
    //         fullName: name,
    //         primaryMobile: "9962998190",
    //         agentId: "50691737d386d8fadbd6b01d",
    //         productType: "Travel"
    //     }

    axiosRequest
      .get(
        apiConfig
      )
      .then((data) => {
        console.log("get lead  : ", data.errMsg.data);
        // console.log("Final response: of new lead", data.errMsg.result_valid_mobile[0]);
        if (data.errCode === -1) {
          setLoading(false)
          setLeadData(data.errMsg.data)
          let finalIn = data.errMsg.data.filter(item => {
            return item.leadStatus === 'In Progress'
          })

          let finalCom = data.errMsg.data.filter(item => {
            return item.leadStatus === 'Completed'
          })

          let finalEx = data.errMsg.data.filter(item => {
            return item.leadStatus === 'Expired'
          })

          let finalRj = data.errMsg.data.filter(item => {
            return item.leadStatus === 'Rejected'
          })

          if (finalIn.length > 0) {
            setInprogressArr(finalIn)
          }

          if (finalCom.length > 0) {
            setCompletedArr(finalCom)
          }

          if (finalEx.length > 0) {
            setExpiredArr(finalEx)
          }

          if (finalRj.length > 0) {
            setRejectedArr(finalRj)
          }
          // setSort('')
          setIsModalOpen(false)
        }
      }).catch((error) => {
        console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
        setIsModalOpen(false)
        // message.failure("OTP is invalid");

      });
  }

  const [view, setView] = useState(true);

  const showModal = () => {
    console.log('yesss');
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [formLayout, setFormLayout] = useState();
  const onFormLayoutChange = ({ layout }) => {
    setFormLayout(layout);
  };

  const breakpoint = 620;

  useEffect(() => {
    handleNext()
    handleAgents()
    // handleInprogress()
  }, [search])


  const handleNext = () => {
    setLoading(true)
    console.log(filtersel, 'filter selc----------->')
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + _store.login.token
      },
    };

    // let formData = {
    //         fullName: name,
    //         primaryMobile: "9962998190",
    //         agentId: "50691737d386d8fadbd6b01d",
    //         productType: "Travel"
    //     }

    const pattern = /^Q\d/;


    if (pattern.test(search)) {
      console.log("Pattern matches!");
      var apiConfig = `${Base_URL}secure/sales/filterAndSort?agentId=${agent}&productId=${filtersel}&sort=${sort}&quoteNo=${search}`
    } else {
      console.log("Pattern doesn't match.")
      var apiConfig = `${Base_URL}secure/sales/filterAndSort?agentId=${agent}&productId=${filtersel}&sort=${sort}&name=${search}`
    }

    axiosRequest
      .get(
        apiConfig
      )
      .then((data) => {
        console.log("get lead  : ", data.errMsg.data);
        console.log(data.errMsg.data[0].created_date, moment(
          parseInt(
            data.errMsg.data[0].created_date
          )
        ).format("DD MMM YYYY"));
        // console.log("Final response: of new lead", data.errMsg.result_valid_mobile[0]);
        if (data.errCode === -1) {
          setLoading(false)
          setLeadData(data.errMsg.data)
          setIsModalOpen(false)
          setFilterSel('All')

          let finalIn = data.errMsg.data.filter(item => {
            return item.leadStatus === 'In Progress'
          })

          let finalCom = data.errMsg.data.filter(item => {
            return item.leadStatus === 'Completed'
          })

          let finalEx = data.errMsg.data.filter(item => {
            return item.leadStatus === 'Expired'
          })

          let finalRj = data.errMsg.data.filter(item => {
            return item.leadStatus === 'Rejected'
          })

          if (finalIn.length > 0) {
            setInprogressArr(finalIn)
          }

          if (finalCom.length > 0) {
            setCompletedArr(finalCom)
          }

          if (finalEx.length > 0) {
            setExpiredArr(finalEx)
          }

          if (finalRj.length > 0) {
            setRejectedArr(finalRj)
          }
          //         history.push({
          // pathname: '/AOBmitraRegistrationForm',
          // state: {
          //   data: phone,
          //   key : data.data.errMsg.key
          // },
        }
      }).catch((error) => {
        console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
        // message.failure("OTP is invalid");

      });
  }

  const handleInprogress = () => {
    console.log(filtersel, 'filter selc----------->')
    // let axiosConfig = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NGE0MGIzOTE3NDdlNDUwYzEyYzUyNjUiLCJpYXQiOjE2ODg1NDYzMTh9.RtYpTgIy_BysjZ6Mi4A1sW638EZbaUJT0-9PqUjJUo4'
    //   },
    // };

    // let formData = {
    //         fullName: name,
    //         primaryMobile: "9962998190",
    //         agentId: "50691737d386d8fadbd6b01d",
    //         productType: "Travel"
    //     }

    axiosRequest
      .get(
        `user/getLeads?id=64a40b391747e450c12c5265&status=In Progress`,
      )
      .then((data) => {
        console.log("get lead  : ", data.errMsg.data);
        console.log(data.errMsg.data[0].created_date, moment(
          parseInt(
            data.errMsg.data[0].created_date
          )
        ).format("DD MMM YYYY"));
        // console.log("Final response: of new lead", data.errMsg.result_valid_mobile[0]);
        if (data.errCode === -1) {
          setLeadIP(data.errMsg.data)
          // setIsModalOpen(false)  
          // setFilterSel('All')
          //         history.push({
          // pathname: '/AOBmitraRegistrationForm',
          // state: {
          //   data: phone,
          //   key : data.data.errMsg.key
          // },
        }
      }).catch((error) => {
        console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
        // message.failure("OTP is invalid");

      });
  }

  const handleQuotes = (value, id) => {
    console.log(value, id, 'stage------>');
    if (value === 'Plan Selected') {
      history.push({
        pathname: "/chooseproducttravel",
        state: {
          quoteId: id,
          index: 1,
          covid : true
        }
      })
    } else if (value === 'Lead Created') {
      history.push({
        pathname: "/chooseproducttravel",
        state: {
          quoteId: id,
          index: 0
        }
      })
    } else if (value === 'Covid Benefit Selected') {
      history.push({
        pathname: "/additionalbenefittravel",
        state: {

          quoteId: id,
          index: 2
        }
      })
    } else if (value === 'Additional Benefit Selected') {
      history.push({
        pathname: "/ordersummarytravel",
        state: {

          quoteId: id,
          index: 3
        }
      })
    } else if (value === 'PromoCode Added') {
      history.push({
        pathname: "/oonacustomerinformation",
        state: {
          quoteId: id
        }
      })

    } else if (value === 'Quick Quote Created') {
      history.push({
        pathname: "/oonacustomerinformation",
        state: {

          quoteId: id
        }
      })
    } else if (value === 'Customer Information Updated') {
      history.push({
        pathname: "/oonaconfirmation",
        state: {

          quoteId: id
        }
      })
    } else if (value === 'Full Quote Created') {
      history.push({
        pathname: "/oonapayment",
        state: {

          quoteId: id
        }
      })
    } else if (value === 'Policy Generated') {
      history.push({
        pathname: "/oonasuccess1",
        state: {

          quoteId: id
        }
      })
    } else if (value === 'Full Quote Created With Payment') {
      history.push({
        pathname: "/oonasuccess1",
        state: {

          quoteId: id
        }
      })
    }
  }


  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, [width]);

  const onSearch = (value) => console.log(value);

  const handleTab = (val) => {
    console.log(val, 'tab active---->');
    setActive(val)
  }

  const path = () => {
    if (_store.login.userType === "Mitra") {
      history.push("/oonahome")
      setView(true)
    }
    else if (_store.login.userType === "Commercial") {
      history.push("/commercialhome")
      setView(false)
    }
    else if (_store.login.userType === "Support") {
      history.push("/commercialsupport")
      setView(false)
    }
    else {
      history.push("/oonahome")
      setView(true)
    }
  }

  const { translate } = useLanguage()

  return (
    <div >
      <Spin tip="Loading" spinning={loading} >
        <div className='main-content-body'>
          {width > breakpoint ? (
            <>
              <div onClick={path} className='arrow-back-font'><ArrowLeftOutlined width={20} color='#482c77' /><div className='arrow-back-font' style={{ fontSize: 14 }}>{translate("onboarding")["Back"]}</div></div>
              <div ><h5 className='heading'>{translate("referal partner")["leads"]}</h5></div>
            </>) :
            <>
              <Row>
                <Col xl={22} md={22} lg={22} xs={2} sm={2}>
                  <div className='arrow-back-font'><ArrowLeftOutlined width={20} color='#482c77' style={{ marginTop: 15, fontSize: 20 }} /></div>
                </Col>
                <Col xl={22} md={22} lg={22} xs={22} sm={22}>
                  <h5 className='heading' style={{ marginTop: 0 }}>{translate("referal partner")["leads"]}</h5>
                </Col>
              </Row>

            </>}

          <Tabs className='tab-inner-leads' centered onChange={(val) => handleTab(val)} activeKey={active}>
            <Tabs.TabPane tab={translate("referal partner")["all"]} key="1">
              <div className='cards'>
                <Row>
                  <Col xl={22} md={22} lg={22} xs={20} sm={20}>
                    <Input onChange={(e) => setSearch(e.target.value)} className='input-sales-width' suffix={<><SearchOutlined style={{ color: "#4AC6BB" }} /></>} placeholder={translate("referal partner")["searchNameQuoteCode"]}>
                    </Input>
                  </Col>
                  <Col xl={2} lg={2} sm={4} xs={4} md={2}>
                    <div style={{ width: 36, float: 'right', marginRight: 10 }} onClick={showModal}><Image src={ButtonImage} preview={false} /></div>
                  </Col>
                </Row>

                <Row style={{ marginTop: 15, justifyContent: 'center' }}>
                  {leaddata ? leaddata.map((item) => {
                    console.log(item.quotes[0].departureDate, 'dateeeeee');
                    return (
                      <Col xl={12} md={12} lg={12} xs={24} sm={24}>
                        <Card className='leads-card'>
                          <div style={{ borderLeft: item.quotes[0].product_Id === '64aff2f3831667919245f86c' ? '2px solid #482c77' : item.quotes[0].product_Id === '64c34807507810de0263ff63' ? '2px solid #dfff00' : '2px solid #f79838', borderTopLeftRadius: 5 }}>
                            <Row style={{ padding: 10, borderBottom: "1px solid lightgray" }}>
                              <Col xl={6} md={6} lg={6} xs={6} sm={6}>
                                <div>{moment(
                                  parseInt(
                                    item.created_date
                                  )
                                ).format("DD MMM YYYY")}
                                </div>
                              </Col>
                              <Col xl={1} md={1} lg={1} xs={1} sm={1}>
                                <Badge color='#87959e' />
                              </Col>
                              <Col xl={5} md={5} lg={5} xs={5} sm={5}>
                                <div>{item.lead_Id}</div>
                              </Col>
                              <Col xl={12} md={12} lg={12} xs={12} sm={12}>
                                <div className='header-right'><Badge count={item.leadStatus} style={{ backgroundColor: item.leadStatus === 'In Progress' ? '#f79838' : item.leadStatus === 'Expired' ? '#a3acb2' : '#5ca46a', borderRadius: 5, textTransform: "uppercase" }} /></div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl={4} md={4} lg={4} xs={4} sm={4}>
                                <div style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 35, paddingBottom: 35 }}>  <img src={item.quotes[0].product_Id == '64aff2f3831667919245f86c' ? travelProd : item.quotes[0].product_Id == '64c34807507810de0263ff63' ? homeProd : Car} alt='car' /></div>
                              </Col>
                              <Col xl={18} md={18} lg={18} xs={18} sm={18}>
                                <div style={{ padding: 10 }}>
                                  <div ><div style={{ fontSize: 16, fontWeight: 'bold' }}>{item.fullName}</div></div>
                                  <div ><div style={{ color: '#818f9a', fontSize: 14 }}>Auto Comprehensive Plus</div></div>
                                  <Row style={{ paddingTop: 10 }}>
                                    <Col xl={12} md={12} lg={12} xs={12} sm={12}>
                                      <div ><div style={{ color: '#818f9a', fontSize: 14, fontWeight: 500 }}>{translate("referal partner")["premium"]}</div></div>
                                      <div ><div style={{ fontSize: 16, fontWeight: 'bold' }}>Rp.13.536.000</div></div>
                                    </Col>
                                    <Col xl={12} md={12} lg={12} xs={12} sm={12}>
                                      <div > <div style={{ color: '#818f9a', fontSize: 14, fontWeight: 500 }}>{translate("referal partner")["expiry"]}</div></div>
                                      <div > <div style={{ fontSize: 16, fontWeight: 'bold' }}> {item.DaysLeft} Days</div></div>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              {/* departureDate */}
                              {(item.DaysLeft === 0 || (item.quotes[0].departureDate != undefined ? (new Date(item.quotes[0].departureDate.toString()) < new Date()) : item.DaysLeft === 0)) ? null :
                                <Col xl={2} md={2} lg={2} xs={2} sm={2}>
                                  <div style={{ padding: '40px 10px' }}><RightOutlined color='#482c77' width={20} style={{ color: '#54c9c0' }} onClick={() => handleQuotes(item.quotes[0].stage, item.quotes[0]._id)} /></div>
                                </Col>
                              }
                            </Row>
                          </div>
                          {view === true ? <>
                            <Row className='customer-card-inner-row'>
                              <Col xl={8} lg={8} sm={8} xs={8} md={8}>
                                <div>
                                  <Image src={Filled} /><b style={{ color: "#482c77", fontWeight: "bold" }}>{translate("referal partner")["message"]}</b>
                                </div>
                              </Col>
                              <Col xl={8} lg={8} sm={8} xs={8} md={8}>
                                <div>
                                  <Image src={callIcon} /><b style={{ color: "#482c77", fontWeight: "bold" }}>{translate("referal partner")["call"]}</b>
                                </div>
                              </Col>
                              <Col xl={8} lg={8} sm={8} xs={8} md={8}>
                                <div>
                                  <Image src={emailIcon} /><b style={{ color: "#482c77", fontWeight: "bold" }}>{translate("referal partner")["email"]}</b>
                                </div>
                              </Col>
                            </Row>
                          </> : ""}
                        </Card>
                      </Col>

                    )
                  })
                    : <div style={{ marginBottom: 100, marginTop: 100 }}>No Lead Found</div>}

                </Row>





              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab={translate("referal partner")["inProgress"]} key="2">
              <div className='cards'>
                <Row>
                  <Col xl={22} md={22} lg={22} xs={20} sm={20}>
                    <Search
                      placeholder={translate("referal partner")["searchNameQuoteCode"]}
                      onSearch={onSearch}

                    />
                  </Col>
                  <Col xl={2} lg={2} sm={4} xs={4} md={2}>
                    <div style={{ width: 36, float: 'right', marginRight: 10 }} onClick={showModal}><Image src={ButtonImage} preview={false} /></div>
                  </Col>
                </Row>

                <Row style={{ marginTop: 15, justifyContent: 'center' }}>
                  {inprogressarr.length > 0 ? inprogressarr.map((item) => {
                    return (
                      <Col xl={12} md={12} lg={12} xs={24} sm={24}>
                        <Card className='leads-card'>
                          <div style={{ borderLeft: item.quotes[0].product_Id === '64aff2f3831667919245f86c' ? '2px solid #482c77' : item.quotes[0].product_Id === '64c34807507810de0263ff63' ? '2px solid #dfff00' : '2px solid #f79838', borderTopLeftRadius: 5 }}>
                            <Row style={{ padding: 10, borderBottom: "1px solid lightgray" }}>
                              <Col xl={6} md={6} lg={6} xs={6} sm={6}>
                                <div>{moment(
                                  parseInt(
                                    item.created_date
                                  )
                                ).format("DD MMM YYYY")}
                                </div>
                              </Col>
                              <Col xl={1} md={1} lg={1} xs={1} sm={1}>
                                <Badge color='#87959e' />
                              </Col>
                              <Col xl={5} md={5} lg={5} xs={5} sm={5}>
                                <div>{item.lead_Id}</div>
                              </Col>
                              <Col xl={12} md={12} lg={12} xs={12} sm={12}>
                                <div className='header-right'><Badge count={item.leadStatus} style={{ backgroundColor: item.leadStatus === 'In Progress' ? '#f79838' : item.leadStatus === 'Expired' ? '#a3acb2' : '#5ca46a', borderRadius: 5 }} /></div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl={4} md={4} lg={4} xs={4} sm={4}>
                                <div style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 35, paddingBottom: 35 }}>  <img src={item.quotes[0].product_Id === '64aff2f3831667919245f86c' ? travelProd : item.quotes[0].product_Id === '64c34807507810de0263ff63' ? homeProd : Car} alt='car' /></div>
                              </Col>
                              <Col xl={18} md={18} lg={18} xs={18} sm={18}>
                                <div style={{ padding: 10 }}>
                                  <div ><div style={{ fontSize: 16, fontWeight: 'bold' }}>{item.fullName}</div></div>
                                  <div ><div style={{ color: '#818f9a', fontSize: 14 }}>Auto Comphrensive Plus</div></div>
                                  <Row style={{ paddingTop: 10 }}>
                                    <Col xl={12} md={12} lg={12} xs={12} sm={12}>
                                      <div ><div style={{ color: '#818f9a', fontSize: 14, fontWeight: 500 }}>{translate("referal partner")["premium"]}</div></div>
                                      <div ><div style={{ fontSize: 16, fontWeight: 'bold' }}>Rp.13.536.000</div></div>
                                    </Col>
                                    <Col xl={12} md={12} lg={12} xs={12} sm={12}>
                                      <div > <div style={{ color: '#818f9a', fontSize: 14, fontWeight: 500 }}>{translate("referal partner")["expiry"]}</div></div>
                                      <div > <div style={{ fontSize: 16, fontWeight: 'bold' }}> {item.DaysLeft} Days</div></div>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              {(item.DaysLeft === 0 || (item.quotes[0].departureDate != undefined ? (new Date(item.quotes[0].departureDate.toString()) < new Date()) : item.DaysLeft === 0)) ? null :
                                <Col xl={2} md={2} lg={2} xs={2} sm={2}>
                                  <div style={{ padding: '40px 10px' }}><RightOutlined color='#482c77' width={20} style={{ color: '#54c9c0' }} onClick={() => handleQuotes(item.quotes[0].stage, item.quotes[0]._id)} /></div>
                                </Col>
                              }
                            </Row>
                          </div>
                          {view === true ? <>
                            <Row className='customer-card-inner-row'>
                              <Col xl={8} lg={8} sm={8} xs={8} md={8}>
                                <div>
                                  <Image src={Filled} /><b style={{ color: "#482c77", fontWeight: "bold" }}>{translate("referal partner")["message"]}</b>
                                </div>
                              </Col>
                              <Col xl={8} lg={8} sm={8} xs={8} md={8}>
                                <div>
                                  <Image src={callIcon} /><b style={{ color: "#482c77", fontWeight: "bold" }}>{translate("referal partner")["call"]}</b>
                                </div>
                              </Col>
                              <Col xl={8} lg={8} sm={8} xs={8} md={8}>
                                <div>
                                  <Image src={emailIcon} /><b style={{ color: "#482c77", fontWeight: "bold" }}>{translate("referal partner")["email"]}</b>
                                </div>
                              </Col>
                            </Row>
                          </> : ""}
                        </Card>
                      </Col>

                    )
                  })
                    : <div style={{ marginBottom: 100, marginTop: 100 }}>No Lead Found</div>}

                </Row>





              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab={translate("referal partner")["completed"]} key="3">
              <div className='cards'>
                <Row>
                  <Col xl={22} md={22} lg={22} xs={20} sm={20}>
                    <Search
                      placeholder={translate("referal partner")["searchNameQuoteCode"]}
                      onSearch={onSearch}

                    />
                  </Col>
                  <Col xl={2} lg={2} sm={4} xs={4} md={2}>
                    <div style={{ width: 36, float: 'right', marginRight: 10 }} onClick={showModal}><Image src={ButtonImage} preview={false} /></div>
                  </Col>
                </Row>

                <Row style={{ marginTop: 15, justifyContent: 'center' }}>
                  {completedarr.length > 0 ? completedarr.map((item) => {
                    return (
                      <Col xl={12} md={12} lg={12} xs={24} sm={24}>
                        <Card className='leads-card'>
                          <div style={{ borderLeft: item.quotes[0].product_Id === '64aff2f3831667919245f86c' ? '2px solid #482c77' : item.quotes[0].product_Id === '64c34807507810de0263ff63' ? '2px solid #dfff00' : '2px solid #f79838', borderTopLeftRadius: 5 }}>
                            <Row style={{ padding: 10, borderBottom: "1px solid lightgray" }}>
                              <Col xl={6} md={6} lg={6} xs={6} sm={6}>
                                <div>{moment(
                                  parseInt(
                                    item.created_date
                                  )
                                ).format("DD MMM YYYY")}
                                </div>
                              </Col>
                              <Col xl={1} md={1} lg={1} xs={1} sm={1}>
                                <Badge color='#87959e' />
                              </Col>
                              <Col xl={5} md={5} lg={5} xs={5} sm={5}>
                                <div>{item.lead_Id}</div>
                              </Col>
                              <Col xl={12} md={12} lg={12} xs={12} sm={12}>
                                <div className='header-right'><Badge count={item.leadStatus} style={{ backgroundColor: item.leadStatus === 'In Progress' ? '#f79838' : item.leadStatus === 'Expired' ? '#a3acb2' : '#5ca46a', borderRadius: 5 }} /></div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl={4} md={4} lg={4} xs={4} sm={4}>
                                <div style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 35, paddingBottom: 35 }}>  <img src={item.quotes[0].product_Id === '64aff2f3831667919245f86c' ? travelProd : item.quotes[0].product_Id === '64c34807507810de0263ff63' ? homeProd : Car} alt='car' /></div>
                              </Col>
                              <Col xl={18} md={18} lg={18} xs={18} sm={18}>
                                <div style={{ padding: 10 }}>
                                  <div ><div style={{ fontSize: 16, fontWeight: 'bold' }}>{item.fullName}</div></div>
                                  <div ><div style={{ color: '#818f9a', fontSize: 14 }}>Auto Comphrensive Plus</div></div>
                                  <Row style={{ paddingTop: 10 }}>
                                    <Col xl={12} md={12} lg={12} xs={12} sm={12}>
                                      <div ><div style={{ color: '#818f9a', fontSize: 14, fontWeight: 500 }}>{translate("referal partner")["premium"]}</div></div>
                                      <div ><div style={{ fontSize: 16, fontWeight: 'bold' }}>Rp.13.536.000</div></div>
                                    </Col>
                                    <Col xl={12} md={12} lg={12} xs={12} sm={12}>
                                      <div > <div style={{ color: '#818f9a', fontSize: 14, fontWeight: 500 }}>{translate("referal partner")["expiry"]}</div></div>
                                      <div > <div style={{ fontSize: 16, fontWeight: 'bold' }}> {item.DaysLeft} Days</div></div>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              {(item.DaysLeft === 0 || (item.quotes[0].departureDate != undefined ? (new Date(item.quotes[0].departureDate.toString()) < new Date()) : item.DaysLeft === 0)) ? null :
                                <Col xl={2} md={2} lg={2} xs={2} sm={2}>
                                  <div style={{ padding: '40px 10px' }}><RightOutlined color='#482c77' width={20} style={{ color: '#54c9c0' }} onClick={() => handleQuotes(item.quotes[0].stage, item.quotes[0]._id)} /></div>
                                </Col>
                              }
                            </Row>
                          </div>
                          {view === true ? <>
                            <Row className='customer-card-inner-row'>
                              <Col xl={8} lg={8} sm={8} xs={8} md={8}>
                                <div>
                                  <Image src={Filled} /><b style={{ color: "#482c77", fontWeight: "bold" }}>{translate("referal partner")["message"]}</b>
                                </div>
                              </Col>
                              <Col xl={8} lg={8} sm={8} xs={8} md={8}>
                                <div>
                                  <Image src={callIcon} /><b style={{ color: "#482c77", fontWeight: "bold" }}>{translate("referal partner")["call"]}</b>
                                </div>
                              </Col>
                              <Col xl={8} lg={8} sm={8} xs={8} md={8}>
                                <div>
                                  <Image src={emailIcon} /><b style={{ color: "#482c77", fontWeight: "bold" }}>{translate("referal partner")["email"]}</b>
                                </div>
                              </Col>
                            </Row>
                            :</> : ""}
                        </Card>
                      </Col>

                    )
                  })
                    : <div style={{ marginBottom: 100, marginTop: 100 }}>No Lead Found</div>}

                </Row>





              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab={translate("referal partner")["rejected"]} key="4">
              <div className='cards'>
                <Row>
                  <Col xl={22} md={22} lg={22} xs={20} sm={20}>
                    <Search
                      placeholder={translate("referal partner")["searchNameQuoteCode"]}
                      onSearch={onSearch}

                    />
                  </Col>
                  <Col xl={2} lg={2} sm={4} xs={4} md={2}>
                    <div style={{ width: 36, float: 'right', marginRight: 10 }} onClick={showModal}><Image src={ButtonImage} preview={false} /></div>
                  </Col>
                </Row>

                <Row style={{ marginTop: 15, justifyContent: 'center' }}>
                  {rejectedarr.length > 0 ? rejectedarr.map((item) => {
                    return (
                      <Col xl={12} md={12} lg={12} xs={24} sm={24}>
                        <Card className='leads-card'>
                          <div style={{ borderLeft: item.quotes[0].product_Id === '64aff2f3831667919245f86c' ? '2px solid #482c77' : item.quotes[0].product_Id === '64c34807507810de0263ff63' ? '2px solid #dfff00' : '2px solid #f79838', borderTopLeftRadius: 5 }}>
                            <Row style={{ padding: 10, borderBottom: "1px solid lightgray" }}>
                              <Col xl={6} md={6} lg={6} xs={6} sm={6}>
                                <div>{moment(
                                  parseInt(
                                    item.created_date
                                  )
                                ).format("DD MMM YYYY")}
                                </div>
                              </Col>
                              <Col xl={1} md={1} lg={1} xs={1} sm={1}>
                                <Badge color='#87959e' />
                              </Col>
                              <Col xl={5} md={5} lg={5} xs={5} sm={5}>
                                <div>{item.lead_Id}</div>
                              </Col>
                              <Col xl={12} md={12} lg={12} xs={12} sm={12}>
                                <div className='header-right'><Badge count={item.leadStatus} style={{ backgroundColor: item.leadStatus === 'In Progress' ? '#f79838' : item.leadStatus === 'Expired' ? '#a3acb2' : '#5ca46a', borderRadius: 5 }} /></div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl={4} md={4} lg={4} xs={4} sm={4}>
                                <div style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 35, paddingBottom: 35 }}>  <img src={item.quotes[0].product_Id === '64aff2f3831667919245f86c' ? travelProd : item.quotes[0].product_Id == '64c34807507810de0263ff63' ? homeProd : Car} alt='car' /></div>
                              </Col>
                              <Col xl={18} md={18} lg={18} xs={18} sm={18}>
                                <div style={{ padding: 10 }}>
                                  <div ><div style={{ fontSize: 16, fontWeight: 'bold' }}>{item.fullName}</div></div>
                                  <div ><div style={{ color: '#818f9a', fontSize: 14 }}>Auto Comphrensive Plus</div></div>
                                  <Row style={{ paddingTop: 10 }}>
                                    <Col xl={12} md={12} lg={12} xs={12} sm={12}>
                                      <div ><div style={{ color: '#818f9a', fontSize: 14, fontWeight: 500 }}>{translate("referal partner")["premium"]}</div></div>
                                      <div ><div style={{ fontSize: 16, fontWeight: 'bold' }}>Rp.13.536.000</div></div>
                                    </Col>
                                    <Col xl={12} md={12} lg={12} xs={12} sm={12}>
                                      <div > <div style={{ color: '#818f9a', fontSize: 14, fontWeight: 500 }}>{translate("referal partner")["expiry"]}</div></div>
                                      <div > <div style={{ fontSize: 16, fontWeight: 'bold' }}> {item.DaysLeft} Days</div></div>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              {(item.DaysLeft === 0 || (item.quotes[0].departureDate != undefined ? (new Date(item.quotes[0].departureDate.toString()) < new Date()) : item.DaysLeft === 0)) ? null :
                                <Col xl={2} md={2} lg={2} xs={2} sm={2}>
                                  <div style={{ padding: '40px 10px' }}><RightOutlined color='#482c77' width={20} style={{ color: '#54c9c0' }} onClick={() => handleQuotes(item.quotes[0].stage, item.quotes[0]._id)} /></div>
                                </Col>
                              }
                            </Row>
                          </div>
                          {view === true ? <>
                            <Row className='customer-card-inner-row'>
                              <Col xl={8} lg={8} sm={8} xs={8} md={8}>
                                <div>
                                  <Image src={Filled} /><b style={{ color: "#482c77", fontWeight: "bold" }}>{translate("referal partner")["message"]}</b>
                                </div>
                              </Col>
                              <Col xl={8} lg={8} sm={8} xs={8} md={8}>
                                <div>
                                  <Image src={callIcon} /><b style={{ color: "#482c77", fontWeight: "bold" }}>{translate("referal partner")["call"]}</b>
                                </div>
                              </Col>
                              <Col xl={8} lg={8} sm={8} xs={8} md={8}>
                                <div>
                                  <Image src={emailIcon} /><b style={{ color: "#482c77", fontWeight: "bold" }}>{translate("referal partner")["email"]}</b>
                                </div>
                              </Col>
                            </Row>
                          </>
                            : <></>
                          }
                        </Card>
                      </Col>

                    )
                  })
                    : <div style={{ marginBottom: 100, marginTop: 100 }}>No Lead Found</div>}

                </Row>





              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab={translate("referal partner")["expired"]} key="5">
              <div className='cards'>
                <Row>
                  <Col xl={22} md={22} lg={22} xs={20} sm={20}>
                    <Search
                      placeholder={translate("referal partner")["searchNameQuoteCode"]}
                      onSearch={onSearch}

                    />
                  </Col>
                  <Col xl={2} lg={2} sm={4} xs={4} md={2}>
                    <div style={{ width: 36, float: 'right', marginRight: 10 }} onClick={showModal}><Image src={ButtonImage} preview={false} /></div>
                  </Col>
                </Row>

                <Row style={{ marginTop: 15, justifyContent: 'center' }}>
                  {expiredarr.length > 0 ? expiredarr.map((item) => {
                    return (
                      <Col xl={12} md={12} lg={12} xs={24} sm={24}>
                        <Card className='leads-card'>
                          <div style={{ borderLeft: item.quotes[0].product_Id === '64aff2f3831667919245f86c' ? '2px solid #482c77' : item.quotes[0].product_Id === '64c34807507810de0263ff63' ? '2px solid #dfff00' : '2px solid #f79838', borderTopLeftRadius: 5 }}>
                            <Row style={{ padding: 10, borderBottom: "1px solid lightgray" }}>
                              <Col xl={6} md={6} lg={6} xs={6} sm={6}>
                                <div>{moment(
                                  parseInt(
                                    item.created_date
                                  )
                                ).format("DD MMM YYYY")}
                                </div>
                              </Col>
                              <Col xl={1} md={1} lg={1} xs={1} sm={1}>
                                <Badge color='#87959e' />
                              </Col>
                              <Col xl={5} md={5} lg={5} xs={5} sm={5}>
                                <div>{item.lead_Id}</div>
                              </Col>
                              <Col xl={12} md={12} lg={12} xs={12} sm={12}>
                                <div className='header-right'><Badge count={item.leadStatus} style={{ backgroundColor: item.leadStatus === 'In Progress' ? '#f79838' : item.leadStatus === 'Expired' ? '#a3acb2' : '#5ca46a', borderRadius: 5 }} /></div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl={4} md={4} lg={4} xs={4} sm={4}>
                                <div style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 35, paddingBottom: 35 }}>  <img src={item.quotes[0].product_Id === '64aff2f3831667919245f86c' ? travelProd : item.quotes[0].product_Id === '64c34807507810de0263ff63' ? homeProd : Car} alt='car' /></div>
                              </Col>
                              <Col xl={18} md={18} lg={18} xs={18} sm={18}>
                                <div style={{ padding: 10 }}>
                                  <div ><div style={{ fontSize: 16, fontWeight: 'bold' }}>{item.fullName}</div></div>
                                  <div ><div style={{ color: '#818f9a', fontSize: 14 }}>Auto Comphrensive Plus</div></div>
                                  <Row style={{ paddingTop: 10 }}>
                                    <Col xl={12} md={12} lg={12} xs={12} sm={12}>
                                      <div ><div style={{ color: '#818f9a', fontSize: 14, fontWeight: 500 }}>{translate("referal partner")["premium"]}</div></div>
                                      <div ><div style={{ fontSize: 16, fontWeight: 'bold' }}>Rp.13.536.000</div></div>
                                    </Col>
                                    <Col xl={12} md={12} lg={12} xs={12} sm={12}>
                                      <div > <div style={{ color: '#818f9a', fontSize: 14, fontWeight: 500 }}>{translate("referal partner")["expiry"]}</div></div>
                                      <div > <div style={{ fontSize: 16, fontWeight: 'bold' }}> {item.DaysLeft} Days</div></div>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              {(item.DaysLeft === 0 || (item.quotes[0].departureDate != undefined ? (new Date(item.quotes[0].departureDate.toString()) < new Date()) : item.DaysLeft === 0)) ? null :
                                <Col xl={2} md={2} lg={2} xs={2} sm={2}>
                                  <div style={{ padding: '40px 10px' }}><RightOutlined color='#482c77' width={20} style={{ color: '#54c9c0' }} onClick={() => handleQuotes(item.quotes[0].stage, item.quotes[0]._id)} /></div>
                                </Col>
                              }
                            </Row>
                          </div>
                          {view === true ? <>
                            <Row className='customer-card-inner-row'>
                              <Col xl={8} lg={8} sm={8} xs={8} md={8}>
                                <div>
                                  <Image src={Filled} /><b style={{ color: "#482c77", fontWeight: "bold" }}>{translate("referal partner")["message"]}</b>
                                </div>
                              </Col>
                              <Col xl={8} lg={8} sm={8} xs={8} md={8}>
                                <div>
                                  <Image src={callIcon} /><b style={{ color: "#482c77", fontWeight: "bold" }}>{translate("referal partner")["call"]}</b>
                                </div>
                              </Col>
                              <Col xl={8} lg={8} sm={8} xs={8} md={8}>
                                <div>
                                  <Image src={emailIcon} /><b style={{ color: "#482c77", fontWeight: "bold" }}>{translate("referal partner")["email"]}</b>
                                </div>
                              </Col>
                            </Row>
                          </> : ""}
                        </Card>
                      </Col>

                    )
                  })
                    : <div style={{ marginBottom: 100, marginTop: 100 }}>No Lead Found</div>}

                </Row>





              </div>
            </Tabs.TabPane>
          </Tabs>
          <Modal className='radio-leads' visible={isModalOpen} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <div>
              <Row style={{ marginTop: 10 }}><div style={{ fontWeight: 'bold', fontSize: 18 }}>Agent Name</div></Row>
              <Row style={{ marginTop: 10 }}>

                <Select style={{ width: '100%' }} onChange={(value) => setAgent(value)} value={agent}>
                  <Option key={'All'} value={'All'}>All</Option>
                  {agentarr && agentarr?.data?.map(item => {
                    return (
                      <Option key={item._id} value={item._id}>{item.fullName}</Option>
                    )
                  })
                  }

                </Select>
              </Row>
              <Row style={{ marginTop: 10 }}><div style={{ fontWeight: 'bold', fontSize: 18 }}>{translate("commercial sales")["filterSort"]}</div></Row> 
              <Row style={{ marginTop: 10 }}><div style={{ fontWeight: 'bold', fontSize: 14, }}>{translate("commercial sales")["productType"]}</div></Row>
              <Radio.Group defaultValue={filtersel} value={filtersel} buttonStyle="solid" style={{ marginTop: 10 }} onChange={(e) => setFilterSel(e.target.value)}>
                <Radio.Button value="All">{translate("referal partner")["all"]}</Radio.Button>
                <Radio.Button value="64aff2f3831667919245f86c">{translate("referal partner")["travelProduct"]}</Radio.Button>
                <Radio.Button value="64c0fd38f6d4bddffcac8f71">{translate("referal partner")["motorVehicle"]}</Radio.Button>
                <Radio.Button value="64c34807507810de0263ff63">{translate("referal partner")["propertyProduct"]}</Radio.Button>
              </Radio.Group>

              <Row><div style={{ fontWeight: 'bold', fontSize: 14, marginTop: 10 }}>{translate("referal partner")["sort"]}</div></Row>

              <Radio.Group onChange={onChange} defaultValue={sort} value={sort} style={{ marginTop: 5 }}>
                <Space direction="vertical">
                  <Radio value={'Newest'}>{translate("referal partner")["newestToOldest"]}</Radio>
                  <Radio value={'Oldest'}>{translate("referal partner")["oldestToNewest"]}</Radio>
                  <Radio value={'Highest'}>{translate("referal partner")["highToLowPremiumAmount"]}</Radio>
                  <Radio value={'Lowest'}>{translate("referal partner")["lowToHighPremiumAmount"]}</Radio>
                </Space>
              </Radio.Group>

              <Row style={{ marginTop: 15 }}>
                <Col xl={12} md={12} lg={12} xs={12} sm={12}>
                  <Button className='no-button' onClick={() => ResetFilter()}>{translate("referal partner")["reset"]}</Button>
                </Col>
                <Col xl={12} md={12} lg={12} xs={12} sm={12}>
                  <Button className='next-button-travel' onClick={() => handleNext()}>{translate("referal partner")["apply"]}</Button>
                </Col>
              </Row>


            </div>
          </Modal>
        </div>
        <Footer />
      </Spin>
    </div>
  )
}

export default CommercialLeads