import React, { useState, useEffect } from 'react'
import { Row, Col, Card, Button, Input } from "antd"
import { useHistory } from "react-router-dom"
import rootIndex from "../../../store/root_index";
import { toast } from 'react-toastify';
import { useLanguage } from "../../../config/LanguageContext"
import { useLocation } from 'react-router-dom';
import axiosRequest from '../../../axios-request/request.methods'
import { useDispatch, useSelector } from 'react-redux';
import * as action from '../../../store/actions/index'

const { store } = rootIndex;

function OrderSummary(props) {
    const fetchingWholeDatafromStore=useSelector((state)=>state?.cipherlinkreducer?.quote)
    const [width, setWidth] = useState(window.innerWidth);
    const breakpoint = 600;

    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, [width]);



    const history = useHistory();


    const dispatch=useDispatch()
    const _store = store.getState();
    const { translate } = useLanguage()
    const [num, setNum] = useState(0);
    const [show, setShow] = useState(true);
    const [promo, setPromo] = useState('')
    const [discountpercent, setDiscountPercent] = useState('')
    const [total, setTotal] = useState('')
    const [planname, setPlanName] = useState('')
    const [premium, setPremium] = useState('')
    const [addbnft, setAddbnft] = useState([])
    const [bnftcalc, setBnftCalc] = useState('')
    const [diserr, setDisErr] = useState(false)
    const [disnumerr, setdisNumErr] = useState(false)
    const [disable, setDisable] = useState(false)
    const [promoarr, setPromoArr] = useState(false)
    const [promocodevalidation, setpromoCodeValiadtionState] = useState(true)

    useEffect(() => {
        if (_store?.cipherlinkreducer?.formdata?.cipherkeyavilable === true) {
            setShow(false)
        } else {
            setShow(true)
        }

        // if (props.planName != '' && props.premium != '') {
        //     console.log((props.planName, 'no enter quotes'));
        //     setPlanName(props.planName)
        //     setPremium(props.premium)
        //     setAddbnft(props.addbnft)
        //     setBnftCalc(props.bnftcalc)
        //     const calculatingDiscountAmount = (Math.round((bnftcalc + parseInt(premium)) * (Number(0)) / 100))
        //     console.log(calculatingDiscountAmount, "calculatingDiscountAmount")
        //     const calculatingTotalAmount = Math.floor((bnftcalc + parseInt(premium)) - (calculatingDiscountAmount))
        //     console.log(calculatingTotalAmount, "calculatingTotalAmount")
        //     setDiscountPercent(parseInt(calculatingDiscountAmount))
        //     setTotal((calculatingTotalAmount))
        // } else {
        //     console.log(('yes enter quotes'));
            
        // }
    }, [_store?.cipherlinkreducer?.formdata?.cipherkeyavilable])

    useEffect(()=>{
        if(fetchingWholeDatafromStore?.quoteId !== ""){
            getQuotes()
        }

    },[fetchingWholeDatafromStore])



    const handlePromo = (value) => {
        setPromo(value)
        if (value?.length === 19) {
            promocodeValidate(value)
            setpromoCodeValiadtionState(true)
        }
        else {
            toast.error('Invalid promo code', { position: "top-center", hideProgressBar: true, autoClose: 1000 })
            setpromoCodeValiadtionState(false)
        }
    }

    //console.log(props.cipher, "akshay")

    const getQuotes = () => {
        var fromReferral = ""
        if (fetchingWholeDatafromStore?.resumeType === "referalJourney" || fetchingWholeDatafromStore?.resumeType === "quoteSearch") {
            fromReferral = `user/get-quotes?id=${fetchingWholeDatafromStore.quoteId}&logkey=${fetchingWholeDatafromStore?.resumeType}`
        }
        else {
            fromReferral = `user/get-quotes?id=${fetchingWholeDatafromStore.quoteId}`
        }

        axiosRequest
            .get(
                `${fromReferral}`,
            )
            .then((data) => {
                console.log(data.errMsg.data[0].discountPercent,data.errMsg.data[0].premium,"akshay")
                setPremium(data.errMsg.data[0].premium)
                setPlanName(data.errMsg.data[0].planName)
                setAddbnft(data.errMsg.data[0].additionalBenefits)
                if (data.errMsg.data[0].discountPercent !== undefined) {
                    setNum(data.errMsg.data[0].discountPercent)
                } else {
                    setNum(0)
                }
                setDisErr(false)
                setdisNumErr(false)
                if (data.errMsg.data[0].additionalBenefits.length > 0) {
                    var finalValue = 0
                    data.errMsg.data[0].additionalBenefits.map(item => {
                        finalValue = finalValue + parseInt(item.Premium)
                    })
                    if (finalValue !== 0) {
                        console.log(finalValue, 'final calc value-------->');
                        setBnftCalc(finalValue)
                        if (_store?.cipherlinkreducer?.formdata?.cipherkeyavilable === true) {
                            if (data?.errMsg?.data?.[0].promoCodeList && data?.errMsg.data?.[0].promoCodeList?.length > 0) {
                              
                                console.log(data.errMsg.data[0].promoCodeList[0].discountPercent,"cbfgyud")
                                console.log(premium,"premium in kjsh")
                                console.log(bnftcalc,"bnftcalcinbkjdfb")
                                setPromo(data.errMsg.data[0].promoCodeList[0].promoCode)
                                setDisable(true)
                                setPromoArr(true)
                                // setDiscountPercent((Math.round((finalValue + parseInt(data.errMsg.data[0].premium)) * (Number(data.errMsg.data[0].promoCodeList[0].discountPercent))  / 100)))
                                // setTotal(Math.round(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) - ((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].promoCodeList[0].discountPercent / 100)))))
                                const calculatingDiscountAmount = (Math.round((finalValue + parseInt(data.errMsg.data[0].premium)) * (Number(data?.errMsg?.data?.[0]?.promoCodeList?.[0].discountPercent)) / 100))
                                console.log(calculatingDiscountAmount, "calculatingDiscountAmount")
                                const calculatingTotalAmount = Math.floor((finalValue + parseInt(data.errMsg.data[0].premium)) - (calculatingDiscountAmount))
                                console.log(calculatingTotalAmount, "calculatingTotalAmount")
                                setDiscountPercent(parseInt(calculatingDiscountAmount))
                                setTotal((calculatingTotalAmount))
                            } else {
                                // if (data.errMsg.data[0].discountPercent === 0) {
                                  
                                    // setDiscountPercent((Math.round((finalValue + parseInt(data.errMsg.data[0].premium)) * (Number(0))  / 100)))
                                    // setTotal(Math.round(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) - ((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (0 / 100)))))
                                    const calculatingDiscountAmount = (Math.round((finalValue + parseInt(data.errMsg.data[0].premium)) * (Number(0)) / 100))
                                    console.log(calculatingDiscountAmount, premium, bnftcalc, data.errMsg.data[0].discountPercent, "calculatingDiscountAmount")
                                    const calculatingTotalAmount = Math.floor((finalValue + parseInt(data.errMsg.data[0].premium)) - (calculatingDiscountAmount))
                                    console.log(calculatingTotalAmount, "calculatingTotalAmount")
                                    setDiscountPercent(parseInt(calculatingDiscountAmount))
                                    setTotal((calculatingTotalAmount))
                                // }
                                // else {
                              
                                //     // setDiscountPercent((Math.round((finalValue + parseInt(data.errMsg.data[0].premium)) * (Number(data.errMsg.data[0].discountPercent))  / 100)))
                                //     // setTotal(Math.round(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) - ((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].discountPercent / 100)))))
                                //     const calculatingDiscountAmount = (Math.round((bnftcalc + parseInt(premium)) * (Number(data.errMsg.data[0].discountPercent)) / 100))
                                //     console.log(calculatingDiscountAmount, "calculatingDiscountAmount")
                                //     const calculatingTotalAmount = Math.floor((bnftcalc + parseInt(premium)) - (calculatingDiscountAmount))
                                //     console.log(calculatingTotalAmount, "calculatingTotalAmount")
                                //     setDiscountPercent(parseInt(calculatingDiscountAmount))
                                //     setTotal((calculatingTotalAmount))
                                // }
                            }
                        } else {
                            if (data.errMsg.data[0].discountPercent !== undefined) {
                                // setDiscountPercent((Math.round((finalValue + parseInt(data.errMsg.data[0].premium)) * (Number(data.errMsg.data[0].discountPercent))  / 100)))
                                // setTotal(Math.round(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) - ((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].discountPercent / 100)))))
                                const calculatingDiscountAmount = (Math.round((finalValue + parseInt(data.errMsg.data[0].premium)) * (Number(data.errMsg.data[0].discountPercent)) / 100))
                                console.log(calculatingDiscountAmount, "calculatingDiscountAmount")

                                const calculatingTotalAmount = Math.floor((finalValue + parseInt(data.errMsg.data[0].premium)) - (calculatingDiscountAmount))
                                console.log(calculatingTotalAmount, "calculatingTotalAmount")

                                setDiscountPercent(parseInt(calculatingDiscountAmount))
                                setTotal((calculatingTotalAmount))

                            } else {

                                // setDiscountPercent((Math.round((finalValue + parseInt(data.errMsg.data[0].premium)) * (Number(0))  / 100)))

                                // setTotal(Math.round(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) - ((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (0 / 100)))))

                                const calculatingDiscountAmount = (Math.round((finalValue + parseInt(data.errMsg.data[0].premium)) * (Number(0)) / 100))
                                console.log(calculatingDiscountAmount, "calculatingDiscountAmount")

                                const calculatingTotalAmount = Math.floor((finalValue + parseInt(data.errMsg.data[0].premium)) - (calculatingDiscountAmount))
                                console.log(calculatingTotalAmount, "calculatingTotalAmount")

                                setDiscountPercent(parseInt(calculatingDiscountAmount))
                                setTotal((calculatingTotalAmount))
                            }
                        }
                    } else {
                        if (_store?.cipherlinkreducer?.formdata?.cipherkeyavilable === true) {
                            if (data.errMsg.data[0].promoCodeList && data.errMsg.data[0].promoCodeList.length > 0) {
                                setPromo(data.errMsg.data[0].promoCodeList[0].promoCode != undefined ? data.errMsg.data[0].promoCodeList[0].promoCode : '')
                                setDisable(true)
                                setPromoArr(true)
                                // setDiscountPercent((Math.round((finalValue + parseInt(data.errMsg.data[0].premium)) * (Number(data.errMsg.data[0].promoCodeList[0].discountPercent))  / 100)))
                                // setTotal(Math.round(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) - ((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].promoCodeList[0].discountPercent / 100)))))
                                const calculatingDiscountAmount = (Math.round((finalValue + parseInt(data.errMsg.data[0].premium)) * (Number(data.errMsg.data[0].promoCodeList[0].discountPercent)) / 100))
                                console.log(calculatingDiscountAmount, "calculatingDiscountAmount")

                                const calculatingTotalAmount = Math.floor((finalValue + parseInt(data.errMsg.data[0].premium)) - (calculatingDiscountAmount))
                                console.log(calculatingTotalAmount, "calculatingTotalAmount")

                                setDiscountPercent(parseInt(calculatingDiscountAmount))
                                setTotal((calculatingTotalAmount))

                            } else {
                                // setDiscountPercent((Math.round((finalValue + parseInt(data.errMsg.data[0].premium)) * (Number(0))  / 100)))
                                // setTotal(Math.round(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) - ((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (0 / 100)))))

                                const calculatingDiscountAmount = (Math.round((finalValue + parseInt(data.errMsg.data[0].premium)) * (Number(0)) / 100))
                                console.log(calculatingDiscountAmount, data.errMsg.data[0].premium, finalValue, "calculatingDiscountAmount")

                                const calculatingTotalAmount = Math.floor((finalValue + parseInt(data.errMsg.data[0].premium)) - (calculatingDiscountAmount))
                                console.log(calculatingTotalAmount, "calculatingTotalAmount")

                                setDiscountPercent(parseInt(calculatingDiscountAmount))
                                setTotal((calculatingTotalAmount))
                            }
                        } else {
                            if (data.errMsg.data[0].discountPercent !== undefined) {
                                // setDiscountPercent((Math.round((finalValue + parseInt(data.errMsg.data[0].premium)) * (Number(data.errMsg.data[0].discountPercent))  / 100)))
                                // setTotal(Math.round(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) - ((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].discountPercent / 100)))))

                                const calculatingDiscountAmount = (Math.round((finalValue + parseInt(data.errMsg.data[0].premium)) * (Number(data.errMsg.data[0].discountPercent)) / 100))
                                console.log(calculatingDiscountAmount, "calculatingDiscountAmount")

                                const calculatingTotalAmount = Math.floor((finalValue + parseInt(data.errMsg.data[0].premium)) - (calculatingDiscountAmount))
                                console.log(calculatingTotalAmount, "calculatingTotalAmount")

                                setDiscountPercent(parseInt(calculatingDiscountAmount))
                                setTotal((calculatingTotalAmount))

                            } else {
                                // setDiscountPercent((Math.round((finalValue + parseInt(data.errMsg.data[0].premium)) * (Number(data.errMsg.data[0].premium))  / 100)))
                                // setTotal(Math.round(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) - ((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (0 / 100)))))

                                const calculatingDiscountAmount = (Math.round((finalValue + parseInt(data.errMsg.data[0].premium)) * (Number(data.errMsg.data[0].premium)) / 100))
                                console.log(calculatingDiscountAmount, "calculatingDiscountAmount")

                                const calculatingTotalAmount = Math.floor((finalValue + parseInt(data.errMsg.data[0].premium)) - (calculatingDiscountAmount))
                                console.log(calculatingTotalAmount, "calculatingTotalAmount")

                                setDiscountPercent(parseInt(calculatingDiscountAmount))
                                setTotal((calculatingTotalAmount))
                            }
                        }
                    }
                } else {
                    setBnftCalc(0)
                    if (_store?.cipherlinkreducer?.formdata?.cipherkeyavilable === true) {
                        if (data.errMsg.data[0].promoCodeList && data.errMsg.data[0].promoCodeList.length > 0) {
                            setPromo(data.errMsg.data[0].promoCodeList[0].promoCode != undefined ? data.errMsg.data[0].promoCodeList[0].promoCode : '')
                            setDisable(true)
                            setPromoArr(true)
                            // setDiscountPercent((Math.round((data.errMsg.data[0].premium)) * (Number(data.errMsg.data[0].promoCodeList[0].discountPercent))  / 100))
                            // setTotal(Math.round(((parseInt(data.errMsg.data[0].premium)) - ((parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].promoCodeList[0].discountPercent / 100)))))

                            const calculatingDiscountAmount = (Math.round((data.errMsg.data[0].premium) * (Number(data.errMsg.data[0].promoCodeList[0].discountPercent)) / 100))
                            console.log(calculatingDiscountAmount, "calculatingDiscountAmount")

                            const calculatingTotalAmount = Math.floor((data.errMsg.data[0].premium) - (calculatingDiscountAmount))
                            console.log(calculatingTotalAmount, "calculatingTotalAmount")

                            setDiscountPercent(parseInt(calculatingDiscountAmount))
                            setTotal((calculatingTotalAmount))
                        } else {
                            if (data.errMsg.data[0].promoCodeList && data.errMsg.data[0].promoCodeList.length == [] && data.errMsg.data[0].discountPercent > 0) {
                                // setDiscountPercent((Math.round((data.errMsg.data[0].premium)) * (Number(data.errMsg.data[0].discountPercent))  / 100))
                                // setTotal(Math.round(((parseInt(data.errMsg.data[0].premium)) - ((parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].discountPercent / 100)))))
                                const calculatingDiscountAmount = (Math.round((data.errMsg.data[0].premium) * (Number(data.errMsg.data[0].discountPercent)) / 100))
                                console.log(calculatingDiscountAmount, "calculatingDiscountAmount")

                                const calculatingTotalAmount = Math.floor((data.errMsg.data[0].premium) - (calculatingDiscountAmount))
                                console.log(calculatingTotalAmount, "calculatingTotalAmount")

                                setDiscountPercent(parseInt(calculatingDiscountAmount))
                                setTotal((calculatingTotalAmount))

                            }
                            else {
                                // setDiscountPercent((Math.round((data.errMsg.data[0].premium)) * (Number(0))  / 100))
                                // setTotal(Math.round(((parseInt(data.errMsg.data[0].premium)) - ((parseInt(data.errMsg.data[0].premium)) * (0 / 100)))))

                                const calculatingDiscountAmount = (Math.round((data.errMsg.data[0].premium) * (Number(0)) / 100))
                                console.log(calculatingDiscountAmount, "calculatingDiscountAmount")

                                const calculatingTotalAmount = Math.floor((data.errMsg.data[0].premium) - (calculatingDiscountAmount))
                                console.log(calculatingTotalAmount, "calculatingTotalAmount")

                                setDiscountPercent(parseInt(calculatingDiscountAmount))
                                setTotal((calculatingTotalAmount))
                            }
                        }
                    } else {
                        if (data.errMsg.data[0].discountPercent != undefined) {
                            // setDiscountPercent((Math.round((data.errMsg.data[0].premium)) * (Number(data.errMsg.data[0].discountPercent))  / 100))
                            // setTotal(Math.round(((parseInt(data.errMsg.data[0].premium)) - ((parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].discountPercent / 100)))))

                            const calculatingDiscountAmount = (Math.round((data.errMsg.data[0].premium) * (Number(data.errMsg.data[0].discountPercent)) / 100))
                            console.log(calculatingDiscountAmount, "calculatingDiscountAmount")

                            const calculatingTotalAmount = Math.floor((data.errMsg.data[0].premium) - (calculatingDiscountAmount))
                            console.log(calculatingTotalAmount, "calculatingTotalAmount")

                            setDiscountPercent(parseInt(calculatingDiscountAmount))
                            setTotal((calculatingTotalAmount))
                        } else {

                            const calculatingDiscountAmount = (Math.round((data.errMsg.data[0].premium) * (Number(0)) / 100))
                            console.log(calculatingDiscountAmount, "calculatingDiscountAmount")

                            const calculatingTotalAmount = Math.floor((data.errMsg.data[0].premium) - (calculatingDiscountAmount))
                            console.log(calculatingTotalAmount, "calculatingTotalAmount")

                            setDiscountPercent(parseInt(calculatingDiscountAmount))
                            setTotal((calculatingTotalAmount))
                            // setDiscountPercent((Math.round((data.errMsg.data[0].premium)) * (Number(0))  / 100))
                            // setTotal(Math.round(((parseInt(data.errMsg.data[0].premium)) - ((parseInt(data.errMsg.data[0].premium)) * (0 / 100)))))
                        }
                    }
                }

            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

            });
    }

    const promocodeValidate = (value) => {
       

        let formData = {
            promoCode: value
        }


        console.log(formData, 'form data--------->');

        axiosRequest
            .post(
                `user/validate-promocode`,
                formData,

            )
            .then((data) => {
                setDisable(true)
                // setDiscountPercent((Math.round((bnftcalc + parseInt(premium)) * (Number(data.errMsg.discount))  / 100)))
                // setTotal(Math.round(((parseInt(bnftcalc) + parseInt(premium)) - ((parseInt(bnftcalc) + parseInt(premium)) * (data.errMsg.discount / 100)))))

                const calculatingDiscountAmount = (Math.round((bnftcalc + parseInt(premium)) * (Number(data.errMsg.discount)) / 100))
                console.log(calculatingDiscountAmount, "calculatingDiscountAmount")

                const calculatingTotalAmount = Math.floor((bnftcalc + parseInt(premium)) - (calculatingDiscountAmount))
                console.log(calculatingTotalAmount, "calculatingTotalAmount")

                setDiscountPercent(parseInt(calculatingDiscountAmount))
                setTotal((calculatingTotalAmount))
                toast.success(`${translate("home")["PromoCode validated Successfully"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })

            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
                toast.error(`${translate("home")["Network Request Failed"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
            });
    }

    const promocodeSubmit = () => {
        if (promoarr === true) {
            quickQuote()
        } else {
            if (promo.length === 19) {
                // quickQuote()
                props.handleLoading(true)
                let formData = {
                    promoCodeList: JSON.parse(JSON.stringify([promo]))
                }
                console.log(formData, 'form data--of promocode------->');
                axiosRequest
                    .patch(
                        `user/update-quote/${fetchingWholeDatafromStore?.quoteId}`,
                        formData,

                    )
                    .then((data) => {
                        quickQuote()
                        toast.success(`${translate("home")["Quote Updated Successfully"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })

                    }).catch((error) => {
                        console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
                        toast.error(`${translate("home")["Network Request Failed"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
                    });
            }
            else {
                quickQuote()
            }
        }
    }

    const discountSubmit = () => {
        props.handleLoading(true)


        let formData = {
            discountPercent: num
        }

        console.log(formData, 'form data--------->');

        axiosRequest
            .patch(
                `user/update-quote/${fetchingWholeDatafromStore?.quoteId}`,
                formData,

            )
            .then((data) => {
                quickQuote()
                toast.success(`${translate("home")["Quote Updated Successfully"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })


            }).catch((error) => {
                history.push({
                    pathname: '/oonaerror',
                    // state: {
                    //     data: 'travel',
                    //     quoteId: props.quoteId,
                    // },
                })
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
                toast.error(`${translate("home")["Network Request Failed"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
            });

    }

    const quickQuote = () => {
        axiosRequest
            .get(
                `user/quick-quote/${fetchingWholeDatafromStore?.quoteId}`,

            )
            .then((data) => {
                // console.log("Final response: of new lead", data.errMsg.result_valid_mobile[0]);

                props.handleLoading(false)
                console.log('quick quote success---->');

                if (_store?.cipherlinkreducer?.formdata?.cipherkeyavilable === true) {
                    history.push({
                        pathname: '/oonacustomerinformation',
                        // state: {
                        //     quoteId: props.quoteId,
                        //     data: location?.state?.data,
                        //     quoteCode: data.errMsg.data.quote_Id,
                        //     phone: props?.phone != undefined ? props?.phone : null,
                        // }
                    })
                } else {
                   if(fetchingWholeDatafromStore?.resumeType === "referalJourney"){
                    let Data={
                        ...fetchingWholeDatafromStore,
                          resumeType:`travel${fetchingWholeDatafromStore?.resumeType}`
                    }
                    dispatch(action.set_quote_id(Data))

                   }
                   else if (fetchingWholeDatafromStore?.resumeType === "quoteSearch"){
                    let Data={
                        ...fetchingWholeDatafromStore,
                          resumeType:`travel${fetchingWholeDatafromStore?.resumeType}`
                    }
                    dispatch(action.set_quote_id(Data))

                   }
                   else{
                    let Data={
                        ...fetchingWholeDatafromStore,
                          resumeType:"travel",
                          quote_Id:data?.errMsg?.data?.quote_Id
                    }
                    dispatch(action.set_quote_id(Data))

                   }
                   
                    history.push({
                        pathname: '/oonasuccess',
                        // state: {
                        //     quoteId: props.quoteId,
                        //     //data:location.state.data == undefined ? 'travel' : `travel${location?.state?.data}`,
                        //     data: location.state.data === "referalJourney" ? `travel${location?.state?.data}` : location?.state?.data === "quoteSearch" ? `travel${location?.state?.data}` : "travel",
                        //     quoteCode: data.errMsg.data.quote_Id
                        // }
                    })
                }
                toast.success(`${translate("home")["Quick Quote Generated Successfully"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })


            }).catch((error) => {
                history.push({
                    pathname: '/oonaerror',
                    // state: {
                    //     data: 'travel',
                    //     quoteId: props.quoteId,
                    // },
                })
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
                props.handleLoading(false)
            });

    }


    let incNum = () => {
        console.log(num, 'numm------>');
        if (diserr === false && disnumerr === false) {
            if (num < 30) {
                if ((Number(num) + 0.5) <= 30) {
                    console.log(num, parseInt(bnftcalc), parseInt(premium), 'numm------>');
                    setNum(Number(num) + 0.5);


                    const calculatingDiscountAmount = (Math.round((bnftcalc + parseInt(premium)) * (Number(num) + 0.5) / 100))
                    console.log(calculatingDiscountAmount, "calculatingDiscountAmount")

                    const calculatingTotalAmount = Math.floor((bnftcalc + parseInt(premium)) - (calculatingDiscountAmount))
                    console.log(calculatingTotalAmount, "calculatingTotalAmount")

                    setDiscountPercent(parseInt(calculatingDiscountAmount))
                    setTotal((calculatingTotalAmount))

                } else {
                    toast.error('Discount will be increse in the interval of 0.5, maximum discount is 30% only', { position: "top-center", hideProgressBar: true, autoClose: 1000 })
                }
            } else {
                toast.error('Discount is upto 30% only', { position: "top-center", hideProgressBar: true, autoClose: 1000 })
            }
        } else {
            if (diserr === true) {
                toast.error(`${translate("home")["Please Enter Only Numbers"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
            }
            if (disnumerr === true) {
                toast.error('Please enter numbers between 0 to 30', { position: "top-center", hideProgressBar: true, autoClose: 1000 })
            }
        }
    };
    let decNum = () => {
        if (diserr === false && disnumerr === false) {
            if (num >= 0.5) {
                if ((Number(num) - 0.5) >= 0) {
                    setNum(num - 0.5);
                    const calculatingDiscountAmount = (Math.round((bnftcalc + parseInt(premium)) * (Number(num) - 0.5) / 100))
                    console.log(calculatingDiscountAmount, "calculatingDiscountAmount")

                    const calculatingTotalAmount = Math.floor((bnftcalc + parseInt(premium)) - (calculatingDiscountAmount))
                    console.log(calculatingTotalAmount, "calculatingTotalAmount")

                    setDiscountPercent(parseInt(calculatingDiscountAmount))
                    setTotal((calculatingTotalAmount))
                } else {
                    toast.error('Discount will be decrease in the interval of 0.5, discount cannot be negative', { position: "top-center", hideProgressBar: true, autoClose: 1000 })
                }
            } else {
                toast.error(`${translate("home")["Discount cannot be negative"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
            }
        } else {
            if (diserr === true) {
                toast.error(`${translate("home")["Please Enter Only Numbers"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
            }
            if (disnumerr === true) {
                toast.error('Please enter numbers between 0 to 30', { position: "top-center", hideProgressBar: true, autoClose: 1000 })
            }
        }
        // if (num >= 0.5) {
        //     setNum(num - 0.5);
        //     setDiscountPercent(Math.round((parseInt(bnftcalc) + parseInt(premium)) * ((Number(num) - 0.5) / 100)))
        //     setTotal(Math.round(((parseInt(bnftcalc) + parseInt(premium)) - ((parseInt(bnftcalc) + parseInt(premium)) * ((Number(num) - 0.5) / 100)))))
        // }else{
        //     message.error('Discount cannot be negative value')
        // }
    }
    let handleChange = (val) => {
        const reg = new RegExp('^(0(\\.\\d{1,2})?|[1-9][0-9]?|30)$');
        console.log(reg.test(val), typeof (val));
        if (reg.test(val) === true) {
            if (val.length <= 4) {
                if (Number(val) <= 30 && Number(val) >= 0) {
                    setNum(val);
                    setDisErr(false)
                    setdisNumErr(false)
                    // setDiscountPercent((Math.round((bnftcalc + parseInt(premium)) * (Number(val))  / 100)))
                    // //setDiscountPercent(Math.round((parseInt(bnftcalc) + parseInt(premium)) * ((Number(val)) / 100)))
                    // setTotal(Math.round(((parseInt(bnftcalc) + parseInt(premium)) - ((parseInt(bnftcalc) + parseInt(premium)) * ((Number(val)) / 100)))))
                    const calculatingDiscountAmount = (Math.round((bnftcalc + parseInt(premium)) * (Number(val)) / 100))
                    console.log(calculatingDiscountAmount, "calculatingDiscountAmount")

                    const calculatingTotalAmount = Math.floor((bnftcalc + parseInt(premium)) - (calculatingDiscountAmount))
                    console.log(calculatingTotalAmount, "calculatingTotalAmount")

                    setDiscountPercent(parseInt(calculatingDiscountAmount))
                    setTotal((calculatingTotalAmount))

                } else {
                    setNum(30);
                    setdisNumErr(false)
                    setDisErr(false)
                    // setDiscountPercent((Math.round((bnftcalc + parseInt(premium)) * (Number(30))  / 100)))
                    // //setDiscountPercent(Math.round((parseInt(bnftcalc) + parseInt(premium)) * (30 / 100)))
                    // setTotal(Math.round(((parseInt(bnftcalc) + parseInt(premium)) - ((parseInt(bnftcalc) + parseInt(premium)) * (30 / 100)))))

                    const calculatingDiscountAmount = (Math.round((bnftcalc + parseInt(premium)) * (Number(30) + 0.5) / 100))
                    console.log(calculatingDiscountAmount, "calculatingDiscountAmount")

                    const calculatingTotalAmount = Math.floor((bnftcalc + parseInt(premium)) - (calculatingDiscountAmount))
                    console.log(calculatingTotalAmount, "calculatingTotalAmount")

                    setDiscountPercent(parseInt(calculatingDiscountAmount))
                    setTotal((calculatingTotalAmount))

                    toast.error(`${translate("home")["Enter only numbers between 0 to 30"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
                }
            } else {
                toast.error(`${translate("home")["Enter only numbers between 0 to 30"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
            }
        } else {
            if (val.length <= 4 && val.length > 0) {
                setNum(val);
                toast.error(`${translate("home")["Enter Only Numbers"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
                setDisErr(true)
            } else if (val.length === 0) {
                setNum(val);
                setDisErr(true)
                toast.error(`${translate("home")["Enter any number, minimum is 0"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
                // setDiscountPercent((Math.round((bnftcalc + parseInt(premium)) * (Number(0))  / 100)))
                // setTotal(Math.round(((parseInt(bnftcalc) + parseInt(premium)) - ((parseInt(bnftcalc) + parseInt(premium)) * ((Number(0)) / 100)))))

                const calculatingDiscountAmount = (Math.round((bnftcalc + parseInt(premium)) * (Number(0)) / 100))
                console.log(calculatingDiscountAmount, "calculatingDiscountAmount")

                const calculatingTotalAmount = Math.floor((bnftcalc + parseInt(premium)) - (calculatingDiscountAmount))
                console.log(calculatingTotalAmount, "calculatingTotalAmount")

                setDiscountPercent(parseInt(calculatingDiscountAmount))
                setTotal((calculatingTotalAmount))
            } else {
                toast.error(`${translate("home")["Enter only numbers between 0 to 30"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
            }
        }
    }

    const nextBtn = async () => {
        if (_store?.cipherlinkreducer?.formdata?.cipherkeyavilable === true) {

            if (promocodevalidation === true) {
                promocodeSubmit()
            }
            else {
                toast.error('Invalid promo code', { position: "top-center", hideProgressBar: true, autoClose: 1000 })
            }
        } else {
            discountSubmit()
        }
    }


    return (
        <div>
            <h3 className='header-card-content'>
                {translate("travel")["Order Summary"]}
            </h3>
            <div><b>{translate("travel")["Please check again your quote summary"]}</b></div>
            <br />
            {console.log(planname, 'plan name in return----->')}

            {width > breakpoint ? (
                <>
                    <Row style={{ gap: "15px" }}>
                        <Col md={12} lg={12} xl={12} xs={24} sm={12}>
                            <div className='accordian-inner'>
                                <div className='additional-font'>
                                    {planname}
                                </div>
                                <div className='additional-font'>
                                    {new Intl.NumberFormat('id-ID', {
                                        style: 'currency',
                                        currency: 'IDR',
                                        minimumFractionDigits: 0,
                                    }).format(parseInt(premium))}
                                </div>
                            </div>
                            <br />
                            {addbnft.length > 0 ? addbnft.map(item => {
                                return (
                                    <>
                                        <div className='accordian-inner'>
                                            <div className='additional-font'>
                                                {item.BenefitDesc}
                                            </div>
                                            <div className='additional-font'>
                                                {new Intl.NumberFormat('id-ID', {
                                                    style: 'currency',
                                                    currency: 'IDR',
                                                    minimumFractionDigits: 0,
                                                }).format(parseInt(item.Premium))}
                                            </div>
                                        </div>
                                        <br />
                                    </>
                                )
                            }) : null}

                            {/* <div className='accordian-inner'>
                        <div className='additional-font'>
                            Visa Protection
                        </div>
                        <div className='additional-font'>
                            Rp125.000
                        </div>
                    </div>
                    <br /> */}
                            <div className='accordian-inner'>
                                <div className='additional-font'>
                                    {translate("motor")["Discount"]}
                                </div>
                                <div style={{ color: "red" }}>
                                    - {discountpercent === 0 || isNaN(discountpercent || discountpercent === "") ? 0 : new Intl.NumberFormat('id-ID', {
                                        style: 'currency',
                                        currency: 'IDR',
                                        minimumFractionDigits: 0,
                                    }).format(discountpercent) }
                                </div>
                            </div>
                            <hr />
                            <div className='accordian-inner'>
                                <div>
                                    <b style={{ color: "#482c77" }}>{translate("motor")["Total"]}</b>
                                    <p style={{ fontSize: 12, fontStyle: "italic" }}>
                                        *{translate("travel")["Premium amount shown excludes admin cost"]}
                                    </p>
                                </div>
                                <div className='thick-font' style={{ fontSize: 18 }}>
                                    {new Intl.NumberFormat('id-ID', {
                                        style: 'currency',
                                        currency: 'IDR',
                                        minimumFractionDigits: 0,
                                    }).format(total)}
                                </div>
                            </div>
                        </Col>
                        <Col md={11} sm={11} xs={24} lg={11} xl={11}>
                          
                                <Card className='card-background'>

                                    {
                                        show === true ?
                                            <>
                                                <div className='thick-font'>{translate("travel")["Discount (Optional)"]}</div>
                                                <div style={{ margin: "10px 10px 10px 0px" }} className='additional-font'>{translate("travel")["Enter discount you will give to the customer"]}
                                                </div>

                                                <Input style={{ borderColor: diserr === true || disnumerr === true ? 'red' : '#818F99', color: diserr === true || disnumerr === true ? 'red' : 'black' }} value={num} onChange={(e) => handleChange(e.target.value)} prefix={<><div onClick={decNum} style={{ cursor: 'pointer' }}>-</div></>} suffix={<><div onClick={incNum} style={{ cursor: 'pointer' }}>+</div></>} />
                                                <div className='justify-space'>
                                                    <div className='font-inner'>
                                                        {translate("home")["Min."]}0%
                                                    </div>
                                                    <div className='font-inner'>
                                                        {translate("home")["Max."]}30%
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <b>{translate("travel")["Promo code (if any)"]}</b>
                                                <div>
                                                    {translate("travel")["Apply to get the discounted price"]}
                                                </div>
                                                <br />
                                                <Input defaultValue={promo} value={promo} disabled={disable} placeholder={translate("travel")["Enter Promo code"]} onChange={(e) => handlePromo(e.target.value)} />
                                            </>
                                    }

                                </Card>
                            
                        </Col>
                    </Row>
                        <Button disabled={diserr || disnumerr} onClick={() => nextBtn()} className='next-button-travel'>{translate("home")["Next"]}</Button>
                </>
            ) : (
                <>
                    <Row style={{ gap: "15px" }}>
                        <Col md={11} sm={11} xs={24} lg={11} xl={11}>
                                <Card style={{ marginBottom: 20 }} className='card-background'>
                                    {
                                        show === true ?
                                            <>
                                                <div className='thick-font'>{translate("travel")["Discount (Optional)"]}</div>
                                                <div style={{ margin: "10px 10px 10px 0px" }} className='additional-font'>{translate("travel")["Enter discount you will give to the customer"]}
                                                </div>

                                                <Input style={{ borderColor: diserr === true || disnumerr === true ? 'red' : '#818F99', color: diserr === true || disnumerr === true ? 'red' : 'black' }} value={num} onChange={(e) => handleChange(e.target.value)} prefix={<><div onClick={decNum} style={{ cursor: 'pointer' }}>-</div></>} suffix={<><div onClick={incNum} style={{ cursor: 'pointer' }}>+</div></>} />
                                                <div className='justify-space'>
                                                    <div className='font-inner'>
                                                        {translate("home")["Min."]}1%
                                                    </div>
                                                    <div className='font-inner'>
                                                        {translate("home")["max."]}30%
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <b>{translate("travel")["Promo code (if any)"]}</b>
                                                <div>
                                                    {translate("travel")["Apply to get the discounted price"]}
                                                </div>
                                                <br />
                                                <Input defaultValue={promo} value={promo} disabled={disable} placeholder={translate("travel")["Enter Promo code"]} onChange={(e) => handlePromo(e.target.value)} />
                                            </>
                                    }

                                </Card>
                            
                        </Col>
                        <Col md={12} lg={12} xl={12} xs={24} sm={12}>
                            <div className='accordian-inner'>
                                <div className='additional-font'>
                                    {planname}
                                </div>
                                <div className='additional-font'>
                                    {new Intl.NumberFormat('id-ID', {
                                        style: 'currency',
                                        currency: 'IDR',
                                        minimumFractionDigits: 0,
                                    }).format(parseInt(premium))}
                                </div>
                            </div>
                            <br />
                            {addbnft.length > 0 ? addbnft.map(item => {
                                return (
                                    <>
                                        <div className='accordian-inner'>
                                            <div className='additional-font'>
                                                {item.BenefitDesc}
                                            </div>
                                            <div className='additional-font'>
                                                {new Intl.NumberFormat('id-ID', {
                                                    style: 'currency',
                                                    currency: 'IDR',
                                                    minimumFractionDigits: 0,
                                                }).format(parseInt(item.Premium))}
                                            </div>
                                        </div>
                                        <br />
                                    </>
                                )
                            }) : null}

                            <div className='accordian-inner'>
                                <div className='additional-font'>
                                    {translate("motor")["Discount"]}
                                </div>
                                <div style={{ color: "red" }}>
                                    - { (discountpercent === 0 || isNaN(discountpercent) || discountpercent === "") ? 0 : new Intl.NumberFormat('id-ID', {
                                        style: 'currency',
                                        currency: 'IDR',
                                        minimumFractionDigits: 0,
                                    }).format(discountpercent) }
                                </div>
                            </div>
                            <hr />
                            <div className='accordian-inner'>
                                <div>
                                    <b style={{ color: "#482c77" }}>{translate("motor")["Total"]}</b>
                                    <p style={{ fontSize: 12, fontStyle: "italic" }}>
                                        *{translate("travel")["Premium amount shown excludes admin cost"]}
                                    </p>
                                </div>
                                <div className='thick-font' style={{ fontSize: 18 }}>
                                    {new Intl.NumberFormat('id-ID', {
                                        style: 'currency',
                                        currency: 'IDR',
                                        minimumFractionDigits: 0,
                                    }).format(total)}
                                </div>
                            </div>
                        </Col>

                            <Button disabled={diserr || disnumerr} onClick={() => nextBtn()} className='next-button-travel'>{translate("home")["Next"]}</Button>
                            
                    </Row>
                </>
            )}
        </div>
    )
}

export default OrderSummary