// Lead Master Azim
export const FETCH_ALL_LEADS_START = "FETCH_ALL_LEADS_START";
export const FETCH_ALL_LEADS_SUCCESS = "FETCH_ALL_LEADS_SUCCESS";
export const FETCH_ALL_LEADS_FAIL = "FETCH_ALL_LEADS_FAIL";


export const CURRENT_UPDATING_TAB = "CURRENT_UPDATING_TAB"

export const FETCH_TEAM_MEMBER_START = "FETCH_TEAM_MEMBER_START";
export const FETCH_TEAM_MEMBER_SUCCESS = "FETCH_TEAM_MEMBER_SUCCESS";
export const FETCH_TEAM_MEMBER_FAIL = "FETCH_TEAM_MEMBER_FAIL";

export const FETCH_DESIGNATION_START = "FETCH_DESIGNATION_START";
export const FETCH_DESIGNATION_SUCCESS = "FETCH_DESIGNATION_SUCCESS";
export const FETCH_DESIGNATION_FAIL = "FETCH_DESIGNATION_FAIL";
export const UPDATE_TAB_POSSITION = "UPDATE_TAB_POSSITION";
export const UPDATE_ALLCATION_TAB_POSSITION = "UPDATE_ALLCATION_TAB_POSSITION";
export const UPDATE_ALLCATED_CHECKED_LEADS = "UPDATE_ALLCATED_CHECKED_LEADS";
export const CURRENT_UPDATING_ID = "CURRENT_UPDATING_ID";


export const FETCH_ALL_TEAM_COUNT = "FETCH_ALL_TEAM_COUNT";

export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

export const FETCH_ALL_TEAM_COUNTT = "FETCH_ALL_TEAM_COUNTT";

export const HEADER_NAME = "HEADER_NAME";

// export const FETCH_TODAY_LEADS_START =  'FETCH_TODAY_LEADS_START'
// export const FETCH_TODAY_LEADS_SUCCESS =  'FETCH_TODAY_LEADS_SUCCESS'
// export const FETCH_TODAY_LEADS_FAIL =  'FETCH_TODAY_LEADS_FAIL'

// export const FETCH_OPEN_LEADS_START =  'FETCH_OPEN_LEADS_START'
// export const FETCH_OPEN_LEADS_SUCCESS =  'FETCH_OPEN_LEADS_SUCCESS'
// export const FETCH_OPEN_LEADS_FAIL =  'FETCH_OPEN_LEADS_FAIL'

// export const FETCH_CONVERTED_LEADS_START =  'FETCH_CONVERTED_LEADS_START'
// export const FETCH_CONVERTED_LEADS_SUCCESS =  'FETCH_CONVERTED_LEADS_SUCCESS'
// export const FETCH_CONVERTED_LEADS_FAIL =  'FETCH_CONVERTED_LEADS_FAIL'

// export const FETCH_FAILED_LEADS_START =  'FETCH_FAILED_LEADS_START'
// export const FETCH_FAILED_LEADS_SUCCESS =  'FETCH_FAILED_LEADS_SUCCESS'
// export const FETCH_FAILED_LEADS_FAIL =  'FETCH_FAILED_LEADS_FAIL'

export const FETCH_LEAD_CATEGORY_START = "FETCH_LEAD_CATEGORY_START";
export const FETCH_LEAD_CATEGORY_SUCCESS = "FETCH_LEAD_CATEGORY_SUCCESS";
export const FETCH_LEAD_CATEGORY_FAIL = "FETCH_LEAD_CATEGORY_FAIL";

export const FETCH_LEAD_DETAILS_START = "FETCH_LEAD_DETAILS_START";
export const FETCH_LEAD_DETAILS_SUCCESS = "FETCH_LEAD_DETAILS_SUCCESS";
export const FETCH_LEAD_DETAILS_FAIL = "FETCH_LEAD_DETAILS_FAIL";

export const FETCH_HISTORY_START = "FETCH_HISTORY_START";
export const FETCH_HISTORY_SUCCESS = "FETCH_HISTORY_SUCCESS";
export const FETCH_HISTORY_FAIL = "FETCH_HISTORY_FAIL";

export const CREATE_LEAD_START = "CREATE_LEAD_START";
export const CREATE_LEAD_SUCCESS = "CREATE_LEAD_SUCCESS";
export const CREATE_LEAD_FAIL = "CREATE_LEAD_FAIL";

export const EDIT_LEAD_START = "EDIT_LEAD_START";
export const EDIT_LEAD_SUCCESS = "EDIT_LEAD_SUCCESS";
export const EDIT_LEAD_FAIL = "EDIT_LEAD_FAIL";

export const STORE_FORM_SUCCESS = "STORE_FORM_SUCCESS";

export const FETCH_PRODUCT_START = "FETCH_PRODUCT_START";
export const FETCH_PRODUCT_SUCCESS = "FETCH_PRODUCT_SUCCESS";
export const FETCH_PRODUCT_FAIL = "FETCH_PRODUCT_FAIL";

export const FETCH_PLAN_NAME_START = "FETCH_PLAN_NAME_START";
export const FETCH_PLAN_NAME_SUCCESS = "FETCH_PLAN_NAME_SUCCESS";
export const FETCH_PLAN_NAME_FAIL = "FETCH_PLAN_NAME_FAIL";
// Addresses
export const FETCH_STATES_START = "FETCH_STATES_START";
export const FETCH_STATES_SUCCESS = "FETCH_STATES_SUCCESS";
export const FETCH_STATES_FAIL = "FETCH_STATES_FAIL";

export const FETCH_CITIES_START = "FETCH_CITIES_START";
export const FETCH_CITIES_SUCCESS = "FETCH_CITIES_SUCCESS";
export const FETCH_CITIES_FAIL = "FETCH_CITIES_FAIL";

// Login Shiva
export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const MULTI_CHANNEL = "MULTI_CHANNEL";

export const AUTH_LOGOUT_START = "AUTH_LOGOUT_START";
export const AUTH_LOGOUT_SUCCESS = "AUTH_LOGOUT_SUCCESS";
export const AUTH_LOGOUT_FAIL = "AUTH_LOGOUT_FAIL";

export const FETCH_USER_DETAILS_START = "FETCH_USER_DETAILS_START";
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_FAIL = "FETCH_USER_DETAILS_FAIL";

export const FETCH_HIERARCHY_START = "FETCH_HIERARCHY_START";
export const FETCH_HIERARCHY_SUCCESS = "FETCH_HIERARCHY_SUCCESS";
export const FETCH_HIERARCHY_FAIL = "FETCH_HIERARCHY_FAIL";

export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

export const HOME_START = "HOME_START";
export const HOME_SUCCESS = "HOME_SUCCESS";
export const HOME_FAIL = "HOME_FAIL";
export const USER_TREE = "USER_TREE";
export const BUSINESS_CARD = "BUSINESS_CARD";

export const ACTIVITIES_START = "ACTIVITIES_START";
export const ACTIVITIES_SUCCESS = "ACTIVITIES_SUCCESS";
export const ACTIVITIES_FAIL = "ACTIVITIES_FAIL";

export const TODO_START = "TODO_START";
export const TODO_SUCCESS = "TODO_SUCCESS";

//KPI DASHBOARD
export const KPI_DASHBOARD_START = "KPI_DASHBOARD_START";
export const KPI_DASHBOARD_SUCCESS = "KPI_DASHBOARD_SUCCESS";
export const KPI_DASHBOARD_FAIL = "KPI_DASHBOARD_FAIL";

//all renewals
export const FETCH_ALL_RENEWALS_START = "FETCH_ALL_RENEWALS_START";
export const FETCH_ALL_RENEWALS_SUCCESS = "FETCH_ALL_RENEWALS_SUCCESS";
export const FETCH_ALL_RENEWALS_FAIL = "FETCH_ALL_RENEWALS_FAIL";
//paid renewals
export const FETCH_PAID_RENEWALS_START = "FETCH_PAID_RENEWALS_START";
export const FETCH_PAID_RENEWALS_SUCCESS = "FETCH_PAID_RENEWALS_SUCCESS";
export const FETCH_PAID_RENEWALS_FAIL = "FETCH_PAID_RENEWALS_FAIL";
//unpaid renewals
export const FETCH_UNPAID_RENEWALS_START = "FETCH_UNPAID_RENEWALS_START";
export const FETCH_UNPAID_RENEWALS_SUCCESS = "FETCH_UNPAID_RENEWALS_SUCCESS";
export const FETCH_UNPAID_RENEWALS_FAIL = "FETCH_UNPAID_RENEWALS_FAIL";
//lapsed renewals
export const FETCH_LAPSED_RENEWALS_START = "FETCH_LAPSED_RENEWALS_START";
export const FETCH_LAPSED_RENEWALS_SUCCESS = "FETCH_LAPSED_RENEWALS_SUCCESS";
export const FETCH_LAPSED_RENEWALS_FAIL = "FETCH_LAPSED_RENEWALS_FAIL";

// renewal details
export const FETCH_DETAILS_RENEWALS_START = "FETCH_DETAILS_RENEWALS_START";
export const FETCH_DETAILS_RENEWALS_SUCCESS = "FETCH_DETAILS_RENEWALS_SUCCESS";
export const FETCH_DETAILS_RENEWALS_FAIL = "FETCH_DETAILS_RENEWALS_FAIL";

// Agent Mirco Site
export const FETCH_AGENT_DETAILS_START = "FETCH_AGENT_DETAILS_START";
export const FETCH_AGENT_DETAILS_SUCCESS = "FETCH_AGENT_DETAILS_SUCCESS";
export const FETCH_AGENT_DETAILS_FAIL = "FETCH_AGENT_DETAILS_FAIL";

// Agent Micro site blog
export const FETCH_BLOGS_START = "FETCH_BLOGS_START";
export const FETCH_BLOGS_SUCCESS = "FETCH_BLOGS_SUCCESS";
export const FETCH_BLOGS_FAIL = "FETCH_BLOGS_FAIL";

//fetch company details
export const FETCH_COMPANY_START = "FETCH_COMPANY_START";
export const FETCH_COMPANY_SUCCESS = "FETCH_COMPANY_SUCCESS";
export const FETCH_COMPANY_FAIL = "FETCH_COMPANY_FAIL";

// Advisor List
export const FETCH_ADVISOR_LIST_START = "FETCH_ADVISOR_LIST_START";
export const FETCH_ADVISOR_LIST_SUCCESS = "FETCH_ADVISOR_LIST_SUCCESS";
export const FETCH_ADVISOR_LIST_FAIL = "FETCH_ADVISOR_LIST_FAIL";
export const FETCH_CIPHER_LINK = "FETCH_CIPHER_LINK";
export const SET_QUOTE_ID = "SET_QUOTE_ID";
export const SET_LANUAGE="SET_LANGUAGE"



//PAPRODUCT-GET API

export const PA_FETCH_DATA_SUCCESS = 'PA_FETCH_DATA_SUCCESS';
export const PA_UPDATE_DATA_SUCCESS = 'PA_UPDATE_DATA_SUCCESS'
export const PA_UPDATE_INDIVIDUAL_DATA_SUCCESS = 'PA_UPDATE_INDIVIDUAL_DATA_SUCCESS'
export const PA_MULTIPLE_INSURED_DETAILS_POSTING="PA_MULTIPLE_INSURED_DETAILS_POSTING"
export const PA_RESET_DATA = 'PA_RESET_DATA';
export const PA_SEPERATE_RESET_DATA = 'PA_SEPERATE_RESET_DATA';
export const POLICY_INCEPTION_DATE = "POLICY_INCEPTION_DATE";
export const SET_COPY_OPTION = "SET_COPY_OPTION";
export const SET_ADDRESS = "SET_ADDRESS";