import React from 'react'

const OOnaPaylaterSuccess = () => {
  return (
    <div>
      OOnaPaylaterSuccess
    </div>
  )
}

export default OOnaPaylaterSuccess
