import { pafetchData } from "./pafetchdata"; 
 
 export const createQuoteObject=(newQuoteObject)=>{
    return {
        type:'CREATE_QUOTE',
        payload:newQuoteObject
    }
}



export const createQuoteObjectfordispatchingObject = (newQuoteObject) => {
    return async (dispatch) => {
      try {
        dispatch({
          type: 'SENDING_QUOTE_ID',
          payload: newQuoteObject
        });

       dispatch(pafetchData(newQuoteObject))
  
      } catch (error) {
       
        console.error('Error creating quote object:', error);
      }
    };
  };



export const updatename=(nameValue)=>{
  return{
    type:"UPDATE_NAME",
    payload:nameValue
  }
}


export const updatenumberofParticipants=(numberofparticipantsValue)=>{
  return{
    type:"UPDATE_NUBEROF_PARTICIPANTS",
    payload:numberofparticipantsValue
  }
}

export const updateSelectedLimittype=(selectedLimitTypeValue)=>{
  return{
    type:"UPDATE_SELECTED_LIMIT_TYPE",
    payload:selectedLimitTypeValue
  }
}




export const seperateRequestLimitResultObject=(requestLimitData)=>{
    return{
        type:'SEPERATE_REQUEST_LIMIT',
        payload:requestLimitData
    }
}


// multipleCustomerLimit

export const multipleCustomerLimitResultObj=(mulResquestData)=>{
    return {
        type:"MULTIPLE_CUSTOMER_REQUEST_LIMIT_DATA",
        payload:mulResquestData
    }
}



//showingordersummary and payment screen when the copy and paste the sharable link

export const displayingdDataUsingSharableLink=(payload)=>{
  return {
      type:"DISPLAYING_DATA_USING_SHARABLE_LINK",
      payload:payload
  }
}


