import React, { useState, useRef, useEffect } from 'react';
import { Button, Modal, Form, Input, Image, Row, Col, Card } from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import { useLocation } from 'react-router-dom/cjs/react-router-dom'
import { Base_URL } from '../config/BaseUrl'
import axiosRequest from '../axios-request/request.methods'
import rootIndex from "../store/root_index";
import { useHistory } from "react-router-dom";
import { Header } from 'antd/lib/layout/layout';
import oonaLogo from "../images/oona-logo@2x.png";
import LangSwitch from "../config/LangSwitch";
import { useLanguage } from "../../src/config/LanguageContext"
import { useDispatch } from 'react-redux';
import * as action from '../store/actions/index'

const { store } = rootIndex;

function MotorOtp(props) {
    const dispatch = useDispatch()
    const location = useLocation();
    const _store = store.getState();
    const history = useHistory();
    const [form] = Form.useForm();
    const [phone, setPhone] = useState("");
    const [otp1, setOTP1] = useState("");
    const [otp2, setOTP2] = useState("");
    const [otp3, setOTP3] = useState("");
    const [otp4, setOTP4] = useState("");
    const [otp5, setOTP5] = useState("");
    const [otp6, setOTP6] = useState("");
    const [key, setKey] = useState('')
    const [phoneErr, setPhoneErr] = useState(false)
    const [phonnumberUsed, setphonnumberUsed] = useState(false);
    const [mobilenumberreq, setmobilenumberreq] = useState(false);
    const [otpInvaludres, setotpInvaludres] = useState(false)
    const [otpreqres, setotpreqres] = useState(false)

    const inputOTP2 = useRef(null);
    const inputOTP3 = useRef(null);
    const inputOTP4 = useRef(null);
    const inputOTP5 = useRef(null);
    const inputOTP6 = useRef(null);
    // method for the otp
    const handleCancel1 = () => {
        setOTP1("")
        setOTP2("")
        setOTP3("")
        setOTP4("")
    };

    const submitOtp = () => {
    }
    const handleOTP1changeHandler = (e) => {
        setOTP1(e.target.value);
        if (e.target.attributes.maxlength.value == 1) {
            inputOTP2.current.focus();
        }
    };

    const handleOTP2changeHandler = (e) => {
        setOTP2(e.target.value);
        if (e.target.attributes.maxlength.value == 1) {
            inputOTP3.current.focus();
        }
    };

    const handleOTP3changeHandler = (e) => {
        setOTP3(e.target.value);
        if (e.target.attributes.maxlength.value == 1) {
            inputOTP4.current.focus();
        }
    };
    const handleOTP4changeHandler = (e) => {
        setOTP4(e.target.value);
        if (e.target.attributes.maxlength.value == 1) {
            inputOTP5.current.focus();
        }
    };
    const handleOTP5changeHandler = (e) => {
        setOTP5(e.target.value);
        if (e.target.attributes.maxlength.value == 1) {
            inputOTP6.current.focus();
        }
    };

    const handleOTP6changeHandler = (e) => {
        setOTP6(e.target.value);

    };


    const verifyOTP = () => {

        if (otp1 === "" || otp2 === "" || otp3 === "" || otp4 === "" || otp5 === "" || otp6 === "") {
            toast.error(`${translate("home")["Otp is required"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
        }
        else {

            let allOTP = "";
            let allOTPIntegration = allOTP.concat(otp1, otp2, otp3, otp4, otp5, otp6);
            let axiosConfig = {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + _store.login.token
                },
            };

            let formData = {
                key: key,
                otp: parseInt(allOTPIntegration)
            }

            console.log(props, "props====>")
            let options = { secure: false, multipart: false }
            axiosRequest
                .post(
                    `auth/verifyOtp?langId=${language === "en" ? "EN" : "BH"}`,
                    formData,
                    options
                )
                .then((data) => {
                   
                    // console.log("Final response: of new lead", data.errMsg.result_valid_mobile[0]);
                    if (data.errCode === -1) {
                        setotpInvaludres(false)
                        setotpreqres(false)
                        history.push({

                            pathname: '/motorscreen',
                            state: {
                                phonenumber: phone,
                                cipher: location?.state?.cipher
                            },
                        })
                        let Data = {
                            quoteId: "",
                            isOld: false
                        }

                        dispatch(action.set_quote_id(Data))

                        toast.success(data.errMsg?.message, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
                    } else {
                        
                        toast.error(data.errMsg, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
                    }
                })
                .catch((error) => {
                
                    toast.error(error.response.data.errMsg.message
                        , { position: "top-center", hideProgressBar: true, autoClose: 1000 })
                
                });

        }



    }


    const handleVerify = () => {
        if (otp1 === "" || otp2 === "" || otp3 === "" || (otp4 === "") === "") {
            toast.error("Enter OTP", { position: "top-center", hideProgressBar: true, autoClose: 1000 })
            return;
        } else {
            form.resetFields();
        }
    };



    const sendOtp = (values) => {
        console.log(values, "values")

        let axiosConfig = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + _store.login.token,
            },
        };

        let formData = { mobileNo: phone };
        let options = { secure: false, multipart: false }
        if(phone?.length > 9){

        axiosRequest
            .post(`auth/sendOtp?langId=${language === "en" ? "EN" : "BH"}&isCustomerFlow=true`, formData, options)
            .then((data) => {
                if (data.errCode === -1) {
                    setphonnumberUsed(false);
                    setmobilenumberreq(false);
                    setSeeContent(false)
                    setKey(data?.errMsg?.key)
                    toast.success(data.errMsg.message, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
                } else {
                    toast.error(data.errMsg.message, { position: "top-center", hideProgressBar: true, autoClose: 1000 });
                }
            })
            .catch((error) => {
                if (error?.response) {
                    console.log(error?.response?.data, "geetha");

                    if (
                        error?.response?.data?.errMsg ===
                        "Agent is already exist on given mobile number"
                    ) {
                        setphonnumberUsed(true);
                    } else if (
                        error?.response?.data?.errMsg === "mobile number is required"
                    ) {
                        setmobilenumberreq(true);
                    } else {
                        toast.error(error?.response?.data?.errMsg, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
                    }
                }
            });
        }
        else{
            toast.error(`${translate("home")["Please lengthen this text to 9 characters or more"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
        }

        console.log(phone, "phone num----->");
    };


    const [seeContent, setSeeContent] = useState(true)
    const { translate, language } = useLanguage()

    return (
        <>
            {_store?.cipherlinkreducer?.formdata?.cipherkeyavilable ?
                <Header className="header-login" >
                    <Image preview={false} className="oona-logo" src={oonaLogo}></Image>
                    <LangSwitch />

                </Header> : null}
            <div className='oona-travel-otp'>
                <Card>
                    {seeContent === true ?
                        <>
                            <div style={{ textAlign: "center" }}>
                                <h5 style={{ marginBottom: 5, color: "#482c77" }}> {translate("onboarding")["You’ve received an invite to fill form from"]} {location?.state?.name} {translate("onboarding")["Untuk Mengisi Form Asuransi"]}</h5>
                                <div style={{ margin: "2% 18%" }}>{translate("onboarding")["Enter your phone number to issue policy! You will receive an One-Time Password (OTP) for confirmation"]}</div>

                                <Form layout='vertical' onFinish={sendOtp}>
                                    <Form.Item
                                        label={translate("onboarding")["Phone number"]}
                                        validateStatus={phoneErr && 'error'}
                                        help={phoneErr && `${translate("home")["Only Number is Allowed"]}`}

                                        rules={[
                                            {
                                                required: true,
                                                message: "phonenumber is required",
                                            },
                                            {
                                                validator: (rule, value) => {
                                                    if (!/^[0-9]+$/.test(value)) {
                                                        return Promise.reject(`${translate("home")["Only Numbers are Allowed"]}`);
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }
                                           
                                        ]}
                                    >
                                        <Input maxLength={13}
                                          

                                            placeholder={translate("onboarding")["Enter your phone numberPlace"]}
                                            className="CreatingAgentAccount__phoneNumberinput"
                                            onChange={(e) => {
                                                if (/^[0-9]+$/.test(e.target.value)) {
                                                    setPhoneErr(false);
                                                    setPhone(e.target.value);
                                                } else if (e.target.value === "") {
                                                    // Handle backspace (empty input)
                                                    setPhoneErr(false);
                                                    setPhone(""); // Clear the phone number
                                                } else {
                                                    setPhoneErr(true);
                                                }

                                                // if (/^[0-9]+$/.test(e.target.value)) {
                                                //     setPhoneErr(false)
                                                //     setPhone(e.target.value);

                                                // }
                                                // else {
                                                //     setPhoneErr(true)
                                                // }
                                            }}

                                            // onChange={(e) => setPhone(e.target.value)}
                                            value={phone}></Input>
                                    </Form.Item>
                                    {phonnumberUsed && (
                                        <div className="text__color__aob">
                                            This phone number has been used. Enter a different phone
                                            number.
                                        </div>
                                    )}
                                    {mobilenumberreq && (
                                        <div className="text__color__aob">
                                           `${translate("home")["You might have entered an invalid phone number. Please check again."]}`
                                        </div>
                                    )}

                                    <Button htmlType="submit" className='next-button-travel'>{translate("onboarding")["Send OTP Cus"]}</Button>
                                </Form>
                            </div>
                        </> :
                        <>
                            <div style={{ textAlign: "center" }}>
                                <h5 style={{ marginBottom: 5, color: "#482c77" }}>{translate("onboarding")["confirmation"]}</h5>

                                <div style={{ margin: "2% 18%" }}>{translate("onboarding")["Enter the OTP that has been sent to"]} {phone} {translate("onboarding")["to complete phone number confirmation"]}</div>

                                <Form
                                    form={form}
                                    fields={[
                                        {
                                            name: ["otp1"],
                                            value: otp1,
                                        },
                                        {
                                            name: ["otp2"],
                                            value: otp2,
                                        },
                                        {
                                            name: ["otp3"],
                                            value: otp3,
                                        },
                                        {
                                            name: ["otp4"],
                                            value: otp4,
                                        },

                                    ]}
                                >
                                    <Row>
                                        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                            <Form.Item
                                                className="form-item-name label-color"
                                                name="otp1"
                                                rules={[
                                                    {
                                                        message: `${translate("home")["Only Number is Allowed"]}`,
                                                        pattern: '^[0-9]+$',
                                                    },
                                                ]}
                                                style={{ marginBottom: "0.5rem" }}
                                            >
                                                <Input
                                                    size="large"
                                                    maxLength="1"
                                                    value={otp1}
                                                    name="otp1"
                                                    rules={[
                                                        // {
                                                        //   required: false,
                                                        //   message: "First Name is required",
                                                        // },
                                                        {
                                                            message: `${translate("home")["Only Number is Allowed"]}`,
                                                            pattern: new RegExp(/^[0-9]+$/),
                                                        },
                                                    ]}
                                                    // defaultValue={storefirstNameValue}
                                                    onChange={(item) => handleOTP1changeHandler(item)}
                                                    autoComplete="off"
                                                    className="OTPInput"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                            <Form.Item
                                                className="form-item-name label-color"
                                                name="otp2"
                                                rules={[
                                                    {
                                                        message: `${translate("home")["Only Number is Allowed"]}`,
                                                        pattern: '^[0-9]+$',
                                                    },
                                                ]}
                                                style={{ marginBottom: "0.5rem" }}
                                            >
                                                <Input
                                                    size="large"
                                                    maxLength="1"
                                                    value={otp2}
                                                    name="otp2"
                                                    ref={inputOTP2}
                                                    className="OTPInput"
                                                    // defaultValue={storefirstNameValue}
                                                    onChange={(item) => handleOTP2changeHandler(item)}
                                                    autoComplete="off"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                            <Form.Item
                                                className="form-item-name label-color"
                                                name="otp3"
                                                rules={[
                                                    {
                                                        message: `${translate("home")["Only Number is Allowed"]}`,
                                                        pattern: '^[0-9]+$',
                                                    },
                                                ]}
                                                style={{ marginBottom: "0.5rem" }}
                                            >
                                                <Input
                                                    size="large"
                                                    maxLength="1"
                                                    name="otp3"
                                                    value={otp3}
                                                    ref={inputOTP3}
                                                    // defaultValue={storefirstNameValue}
                                                    onChange={(item) => handleOTP3changeHandler(item)}
                                                    autoComplete="off"
                                                    className="OTPInput"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col
                                            xs={4}
                                            sm={4}
                                            md={4}
                                            lg={4}
                                            xl={4}
                                            style={{ marginBottom: "0.5rem" }}
                                        >
                                            {" "}
                                            <Form.Item
                                                className="form-item-name label-color"
                                                name="otp4"
                                                rules={[
                                                    {
                                                        message: `${translate("home")["Only Number is Allowed"]}`,
                                                        pattern: '^[0-9]+$',
                                                    },
                                                ]}
                                                style={{ marginBottom: "0.5rem" }}
                                            >
                                                <Input
                                                    size="large"
                                                    maxLength="1"
                                                    value={otp4}
                                                    name="opt4"
                                                    ref={inputOTP4}

                                                    // defaultValue={storefirstNameValue}
                                                    onChange={(item) => handleOTP4changeHandler(item)}
                                                    autoComplete="off"
                                                    className="OTPInput"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col
                                            xs={4}
                                            sm={4}
                                            md={4}
                                            lg={4}
                                            xl={4}
                                            style={{ marginBottom: "0.5rem" }}
                                        >
                                            {" "}
                                            <Form.Item
                                                className="form-item-name label-color"
                                                name="otp5"
                                                rules={[
                                                    {
                                                        message: `${translate("home")["Only Number is Allowed"]}`,
                                                        pattern: '^[0-9]+$',
                                                    },
                                                ]}
                                                style={{ marginBottom: "0.5rem" }}
                                            >
                                                <Input
                                                    size="large"
                                                    maxLength="1"
                                                    value={otp5}
                                                    name="opt5"
                                                    ref={inputOTP5}

                                                    // defaultValue={storefirstNameValue}
                                                    onChange={(item) => handleOTP5changeHandler(item)}
                                                    autoComplete="off"
                                                    className="OTPInput"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col
                                            xs={4}
                                            sm={4}
                                            md={4}
                                            lg={4}
                                            xl={4}
                                            style={{ marginBottom: "0.5rem" }}
                                        >
                                            {" "}
                                            <Form.Item
                                                className="form-item-name label-color"
                                                name="otp6"
                                                rules={[
                                                    {
                                                        message: `${translate("home")["Only Number is Allowed"]}`,
                                                        pattern: '^[0-9]+$',
                                                    },
                                                ]}
                                                style={{ marginBottom: "0.5rem" }}
                                            >
                                                <Input
                                                    size="large"
                                                    maxLength="1"
                                                    value={otp6}
                                                    name="opt6"
                                                    ref={inputOTP6}

                                                    // defaultValue={storefirstNameValue}
                                                    onChange={(item) => handleOTP6changeHandler(item)}
                                                    autoComplete="off"
                                                    className="OTPInput"
                                                />
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                    <Form.Item>
                                        <Row>
                                            <Col xl={24} md={24} lg={24} sm={24} xs={24} className="otp__error__message">
                                                {otpreqres && (<div className="otp__error__message__text">Otp is required</div>)}
                                                {otpInvaludres && (<div className="otp__error__message__text">You have entered an invalid OTP. Please check again.</div>)}
                                            </Col>
                                        </Row>
                                        <Button
                                            htmlType="submit"
                                            className="next-button-travel"
                                            onClick={() => {
                                                verifyOTP();
                                            }}
                                        >
                                            {translate("onboarding")["Verify OTP"]}
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </>}
                </Card>
            </div >
        </>
    )
}

export default MotorOtp