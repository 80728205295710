import React, { useEffect } from 'react'
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux";
import * as action from '../store/actions/index'
import axiosRequest from '../axios-request/request.methods'
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';


function TravelDynamicRoute(props) {
    const history = useHistory();
    const dispatch = useDispatch()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const langParam = searchParams.get('lang');
    console.log(langParam,"langParam")
    useEffect(() => {
        if (props.match.params.id.length === 6) {
            let Data ={
                cipherkaye:props.match.params.id,
                cipherkeyavilable:true
            }

            dispatch(action.fetch_cipher_data(Data))
            let options = { secure: false, multipart: false }

            axiosRequest
                .get(
                    `auth/decrypt-link`,
                    options
                )
                .then((data) => {
                        if (data?.errMsg?.data.hasOwnProperty("stage") === false) {
                            dispatch(action.set_global_language(langParam));
                            Cookies.set('LANG', langParam === 'en' ? 'en' : 'id', { domain: process.env.REACT_APP_COOKIE_URL });
                            dispatch(action.set_quote_id({
                                quoteId: "",
                                isTravel: false
                              }));
                              history.push("/oonatravel")

                        } else {
                            dispatch(action.set_global_language(langParam));
                            Cookies.set('LANG', langParam === 'en' ? 'en' : 'id', { domain: process.env.REACT_APP_COOKIE_URL });
                            if (data?.errMsg?.data?.stage === 'Plan Selected') {
                                dispatch(action.set_quote_id({
                                    quoteId: data?.errMsg?.data?.quoteId,
                                    isTravel: false,
                                  }));
                                history.push({
                                    pathname: "/chooseproducttravel",
                                    // state: {
                                    //     quoteId: data?.errMsg?.data?.quoteId,
                                    //     getQuote : 'yes',
                                    //     covid : true
                                    // }
                                })
                            } else if (data?.errMsg?.data?.stage === 'Covid Benefit Selected') {
                                dispatch(action.set_quote_id({
                                    quoteId: data?.errMsg?.data?.quoteId,
                                  }));
                                history.push({
                                    pathname: "/additionalbenefittravel",
                                    // state: {
                                      
                                    //     quoteId: data?.errMsg?.data?.quoteId,
                                    //     getQuote : 'yes'
                                    // }
                                })
                            }else if (data?.errMsg?.data?.stage === 'Lead Created') {
                                dispatch(action.set_quote_id({
                                    quoteId: data?.errMsg?.data?.quoteId,
                                  }));
                                history.push({
                                    pathname: "/chooseproducttravel",
                                    // state: {
                                       
                                    //     quoteId: data?.errMsg?.data?.quoteId,
                                    //     getQuote : 'yes'
                                    // }
                                })
                            } else if (data?.errMsg?.data?.stage === 'Additional Benefit Selected') {
                                dispatch(action.set_quote_id({
                                    quoteId: data?.errMsg?.data?.quoteId,
                                  }));   
                                
                                history.push({
                                    pathname: "/ordersummarytravel",
                                    // state: {
                                    //     quoteId: data?.errMsg?.data?.quoteId,
                                    //     getQuote : 'yes',
                                    //     quote : true
                                    // }
                                })
                            } else if (data.data?.errMsg?.data?.stage === 'PromoCode Added') {
                                dispatch(action.set_quote_id({
                                    quoteId: data?.errMsg?.data?.quoteId,
                                  }));   
                                history.push({
                                    pathname: "/oonasuccess",
                                    // state: {
                                    //     data: props?.location?.state?.data,
                                    //     quoteId: data?.errMsg?.data?.quoteId
                                    // }
                                })

                            } else if (data?.errMsg?.data?.stage === 'Quick Quote Created') {
                                dispatch(action.set_quote_id({
                                    quoteId: data?.errMsg?.data?.quoteId,
                                  }));    
                                history.push({
                                    pathname: "/ordersummarytravel",
                                    // state: {
                                    //     quoteId: data?.errMsg?.data?.quoteId,
                                    //     quote : true
                                    // }
                                })
                            } else if (data?.errMsg?.data?.stage === 'Customer Information Updated') {
                                dispatch(action.set_quote_id({
                                    quoteId: data?.errMsg?.data?.quoteId,
                                  }));   
                                history.push({
                                    pathname: "/oonaconfirmation",
                                    // state: {
                                       
                                    //     quoteId: data?.errMsg?.data?.quoteId
                                    // }
                                })
                            } else if (data?.errMsg?.data?.stage === 'Full Quote Created') {
                                dispatch(action.set_quote_id({
                                    quoteId: data?.errMsg?.data?.quoteId,
                                  }));   
                                history.push({
                                    pathname: "/oonapayment",
                                    // state: {
                                       
                                    //     quoteId: data?.errMsg?.data?.quoteId
                                    // }
                                })
                            } else if (data?.errMsg?.data?.stage === 'Policy Generated') {
                                dispatch(action.set_quote_id({
                                    quoteId: data?.errMsg?.data?.quoteId,
                                  }));   
                                history.push({
                                    pathname: "/oonasuccess1",
                                    // state: {
                                       
                                    //     quoteId: data?.errMsg?.data?.quoteId
                                    // }
                                })
                            } else if (data?.errMsg?.data?.stage === 'Full Quote Created With Payment') {
                                dispatch(action.set_quote_id({
                                    quoteId: data?.errMsg?.data?.quoteId,
                                  }));   
                                history.push({
                                    pathname: "/oonasuccess1",
                                    // state: {
                                       
                                    //     quoteId: data?.errMsg?.data?.quoteId
                                    // }
                                })
                            }
                            else{
                                history.push({
                                    pathname: "/oonatravel",
                                })
                            }
                        }
                    
                }).catch((error) => {
                    console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

                });
        }else{
            history.push("/login")
            let Data ={cipherkeyavilable:false}

            dispatch(action.fetch_cipher_data(Data))
        }

    }, [])
    return (
        <div></div>
    )
}

export default TravelDynamicRoute