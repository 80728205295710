import * as actionTypes from '../actions/actionTypes'
// import { set_quote_id } from '../actions/cipherlink';
import { updateObject } from "../utility";
import Cookies from 'js-cookie'
console.log()

const getLanguageCookie = () => 
    {
        const geettingcookie=Cookies.get('LANG')
        return geettingcookie === "en" ? "en" : "es"
    }
    console.log(getLanguageCookie(),"geettingcookie")
   

// const fetchinglanguagecookie=Cookies.get('LANG')
// console.log(fetchinglanguagecookie,"fetchinglanguagecookie")
const initialState = { lang: getLanguageCookie() };

export const fetch_global_language = (state, action) => {
    console.log("Reducer Console ====>>>", state, action)
    return updateObject(state, { lang: action.lang })
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        //state
        case actionTypes.SET_LANUAGE:
            return fetch_global_language(state, action);

        default:
             const dynamicLang = getLanguageCookie();
             if (dynamicLang && dynamicLang !== state.lang) {
                 return updateObject(state, { lang: dynamicLang });
             }
             return state;
    }
};

export default reducer