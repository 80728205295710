import { PA_FETCH_DATA_SUCCESS, PA_UPDATE_DATA_SUCCESS, PA_RESET_DATA, PA_SEPERATE_RESET_DATA, POLICY_INCEPTION_DATE, PA_UPDATE_INDIVIDUAL_DATA_SUCCESS,SET_COPY_OPTION,SET_ADDRESS } from '../../actions/actionTypes.js'
import axiosRequest from '../../../axios-request/request.methods.js'

import { toast } from 'react-toastify';

export const fetchDataSuccess = (data) => ({
  type: PA_FETCH_DATA_SUCCESS,
  payload: data,
});

export const pafetchData = (id,language) => {
  console.log(language,"Language in ==>",language)
  return async (dispatch) => {
    try {
      const response = await axiosRequest.get(`user/get-pa-quote/${id}?langId=${language === "en" ? "EN" : "BH"}`)
      console.log(response, "response")
      if(response?.errCode === -1){
      dispatch(fetchDataSuccess(response?.errMsg?.data,language));
    }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
};

// update api

export const updateDataSuccess = (data) => ({
  type: PA_UPDATE_DATA_SUCCESS,
  payload: data,
});

export const updateIndividualDataSuccess = (data) => ({
  type: PA_UPDATE_INDIVIDUAL_DATA_SUCCESS,
  payload: data,
});

// update api for PA product
export const paUpdateData = (id, payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosRequest.patch(`user/update-pa-customerinfo/${id}`, payload)
      if (response.errCode === -1) {
        toast.success(response?.errMsg?.message, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
      }
      console.log(response, "response")
      dispatch(updateDataSuccess(response));
      dispatch(pafetchData(id))
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
};

//update api for multiple PA product
export const paMultipleUpdate = (id, payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosRequest.patch(`user/update-pa-quote/${id}`, payload)
      if (response.errCode === -1) {
        toast.success(response?.errMsg?.message, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
        console.log(response, "response")
        dispatch(updateDataSuccess(response));
        dispatch(pafetchData(id))
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
};

// multipleInsureddetailsEntering

export const multipleInsuredDataPosting = (multipleInsureddata) => {
  return {
    type: 'PA_MULTIPLE_INSURED_DETAILS_POSTING',
    payload: multipleInsureddata
  }
}

export const resetPaData = () => ({
  type: PA_RESET_DATA,
});

export const paseperateRequestData = () => ({
  type: PA_SEPERATE_RESET_DATA,
})

export const setPolicyInceptionDate = (effectiveDate, expiryDate) => ({
  type: POLICY_INCEPTION_DATE,
  payload: {
    effectiveDate,
    expiryDate
  }
})

//hard copy and soft copy handing in PA product
export const setCopyOption = (option) => ({
  type: SET_COPY_OPTION,
  payload: option
});
 //for hardcopy we need to store address in PA product
export const setAddress = (address) => ({
  type: SET_ADDRESS,
  payload: address
});




