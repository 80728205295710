import React,{ useEffect } from 'react'
import rootIndex from "../../store/root_index";
import { useHistory } from "react-router-dom"
import { Base_URL } from '../../config/BaseUrl';
import { useDispatch } from "react-redux";
import * as action from '../../store/actions/index'
import axiosRequest from '../../axios-request/request.methods'
import Cookies from 'js-cookie';
import { pafetchData, paseperateRequestData, resetPaData } from '../../store/actions/paProduct/pafetchdata';
import { createQuoteObject, displayingdDataUsingSharableLink } from '../../store/actions/paProduct/createQuoteactionType';
import { useLocation } from 'react-router-dom';

const { store } = rootIndex;
export default function AccidentDynamicRoute(props) {
    console.log("Props",props)
    const _store = store.getState();
    const history = useHistory();
    const dispatch = useDispatch()
    
 const location = useLocation();
 const searchParams = new URLSearchParams(location.search);
 const langParam = searchParams.get('lang');
 console.log(langParam,"langParam")
    useEffect(() => {
      
        console.log(props.match.params.id, 'params-----> Check ID==');
        console.log(props.match.params.id, 'params-----> Check ID==');
        console.log(props.match.params.id.length,"skdnjsdb")
        // return

        if (props?.match?.params?.id?.length === 6) {
            let Data ={
                cipherkaye:props.match.params.id,
                cipherkeyavilable:true
            }
            console.log(Data,"Data")
            dispatch(action.fetch_cipher_data(Data)) 
            let options = { secure: false, multipart: false }
            axiosRequest.get(`auth/decrypt-link-pa`,options).then((data) => { 
                    if(data?.errCode === -1){
                        dispatch(action.set_global_language(langParam));
                        Cookies.set('LANG', langParam === 'en' ? 'en' : 'id', { domain: process.env.REACT_APP_COOKIE_URL });
                        console.log(data.errMsg, 'AccidentDynamicAPIResponse');
                        if (data?.errMsg?.data?.hasOwnProperty("stage") === false) {

                            dispatch(resetPaData());
                            dispatch(paseperateRequestData())
                                history.push("/oonaaccidentscreen")
                        }
                        else {
                            if (data.errMsg.data?.stage === "Plan Selected") {
                                dispatch(pafetchData(data?.errMsg?.data?.quoteId))
                                dispatch(createQuoteObject(data?.errMsg?.data?.quoteId))
                                dispatch(displayingdDataUsingSharableLink(false))
                                history.push("/accidentordersummary")
                            } 
                            else if (data?.errMsg?.data.stage === 'Quick Quote Created') {
                               
                                dispatch(pafetchData(data?.errMsg?.data?.quoteId))
                                dispatch(createQuoteObject(data?.errMsg?.data?.quoteId))
                                dispatch(displayingdDataUsingSharableLink(true))
                                history.push("/accidentordersummary")
                            }

                            else if (data.errMsg.data?.stage === "Full Quote Created") {
                                dispatch(pafetchData(data?.errMsg?.data?.quoteId))
                                dispatch(createQuoteObject(data?.errMsg?.data?.quoteId))
                                dispatch(displayingdDataUsingSharableLink(true))
                                history.push("/accidentpaymentconfirmation")

                            } 
                            
                            else if (data.errMsg.data?.stage === "Customer Information Updated") {
                                dispatch(pafetchData(data?.errMsg?.data?.quoteId))
                                dispatch(createQuoteObject(data?.errMsg?.data?.quoteId))
                                dispatch(displayingdDataUsingSharableLink(false))
                                history.push("/accidentcustomerdetails")

                            } 
                       
                        else if (data.errMsg.data?.stage === "Policy Generated"){
                            dispatch(pafetchData(data?.errMsg?.data?.quoteId))
                            dispatch(createQuoteObject(data?.errMsg?.data?.quoteId))
                            dispatch(displayingdDataUsingSharableLink(true))
                            history.push("/accidentpaymentconfirmation")

                        }

                        else if (data.errMsg.data?.stage === "Full Quote Created With Payment"){
                            dispatch(pafetchData(data?.errMsg?.data?.quoteId))
                            dispatch(createQuoteObject(data?.errMsg?.data?.quoteId))
                            dispatch(displayingdDataUsingSharableLink(false))
                            history.push("/accidentpaylater")

                        }
                    }
                }

                })
        }else{
            history.push("/login")
            let Data ={cipherkeyavilable:false}

            dispatch(action.fetch_cipher_data(Data))
        }

    }, [])
  return (
    <></>
  )
}
