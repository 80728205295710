import React from 'react'

const OONApaylaterError = () => {
  return (
    <div>
      OONApaylaterError
    </div>
  )
}

export default OONApaylaterError
