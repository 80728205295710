// import { Collapse } from 'antd'
// import React from 'react'

// const MultiplePanel = ({additionalbenefitsData,stage}) => {
//     console.log(additionalbenefitsData,"additionalbenefitsData")
//     const { Panel } = Collapse;
//   return (
//     <div>
//       {
//         additionalbenefitsData?.map((items,index)=>{
//           console.log(items?.BenefitCode,"csv==>")
//         return    <>
//               <Collapse  accordion collapsible="header" className='nestedCollapse'>
//                   <Panel header={
//                     <div style={{ display: "flex", justifyContent: "space-between" }}>
//                     <div style={{fontWeight:"600",color:"#4F5B66",width:"70%"}}>{items.BenefitDesc}</div>
//                     <div style={{fontWeight:"600",color:"#4F5B66"}}>{new Intl.NumberFormat('id-ID', {
//                         style: 'currency',
//                         currency: 'IDR',
//                         minimumFractionDigits: 0,
//                     }).format( items?.BenefitCode === "AU01" && stage === "ordersummary" ? items?.PremiumWithoutDiscount : items.Premium || 0)}</div>
//                 </div>
//                 } >
//                   <div className="accordian-inner" style={{ display: "flex", flexDirection: "column" }}>
//                                         {["FirstYrPremium", "SecondYrPremium", "ThirdYrPremium", "FourthYrPremium", "FifthYrPremium"].map((year, index) => {
//                                             const yearLabel = `${items?.BenefitDesc} Year ${index + 1}`;
//                                             console.log(yearLabel,"yearLabel")
//                                             const yearPremium = items[year];
//                                             console.log(yearPremium,"yearPremium===>")
//                                             if (yearPremium) {
//                                                 return (
//                                                     <div key={index} style={{ display: "flex", justifyContent: "space-between" ,padding:"0.2em 0em 0.2em 0em"}}>
//                                                         <div style={{fontWeight:"500",color:"#4F5B66",width:"70%"}}>{yearLabel}</div>
//                                                         {/* <div style={{fontWeight:"500",color:"#4F5B66"}}>{new Intl.NumberFormat('id-ID', {
//                                                             style: 'currency',
//                                                             currency: 'IDR',
//                                                             minimumFractionDigits: 0,
//                                                         }).format(yearPremium || 0)}</div> */}
//                                                     </div>
//                                                 );
//                                             }
//                                             return null;
//                                         })}
//                                     </div>
        
//                   </Panel>
//                   </Collapse>
//             </>

//         })
//       }
//     </div>
//   )
// }

// export default MultiplePanel


import { Collapse } from 'antd';
import React from 'react';

const MultiplePanel = ({ additionalbenefitsData, stage,duration }) => {
    console.log(additionalbenefitsData, "additionalbenefitsData");
    console.log(duration,"duration")
    const { Panel } = Collapse;

    return (
        <div>
            {additionalbenefitsData?.map((items, index) => {
                console.log(items?.BenefitCode, "csv==>");

                return (
                    <Collapse key={index} accordion collapsible="header" className='nestedCollapse'>
                        <Panel header={
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div style={{ fontWeight: "600", color: "#4F5B66", width: "70%" }}>{items.BenefitDesc}</div>
                                <div style={{ fontWeight: "600", color: "#4F5B66" }}>
                                    {new Intl.NumberFormat('id-ID', {
                                        style: 'currency',
                                        currency: 'IDR',
                                        minimumFractionDigits: 0,
                                    }).format((items?.BenefitCode === "AU01" && stage === "ordersummary" && duration > 1)  ? items?.PremiumWithoutDiscount : items.Premium || 0)}
                                </div>
                                   
                            </div>
                        }>
                            <div className="accordian-inner" style={{ display: "flex", flexDirection: "column" }}>
                                {["FirstYrPremium", "SecondYrPremium", "ThirdYrPremium", "FourthYrPremium", "FifthYrPremium"].map((year, yearIndex) => {
                                    const yearLabel = `${items?.BenefitDesc} Year ${yearIndex + 1}`;
                                    console.log(yearLabel, "yearLabel");

                                    // Determine which premium to display
                                    let yearPremium = items[year];
                                    if (items.BenefitCode === "AU01" && stage === "ordersummary" && yearIndex === 0 && yearPremium === "0") {
                                        yearPremium = items.SecondYrPremium;
                                    }
                                    
                                    console.log(yearPremium, "yearPremium===>");

                                    if (yearPremium) {
                                        return (
                                            <div key={yearIndex} style={{ display: "flex", justifyContent: "space-between", padding: "0.2em 0em 0.2em 0em" }}>
                                                <div style={{ fontWeight: "500", color: "#4F5B66", width: "70%" }}>{yearLabel}</div>
                                                <div style={{ fontWeight: "500", color: "#4F5B66" }}>
                                                    {new Intl.NumberFormat('id-ID', {
                                                        style: 'currency',
                                                        currency: 'IDR',
                                                        minimumFractionDigits: 0,
                                                    }).format(yearPremium || 0)}
                                                </div>
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                        </Panel>
                    </Collapse>
                );
            })}
        </div>
    );
};

export default MultiplePanel;








