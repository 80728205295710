import React, { useState } from 'react'
import './FullPageLoader.css'
import loadImg from '../../images/oona-logo@2x.png'
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { useSelector } from "react-redux";

//const [loading, setLoading] = useState()


const FullPageLoader = ({ fromapploader }) => {


  const antIcon = () => {
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  }

  // var loader = useSelector((state) => state?.leads?.showloader); 
  // var hide = useSelector((state) => state?.leads?.hideloader); 


  // if(!fromloginloader) return null
  // if(!fromhomeloader) return null

  // alert(fromloginloader)
  return (
    <>
      {
        fromapploader ?
          <div className='loader_data'>
            <div className='loader'>
              <Spin indicator={antIcon} />
            </div>

          </div> : null
      }

    </>
  )
}

export default FullPageLoader