import Keycloak from "keycloak-js";
import { stoageSetter } from '../helpers';

let initOptions = {
    url: "https://auth.myoona.id/",
    realm: "id_rlm_external",
    clientId: "id_cl_kahoona_app",
}

const keycloak = new Keycloak(initOptions);



// keycloak.redirectUri="http://localhost:3000/login";

export default keycloak;