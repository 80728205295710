import React, { useState, useEffect } from 'react'
import { Collapse, Button, Card, Row, Col, Modal, message, Progress, Form, Spin } from "antd";
import { DownOutlined, UpOutlined, ArrowLeftOutlined } from "@ant-design/icons"
import { useHistory } from "react-router-dom"
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import rootIndex from "../../../store/root_index";
import { useLanguage } from '../../../config/LanguageContext';
import { toast } from 'react-toastify';
import { getLanguage } from '../../../config/LanguageGetter';
import axiosRequest from '../../../axios-request/request.methods'
import CustomerHeader from '../../../CustomerHeader/CustomerHeader';
import {Slider} from "antd"
// import * as action from '../../store/actions/index'
import * as action from '../../../store/actions/index'
import { useSelector,useDispatch } from 'react-redux';



export default function Index() {
    const [form] = Form.useForm();
    const { store } = rootIndex;
    const { Panel } = Collapse;

    const fetchingWholeDatafromStore=useSelector((state)=>state?.cipherlinkreducer?.quote)
    const dispatch=useDispatch()

    console.log(fetchingWholeDatafromStore,"fetchingWholeDatafromStore")
    const { translate, language } = useLanguage()
    const fetchingIDfromStore=useSelector((state)=>state?.cipherlinkreducer?.quote?.quoteId)
    console.log(fetchingIDfromStore,"fetchingIDfromStore")
    const checkingResumeType=useSelector((state)=>state?.cipherlinkreducer?.quote?.resumeType);
    console.log(checkingResumeType,"checkingResumeType")
    const history = useHistory()

    const [productList, setProductList] = useState([])
    console.log(productList,"productList")
    const [selectedPlan, setSelectedPlan] = useState({})
    console.log(selectedPlan,"selectedPlan")
    const [quoteData, setQuoteData] = useState({});
    const [checkingcomportlo,setCheckingcomporTlo]=useState("")
    console.log(checkingcomportlo,"checkingcomportlo")
    console.log(quoteData,"quoteData")
    const [type, setType] = useState('')
    const [isModalOpen,setisModalOpen]=useState(false)
    const [width, setWidth] = useState(window.innerWidth);
    const breakpoint = 620;
    const _store = store.getState();
    const [planCode, setPlanCode] = useState('');
    const [sliderYear,setSliderYear]=useState(3)
    console.log(sliderYear,"sliderYear")
    const [planName, setPlanName] = useState('');
    const [planCost, setPremium] = useState('');
    const [activeKey, setActiveKey] = useState([]);
    const [planDetails,setplanDetails]=useState([])
    console.log(planDetails,"planDetails")
    console.log(_store, "assd")
    const[loader,setLoader]=useState(false)
    const accordionColors = ["linear-gradient(260.99deg, #B18E75 -0.7%, #66462F 96.39%)", "linear-gradient(74.62deg, #1C1F20 -10.07%, #476372 99.04%)", "linear-gradient(74.62deg, #6F5000 -10.07%, #D1A737 99.04%)", "linear-gradient(260.99deg, #B18E75 -0.7%, #66462F 96.39%)", "linear-gradient(74.62deg, #1C1F20 -10.07%, #476372 99.04%)"];

    console.log(language, "ghj location")
    useEffect(() => {

        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, [width]);

      // Scroll to top when the component mounts
      useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }, []);
      
    useEffect(() => {
        if(fetchingIDfromStore !== ""){
            getProductListHandler()
        }
    }, [language,fetchingIDfromStore])



    useEffect(()=>{
        console.log(quoteData?.duration,"durationcbxh==>")
        if(quoteData?.duration){
            console.log("checkingDurationinUSeEFfect==>")
            form.setFieldsValue({
                sliderYear:quoteData?.duration
            })
            setSliderYear(quoteData?.duration)
        }

    },[quoteData])


useEffect(()=>{
    if (quoteData?.product_Id) {
        if (quoteData.product_Id === '64c0fd38f6d4bddffcac8f71') {
            setType('MotorCycle')
        }
        else {
            setType('Car')
        }
    }

},[quoteData])



useEffect(()=>{
    if(quoteData?.product_Id && fetchingIDfromStore !==""){
        getQuote(quoteData?.coverageOption,quoteData.product_Id === '64c0fd38f6d4bddffcac8f71' ? "0215" : "PC0214",sliderYear,quoteData?.isEligibleForMultiyear);
    }

},[quoteData,sliderYear,fetchingIDfromStore])


    const callback = (key) => {
        if (key >= 0) {
            setActiveKey(key);
        } else {
            setActiveKey([]);
        }
    };



    useEffect(()=>{
        const selctedplnsList=productList?.find((item)=>{
            console.log(item,"iteminUSEEFfect")
            return selectedPlan?.planCode === item?.PlanCode
        })
        console.log(selctedplnsList,"selctedplnsList")
        setplanDetails(selctedplnsList)

    },[productList,selectedPlan,sliderYear])
  
    
   


    const genExtra = (item) => (
        <Button onClick={() => handleSelect(item)} style={{ background: "transparent", color: "white" }}>
            {selectedPlan?.planCode === item.PlanCode ? `${translate("home")["Selected"]}` : `${translate("travel")["Select"]}`}
        </Button>
    );


    const getProductListHandler = () => {
        let options = { secure: true, multipart: false }
        var fromReferral = ""
        if(checkingResumeType === "referalJourney" || checkingResumeType === "quoteSearch"){
          fromReferral=`user/get-quotes?id=${fetchingIDfromStore}&logkey=${checkingResumeType}`
        }
        else{
          fromReferral=`user/get-quotes?id=${fetchingIDfromStore}`
        }

        try {
            axiosRequest
                .get(
                    `${fromReferral}`, options
                )
                .then((data) => {
                    if (data.errCode === -1) {
                        console.log(data.errMsg, "dfghj")
                        setQuoteData(data.errMsg.data[0])
                        setCheckingcomporTlo(data.errMsg.data[0]?.coverageOption)
                        setSelectedPlan({ planCode: data.errMsg.data[0].planCode, cost: data.errMsg.data[0].premium, planName: data.errMsg.data[0].planName })
                    }
                })
                .catch((error) => {
                    console.log(
                        "error:2---forgotpass-----> ",
                        typeof error,
                        error.Error,
                        error.message
                    );
                });
        } catch (error) { }
    };
    const getQuote = (coverageOption,id,durationyear,isEligibleForMultiyear) => {
        setLoader(true)

        try {
            let options = { secure: true, multipart: false }
            let checkingcomporTlo = coverageOption === "1" && isEligibleForMultiyear 
            ? `user/motor-getplantwoWheeler/${fetchingIDfromStore}?productCode=${id}&LangId=${getLanguage(language)}&duration=${durationyear}`
            : `user/motor-getplantwoWheeler/${fetchingIDfromStore}?productCode=${id}&LangId=${getLanguage(language)}&duration=${1}`
            axiosRequest
                .get(
                    checkingcomporTlo,
                    options
                )
                .then((data) => {
                    if (data.errCode === -1) {
                        setLoader(false)
                        const sortedA = data.errMsg?.data[0]?.Plans?.slice()?.sort((plan1, plan2) => {
                            const premium1 = parseInt(plan1.Premium);
                            const premium2 = parseInt(plan2.Premium);

                            if (premium1 < premium2) {
                                return 1; // Return 1 to indicate that plan2 comes before plan1
                            } else if (premium1 > premium2) {
                                return -1; // Return -1 to indicate that plan1 comes before plan2
                            } else {
                                return 0; // Return 0 if premiums are equal (no change in order)
                            }
                        });

                        setProductList(sortedA)
                        // console.log(data.data.errMsg.data, "res");
                    }
                    else{
                        setLoader(false)
                    }
                })
                .catch((error) => {
                    setLoader(false)
                    console.log(
                        "error:2---forgotpass-----> ",
                        typeof error,
                        error.Error,
                        error.message
                    );
                });
        } catch (error) { }
    }
    const handleSelect = (e) => {
        console.log(e)
        setSelectedPlan({ planCode: e.PlanCode, cost: e.Premium, planName: e.PlanName })
    }
    const handleNext = () => {
        if (selectedPlan.cost) {
            handleChooseProduct(selectedPlan.planCode, selectedPlan.planName, selectedPlan.cost)
        } else {
            toast.error('Select a Plan to further Proceed', { position: "top-center", hideProgressBar: true, autoClose: 1000 })
        }
    }

   


    const sendEmail = () => {
        window.open("mailto:noreply@oona-insurance.co.id?subject=SendMail&body=Description");
    };

    const handleChooseProduct = (planCode, planName, premium) => {
console.log(checkingcomportlo,"checkingcomportlo")
let Data={
    ...fetchingWholeDatafromStore,
    mvduration:sliderYear,
    isMvdurationChanged:true

}

let formData
if(quoteData?.isEligibleForMultiyear && sliderYear > 1){
     formData = {
        planCode: planDetails?.PlanCode,
        premium: planDetails?.Premium,
        planName: planDetails?.PlanName,
        duration:sliderYear,
        planDetails:planDetails,
        isEligibleForMultiyear:true
    }
}
else if (quoteData?.isEligibleForMultiyear && sliderYear === 1){
    formData = {
        planCode: planDetails?.PlanCode,
        premium: planDetails?.Premium,
        planName: planDetails?.PlanName,
        duration:sliderYear,
        planDetails:planDetails,
        isEligibleForMultiyear:true
    }

}
else{
    formData = {
        planCode: planDetails?.PlanCode,
        premium: planDetails?.Premium,
        planName: planDetails?.PlanName,
        duration:1,
    }

}

        let options = { multipart: false, secure: true }

        axiosRequest
            .patch(
                `user/update-quote/${fetchingIDfromStore}`,
                formData,
                options
            )
            .then((data) => {
                if (data.errCode === -1) {
                    setPlanCode(planCode)
                    setPlanName(planName)
                    setPremium(premium)
                    dispatch(action.set_quote_id(Data))
                    if (data.errMsg.data.product_Id === "64c0fd38f6d4bddffcac8f71") {
                        history.push({
                            pathname: 'motorordersummary',
                            // state: {
                            //     data:location?.state?.data,
                            //     quoteId: data.errMsg.data._id,
                            //     cipher: location.state?.cipher != undefined ? location.state?.cipher : null,
                            // }
                        })
                    } else {
                        history.push({
                            pathname: 'motoradditionalbenefits',
                            // state: {
                            //     data:location?.state?.data,
                            //     quoteId: data.errMsg.data._id,
                            //     cipher: location.state?.cipher != undefined ? location.state?.cipher : null
                            // }
                        })
                    }
                    message.success(`${translate("home")["Quote Updated Successfully"]}`);
                }
            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
                message.error(`${translate("home")["Network Request Failed"]}`);

            });

    }

    const marks  = {
        1: '1 Year',
        5: '5 Years',
      };


      const handleSlideryearChange=(value)=>{
        setSliderYear(value);
        form.setFieldsValue({ sliderYear: value });
      }

      const handleOk=()=>{
        setisModalOpen(true)
      }
      const handleCancel=()=>{
        setisModalOpen(true)
      }

      const plansbenefits=[
        {
            year:"Sum Insured Year 1", sumInsured:productList[0]?.FirstYrSumInsured !== undefined && productList[0]?.FirstYrSumInsured
        },
        {
            year:"Sum Insured Year 2", sumInsured:productList[0]?.SecondYrSumInsured !== undefined && productList[0]?.SecondYrSumInsured
        },
        {
            year:"Sum Insured Year 3", sumInsured:productList[0]?.ThirdYrSumInsured !== undefined && productList[0]?.ThirdYrSumInsured
        },
        {
            year:"Sum Insured Year 4", sumInsured:productList[0]?.FourthYrSumInsured !== undefined && productList[0]?.FourthYrSumInsured
        },
        {
            year:"Sum Insured Year 5", sumInsured:productList[0]?.FifthYrSumInsured !== undefined && productList[0]?.FifthYrSumInsured
        },
      ]
      
      console.log(plansbenefits,"plansbenefits")

      

    return (
        <>
                <CustomerHeader/>

            <div className="row-travel">
                <Row>
                    <Col xl={7} md={7} lg={7} sm={24} xs={24}>
                        {width > breakpoint ? (
                            <>
                                <h5 className="heading-content">{translate("travel")["Quick quote"]}</h5>
                                <Card className="card-progress">
                                    <Row>
                                        <Col md={3}>
                                            <Progress className="progress-content"
                                                percent={30} showInfo={false}
                                            />
                                        </Col>
                                        <Col className="col-content" md={10}>
                                            <div className='thick-font'>{translate("travel")["Create Quote"]}</div>
                                            <div className='thick-font'>{translate("travel")["Customer Details"]}</div>
                                            <div className='thick-font'>{translate("travel")["Confirmation"]}</div>
                                        </Col>
                                    </Row>
                                </Card>
                            </>
                        ) : (
                            <>
                                <Card className="card-mobile">
                                    <div className="justify-space">
                                        <div className="mob-header">
                                            {translate("travel")["Quick quote"]}
                                        </div>
                                        <div style={{ cursor: "pointer" }} onClick={sendEmail} className='need-content'>
                                            {translate("onboarding")["Need Help"]} ?
                                        </div>
                                    </div>
                                </Card>
                                <Card className="card-mobile">
                                    <Row>
                                        <Col xs={3}>
                                            <div className="number-mob-selected">
                                                1
                                            </div>
                                        </Col>
                                        <Col xs={14}>
                                            <div className='thick-font'>
                                                {translate("travel")["Create Quote"]}
                                            </div>
                                        </Col>
                                        <Col xs={3}>
                                            <div className="number-mob-non-selected">
                                                2
                                            </div>
                                        </Col>
                                        <Col xs={3}>
                                            <div className="number-mob-non-selected">
                                                3
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </>
                        )}
                    </Col>
                    <Col xl={14} md={14} lg={14} sm={24} xs={24}>
                        <Card>
                            <div className='travel-row-1 back-content'>
                                <div className='arrow-back-font' onClick={() =>
                                    history.push({
                                        pathname: "/motorscreen",
                                        // state: {
                                        //     data:location?.state?.data,
                                        //     quoteId: fetchingIDfromStore,
                                        //     cipher: location.state.cipher
                                        // }

                                    })
                                }><ArrowLeftOutlined />{translate("onboarding")["Back"]}</div>
                                <div style={{ cursor: "pointer" }} onClick={sendEmail} className='need-content'>{translate("onboarding")["Need Help"]} ?</div>
                            </div>
                            <div className="steps-content">

                                <div>
                                    <h3 className="header-card-content">{translate("travel")["Choose Product"]}</h3>
                                    <div>{translate("motor")["Our best selection of product for your vehicle."]}</div>

                                    <div className="destination-heading">
                                        {translate("motor")["Vehicle Type"]}: {type === "Car" ? `${translate("motor")["Car"]}` : type}, {translate("motor")["Coverages"]}: {quoteData?.coverageOption === '2' ? 'TLO' : 'Comperhensive'}
                                    </div>
                                    {
                                        quoteData?.isEligibleForMultiyear ? (<>
                                        <Col xs={15} sm={15} md={15} xl={15} lg={15} style={{marginBottom:"3.50em"}}> 
                                    <div className='product__title__slider'>
                                    Enter your car's years of coverage
                                    </div>
                                    <div style={{color:"#482C77",fontWeight:"700",fontSize:"1.8em",lineHeight:"2em"}}>
                                  {sliderYear} Years
                                        </div>
                                        <div className="slidertextAlign">
  <div className="textSection">
    There would be sum insured depreciation per year.
    <span className="linkSection" onClick={() => setisModalOpen(true)}> See detail</span>
  </div>
</div>
<Form form={form} initialValues={{ sliderYear: 3 }}>
    <Form.Item name="sliderYear">
    <Slider
        min={1}
        max={5}
        step={1}
        marks={marks}
        value={sliderYear}
        // defaultValue={5}
        className='slider__multiline'
        onChange={handleSlideryearChange}
       
      />

    </Form.Item>


</Form>

         
                                    </Col>
                                        </>

                                        ):(<></>)
                                    }
                                    

                                    <Modal closable={false} visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                                        <h5>Sum Insured</h5>
                                        <br />
                                        {plansbenefits?.filter((item)=>{
                                            return item?.sumInsured !== false
                                        }).map((item) => (
                                            <div className='accordian-inner'>
                                                <div style={{ padding: "0% 20% 2% 0%" }}>
                                              {item?.year}
                                                </div>
                                                <div>
                                                    {new Intl.NumberFormat('id-ID', {
                                                        style: 'currency',
                                                        currency: 'IDR',
                                                        minimumFractionDigits: 0,
                                                    }).format(item?.sumInsured)}</div>
                                            </div>
                                        ))}

                                        <hr />
                                        <div className='accordian-inner'>
                                            <div style={{ color: "#482c77", fontWeight: 600 }}>
                                                {translate("motor")["Total"]}
                                            </div>
                                            <div className='thick-font' style={{ fontSize: 18, fontWeight: "bold" }}>
                                            
                                            {new Intl.NumberFormat('id-ID', {
                                                        style: 'currency',
                                                        currency: 'IDR',
                                                        minimumFractionDigits: 0,
                                                    }).format(productList[0]?.SumInsured)}
                                            
                                            </div>
                                        </div>
                                        <br />
                                        <Button className='next-button-travel' onClick={()=>setisModalOpen(false)}>Ok</Button>
                                    </Modal>
         
                                  
                                                               
                                    <Spin tip="Loading" spinning={loader} >
                                    {productList &&
                                        productList?.map((item, i) => (
                                            <Collapse activeKey={activeKey} onChange={callback} className="travel-header-accord" accordion collapsible="header">
                                                <Panel
                                                    style={{
                                                        background: accordionColors[i % accordionColors.length], // Apply background color based on index
                                                        color: "white",
                                                    }} className="accordian-image-4"
                                                    extra={genExtra(item, i)}
                                                    showArrow={false}
                                                    header={
                                                        <>
                                                            <div>
                                                                <Card style={{ background: "transparent", color: "white" }}>
                                                                    <Row>
                                                                        <Col md={20} xl={20} lg={20} sm={20} xs={16}>
                                                                            <div>
                                                                                <b>{item.PlanName}</b>
                                                                            </div>
                                                                            <div>{new Intl.NumberFormat('id-ID', {
                                                                                style: 'currency',
                                                                                currency: 'IDR',
                                                                                minimumFractionDigits: 0,
                                                                            }).format(item?.Premium)} / { quoteData?.isEligibleForMultiyear ? `${sliderYear} years` : "year"}</div>
                                                                        </Col>
                                                                       
                                                                    </Row>
                                                                </Card>
                                                            </div>
                                                        </>
                                                    }
                                                    key={i}
                                                >
                                                    <>
                                                        <div style={{ marginBottom: 10 }} className="accordian-inner">
                                                            <div className="thick-font">BASIC BENEFITS</div>
                                                            <div className="thick-font">TOTAL PREMIUM</div>
                                                        </div>
                                                      
                                                      {
                                                        quoteData?.isEligibleForMultiyear ? (item.MainBenefits.filter(benefit => benefit.BenefitType === "C").map((benefit, j) => (
                                                            <Collapse key={j} accordion collapsible="header" className='nestedCollapse'>
                                                                <Panel
                                                                    header=<div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                    <div style={{fontWeight:"600",color:"#4F5B66"}}>{benefit.BenefitDesc}</div>
                                                                    <div style={{fontWeight:"600",color:"#4F5B66"}}>{new Intl.NumberFormat('id-ID', {
                                                                        style: 'currency',
                                                                        currency: 'IDR',
                                                                        minimumFractionDigits: 0,
                                                                    }).format(benefit.Premium || 0)}</div>
                                                                </div>
                                                                    key={j}
                                                                >
                                                                    <div className="accordian-inner" style={{ display: "flex", flexDirection: "column" }}>
                                                                        {["FirstYrPremium", "SecondYrPremium", "ThirdYrPremium", "FourthYrPremium", "FifthYrPremium"].map((year, index) => {
                                                                            const yearLabel = `Year ${index + 1}`;
                                                                            const yearPremium = benefit[year];
                                                                            if (yearPremium) {
                                                                                return (
                                                                                    <div key={index} style={{ display: "flex", justifyContent: "space-between" ,padding:"0.2em 0em 0.2em 0em"}}>
                                                                                        <div style={{fontWeight:"500",color:"#4F5B66"}}>{yearLabel}</div>
                                                                                        <div style={{fontWeight:"500",color:"#4F5B66"}}>{new Intl.NumberFormat('id-ID', {
                                                                                            style: 'currency',
                                                                                            currency: 'IDR',
                                                                                            minimumFractionDigits: 0,
                                                                                        }).format(yearPremium || 0)}</div>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                            return null;
                                                                        })}
                                                                    </div>
                                                                </Panel>
                                                            </Collapse>
                                                        ))):(
                                                            item?.MainBenefits.map((data) => (
                                                                <div className="accordian-inner">
                                                                    <div>{data.BenefitDesc}</div>
                                                                    <div>{new Intl.NumberFormat('id-ID', {
                                                                        style: 'currency',
                                                                        currency: 'IDR',
                                                                        minimumFractionDigits: 0,
                                                                    }).format(data?.Premium || 0)}</div>
                                                                </div>
                                                            ))
                                                        )
                                                      }
                                                      

                                                        

                                                    </>
                                                </Panel>
                                                <div className="accord-bottom-font view-detail-font" accordion collapsible="header" onClick={() => callback(activeKey.includes(i.toString()) ? [] : i.toString())} style={{
                                                    cursor: 'pointer'
                                                }}>
                                                    {activeKey.includes(i.toString()) ? <>{translate("home")["Hide Detail"]}<UpOutlined style={{ color: "#4AC6BB", strokeWidth: 30 }} /></> : <>{translate("travel")["See Details"]}<DownOutlined style={{ color: "#4AC6BB", strokeWidth: 30 }} /></>}
                                                </div>
                                            </Collapse>
                                        ))
                                    }
                                    </Spin>

                                    <Button className="next-button-travel" onClick={handleNext}>
                                        {translate("home")["Next"]}
                                    </Button>
                                </div>
                            </div>

                        </Card>
                    </Col>
                </Row>
            </div >
        </>
    )
}

