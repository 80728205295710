import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  Image,
  Button,
  Progress,
  DatePicker,
  Modal,
  Spin,
  Select,
} from "antd";
import {
  ArrowLeftOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import rootIndex from "../.././store/root_index";
import moment from "moment";
import { Base_URL } from "../.././config/BaseUrl";
import { toast } from "react-toastify";
import { useLanguage } from "../.././config/LanguageContext";
import "../AccidentScroll.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { pafetchData } from "../../store/actions/paProduct/pafetchdata";
import MapContainer from "../../oona-home/screens/MapContainer";
import Feather from "../../oonaImages/Feather.png";
import axiosRequest from "../../axios-request/request.methods";
import { sdk } from "../sdk/SDK";
import CustomerHeader from "../../CustomerHeader/CustomerHeader";

const { store } = rootIndex;

export default function MultipleCustomerInformationInd(props) {
  const { Option } = Select;
  const _store = store.getState();
  const quoteData = useSelector((state) => state.paQuoteObject);
  const { paQuoteObject } = quoteData;
  const data = useSelector((state) => state.paQuotefetchedData);
  const updateData = useSelector((state) => state.updateQuoteData)
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [otrRelation, setOtrRelation] = useState([]);
  const { paQuotefetchedData } = data;
  const dispatch = useDispatch();
  const indInformationdetails = {
    insuredName: "",
    insuredIdCard: "",
    insuredDob: "",
    address: "",
    insuredMobile: "",
    insuredEmail: "",
    beneficiaryName: "",
    beneficiaryDateOfBirth: "",
  };
  const [customerDeatils, setCustomerDetails] = useState(indInformationdetails);
  const location = useLocation();
  const [renderInsuredScreen, setRenderInsuredScreen] = useState(location?.state?.index + 1);
  const [addressmodalPopup, setaddressmodalpopup] = useState(false);
  const [mapsaddress, setmapsAddress] = useState("");
  const [cords, setCords] = useState({ lat: 0, long: 0 });
  const [fetchingtheHypervergeData, setfetchingtheHypervergeData] = useState("");
  const [insuredEditable, setInsuredEditable] = useState(false)
  const [imagedetails, setImageDetails] = useState("");
  const [getImage, setGetImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const { translate, language } = useLanguage();
  console.log(language,"bsjgduwdgf")
  const history = useHistory();
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 620;


  const [form] = Form.useForm();

  console.log(updateData?.updateQuote, "geetha")

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [width]);

  useEffect(() => {
    axiosRequest
      .get(`user/getPaBeneficiary?lang=${language === "en" ? "EN" : "ID"}`)
      .then((res) => {
        setOtrRelation(res?.errMsg);
      });
  }, [language]);

  useEffect(() => {
    form.setFieldsValue({ address: mapsaddress?.data });
    setCustomerDetails({ ...customerDeatils, address: mapsaddress?.data });
  }, [mapsaddress]);


  useEffect(() => {
    if (data.paQuotefetchedData?.ocrDetails?.s3Url === "" || data.paQuotefetchedData?.ocrDetails?.s3Url === undefined || data.paQuotefetchedData?.ocrDetails?.s3Url === null) {
      setImageDetails("");
      setGetImage(false);
    }
    else {
      setImageDetails(data?.paQuotefetchedData?.ocrDetails?.s3Url);
      setGetImage(true);
    }
    if (renderInsuredScreen === 1) {
      setInsuredEditable(true)
    }
    else if (renderInsuredScreen > 1) {
      setInsuredEditable(false)
    }
    if(data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.beneficiaryRelation === "9"){
      setShowOtherInput(true)
    }
    else{
      setShowOtherInput(false)
    }
    if (renderInsuredScreen > 1) {
      if (data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.insuredName.startsWith("INSURED")) {
        form.setFieldsValue({
          insuredName: ""
        });
        setTimeout(() => {
          setCustomerDetails((customerDeatils) => ({
            ...customerDeatils,
            insuredName: ""
          }));
        }, 0);
        form.resetFields(['insuredName'])
      }
      else {
        form.setFieldsValue({
          insuredName: data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.insuredName || ""
        });
        setTimeout(() => {
          setCustomerDetails((customerDeatils) => ({
            ...customerDeatils,
            insuredName: data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.insuredName || ""
          }));
        }, 0);
      }
      form.setFieldsValue({
        insuredMobile: data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.insuredMobile || "",
        insuredEmail: data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.insuredEmail || "",
      });
      setTimeout(() => {
        setCustomerDetails((customerDeatils) => ({
          ...customerDeatils,
          insuredMobile: data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.insuredMobile || "",
          insuredEmail: data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.insuredEmail || "",
        }));
      }, 0);
      if (data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.beneficiaryName == undefined || data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.beneficiaryName == "" || data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.beneficiaryName == null) {
        form.setFieldsValue({
          beneficiaryName: data.paQuotefetchedData.requestLimit[0]?.beneficiaryName,
        });
        setTimeout(() => {
          setCustomerDetails((customerDeatils) => ({
            ...customerDeatils,
            beneficiaryName: data.paQuotefetchedData.requestLimit[0]?.beneficiaryName,
          }));
        }, 0);
      }
      else {
        form.setFieldsValue({
          beneficiaryName: data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.beneficiaryName,
        });
        setTimeout(() => {
          setCustomerDetails((customerDeatils) => ({
            ...customerDeatils,
            beneficiaryName: data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.beneficiaryName,
          }));
        }, 0);
      }
      if (data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.beneficiaryRelation == undefined || data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.beneficiaryRelation == null || data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.beneficiaryRelation == "") {
        form.setFieldsValue({
          beneficiaryRelation: data.paQuotefetchedData.requestLimit[0]?.relationDescription,
          beneficiaryOtherRelation: data.paQuotefetchedData.requestLimit[0]?.beneficiaryOtherRelation
        });
        setTimeout(() => {
          setCustomerDetails((customerDeatils) => ({
            ...customerDeatils,
            beneficiaryRelation: data.paQuotefetchedData.requestLimit[0]?.beneficiaryRelation,
            beneficiaryOtherRelation: data.paQuotefetchedData.requestLimit[0]?.beneficiaryOtherRelation
          }));
        }, 0);
      }
      else {
        form.setFieldsValue({
          beneficiaryRelation: data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.relationDescription,
          beneficiaryOtherRelation: data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.beneficiaryOtherRelation
        });
        setTimeout(() => {
          setCustomerDetails((customerDeatils) => ({
            ...customerDeatils,
            beneficiaryRelation: data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.beneficiaryRelation,
            beneficiaryOtherRelation: data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.beneficiaryOtherRelation
          }));
        }, 0);
      }
      if (data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.address == undefined || data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.address == null || data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.address == "") {
        form.setFieldsValue({
          address: data.paQuotefetchedData.requestLimit[0]?.address,
        });
        setTimeout(() => {
          setCustomerDetails((customerDeatils) => ({
            ...customerDeatils,
            address: data.paQuotefetchedData.requestLimit[0]?.address,
          }));
        }, 0);
      }
      else {
        form.setFieldsValue({
          address: data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.address,
        });
        setTimeout(() => {
          setCustomerDetails((customerDeatils) => ({
            ...customerDeatils,
            address: data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.address,
          }));
        }, 0);
      }
      if (data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.insuredDob != undefined || data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.insuredDob != null) {
        form.setFieldsValue({
          insuredDob: moment(data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.insuredDob),
        });
        setTimeout(() => {
          setCustomerDetails((customerDeatils) => ({
            ...customerDeatils,
            insuredDob: moment(data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.insuredDob),
          }));
        }, 0);
      }
      if (data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.beneficiaryDateOfBirth == undefined) {
        if (data.paQuotefetchedData.requestLimit[0]?.beneficiaryDateOfBirth == null) {
          form.resetFields(['beneficiaryDateOfBirth'])
        }
        else {
          form.setFieldsValue({
            beneficiaryDateOfBirth: moment(data.paQuotefetchedData.requestLimit[0]?.beneficiaryDateOfBirth),
          });
          setTimeout(() => {
            setCustomerDetails((customerDeatils) => ({
              ...customerDeatils,
              beneficiaryDateOfBirth: moment(data.paQuotefetchedData.requestLimit[0]?.beneficiaryDateOfBirth),
            }));
          }, 0);
        }
      }
      else {
        if (data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.beneficiaryDateOfBirth != undefined) {
          form.setFieldsValue({
            beneficiaryDateOfBirth: moment(data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.beneficiaryDateOfBirth),
          });
          setTimeout(() => {
            setCustomerDetails((customerDeatils) => ({
              ...customerDeatils,
              beneficiaryDateOfBirth: moment(data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.beneficiaryDateOfBirth),
            }));
          }, 0);
        }
      }
    }
    else {
      form.setFieldsValue({
        insuredName: data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.insuredName || "",
        insuredIdCard: data.paQuotefetchedData.insuredIdCard || "",
        insuredMobile: data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.insuredMobile || "",
        insuredEmail: data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.insuredEmail || "",
        address: data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.address || "",
        beneficiaryName: data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.beneficiaryName || "",
        beneficiaryRelation: data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.relationDescription || "",
        beneficiaryOtherRelation: data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.beneficiaryOtherRelation || ""
      });
      setTimeout(() => {
        setCustomerDetails((customerDeatils) => ({
          ...customerDeatils,
          insuredName: data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.insuredName || "",
          insuredIdCard: data.paQuotefetchedData.insuredIdCard || "",
          insuredMobile: data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.insuredMobile || "",
          insuredEmail: data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.insuredEmail || "",
          address: data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.address || "",
          beneficiaryName: data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.beneficiaryName || "",
          beneficiaryRelation: data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.beneficiaryRelation || "",
          beneficiaryOtherRelation: data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.beneficiaryOtherRelation || ""
        }));
      }, 0);
      if (data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.insuredDob != undefined) {
        form.setFieldsValue({
          insuredDob: moment(data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.insuredDob),
        });
        setTimeout(() => {
          setCustomerDetails((customerDeatils) => ({
            ...customerDeatils,
            insuredDob: moment(data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.insuredDob),
          }));
        }, 0);
      }
      if (data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.beneficiaryDateOfBirth == undefined) {
        if (data.paQuotefetchedData.requestLimit[0]?.beneficiaryDateOfBirth != undefined || data.paQuotefetchedData.requestLimit[0]?.beneficiaryDateOfBirth != null) {
          form.setFieldsValue({
            beneficiaryDateOfBirth: moment(data.paQuotefetchedData.requestLimit[0]?.beneficiaryDateOfBirth),
          });
          setTimeout(() => {
            setCustomerDetails((customerDeatils) => ({
              ...customerDeatils,
              beneficiaryDateOfBirth: moment(data.paQuotefetchedData.requestLimit[0]?.beneficiaryDateOfBirth),
            }));
          }, 0);
        }
        else if (data.paQuotefetchedData.requestLimit[0]?.beneficiaryDateOfBirth == null) {
          form.setFieldsValue({
            beneficiaryDateOfBirth: "",
          });
          setTimeout(() => {
            setCustomerDetails((customerDeatils) => ({
              ...customerDeatils,
              beneficiaryDateOfBirth: "",
            }));
          }, 0);
          form.resetFields(['beneficiaryDateOfBirth'])
        }
      }
      else {
        console.log(data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.beneficiaryDateOfBirth, "akshay")
        form.setFieldsValue({
          beneficiaryDateOfBirth: moment(data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.beneficiaryDateOfBirth),
        });
        setTimeout(() => {
          setCustomerDetails((customerDeatils) => ({
            ...customerDeatils,
            beneficiaryDateOfBirth: moment(data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?.beneficiaryDateOfBirth),
          }));
        }, 0);
      }
    }
  }, [renderInsuredScreen, data.paQuotefetchedData])

  const handleInsuredData = (i) => {
    if (props?.location?.state?.edit === "editable") {
      var formattedDate2 = "";
      if (customerDeatils?.beneficiaryDateOfBirth && customerDeatils.beneficiaryDateOfBirth != "") {
        formattedDate2 = moment(customerDeatils.beneficiaryDateOfBirth, "DD/MM/YYYY").format("YYYY-MM-DD");
      }
      var insuredIdCardField = ""
      if (renderInsuredScreen === 1) {
        insuredIdCardField = customerDeatils?.insuredIdCard
      }
      const updatePayload = {
        _id: data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?._id,
        insuredName: customerDeatils?.insuredName,
        insuredDob: moment(customerDeatils?.insuredDob, "DD/MM/YYYY").format("YYYY-MM-DD"),
        address: customerDeatils?.address,
        ...(insuredIdCardField && { insuredIdCard: insuredIdCardField }),
        insuredMobile: customerDeatils?.insuredMobile,
        insuredEmail: customerDeatils?.insuredEmail,
        beneficiaryName: customerDeatils?.beneficiaryName,
        beneficiaryOtherRelation: customerDeatils?.beneficiaryOtherRelation,
        ...(formattedDate2 && { beneficiaryDateOfBirth: formattedDate2 }),
        beneficiaryRelation: customerDeatils?.beneficiaryRelation,
        "requestLimit": [
          {
            "insuredName": customerDeatils?.insuredName,
            "requestLimit": data?.paQuotefetchedData?.requestLimit[renderInsuredScreen - 1]?.requestLimit,
            "planName": data?.paQuotefetchedData?.requestLimit[renderInsuredScreen - 1]?.planName,
            "premium": data?.paQuotefetchedData?.requestLimit[renderInsuredScreen - 1]?.premium,
            "_id": data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?._id
          }
        ],
        "isNew": false
      }
      axiosRequest
        .patch(`user/update-pa-customerinfo/${quoteData?.paQuoteObject}`, updatePayload)
        .then((data) => {
          if (data?.errCode === -1) {
            dispatch(pafetchData(paQuoteObject,language))
            history.push("/accidentcustomerdetails")
          }
        })
    }
    else if (renderInsuredScreen <= paQuotefetchedData?.numberOfParticipants) {
      var customerDetailsPayload = {};
      if (renderInsuredScreen === 1) {
        var formattedDate = "";
        if (customerDeatils?.beneficiaryDateOfBirth && customerDeatils.beneficiaryDateOfBirth != "") {
          formattedDate = moment(customerDeatils.beneficiaryDateOfBirth, "DD/MM/YYYY").format("YYYY-MM-DD");
        }
        console.log(customerDeatils, "akshay")
        customerDetailsPayload = {
          _id: data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?._id,
          insuredName: customerDeatils?.insuredName,
          insuredIdCard: customerDeatils?.insuredIdCard,
          insuredDob: moment(customerDeatils?.insuredDob, "DD/MM/YYYY").format("YYYY-MM-DD"),
          insuredMobile: customerDeatils?.insuredMobile,
          insuredEmail: customerDeatils?.insuredEmail,
          address: customerDeatils?.address,
          beneficiaryName: customerDeatils?.beneficiaryName,
          beneficiaryRelation: customerDeatils?.beneficiaryRelation,
          beneficiaryOtherRelation: customerDeatils?.beneficiaryOtherRelation,
          ...(formattedDate && { beneficiaryDateOfBirth: formattedDate }),
          "requestLimit": [
            {
              "insuredName": customerDeatils?.insuredName,
              "requestLimit": data?.paQuotefetchedData?.requestLimit[renderInsuredScreen - 1]?.requestLimit,
              "planName": data?.paQuotefetchedData?.requestLimit[renderInsuredScreen - 1]?.planName,
              "premium": data?.paQuotefetchedData?.requestLimit[renderInsuredScreen - 1]?.premium,
              "_id": data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?._id
            }
          ],
          isNew: false
        };
        axiosRequest.patch(`user/update-pa-customerinfo/${quoteData.paQuoteObject}`, customerDetailsPayload)
          .then((data) => {
            if (data.errCode === -1) {
              toast.success(data?.errMsg?.message, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
              dispatch(pafetchData(quoteData.paQuoteObject,language))
              form.resetFields();
              setRenderInsuredScreen(i + 1)
            }
          })
      }
      else if (renderInsuredScreen > 1) {
        if (customerDeatils?.beneficiaryDateOfBirth != "") {
          var formattedDate1 = moment(
            customerDeatils?.beneficiaryDateOfBirth,
            "DD/MM/YYYY"
          ).format("YYYY-MM-DD")
        }
        else {
          var formattedDate1 = ""
        }
        customerDetailsPayload = {
          _id: data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?._id,
          insuredName: customerDeatils?.insuredName,
          insuredDob: moment(customerDeatils?.insuredDob, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          ),
          address: customerDeatils?.address,
          insuredMobile: customerDeatils?.insuredMobile,
          insuredEmail: customerDeatils?.insuredEmail,
          beneficiaryName: customerDeatils?.beneficiaryName,
          beneficiaryRelation: customerDeatils?.beneficiaryRelation,
          beneficiaryOtherRelation: customerDeatils?.beneficiaryOtherRelation,
          beneficiaryDateOfBirth: formattedDate1,
          "requestLimit": [
            {
              "insuredName": customerDeatils?.insuredName,
              "requestLimit": data?.paQuotefetchedData?.requestLimit[renderInsuredScreen - 1]?.requestLimit,
              "planName": data?.paQuotefetchedData?.requestLimit[renderInsuredScreen - 1]?.planName,
              "premium": data?.paQuotefetchedData?.requestLimit[renderInsuredScreen - 1]?.premium,
              "_id": data.paQuotefetchedData.requestLimit[renderInsuredScreen - 1]?._id
            }
          ],
          isNew: false
        };
        if(customerDeatils?.insuredName === ""){
          toast.error(`${translate("home")["Insured Name is required"]}`,{ position: "top-center", hideProgressBar: true, autoClose: 1000 });
          return;
        }
        else if(!/^[a-zA-Z][a-zA-Z ]*$/.test(customerDeatils?.insuredName)){
            toast.error('Insured name only Alphabets are allowed',{ position: "top-center", hideProgressBar: true, autoClose: 1000 });
            return;
        }
        axiosRequest.patch(`user/update-pa-customerinfo/${quoteData.paQuoteObject}`, customerDetailsPayload)
          .then((data) => {
            if (data.errCode === -1) {
              toast.success(data?.errMsg?.message, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
              dispatch(pafetchData(quoteData.paQuoteObject,language))
              form.resetFields();
              if (renderInsuredScreen < paQuotefetchedData?.numberOfParticipants) {
                setRenderInsuredScreen(i + 1)
              }
              else { history.push("/accidentmultiplecustomerinformation") }
            }
          }
          )
      }
      else { history.push("/accidentmultiplecustomerinformation") }
    }
  };

  const disableDate = (current) => {
    const today = new Date();
    return current && current >= today;
  };

  const isValidAge = (current) => {
    const today = moment();
    const age = today.diff(current, "years");
    return age > 17 && age < 65;
  };
  const disabledDate = (current) => {
    return !isValidAge(current);
  };
  const handleCustomerdetailsOnChange = (name, value) => {
    setCustomerDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };
  /*address*/
  const handleAddressPropertysearch = () => {
    setaddressmodalpopup(true);
  };
  const handleOk = () => {
    setaddressmodalpopup(false);
  };
  const handleCancel = () => {
    setaddressmodalpopup(false);
  };
  const handleInsuredAddress = (data) => {
    var latitudemaps = "";
    var longitudemaps = "";

    navigator.geolocation.getCurrentPosition((position) => {
      latitudemaps = position.coords.latitude;
      longitudemaps = position.coords.longitude;
      setCords({
        lat: position.coords.latitude,
        long: position.coords.longitude,
      });
    });
    const mapspayload = {
      latitude: data?.lat || latitudemaps,
      longitude: data?.long || longitudemaps,
    };
    axiosRequest
      .post(`home/get-address`, mapspayload)
      .then((data) => {
        if (data?.errCode === -1) {
          setmapsAddress(data?.errMsg);
        }
      })
      .catch((error) => {
        toast.error(
          `${translate("home")["Invalid Address / Address not found"]}`,
          { position: "top-center", hideProgressBar: true, autoClose: 1000 }
        );
        console.log(
          "error:2---forgotpass-----> ",
          typeof error,
          error.Error,
          error.message
        );
      });
  };

  useEffect(() => {
    if (fetchingtheHypervergeData?.details?.idNumber !== undefined) {
      setLoading(true);
      setTimeout(gettingOCRDetails, 5000);
    }
  }, [fetchingtheHypervergeData]);

  const gettingOCRDetails = () => {
    let options = { secure: false, multipart: false };
    axiosRequest
      .get(
        `auth/hyperverge/ocr-details?transactionId=${fetchingtheHypervergeData?.transactionId}`,
        options
      )
      .then((data) => {
        if (data?.errCode === -1) {
          setLoading(false);
          setGetImage(true);
          setImageDetails(data?.errMsg?.details?.ocrDetails?.s3Url);
          form.setFieldsValue({
            insuredIdCard: data?.errMsg?.details?.ocrDetails?.idNumber,
          });
          setTimeout(() => {
            setCustomerDetails((customerDeatils) => ({
              ...customerDeatils,
              insuredIdCard: data?.errMsg?.details?.ocrDetails?.idNumber,
            }));
          }, 0);
        }
      });
  };

  const getBack = () => {
    if (props?.location?.state?.edit === "editable") {
      history.push("/accidentcustomerdetails")
    }
    else if (renderInsuredScreen === 1) {
      history.push("/accidentmultiplecustomerinformation")
    }
    else if (renderInsuredScreen > 1) {
      setRenderInsuredScreen(renderInsuredScreen - 1)
    }
  }

  const handleSelectRelationChange = (value, Id, _id) => {
    form.setFieldsValue({
      beneficiaryRelation: value,
    });
    setCustomerDetails({ ...customerDeatils, beneficiaryRelation: Id?.key });
    if (value === "6593e9cb21282ff57bcbeda5") {
      setShowOtherInput(true);
    } else {
      setShowOtherInput(false);
    }
  };

  return (
    <>
      <CustomerHeader />
      <div className="row-travel">
        <Row>
          <Col xl={7} md={7} lg={7} sm={24} xs={24}>
            {width > breakpoint ? (
              <>
                <h5 className="heading-content" style={{ width: "80%" }}>{translate("accident")["Insured Details"]}</h5>
                <Card className="card-progress">
                  <Row>
                    <Col md={3}>
                      <Progress
                        className="progress-content"
                        percent={60}
                        showInfo={false}
                      />
                    </Col>
                    <Col className="col-content" md={10}>
                      <div className="thick-font">{translate("accident")["Create quote"]}</div>
                      <div className="thick-font">{translate("accident")["Complete Insured Information"]}</div>
                      <div className="thick-font">{translate("accident")["Insured Information Form"]}</div>
                    </Col>
                  </Row>
                </Card>
              </>
            ) : (
              <>
                <Card className="card-mobile">
                  <div className="justify-space">
                    <div className="mob-header">{translate("accident")["Just need your confirmation now"]}</div>
                    <div style={{ cursor: "pointer" }} className="need-content">{translate("onboarding")["Need Help"]} ?</div>
                  </div>
                </Card>
                <Card className="card-mobile">
                  <Row>
                    <Col xs={3}>
                      <div className="number-mob-selected">1</div>
                    </Col>
                    <Col xs={3}>
                      <div className="number-mob-selected">2</div>
                    </Col>
                    <Col xs={14}>
                      <div className="thick-font">
                        {translate("accident")["Complete Insured Information"]}
                      </div>
                    </Col>

                    <Col xs={3}>
                      <div className="number-mob-non-selected">3</div>
                    </Col>
                  </Row>
                </Card>
              </>
            )}
          </Col>
          <Col xl={15} md={15} lg={15} sm={24} xs={24}>
            <Form layout="vertical" form={form} requiredMark={false}>
              <Card style={{ marginBottom: 3 }}>
                <div className="travel-row-1 back-content">
                  <div
                    className="arrow-back-font"
                    style={{ cursor: "pointer" }}
                    onClick={() => getBack()}
                  >
                    <ArrowLeftOutlined />
                    {translate("onboarding")["Back"]}
                  </div>
                  <div style={{ cursor: "pointer" }} className="need-content">
                    {translate("onboarding")["Need Help"]} ?
                  </div>
                </div>
                <h3 className="header-card-content">
                  {language === "en" ? `Insured ${renderInsuredScreen} Information` : `Informasi Tertanggung ${renderInsuredScreen}`}
                </h3>
                <div>{translate("accident")["Complete few more details to issue a policy"]}</div>

                <div className="justify-space">
                  <div className="thick-font letter-space">
                    <b>{translate("accident")["Personal Information"]}</b>
                  </div>
                </div>
                <Form.Item
                  label={translate('accident')["Insured Name"]}
                  name="insuredName"
                  rules={
                    
                    !insuredEditable && [
                    {
                      required: true,
                      message: `${translate("home")["Insured Name is required"]}`,
                    },
                     {
                      message: `${translate("home")["Only Alphabets are Allowed"]}`,
                      pattern: new RegExp(/^[a-zA-Z][a-zA-Z ]*$/),
                    },
                  ]}
                >
                  <Input
                    disabled={insuredEditable}
                    placeholder={translate("accident")["Enter Insured Name"]}
                    className="customerinfo-input"
                    value={customerDeatils?.insuredName}
                    onChange={(e) =>
                      handleCustomerdetailsOnChange(
                        "insuredName",
                        e.target.value
                      )
                    }
                  ></Input>
                </Form.Item>
                {renderInsuredScreen === 1 && (
                  <Form.Item label={translate("accident")["National ID Card"]} name="idcard">
                    {getImage ? (
                      <>
                        <Card className="avatar-uploader__customerInformation">
                          <div>
                            <>
                              <img
                                src={`${Base_URL}auth/user/getLinkUsingKey?key=${imagedetails}`}
                                alt="avatar"
                                object-fit="contain"
                                crossOrigin="anonymous"
                                style={{
                                  width: "100%",
                                  height: "15vh",
                                  textAlign: "center",
                                  objectFit: "cover",
                                }}
                              />
                            </>
                          </div>
                        </Card>
                        <Button
                          onClick={() =>
                            sdk(paQuoteObject, setfetchingtheHypervergeData)
                          }
                          style={{
                            color: "#482C77",
                            display: "flex",
                            width: "100%",
                            justifyContent: "Center",
                            outline: "none",
                            alignContent: "center",
                            border: "none",
                            backgroundColor: "white",
                          }}
                        >
                          {translate("accident")["Browse files"]}
                        </Button>
                        <Col xl={24} xs={24} sm={24} lg={24} md={24}></Col>
                      </>
                    ) : (
                      <>
                        <Card
                          className="avatar-uploader__customerInformation"
                          onClick={() =>
                            sdk(paQuoteObject, setfetchingtheHypervergeData)
                          }
                        >
                          <Spin tip="Loading" spinning={loading}>
                            <div
                              src=""
                              alt="avatar"
                              style={{ width: "100%", textAlign: "center" }}
                            >
                              <UploadOutlined />
                            </div>
                          </Spin>
                        </Card>
                        <Button
                          onClick={() =>
                            sdk(paQuoteObject, setfetchingtheHypervergeData)
                          }
                          style={{
                            color: "#482C77",
                            display: "flex",
                            width: "100%",
                            outline: "none",
                            justifyContent: "Center",
                            alignContent: "center",
                            border: "none",
                            backgroundColor: "white",
                          }}
                        >
                          {translate("accident")["Browse files"]}
                        </Button>
                      </>
                    )}
                  </Form.Item>
                )}
                <Form.Item
                  label={translate("accident")["Date of Birth"]}
                  name="insuredDob"
                  rules={[
                    { required: true, message: 'Please enter Date of Birth' },
                  ]}
                >
                  <DatePicker
                    format="DD/MM/YYYY"
                    style={{ width: "100%" }}
                    placeholder="DD/MM/YYYY"
                    disabledDate={disabledDate}
                    value={customerDeatils?.insuredDob}
                    onChange={(date, dateString) =>
                      handleCustomerdetailsOnChange("insuredDob", dateString)
                    }
                  />
                </Form.Item>
                <Form.Item
                  label={translate("accident")["Address"]}
                  name="address"
                  rules={[{ required: true, message: `${translate('accident')["Enter Address"]}` }]}
                >
                  <Input
                    placeholder={translate('accident')["Enter Address"]}
                    value={customerDeatils?.address}
                    onChange={(e) =>
                      handleCustomerdetailsOnChange("address", e.target.value)
                    }
                    className="customerinfo-input"
                    suffix={
                      <div>
                        <Image
                          src={Feather}
                          preview={false}
                          onClick={handleAddressPropertysearch}
                        />
                      </div>
                    }
                  ></Input>
                </Form.Item>
              </Card>
              <Card style={{ marginBottom: 3 }}>
                <div className="justify-space">
                  <div className="thick-font letter-space">
                    <b>{translate("accident")["Contact Information"]}</b>
                  </div>
                </div>
                <Form.Item
                  label={translate("accident")["Insured Phone Number"]}
                  name="insuredMobile"
                  rules={[
                    { required: true, message: "Please enter phone number" },
                    { pattern: /^[0-9]+$/, message: "Please enter only numbers", },
                    { min: 9, message: "Phone number must be at least 9 digits", },
                    { max: 13, message: "Phone number cannot exceed 13 digits", },
                  ]}
                >
                  <Input
                    maxLength={13}
                    minLength={9}
                    placeholder={translate("accident")["Enter Insured Phone Number"]}
                    className="customerinfo-input"
                    value={customerDeatils?.insuredMobile}
                    onChange={(e) =>
                      handleCustomerdetailsOnChange(
                        "insuredMobile",
                        e.target.value
                      )
                    }
                  />
                </Form.Item>
                <Form.Item
                  label={translate("accident")["Insured Email Address"]}
                  name="insuredEmail"
                  rules={[
                    { required: true, message: `${translate("accident")["Enter Insured Email Address"]}` },
                    { pattern: /^[a-zA-Z0-9._%+-]+@(gmail\.com|mail\.com|iorta\.in|oona-insurance\.co\.id)$/, message: 'Please enter a valid email address' },
                  ]}
                >
                  <Input
                    placeholder={translate("accident")["Enter Insured Email Address"]}
                    className="customerinfo-input"
                    value={customerDeatils?.insuredEmail}
                    onChange={(e) =>
                      handleCustomerdetailsOnChange(
                        "insuredEmail",
                        e.target.value
                      )
                    }
                  />
                </Form.Item>
              </Card>
              <Card style={{ marginBottom: 3 }}>
                <div className="justify-space">
                  <div className="thick-font letter-space">
                    <b>{translate("accident")["Beneficiary Information"]}</b>
                  </div>
                </div>
                <Form.Item
                  label={translate("accident")["Beneficiary's Name ( Optional )"]}
                  name="beneficiaryName"
                  rules={
                    [{ required: true, message: `${translate("accident")["Enter Name"]}` },
                  {
                    message: `${translate("home")["Only Alphabets are Allowed"]}`,
                    pattern: new RegExp(/^[a-zA-Z][a-zA-Z ]*$/),
                  },
                  ]}
                >
                  <Input
                    placeholder={translate("accident")["Enter Name"]}
                    className="customerinfo-input"
                    value={customerDeatils?.beneficiaryName}
                    onChange={(e) =>
                      handleCustomerdetailsOnChange(
                        "beneficiaryName",
                        e.target.value
                      )
                    }
                  />
                </Form.Item>
                <Form.Item
                  label={translate("accident")["Beneficiary Relation (Optional)"]}
                  name="beneficiaryRelation"
                  rules={[
                    { required: false, message: `${translate("accident")["Please enter beneficiary relation"]}` },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={translate("accident")["Select Beneficiary Relation"]}
                    filterOption={(input, option) =>
                      option?.children.toLowerCase().includes(input.toLowerCase())
                    }
                    onChange={(value, Id, _id) => handleSelectRelationChange(value, Id, _id)}
                  >
                    {otrRelation?.map((item) => {
                      return (
                        <Option key={item?.Id} value={item?._id}>
                          {item?.Beneficiary}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                {showOtherInput && (
                  <Form.Item
                    label={translate("accident")["Other Beneficiary Relation"]}
                    name="beneficiaryOtherRelation"
                    rules={[
                      { required: true, message: `${translate("accident")["Enter other beneficiary relation"]}` },
                      {
                        message: `${translate('home')["Only Alphabets are Allowed"]}`,
                        pattern: new RegExp(/^[a-zA-Z][a-zA-Z ]*$/),
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) =>
                        handleCustomerdetailsOnChange(
                          "beneficiaryOtherRelation",
                          e.target.value
                        )
                      }
                      value={customerDeatils?.beneficiaryOtherRelation}
                      placeholder={translate("accident")["Enter other beneficiary relation"]}
                      className="customerinfo-input"
                    />
                  </Form.Item>
                )}
                <Form.Item
                  label={translate("accident")["Beneficiary's Date of Birth ( Optional )"]}
                  name="beneficiaryDateOfBirth"
                  rules={[{ required: false, message: `${translate("accident")["Enter Beneficiary's Date of Birth"]}` },]}
                >
                  <DatePicker
                    format="DD/MM/YYYY"
                    disabledDate={disableDate}
                    // style={{ width: '100%' }}
                    placeholder="DD/MM/YYYY"
                    value={customerDeatils?.beneficiaryDateOfBirth}
                    onChange={(date, dateString) =>
                      handleCustomerdetailsOnChange(
                        "beneficiaryDateOfBirth",
                        dateString
                      )
                    }
                  />
                </Form.Item>
              </Card>
              <Button
                className="next-button-travel"
                onClick={() => handleInsuredData(renderInsuredScreen)}
              >
                {translate("home")["next"]}
              </Button>
            </Form>
          </Col>
        </Row>
      </div>

      <></>
      <Modal
        closable={true}
        visible={addressmodalPopup}
        width={1000}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div style={{ width: "100%", height: "30rem", marginTop: "50px" }}>
          <MapContainer
            cords={cords}
            setLocation={setCords}
            handleInsuredAddress={handleInsuredAddress}
            mapsaddress={mapsaddress}
          />
          {/* <MyGoogleMap /> */}
        </div>
      </Modal>
    </>
  );
}
