import React, { useEffect } from 'react'
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import * as action from '../store/actions/index'
import Cookies from 'js-cookie';
import axiosRequest from '../axios-request/request.methods'
import { useLocation } from 'react-router-dom';

function HomeDynamicRoute(props) {
    console.log(props,"props")
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const langParam = searchParams.get('lang');
    console.log(langParam,"langParam")
    const history = useHistory();
    const dispatch = useDispatch()

    useEffect(() => {
        if (props?.match?.params?.id?.length === 6) {
            let Data ={
                cipherkaye:props.match.params.id,
                cipherkeyavilable:true
            }


            dispatch(action.fetch_cipher_data(Data))
            
            let options = { secure: false, multipart: false }
            axiosRequest
                .get(
                    `auth/decrypt-link-home`,options
                )
                .then((data) => {
                    
                    if(data?.errCode === -1){
                        dispatch(action.set_global_language(langParam));
                        Cookies.set('LANG', langParam === 'en' ? 'en' : 'id', { domain: process.env.REACT_APP_COOKIE_URL });
                        console.log(data.errMsg, 'HomeDynamicAPIResponse');
                        if (data?.errMsg?.data?.hasOwnProperty("stage") === false) {
                            dispatch(action.set_quote_id({
                                quoteId: "",
                                isHome: false,
                                leadId:""
                              }));
                              history.push("/oonahomescreen")
                            // history.push({
                            //     pathname: "/oonahomescreen",
                            //     state: {
                            //         fullName:data?.errMsg?.data?.fullName
                            //     }
                            // })
                        } else {
                            if (data?.errMsg?.data.stage === 'Plan Selected') {
                                history.push("/home-additional-benefit")
                                let Data={
                                    quoteId: data?.errMsg?.data?.quoteId,
                                }
                                dispatch(action.set_quote_id(Data))
                                // history.push({
                                //     pathname: "/home-additional-benefit",
                                //     state: {
                                //         quoteId: data?.errMsg?.data?.quoteId,
                                //         getQuote : 'yes'
                                //     }
                                // })
                            } 

                            else if (data?.errMsg?.data.stage === 'Lead Created') {
                                let Data={
                                    quoteId: data?.errMsg?.data?.quoteId,
                                }
                                dispatch(action.set_quote_id(Data))
                                    history.push("/home-choose-product")
                                // history.push({
                                //     pathname: "/home-choose-product",
                                //     state: {
                                //         quoteId: data?.errMsg?.data?.quoteId,
                                //         getQuote : 'yes'
                                //     }
                                // })
                            } 
                            
                            else if (data?.errMsg?.data.stage === 'Covid Benefit Selected') {
                                let Data={
                                    quoteId: data?.errMsg?.data?.quoteId,
                                }
                                dispatch(action.set_quote_id(Data))
                                    history.push("/createHomequote")
                                // history.push({
                                //     pathname: "/createHomequote",
                                //     state: {
                                //         quoteId: data?.errMsg?.data?.quoteId,
                                //         getQuote : 'yes'
                                //     }
                                // })
                            } 
                            
                            else if (data?.errMsg?.data.stage === 'Additional Benefit Selected') {
                                let Data={
                                    quoteId: data?.errMsg?.data?.quoteId,
                                }
                                dispatch(action.set_quote_id(Data))
                                    history.push("/home-order-summary")
                                // history.push({
                                //     pathname: "/home-order-summary",
                                //     state: {
                                //         quouteId: data?.errMsg?.data?.quoteId,
                                //         getQuote : 'yes',
                                //         quote : true
                                //     }
                                // })
                            } else if (data?.errMsg?.data.stage === 'PromoCode Added') {
                                let Data={
                                    quoteId: data?.errMsg?.data?.quoteId,
                                }
                                dispatch(action.set_quote_id(Data))
                                    history.push("/home-quick-quote-success-screen")

                                // history.push({
                                //     pathname: "/home-quick-quote-success-screen",
                                //     state: {
                                //         data: props.location.state.data,
                                //         quoteId: data?.errMsg?.data?.quoteId
                                //     }
                                // })

                            } else if (data?.errMsg?.data.stage === 'Quick Quote Created') {
                                let Data={
                                    quoteId: data?.errMsg?.data?.quoteId,
                                }
                                dispatch(action.set_quote_id(Data))
                                    history.push("/home-order-summary")

                                // history.push({
                                //     pathname: "/home-order-summary",
                                //     state: {
                                //         quouteId: data?.errMsg?.data?.quoteId,
                                //         quote : true
                                //     }
                                // })
                            } else if (data?.errMsg?.data.stage === 'Customer Information Updated') {
                                let Data={
                                    quoteId: data?.errMsg?.data?.quoteId,
                                }
                                dispatch(action.set_quote_id(Data))
                                    history.push("/customerconfirmationhome")
                                
                                // history.push({
                                //     pathname: "/customerconfirmationhome",
                                //     state: {
                                //         quoteId: data?.errMsg?.data?.quoteId
                                //     }
                                // })
                            } else if (data?.errMsg?.data.stage === 'Full Quote Created') {
                                let Data={
                                    quoteId: data?.errMsg?.data?.quoteId,
                                }
                                dispatch(action.set_quote_id(Data))
                                    history.push("/homepaymentScreen")
                                // history.push({
                                //     pathname: "/homepaymentScreen",
                                //     state: {
                                //         quoteId: data?.errMsg?.data?.quoteId
                                //     }
                                // })
                            } else if (data?.errMsg?.data.stage === 'Policy Generated') {
                                let Data={
                                    quoteId: data?.errMsg?.data?.quoteId,
                                }
                                dispatch(action.set_quote_id(Data))
                                    history.push("/homepaymentScreen")

                                // history.push({
                                //     pathname: "/homepaymentScreen",
                                //     state: {
                                //         quoteId: data?.errMsg?.data?.quoteId
                                //     }
                                // })
                            } else if (data?.errMsg?.data.stage === 'Full Quote Created With Payment') {
                                let Data={
                                    quoteId: data?.errMsg?.data?.quoteId,
                                }
                                dispatch(action.set_quote_id(Data))
                                    history.push("/home-success-print-policy")
                                
                                // history.push({
                                //     pathname: "/home-success-print-policy",
                                //     state: {
                                //         quoteId: data?.errMsg?.data?.quoteId
                                //     }
                                // })
                            }
                            else{
                                history.push({
                                    pathname: "/oonahomescreen",
                                    
                                })
                            }
                        }
                    }
                }).catch((error) => {
                    console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

                });
        }else{
            history.push("/login")
            let Data ={cipherkeyavilable:false}

            dispatch(action.fetch_cipher_data(Data))
        }

    }, [])
    return (
        <div></div>
    )
}

export default HomeDynamicRoute