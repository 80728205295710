import * as actionTypes from './actionTypes'

export const fetch_cipher_data = (payload) => {
    console.log("Actions Pyload", payload)
    return { type: actionTypes.FETCH_CIPHER_LINK, linkdata: payload }
}
export const set_quote_id = (payload) => {
    console.log("Actions Pyload", payload)
    return { type: actionTypes.SET_QUOTE_ID, 
        id: payload?.quoteId,
         isOld: payload?.isOld, 
         isTravel : payload?.isTravel,
         isHome:payload?.isHome ,
         isCI:payload?.isCI ,
         leadId:payload?.leadId,
         resumeType:payload?.resumeType,
         quote_Id:payload?.quote_Id,
         ProposerNumberdata:payload?.ProposerNumberdata,
         isMvdurationChanged:payload?.isMvdurationChanged,
        mvduration:payload?.mvduration,
        tca:payload?.tca,
        triptype:payload?.triptype,
        tcaid:payload?.tcaid,
        age:payload?.age,
        covid:payload?.covid,
        qNo:payload?.qNo,
        redirectingtohome:payload?.redirectingtohome
        }
}