import { Row, Col, Card, Image, Form, Input, Button, Select, Radio, Modal, Space, DatePicker, message, Alert } from 'antd'
import React, { useState, useEffect } from 'react'
import Filled from "../oonaImages/Filled.png"
import callIcon from "../oonaImages/callIcon.png"
import emailIcon from "../oonaImages/emailIcon.png"
import InputImage from "../oonaImages/Frame 1000004254.png"
import pendingIcon from "../oonaImages/pending.png"
import TravelIcon from "../oonaImages/travel.png"
import HomeIcon from "../oonaImages/home.png"
import Car from '../assets/car-product.png'
import Footer from "../components/Footer/Footer"
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useHistory } from "react-router-dom"
import rootIndex from "../store/root_index";
import moment from "moment";
import '../../src/oonaLeads/oonaLeads.css'
import { toast } from 'react-toastify';
import { useLanguage } from '../config/LanguageContext'
import axiosRequest from '../axios-request/request.methods'
import { toCurrencyConvertor } from '../toCurrencyConvertor'
import { createQuoteObject } from '../store/actions/paProduct/createQuoteactionType'
import { useDispatch, useSelector } from 'react-redux'
import { pafetchData, paseperateRequestData } from '../store/actions/paProduct/pafetchdata'
import * as action from '../store/actions/index'
import { PIDList } from '../utils/productId'



const { store } = rootIndex;

function OonanotifyPayment(props) {
    const fetchingWholeDatafromStore=useSelector((state)=>state?.cipherlinkreducer?.quote)
    const { Option } = Select
    const [view, setView] = useState(true)
    const [leaddata, setLeadData] = useState([])
    const [paymentdata, setpaymentData] = useState([])
    console.log(paymentdata,"paymentdata")
    const [paymenttotal, setpaymentTotal] = useState(0)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen1, setIsModalOpen1] = useState(false)
    const [isModalOpen2, setIsModalOpen2] = useState(false)
    const [filtersel, setFilterSel] = useState('All')
    const [filtersel2, setFilterSel2] = useState('all')
    const [sort, setSort] = useState('');
    const [sort2, setSort2] = useState('');
    const [agent, setAgent] = useState('All');
    const [agent2, setAgent2] = useState('All');
    const [agentarr, setAgentArr] = useState([]);
    const [search, setSearch] = useState('');
    const [search2, setSearch2] = useState('');
    const [active, setActive] = useState('1')
    const [showDetails, setShowDetails] = useState(false);
    const currentDate = new Date();
    const [data, setData] = useState(currentDate);
    const history = useHistory()
    const _store = store.getState();
    const [content, setContent] = useState("to-do")
    const [list, setList] = useState([])
    const [quotesarr, setQuotesArr] = useState([])
    const dispatch=useDispatch()
    console.log(quotesarr,"quotesarr")
    const [adultarr, setAdultArr] = useState([])
    const [childarr, setChildArr] = useState([])
    const [tvgarea, setTvgArea] = useState('')
    const [quotecode, setQuoteCode] = useState('')
    const [travellink, setTravelLink] = useState('')
    const [checkout, setCheckOut] = useState(false)
    const [loading, setLoading] = useState(false)
    const [discountpercent, setDiscountPercent] = useState('')
    const [total, setTotal] = useState('')
    const [bnftcalc, setBnftCalc] = useState('')
    const [fullsuccess, setFullSuccess] = useState(false)
    const [policy, setPolicy] = useState('')
    const [domestic, setDomestic] = useState(false)
    const [productname, setProductName] = useState('')
    const [fetchingQuoteData,setfetchingQuoteData]=useState("")
    console.log(fetchingQuoteData,"fetchingQuoteData")
    const [imgArr, setImgArr] = useState()
    const [quotedetails, setQuotedetails] = useState("")

    const [homeprovinces, sethomeProvinceResponse] = useState([])
    console.log(homeprovinces, "homeprovinces")
    const [homeCity, sethomeCity] = useState([])
    console.log(homeCity, "homeCity")
    const [district, setdistrict] = useState([])
    console.log(district, "district")
    const [subDistrict, setsubdistrict] = useState([])
    const [findingprovinceobj, setFindinngProvince] = useState("")
    console.log(findingprovinceobj, "findingprovinceobj")
    const [findingCitiesobj, setFindingCities] = useState("")
    console.log(findingCitiesobj, "findingCitiesobj")
    const [findingDistrictsobj, setFindingDistricts] = useState("")
    console.log(findingDistrictsobj, "findingDistricts")
    const [findingsubdistrictsobj, setFindingSubDistricts] = useState("")
    console.log(findingsubdistrictsobj, "findingsubdistrictsobj")
    const language = useSelector((state) => state.languageReducer.lang);
    
    console.log(quotedetails,"quotedetails")
    const paymentDetailsArray = quotedetails?.policyLobList?.[0]?.policyRiskList?.[0]?.policyCoverageList


    const searchString = "Opted"
    const custObJDetailsOpted = Object.keys(quotedetails).length > 0 && Object.keys(quotedetails).filter(key => key.endsWith(searchString))
    console.log(custObJDetailsOpted, "custObJDetailsOpted")

    const customerbenefitsObj = custObJDetailsOpted !== false && custObJDetailsOpted
        .filter(key => quotedetails.hasOwnProperty(key))
        .reduce((obj, key) => {
            obj[key] = quotedetails[key];
            return obj;
        }, {});

    const customerbenefitsObjarray = customerbenefitsObj !== false && Object.keys(customerbenefitsObj).map(key => ({ key, value: customerbenefitsObj[key] }));

    const mappedcustomerbenefitsObjarraylength = customerbenefitsObjarray !== false && customerbenefitsObjarray.filter(obj => obj.value === "Y").map(obj => obj.value);

    console.log(mappedcustomerbenefitsObjarraylength, "mappedcustomerbenefitsObjarraylength")
    console.log(customerbenefitsObjarray, "customerbenefitsObjarray")

    const sortedProductOptions = paymentDetailsArray?.sort((planA, planB) => {
        let planFlexas = planA?.coverageName?.toLowerCase()
        let planPAR = planB?.coverageName?.toLowerCase()

        if (planFlexas < planPAR) {
            return -1
        }
        if (planFlexas > planPAR) {
            return 1
        }
        return 0
    })

    useEffect(() => {
       
        const totalSum = paymentDetailsArray?.reduce(function (acc, obj) { return Number(acc) + Number(obj.grossPremium); }, 0);
        console.log(totalSum, "totalSum")

        //setSubtotalAmount(totalSum)


        const totalSumAfterDiscount = totalSum - Number(quotedetails?.discountAmount)
        console.log(totalSumAfterDiscount, "totalSumAfterDiscount")

        //settotalSumAfterDiscount(totalSumAfterDiscount)


        const findingProvince = homeprovinces.length > 0 && homeprovinces?.find((item) => {
            return item.Id === quotedetails?.addrProvince

        })
        console.log(findingProvince, "findingProvince")
        setFindinngProvince(findingProvince)


        const findingCities = homeCity.length > 0 && homeCity.find((item) => {
            return item.Id === Number(quotedetails?.city)
        })
        console.log(findingCities, "findingCities")
        setFindingCities(findingCities)


        const findingDistrict = district.length > 0 && district.find((item) => {
            return item?.District === quotedetails?.district
        })
        console.log(findingDistrict, "findingDistrict")
        setFindingDistricts(findingDistrict)


        const findingsubDistrict = subDistrict?.length > 0 && subDistrict.find((item) => {
            return item?.subDistrict === quotedetails?.subDistrict
        })
        console.log(findingsubDistrict, "findingsubDistrict")
        setFindingSubDistricts(findingsubDistrict)


    }, [quotedetails,homeCity])

    useEffect(() => {
        /*getting Province*/
        var axiosConfig = ""
   
            axiosConfig = {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + _store.login.token
                },
            };
        
        /*getting provinces*/
        axiosRequest
            .get(
                `user/provinces`,
              
            )
            .then((data) => {

                if (data.errCode === -1) {
                   
                    sethomeProvinceResponse(data.errMsg)
                }
            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

            });
    }, [])


    useEffect(() => {

        var axiosConfig = ""
     

            axiosConfig = {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + _store.login.token
                },
            };
        
        /*getting cities*/
        axiosRequest
            .get(
                `user/${findingprovinceobj?.Id}/cities`,
              
            )
            .then((data) => {

                if (data.errCode === -1) {
                  
                    //sethomeProvinceResponse(data.errMsg)
                    sethomeCity(data.errMsg)
                }
            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

            });



    }, [homeprovinces, findingprovinceobj])


    useEffect(() => {

        var axiosConfig = ""
      
            axiosConfig = {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + _store.login.token
                },
            };
        
        /*getting districts*/
        axiosRequest
            .get(
                `user/${findingCitiesobj?.RegionalLevel}/districts`,
               
            )
            .then((data) => {

                if (data.errCode === -1) {
                    console.log(data.errMsg, "data.errMsg")
                    //sethomeProvinceResponse(data.errMsg)
                    setdistrict(data.errMsg)
                }
            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

            });



    }, [homeCity, findingCitiesobj])



    useEffect(() => {
        console.log(findingDistrictsobj?.District, "findingDistrictsobj?.District")
        if (findingDistrictsobj?.District !== undefined) {

            var axiosConfig = ""
        
                axiosConfig = {
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': 'Bearer ' + _store.login.token
                    },
                };
          
            /*getting  sub districts*/
            axiosRequest
                .get(
                    `user/${findingDistrictsobj?.District}/sub-districts`,
                   
                )
                .then((data) => {

                    if (data.errCode === -1) {
                     
                        //sethomeProvinceResponse(data.errMsg)
                        setsubdistrict(data.errMsg)
                    }
                }).catch((error) => {
                    console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

                });


        }



    }, [findingDistrictsobj])


    const handleShare = () => {
        if( (fetchingQuoteData?.product_Id === PIDList?.travel) && (moment(fetchingQuoteData?.departureDate,"YYYY-MM-DD").format("YYYY-MM-DD") < moment().format("YYYY-MM-DD"))){
            message.error(`${translate("travel")["travelbackdatedmessage"]}`)
          }
          else{
            const linkGeneerationwithLanguage=`${travellink}?lang=${language}`
            navigator.clipboard.writeText(linkGeneerationwithLanguage)
            toast.success(`${translate("home")["link copied"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
          }
        
      

    }
    const getImages = (id) => {
        var axiosConfig = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + _store.login.token
            },
        };

        try {
            // let productCode = productData.productData.Id;
            axiosRequest
                .get(
                    `user/getDocumentInspectionMotor/${id}`,
                    
                )
                .then((data) => {
                    if (data.errCode === -1) {

                        setImgArr(data.errMsg?.data)
                        // console.log(data.errMsg.data, "res");
                    }
                })
                .catch((error) => {
                    console.log(
                        "error:2---forgotpass-----> ",
                        typeof error,
                        error.Error,
                        error.message
                    );
                });
        } catch (error) { }

    }
    const getQuotes = (id, pID) => {

        console.log('enter get quotes-------->');
        
        setContent('policy-detail')



        axiosRequest
            .get(
                `user/get-quotes?id=${id}&logkey=paymentNotification`
                
            )
            .then((data) => {
              
                setQuotedetails(data?.errMsg?.data[0])
                if (data.errCode === -1) {
                    getLink(id, data.errMsg.data[0].product_Id)
                    if (data.errMsg.data[0].product_Id === '64aff2f3831667919245f86c') {
                        console.log('travel-------->');
                        setProductName('Travel')
                        setfetchingQuoteData(data?.errMsg?.data[0])
                        //------Travel-------GET QUOTE-------------------------
                        if (data.errMsg.data[0].travelCoverageAreaDesc === 'Domestic') {
                            setDomestic(true)
                        } else {
                            setDomestic(false)
                        }
                        if (data.errMsg.data[0].proposalNo != undefined && data.errMsg.data[0].proposalNo != '') {
                            setFullSuccess(true)
                            setPolicy(data.errMsg.data[0].proposalNo)
                        }
                       
                        setQuotesArr(data.errMsg.data)

                        trvcvgarea(data.errMsg.data)

                        if (data.errMsg.data[0].adultCount > 1) {
                            setAdultArr(data.errMsg.data[0].adults)
                        }

                        if (data.errMsg.data[0].childCount > 0) {
                            setChildArr(data.errMsg.data[0].childs)
                        }

                        if (data.errMsg.data[0].additionalBenefits.length > 0) {
                            var finalValue = 0
                            data.errMsg.data[0].additionalBenefits.map(item => {
                                finalValue = finalValue + parseInt(item.Premium)
                            })
                            if (finalValue != 0) {
                                console.log(finalValue, 'final calc value-------->');
                                setBnftCalc(finalValue)

                                if (data.errMsg.data[0].promoCodeList.length > 0) {
                                    if (data.errMsg.data[0].promoCodeList.length > 0) {
                                        setDiscountPercent(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].promoCodeList[0].discountPercent / 100)))
                                        setTotal(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium) + parseInt(data.errMsg.data[0].adminCost != undefined ? data.errMsg.data[0].adminCost : 0)) - ((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].promoCodeList[0].discountPercent / 100))))
                                    } else {
                                        setDiscountPercent(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (0 / 100)))
                                        setTotal(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium) + parseInt(data.errMsg.data[0].adminCost != undefined ? data.errMsg.data[0].adminCost : 0)) - ((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (0 / 100))))
                                    }
                                } else {

                                    if (data.errMsg.data[0].adminCost != undefined) {
                                        console.log('yes -2');
                                        setDiscountPercent(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].discountPercent / 100)))
                                        setTotal(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium) + parseInt(data.errMsg.data[0].adminCost)) - ((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].discountPercent / 100))))
                                    } else {
                                        console.log('yes -3');
                                        setDiscountPercent(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].discountPercent / 100)))
                                        setTotal(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) - ((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].discountPercent / 100))))
                                    }
                                }
                            } else {
                                if (data.errMsg.data[0].promoCodeList.length > 0) {
                                    if (data.errMsg.data[0].promoCodeList.length > 0) {
                                        setDiscountPercent(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].promoCodeList[0].discountPercent / 100)))
                                        setTotal(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium) + parseInt(data.errMsg.data[0].adminCost != undefined ? data.errMsg.data[0].adminCost : 0)) - ((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].promoCodeList[0].discountPercent / 100))))
                                    } else {
                                        setDiscountPercent(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (0 / 100)))
                                        setTotal(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium) + parseInt(data.errMsg.data[0].adminCost != undefined ? data.errMsg.data[0].adminCost : 0)) - ((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (0 / 100))))
                                    }

                                } else {
                                    console.log('yes -4');
                                    setDiscountPercent(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].discountPercent / 100)))
                                    if (data.errMsg.data[0].adminCost != undefined) {
                                        setTotal(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium) + parseInt(data.errMsg.data[0].adminCost)) - ((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].discountPercent / 100))))
                                    }
                                    else {
                                        setTotal(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) - ((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].discountPercent / 100))))
                                    }
                                }
                            }
                        } else {
                            setBnftCalc(0)
                            if (data.errMsg.data[0].promoCodeList.length > 0) {
                                if (data.errMsg.data[0].promoCodeList.length > 0) {
                                    setDiscountPercent(((parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].promoCodeList[0].discountPercent / 100)))
                                    setTotal(((parseInt(data.errMsg.data[0].premium) + parseInt(data.errMsg.data[0].adminCost != undefined ? data.errMsg.data[0].adminCost : 0)) - ((parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].promoCodeList[0].discountPercent / 100))))
                                } else {
                                    setDiscountPercent(((parseInt(data.errMsg.data[0].premium)) * (0 / 100)))
                                    setTotal(((parseInt(data.errMsg.data[0].premium) + parseInt(data.errMsg.data[0].adminCost != undefined ? data.errMsg.data[0].adminCost : 0)) - ((parseInt(data.errMsg.data[0].premium)) * (0 / 100))))
                                }

                            } else {
                                console.log('yes -5');
                                setDiscountPercent(((parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].discountPercent / 100)))
                                if (data.errMsg.data[0].adminCost != undefined) {
                                    setTotal(((parseInt(data.errMsg.data[0].premium) + parseInt(data.errMsg.data[0].adminCost)) - ((parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].discountPercent / 100))))
                                } else {
                                    setTotal(((parseInt(data.errMsg.data[0].premium)) - ((parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].discountPercent / 100))))
                                }
                            }
                        }
                    }
                    else if ( data.errMsg.data[0].product_Id === "64c0fd4af6d4bddffcac8f72" ||  data.errMsg.data[0].product_Id === "64c0fd4af6d4bddffcac8f71") {
                        setProductName('Motor')
                        getImages(data.errMsg.data[0]._id)
                        if (data.errMsg.data[0].proposalNo != undefined && data.errMsg.data[0].proposalNo != '') {
                            setFullSuccess(true)
                            setPolicy(data.errMsg.data[0].proposalNo)
                        }
                        console.log(data.errMsg.data[0].adultCount, 'Travel Coverage API Response----->');
                        setQuotesArr(data.errMsg.data)

                        if (data.errMsg.data[0].additionalBenefits.length > 0) {
                            var finalValue = 0
                            data.errMsg.data[0].additionalBenefits.map(item => {
                                finalValue = finalValue + parseInt(item.Premium)
                            })
                            if (finalValue != 0) {
                                console.log(finalValue, 'final calc value-------->');
                                setBnftCalc(finalValue)

                                if (data.errMsg.data[0].promoCodeList.length > 0) {
                                    if (data.errMsg.data[0].promoCodeList.length > 0) {
                                        setDiscountPercent(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].promoCodeList[0].discountPercent / 100)))
                                        setTotal(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium) + parseInt(data.errMsg.data[0].adminCost != undefined ? data.errMsg.data[0].adminCost : 0)) - ((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].promoCodeList[0].discountPercent / 100))))
                                    } else {
                                        setDiscountPercent(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (0 / 100)))
                                        setTotal(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium) + parseInt(data.errMsg.data[0].adminCost != undefined ? data.errMsg.data[0].adminCost : 0)) - ((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (0 / 100))))
                                    }
                                } else {

                                    if (data.errMsg.data[0].adminCost != undefined) {
                                        console.log('yes -2');
                                        setDiscountPercent(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].discountPercent / 100)))
                                        setTotal(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium) + parseInt(data.errMsg.data[0].adminCost)) - ((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].discountPercent / 100))))
                                    } else {
                                        console.log('yes -3');
                                        setDiscountPercent(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].discountPercent / 100)))
                                        setTotal(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) - ((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].discountPercent / 100))))
                                    }
                                }
                            } else {
                                if (data.errMsg.data[0].promoCodeList.length > 0) {
                                    if (data.errMsg.data[0].promoCodeList.length > 0) {
                                        setDiscountPercent(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].promoCodeList[0].discountPercent / 100)))
                                        setTotal(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium) + parseInt(data.errMsg.data[0].adminCost != undefined ? data.errMsg.data[0].adminCost : 0)) - ((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].promoCodeList[0].discountPercent / 100))))
                                    } else {
                                        setDiscountPercent(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (0 / 100)))
                                        setTotal(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium) + parseInt(data.errMsg.data[0].adminCost != undefined ? data.errMsg.data[0].adminCost : 0)) - ((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (0 / 100))))
                                    }

                                } else {
                                    console.log('yes -4');
                                    setDiscountPercent(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].discountPercent / 100)))
                                    if (data.errMsg.data[0].adminCost != undefined) {
                                        setTotal(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium) + parseInt(data.errMsg.data[0].adminCost)) - ((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].discountPercent / 100))))
                                    }
                                    else {
                                        setTotal(((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) - ((parseInt(finalValue) + parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].discountPercent / 100))))
                                    }
                                }
                            }
                        } else {
                            setBnftCalc(0)
                            if (data.errMsg.data[0].promoCodeList.length > 0) {
                                if (data.errMsg.data[0].promoCodeList.length > 0) {
                                    setDiscountPercent(((parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].promoCodeList[0].discountPercent / 100)))
                                    setTotal(((parseInt(data.errMsg.data[0].premium) + parseInt(data.errMsg.data[0].adminCost != undefined ? data.errMsg.data[0].adminCost : 0)) - ((parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].promoCodeList[0].discountPercent / 100))))
                                } else {
                                    setDiscountPercent(((parseInt(data.errMsg.data[0].premium)) * (0 / 100)))
                                    setTotal(((parseInt(data.errMsg.data[0].premium) + parseInt(data.errMsg.data[0].adminCost != undefined ? data.errMsg.data[0].adminCost : 0)) - ((parseInt(data.errMsg.data[0].premium)) * (0 / 100))))
                                }

                            } else {
                                console.log('yes -5');
                                setDiscountPercent(((parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].discountPercent / 100)))
                                if (data.errMsg.data[0].adminCost != undefined) {
                                    setTotal(((parseInt(data.errMsg.data[0].premium) + parseInt(data.errMsg.data[0].adminCost)) - ((parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].discountPercent / 100))))
                                } else {
                                    setTotal(((parseInt(data.errMsg.data[0].premium)) - ((parseInt(data.errMsg.data[0].premium)) * (data.errMsg.data[0].discountPercent / 100))))
                                }
                            }
                        }
                    }

                    else if(data.errMsg.data[0].product_Id === "64c34807507810de0263ff63"){
                        setProductName('Home')
                        //alert("Home")
                        setQuotesArr(data.errMsg.data)


                    }

                    else if(data.errMsg.data[0].product_Id === "657044193c379101206fd9c6"){
                        setProductName('PA')
                        dispatch(pafetchData(data?.errMsg?.data[0]?._id))
                         dispatch(createQuoteObject(data?.errMsg?.data[0]?._id))
                        setQuotesArr(data?.errMsg?.data)
                    }



                    //------Travel-------GET QUOTE--------------------------------
                }
            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

            });
    }

    const getLink = (id, pID) => {

       

if(pID){
    let endPoint = pID === '64aff2f3831667919245f86c' ? 'generate-link' : pID === "64c34807507810de0263ff63" ? 'generate-link-home' : pID === "657044193c379101206fd9c6" ? 'generate-link-pa' : "generate-link-motor";
        axiosRequest
            .get(
                `user/${endPoint}?type=lead&quoteId=${id}`
                
            )
            .then((data) => {
                if (data.errCode === -1) {
                    console.log(data.errMsg.link, 'Generate link Response----->');
                    setTravelLink(data?.errMsg?.link)
                }
            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

            });
        }

            if(pID === "64c34807507810de0263ff63"){

            axiosRequest
            .get(
                `home/generate-link-home?type=lead&quoteId=${id}`
               
            )
            .then((data) => {
                if (data.errCode === -1) {
                    console.log(data.errMsg.link, 'Generate link Response----->');
                    setTravelLink(data.errMsg.link)
                }
            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

            }); 

                

            }


            if(pID === "657044193c379101206fd9c6"){
                axiosRequest
                .get(
                    `user/generate-link-pa?type=lead&quoteId=${id}`,
                  
                )
                .then((data) => {
                    if (data.errCode === -1) {
                        console.log(data.errMsg.link, 'Generate link Response----->');
                        setTravelLink(data?.errMsg?.link)
                    }
                }).catch((error) => {
                    console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
    
                }); 


            }
    }


    const trvcvgarea = (val) => {

        var axiosConfig = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + _store.login.token
            },
        };



        axiosRequest
            .get(
                `user/travel-coveragearea`,
               
            )
            .then((data) => {
                // console.log("Final response: of new lead", data.errMsg.result_valid_mobile[0]);
                if (data.errCode === -1) {
                    console.log(data.errMsg.data, 'Travel Coverage API Response----->');

                    data.errMsg.data.map(item => {
                        if (val[0].travelCoverageArea === item.id) {
                            setTvgArea(item.description)
                        }
                    })

                }
            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

            });
    }

    const indrpForm = (val) => {
        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 0,
        }).format(parseInt(val))
    }

    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setSort(e.target.value);
    };

    const ResetFilter = () => {
        setFilterSel('All')
        setSort('')
        setAgent('All')
        handleNext()
    }

    const onChange2 = (e) => {
        console.log('radio checked', e.target.value);
        setSort2(e.target.value);
    };

    const ResetFilter2 = () => {
        setFilterSel2('All')
        setSort2('')
        setAgent2('All')
        handlePayment()
    }

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showModal = () => {
        setIsModalOpen1(true);
    };
    const handleOk1 = () => {
        setIsModalOpen1(false);
    };
    const handleCancel1 = () => {
        setIsModalOpen1(false);
    };

    const showModal2 = () => {
        setIsModalOpen2(true);
    };
    const handleOk2 = () => {
        setIsModalOpen2(false);
    };
    const handleCancel2 = () => {
        setIsModalOpen2(false);
    };

    useEffect(() => {
        handleNext()
        // handleInprogress()
    }, [search, active])

    useEffect(() => {
        if(fetchingWholeDatafromStore?.quoteId !== ""){
         getQuotes(fetchingWholeDatafromStore?.quoteId)
        handlePayment()
        handlePaymentTotal()
      
    }
    }, [fetchingWholeDatafromStore])

    const handleNext = () => {

        console.log(filtersel, 'filter selc----------->')
        let axiosConfig = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + _store.login.token
            },
        };

        const pattern = /^PO\d/;

        if (active === '1') {
            var api = 'expiring-policies'
        } else {
            var api = 'filterPendingPayment'
        }

        if (pattern.test(search)) {
            console.log("Pattern matches!");
            var apiConfig = `agent/${api}?productId=${filtersel2}&sort=${sort2}&policyNo=${search}`
        } else {
            console.log("Pattern doesn't match.")
            var apiConfig = `agent/${api}?productId=${filtersel2}&sort=${sort2}&name=${search}`
        }

        axiosRequest
            .get(
                apiConfig
               
            )
            .then((data) => {
                // console.log("Final response: of new lead", data.errMsg.result_valid_mobile[0]);
                if (data.errCode === -1) {

                    setLeadData(data.errMsg.expiringList)
                    setIsModalOpen(false)
                } else if (data.errCode === 1) {
                    setLeadData([])
                    setIsModalOpen(false)
                    setFilterSel('All')
                    setAgent('All')
                    setSort('')
                }
            }).catch((error) => {
                setLeadData([])
                setIsModalOpen(false)
                setFilterSel('All')
                setAgent('All')
                setSort('')
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
                // message.failure("OTP is invalid");

            });
    }



    const handlePayment = () => {

       

        const pattern = /^PO\d/;

        if (search2.length > 0) {
            if (pattern.test(search2)) {
                console.log("Pattern matches!");
                if (sort2 != '') {
                    var apiConfig = `user/payment-pending?product=${filtersel2}&sort=${sort2}&proposalNo=${search2}`
                } else {
                    var apiConfig = `user/payment-pending?product=${filtersel2}&sort=''&proposalNo=${search2}`
                }

            } else {
                console.log("Pattern doesn't match.")

                if (sort2 != '') {
                    var apiConfig = `user/payment-pending?product=${filtersel2}&sort=${sort2}&insuredName=${search2}`
                } else {
                    var apiConfig = `user/payment-pending?product=${filtersel2}&sort=''&insuredName=${search2}`
                }
            }
        } else {
            if (sort2 != '') {
                var apiConfig = `user/payment-pending?product=${filtersel2}&sort=${sort2}`
            } else {
                var apiConfig = `user/payment-pending?product=${filtersel2}&sort=''`
            }

        }

        axiosRequest
            .get(
                apiConfig
              
            )
            .then((data) => {
                console.log("Final response: of payment", data);
                if (data.errCode === 1) {
                    console.log(data.errMsg.data, 'payment data----->');
                    setpaymentData(data.errMsg.data)
                    setIsModalOpen2(false)
                } else if (data.errCode != 1) {
                    setpaymentData([])
                    setpaymentTotal(0)
                    setIsModalOpen2(false)
                    setFilterSel2('All')
                    setAgent2('All')
                    setSort2('')
                }
            }).catch((error) => {
                setpaymentData([])
                setpaymentTotal(0)
                setIsModalOpen2(false)
                setFilterSel2('All')
                setAgent2('All')
                setSort2('')
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

            });
    }

    const handlePaymentTotal = () => {

     

        const pattern = /^PO\d/;

        var apiConfig = `user/payment-pending`
        axiosRequest
            .get(
                apiConfig
              
            )
            .then((data) => {
                console.log("Final response: of payment", data);
                if (data.errCode === 1) {
                    console.log(data.errMsg.data[0].totalCount[0].total, 'hvjhknjn');
                    setpaymentTotal(data.errMsg.data[0].totalCount[0].total)
                    setIsModalOpen2(false)
                } else if (data.errCode != 1) {

                    setpaymentTotal(0)
                    setIsModalOpen2(false)
                    setFilterSel2('All')
                    setAgent2('All')
                    setSort2('')
                }
            }).catch((error) => {
                setpaymentTotal(0)
                setIsModalOpen2(false)
                setFilterSel2('All')
                setAgent2('All')
                setSort2('')
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

            });
    }
    const handlePayNow = () => {
        console.log(productname,"productname in kjag==>")
        dispatch(paseperateRequestData())
        if (productname === 'Travel') {
            if(moment(fetchingQuoteData?.departureDate,"YYYY-MM-DD").format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")){
                // toast.error(`${translate("travel")["travelbackdatedmessage"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 ,style: { width: '600px' }})
                message.error({
                    content: `${translate("travel")["travelbackdatedmessage"]}`,
                    style: {
                      marginTop: '10vh', 
                    },
                    duration:2
                  });
              }
              else{
                let Data={
                    quoteId: quotesarr && quotesarr[0]?._id,
                }
                dispatch(action.set_quote_id(Data))
                history.push({
                    pathname: "/oonapayment",
                    // state: {
                    //     quoteId: quotesarr && quotesarr[0]?._id,
    
                    // }
                })
              }
          
           
        }
        else if (productname === 'Motor') {
            let data={
                quoteId:quotesarr && quotesarr[0]?._id,
                resumeType:"motor"
            }
            dispatch(action.set_quote_id(data))
            history.push({
                pathname: "/oonapayment",
                // state: {
                //     quoteId: quotesarr && quotesarr[0]?._id,
                //     data: 'motor'
                // }
            })
        }
        else if (productname === 'PA') {
           
            history.push("/accidentpaymentconfirmation")
        }
        else{
            let data={
                quoteId:quotesarr && quotesarr[0]?._id,
                resumeType:"homefromTodo"
            }
            dispatch(action.set_quote_id(data))
            history.push("/homepaymentScreen")
            // history.push({
            //     pathname: "/homepaymentScreen",
            //     state: {
            //         quoteId: quotesarr && quotesarr[0]?._id,
            //         data: 'homefromTodo'
            //     }
            // })

        }
    }

    useEffect(() => {
        let axiosConfig = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + _store.login.token
            },
        };
        var apiConfig = `agent/to-do`
        axiosRequest
            .get(
                apiConfig
                
            )
            .then((data) => {
                if (data?.errCode === -1) {
                    let resData = data?.errMsg?.list.map((item) => {
                        return {
                            label: item?.fullName,
                            value: `${item?._id}`
                        };
                    });
                    setList([...resData])
                }
            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
            });
    }, [])
    const [submitContent, setSubmitContent] = useState('')
    useEffect(() => {
        let axiosConfig = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + _store.login.token
            },
        };
        var apiConfig = `agent/to-do-datewise`
        const queryParams = {
            date: data
        };
        const searchParams = new URLSearchParams(queryParams);
        const urlWithParams = `${apiConfig}?${searchParams.toString()}`;
        console.log(urlWithParams, "aks")
        axiosRequest
            .get(
                urlWithParams
              
            )
            .then((data) => {
                if (data?.errCode === -1) {
                    let response = data?.errMsg?.activities
                    console.log(response, "akshay")
                    setActivities(response)
                }
            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
            });
    }, [submitContent])

    const [activities, setActivities] = useState([])

    console.log(activities, "aksjau")
    const path = () => {
        if (_store.login.userType === "Mitra") {
            history.push("/oonahome")
            setView(true)
        }
        else if (_store.login.userType === "Commercial") {
            history.push("/commercialhome")
            setView(false)
        }
        else if (_store.login.userType === "Support") {
            history.push("/commercialsupport")
            setView(false)
        }
        else {
            history.push("/oonahome")
            setView(true)
        }
    }

    const showDetailsHandle = (dayStr) => {
        setSubmitContent(dayStr)
        setShowDetails(true);
        const inputDate = dayStr;
        const monthNames = {
            Jan: '01',
            Feb: '02',
            Mar: '03',
            Apr: '04',
            May: '05',
            Jun: '06',
            Jul: '07',
            Aug: '08',
            Sep: '09',
            Oct: '10',
            Nov: '11',
            Dec: '12',
        };

        const dateComponents = inputDate.split(' ');
        const day = dateComponents[1];
        const monthAbbreviation = dateComponents[2];
        const year = `20${dateComponents[3]}`;
        const month = monthNames[monthAbbreviation];
        const paddedDay = day.length === 1 ? `0${day}` : day;
        const formattedDate = `${year}-${month}-${paddedDay}`;

        console.log(formattedDate, "akshay");
        setData(formattedDate);
    };

    const options = [
        {
            value: 'appointment',
            label: 'Appointment',
        },
        {
            value: 'birthday',
            label: 'Birthday',
        },
    ]

    const options1 = [
        {
            value: 'todo',
            label: 'Todo',
        },
        // {
        //     value: 'archieve',
        //     label: 'Archieve',
        // },
    ]

    const initialState = {
        taskDescription: '',
        taskStatus: '',
        taskCategory: '',
        scheduledDate: '',
        scheduledTime: '',
        members: null
    }
    const [formData, setFormData] = useState(initialState);

    const handleFormSubmit = () => {
        let axiosConfig = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + _store.login.token
            },
        };
        axiosRequest.post(
            `agent/to-do`,
            formData,
          )
            .then(response => {
                console.log('API Response:', response.data);
                if (response.status === 200) {
                    setIsModalOpen1(false)
                    toast.success("Task Created Successfully")
                    setFormData(initialState)
                    setSelectedOptions(null)
                }
            })
            .catch(error => {
                console.error('API Error:', error);
                toast.error(error)
                setIsModalOpen1(false)
            });
    };

    const handleChange = (value) => {
        setFormData({ ...formData, taskCategory: value })
    };


    const handleStatus = (value) => {
        setFormData({ ...formData, taskStatus: value })
    };

    const [selectedOptions, setSelectedOptions] = useState([]);

    const { translate } = useLanguage()

    const handleChange1 = (value) => {
        console.log(selectedOptions, "ak")
        setSelectedOptions(value)
        setFormData({ ...formData, members: selectedOptions })
    };

    const onDateChange = (value, dateString) => {
        const dateTime = new Date(dateString);
        const day = String(dateTime.getDate()).padStart(2, '0'); // Ensure two-digit day
        const month = String(dateTime.getMonth() + 1).padStart(2, '0'); // Ensure two-digit month (January is 0)
        const year = dateTime.getFullYear();
        const formattedDate = `${year}-${month}-${day}`;
        const time = dateTime.toLocaleTimeString();
        setFormData({ ...formData, scheduledDate: formattedDate, scheduledTime: time });
    };


    const onOk = (value) => {
        console.log('onOk: ', value);
    };

    return (
        <>
            <div className='oona-home'>
                
                                    <>
                                        {/* <div onClick={() => setContent("to-do")} className='arrow-back-font'><ArrowLeftOutlined width={20} color='#482c77' /><div className='arrow-back-font' style={{ fontSize: 14 }}>{translate("onboarding")["Back"]}</div></div> */}
                                        <div className='justify-space'>
                                            <h5 className='performance-heading'>Policy Detail</h5>
                                            <div><Image style={{ width: "55%",float:"right" }} src={pendingIcon} preview={false} /></div>
                                        </div>
                                        <Card className='policy-detail-card'>
                                            <Row style={{ padding: 20, borderLeft: "3px solid #482c77", borderRadius: 6 }}>
                                                <Col style={{ display: "flex", alignItems: "center" }} xl={3} xs={3} sm={3} lg={3} md={3}>
                                                    {/* <Image src={quotesarr && quotesarr[0]?.product_Id == '64aff2f3831667919245f86c' ? TravelIcon : quotesarr && quotesarr[0]?.product_Id == '64c34807507810de0263ff63' ? HomeIcon : Car} preview={false} /> */}
                                                    <Image src={quotesarr && quotesarr[0]?.product_Id === '64aff2f3831667919245f86c' ? TravelIcon : quotesarr && quotesarr[0]?.product_Id == '64c34807507810de0263ff63' ? HomeIcon : Car} preview={false} />
                                                </Col>
                                                <Col xl={17} xs={17} sm={17} lg={17} md={17}>
                                                    <div className='home-small-font'>{quotesarr && quotesarr[0]?.planCode}</div>
                                                    <div style={{ marginBottom: 5 }} className='thick-font'>{quotesarr && quotesarr[0]?.planName}</div>
                                                    <div className='home-small-font'>Premium</div>
                                                    <div className='thick-font'> {
                                                       quotesarr && quotesarr[0]?.product_Id !== '64c34807507810de0263ff63' ?
                                                 
                                                    (new Intl.NumberFormat('id-ID', {
                                                        style: 'currency',
                                                        currency: 'IDR',
                                                        minimumFractionDigits: 0,
                                                    }).format(parseInt(quotesarr && quotesarr[0]?.premium)))  :  new Intl.NumberFormat('id-ID', {
                                                        style: 'currency',
                                                        currency: 'IDR',
                                                        minimumFractionDigits: 0,
                                                    }).format(parseInt(quotesarr && quotesarr[0]?.duePremium)) }</div>
                                                </Col>
                                                <Col xl={4} xs={4} sm={4} lg={4} md={4}>
                                                    <Button onClick={handlePayNow} className='next-button-travel'>Pay Now</Button>
                                                </Col>
                                            </Row>
                                        </Card>
                                        <Card style={{ textAlign: "center" }} className='policy-detail-card'>
                                            <div style={{ padding: 15 }}>
                                                <div className='home-small-font'>Customer Name</div>
                                                <div className='thick-font' style={{ marginBottom: 10, fontSize: 20 }}>{quotesarr && quotesarr[0]?.insuredName}</div>

                                                <div>{translate("home")["Send this payment link to the client"]}</div>
                                                {travellink != '' ?
                                                    <Form.Item
                                                        name="City"
                                                    >
                                                        <Input defaultValue={travellink} value={travellink} suffix={<><Image className="input-img" style={{ width: "75%", cursor: 'pointer' }} onClick={() => handleShare()} src={InputImage} preview={false} /></>} disabled className='link-input'></Input>
                                                    </Form.Item> : null}
                                            </div>
                                            <Row className='customer-card-inner-row'>
                                                <Col xl={8} lg={8} sm={8} xs={8} md={8}>
                                                    <div>
                                                        <Image src={Filled} />Message
                                                    </div>
                                                </Col>
                                                <Col xl={8} lg={8} sm={8} xs={8} md={8}>
                                                    <div>
                                                        <Image src={callIcon} /> Call
                                                    </div>
                                                </Col>
                                                <Col xl={8} lg={8} sm={8} xs={8} md={8}>
                                                    <div>
                                                        <Image src={emailIcon} />  Email
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card>

                                        {productname === 'Travel' ?
                                            <Col xl={24} md={24} lg={24} sm={24} xs={24} style={{ marginBottom: 20 }}>
                                                <Card>


                                                    <Card className='add-benefit-card  back-img-1'>
                                                        <Row style={{ alignItems: "center" }}>
                                                            <Col md={20} xl={20} xs={16} sm={20} lg={20}>
                                                                <div>
                                                                    <div>Subtotal</div>
                                                                    <div><b style={{ fontSize: 21 }}>{new Intl.NumberFormat('id-ID', {
                                                                        style: 'currency',
                                                                        currency: 'IDR',
                                                                        minimumFractionDigits: 0,
                                                                    }).format(total)}</b></div>
                                                                    <div>{quotesarr && quotesarr[0]?.planName} + {quotesarr && quotesarr[0]?.additionalBenefits.length} {translate('travel')["Add Ons"]}</div>
                                                                </div>
                                                            </Col>
                                                            {/* <Col md={4} xl={4} xs={8} sm={4} lg={4}>
                                        <div>View Detail</div></Col> */}
                                                        </Row>
                                                    </Card>
                                                    <div className='justify-space'>
                                                        <div>
                                                            <b>{translate("travel")["Product Package"]}</b>
                                                        </div>
                                                    </div>

                                                    <div className='justify-space'>
                                                        <div>
                                                            {translate("travel")["Product Package"]}
                                                        </div>
                                                        <div>
                                                            {quotesarr && quotesarr[0]?.planName}
                                                        </div>
                                                    </div>
                                                    <div >
                                                        <Row>
                                                            <Col sm={6} md={6} lg={6} xl={6} xxl={6} xs={6}>
                                                                <div>
                                                                    {translate("travel")["Additional Benefits(s)"]}
                                                                </div>
                                                            </Col>
                                                            <Col sm={18} md={18} lg={18} xl={18} xxl={18} xs={18}>
                                                                <Row style={{ justifyContent: 'right' }}>
                                                                    {quotesarr && quotesarr[0]?.additionalBenefits.length > 0 ?
                                                                        quotesarr[0]?.additionalBenefits.map((item, index) => {
                                                                            return (
                                                                                <Row>
                                                                                    {` ${item.BenefitDesc} `} {index === (quotesarr && quotesarr[0]?.additionalBenefits.length - 1) ? '' : ', '}
                                                                                </Row>
                                                                            )
                                                                        }) : '-'}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Card>
                                                <Card>
                                                    <div>
                                                        <b>{translate("travel")["Travel Details"]}</b>
                                                    </div>
                                                    <div className='justify-space'>
                                                        <div>
                                                            {translate("travel")["Travel Destination"]}
                                                        </div>
                                                        <div>
                                                            {tvgarea}
                                                        </div>
                                                    </div>
                                                    <div className='justify-space'>
                                                        <div>
                                                            {translate("travel")["Departure Date"]}
                                                        </div>
                                                        <div>
                                                            {quotesarr && moment(quotesarr[0]?.departureDate).format('DD/MM/YYYY')}
                                                        </div>
                                                    </div>
                                                    <div className='justify-space'>
                                                        <div>
                                                            {translate("travel")["Arrival Date"]}
                                                        </div>
                                                        <div>
                                                            {quotesarr && moment(quotesarr[0]?.arrivalDate).format('DD/MM/YYYY')}
                                                        </div>
                                                    </div>
                                                </Card>

                                                {adultarr.length > 1 ? adultarr.map((item, index) => {
                                                    return (
                                                        <Card>
                                                            <div className='justify-space'>
                                                                <div>
                                                                    <b>ADULT TRAVELLER {index + 1} INFORMATION </b>
                                                                </div>
                                                            </div>

                                                            <div className='justify-space'>
                                                                <div>
                                                                    {translate("travel")["Name"]}
                                                                </div>
                                                                <div>
                                                                    {item.fullName}
                                                                </div>
                                                            </div>
                                                            <div className='justify-space'>
                                                                <div>
                                                                    {translate("travel")["Birthdate"]}
                                                                </div>
                                                                <div>
                                                                    {moment(item.dob).format('DD/MM/YYYY')}
                                                                </div>
                                                            </div>
                                                            {index === 0 ?
                                                                <div className='justify-space'>
                                                                    <div>
                                                                        {translate("travel")["ID Number (Passport)"]}(KTP/KITAS)
                                                                    </div>
                                                                    <div>
                                                                        {item.idCard}
                                                                    </div>
                                                                </div> : index === 1 && domestic === true ? <div className='justify-space'>
                                                                    <div>
                                                                        {translate("travel")["ID Number (Passport)"]}(KTP/KITAS)
                                                                    </div>
                                                                    <div>
                                                                        {item.idCard}
                                                                    </div>
                                                                </div> : null}

                                                            {index === 0 ?
                                                                <>
                                                                    <div className='justify-space'>
                                                                        <div>
                                                                            {translate("travel")["Email Address (optional)"]}
                                                                        </div>
                                                                        <div>
                                                                            {item.email}
                                                                        </div>
                                                                    </div>
                                                                    <div className='justify-space'>
                                                                        <div>
                                                                            {translate("travel")["Mobile Number (optional)"]}
                                                                        </div>
                                                                        <div>
                                                                            {item.mobile}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                : null}
                                                            <div className='justify-space'>
                                                                <div>
                                                                    {translate("travel")["ID Number (Passport)"]}
                                                                </div>
                                                                <div>
                                                                    {item.passportNo}
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    )
                                                }) : <Card>
                                                    <div className='justify-space'>
                                                        <div>
                                                            <b>{translate("travel")["Customer Information"]}</b>
                                                        </div>
                                                    </div>

                                                    <div className='justify-space'>
                                                        <div>
                                                            {translate("travel")["Name"]}
                                                        </div>
                                                        <div>
                                                            {quotesarr && quotesarr[0]?.insuredName}
                                                        </div>
                                                    </div>
                                                    <div className='justify-space'>
                                                        <div>
                                                            {translate("travel")["Birthdate"]}
                                                        </div>
                                                        <div>
                                                            {quotesarr && moment(quotesarr[0]?.insuredDob).format('DD/MM/YYYY')}
                                                        </div>
                                                    </div>
                                                    <div className='justify-space'>
                                                        <div>
                                                            {translate("travel")["ID Number (Passport)"]} (KTP/KITAS)
                                                        </div>
                                                        <div>
                                                            {quotesarr && quotesarr[0]?.insuredIdCard}
                                                        </div>
                                                    </div>
                                                    <div className='justify-space'>
                                                        <div>
                                                            {translate("travel")["Email Address (optional)"]}
                                                        </div>
                                                        <div>
                                                            {quotesarr && quotesarr[0]?.insuredEmail}
                                                        </div>
                                                    </div>
                                                    <div className='justify-space'>
                                                        <div>
                                                            {translate("travel")["Mobile Number (optional)"]}
                                                        </div>
                                                        <div>
                                                            {quotesarr && quotesarr[0]?.insuredMobile}
                                                        </div>
                                                    </div>
                                                    <div className='justify-space'>
                                                        <div>
                                                            {translate("travel")["ID Number (Passport)"]}
                                                        </div>
                                                        <div>
                                                            {quotesarr && quotesarr[0]?.insuredPassportNo}
                                                        </div>
                                                    </div>
                                                </Card>}



                                                {/* <Card>


                        <div className='justify-space'>
                            <div>
                                <b>ADULT TRAVEL INFORMATION 2</b>
                            </div>
                            <div style={{ color: "#4AC6BB" }}>
                                Edit
                            </div>
                        </div>
                        <div className='justify-space'>
                            <div>
                                Name
                            </div>
                            <div>
                                Jennifer Santoso
                            </div>
                        </div>
                        <div className='justify-space'>
                            <div>
                                Birth Date
                            </div>
                            <div>
                                01/01/1980
                            </div>
                        </div>
                        <div className='justify-space'>
                            <div>
                                ID Number
                            </div>
                            <div>
                                X1234153A
                            </div>
                        </div>
                        <div className='justify-space'>
                            <div>
                                Email Address
                            </div>
                            <div>
                                jennifer.santoso@gmail.com
                            </div>
                        </div>
                        <div className='justify-space'>
                            <div>
                                Phone Number
                            </div>
                            <div>
                                081234567890
                            </div>
                        </div>
                    </Card> */}

                                                {childarr.length > 0 ? childarr.map((item, index) => {
                                                    return (
                                                        <Card>
                                                            <div className='justify-space'>
                                                                <div>
                                                                    <b>CHILD TRAVELLER {index + 1} INFORMATION </b>
                                                                </div>
                                                            </div>

                                                            <div className='justify-space'>
                                                                <div>
                                                                    {translate("travel")["Name"]}
                                                                </div>
                                                                <div>
                                                                    {item.fullName}
                                                                </div>
                                                            </div>
                                                            <div className='justify-space'>
                                                                <div>
                                                                    {translate("travel")["Birthdate"]}
                                                                </div>
                                                                <div>
                                                                    {moment(item.dob).format('DD/MM/YYYY')}
                                                                </div>
                                                            </div>
                                                            <div className='justify-space'>
                                                                <div>
                                                                    {translate("travel")["Name"]}
                                                                </div>
                                                                <div>
                                                                    {item.passportNo}
                                                                </div>
                                                            </div>
                                                            {/* <div className='justify-space'>
                            <div>
                                Email Address
                            </div>
                            <div>
                                jennifer.santoso@gmail.com
                            </div>
                        </div>
                        <div className='justify-space'>
                            <div>
                                Phone Number
                            </div>
                            <div>
                                081234567890
                            </div>
                        </div> */}
                                                        </Card>
                                                    )
                                                }) : null}
                                                {/* <Card>
                        <div className='justify-space'>
                            <div>
                                <b>CHILD TRAVELLER INFORMATION</b>
                            </div>
                            <div style={{ color: "#4AC6BB" }}>
                                Edit
                            </div>
                        </div>

                        <div className='justify-space'>
                            <div>
                                Name
                            </div>
                            <div>
                                Jennifer Santoso
                            </div>
                        </div>
                        <div className='justify-space'>
                            <div>
                                Birth Date
                            </div>
                            <div>
                                01/01/1980
                            </div>
                        </div>
                        <div className='justify-space'>
                            <div>
                                ID Number
                            </div>
                            <div>
                                X1234153A
                            </div>
                        </div>
                        <div className='justify-space'>
                            <div>
                                Email Address
                            </div>
                            <div>
                                jennifer.santoso@gmail.com
                            </div>
                        </div>
                        <div className='justify-space'>
                            <div>
                                Phone Number
                            </div>
                            <div>
                                081234567890
                            </div>
                        </div>
                    </Card> */}
                                                <Card>
                                                    <div className='justify-space'>
                                                        <div>
                                                            <b>{translate("motor")["Personal Information"]}</b>
                                                        </div>
                                                    </div>
                                                    <div className='justify-space'>
                                                        <div>
                                                            {quotesarr && quotesarr[0]?.planName}

                                                        </div>
                                                        <div>
                                                            {new Intl.NumberFormat('id-ID', {
                                                                style: 'currency',
                                                                currency: 'IDR',
                                                                minimumFractionDigits: 0,
                                                            }).format(parseInt(quotesarr && quotesarr[0]?.premium))}
                                                        </div>
                                                    </div>
                                                    {quotesarr && quotesarr[0]?.additionalBenefits.length > 0 ?
                                                        quotesarr[0]?.additionalBenefits.map(item => {
                                                            return (
                                                                <div className='justify-space'>
                                                                    <div>
                                                                        {item.BenefitDesc}
                                                                    </div>
                                                                    <div>
                                                                        {new Intl.NumberFormat('id-ID', {
                                                                            style: 'currency',
                                                                            currency: 'IDR',
                                                                            minimumFractionDigits: 0,
                                                                        }).format(parseInt(item.Premium))}
                                                                    </div>
                                                                </div>

                                                            )
                                                        }) : null}

                                                    {/* <div className='justify-space'>
                                <div>
                                    Visa Protection
                                </div>
                                <div>
                                    X1234153A
                                </div>
                            </div>*/}
                                                    {quotesarr && quotesarr[0]?.adminCost != undefined ?
                                                        <div className='justify-space'>
                                                            <div>
                                                                {translate("motor")["Admin Cost"]}
                                                            </div>
                                                            <div>
                                                                {new Intl.NumberFormat('id-ID', {
                                                                    style: 'currency',
                                                                    currency: 'IDR',
                                                                    minimumFractionDigits: 0,
                                                                }).format(parseInt(quotesarr && quotesarr[0]?.adminCost))}
                                                            </div>
                                                        </div>
                                                        : null}
                                                    <div className='justify-space'>
                                                        <div>
                                                            {translate("motor")["Discount"]}
                                                        </div>
                                                        <div style={{ color: "red" }}>
                                                            -{new Intl.NumberFormat('id-ID', {
                                                                style: 'currency',
                                                                currency: 'IDR',
                                                                minimumFractionDigits: 0,
                                                            }).format(parseInt(discountpercent))}
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className='justify-space'>
                                                        <div className='view-detail-font'>
                                                            TOTAL
                                                        </div>
                                                        <div className='thick-font' style={{ fontSize: 18 }}>
                                                            <b>
                                                                {new Intl.NumberFormat('id-ID', {
                                                                    style: 'currency',
                                                                    currency: 'IDR',
                                                                    minimumFractionDigits: 0,
                                                                }).format(parseInt(total))}
                                                            </b>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Col> : null}

                                        {productname === 'Motor' ?
                                            <Col xl={24} md={24} lg={24} sm={24} xs={24} style={{ marginBottom: 20 }}>
                                                <Card>


                                                    <Card className='add-benefit-card  back-img-1'>
                                                        <Row style={{ alignItems: "center" }}>
                                                            <Col md={20} xl={20} xs={16} sm={20} lg={20}>
                                                                <div>
                                                                    <div>Subtotal</div>
                                                                    <div><b style={{ fontSize: 21 }}>{new Intl.NumberFormat('id-ID', {
                                                                        style: 'currency',
                                                                        currency: 'IDR',
                                                                        minimumFractionDigits: 0,
                                                                    }).format(total)}</b></div>
                                                                    <div>{quotesarr && quotesarr[0]?.planName} {quotesarr[0]?.additionalBenefits.length > 0 && `+ ${quotesarr[0]?.additionalBenefits.length} ${translate('travel')["Add Ons"]}`}</div>
                                                                </div>
                                                            </Col>
                                                            {/* <Col md={4} xl={4} xs={8} sm={4} lg={4}>
                                        <div>View Detail</div></Col> */}
                                                        </Row>
                                                    </Card>
                                                    {/* <div className='justify-space'>
                                                        <div>
                                                            <b>{translate("travel")["Product Package"]}</b>
                                                        </div>
                                                    </div> */}

                                                    <div className='justify-space'>
                                                        <div>
                                                            {translate("travel")["Product Package"]}
                                                        </div>
                                                        <div>
                                                            {quotesarr && quotesarr[0]?.planName}
                                                        </div>
                                                    </div>
                                                    {quotesarr[0]?.additionalBenefits.length > 0 && <div >
                                                        <Row>
                                                            <Col sm={6} md={6} lg={6} xl={6} xxl={6} xs={6}>
                                                                <div>
                                                                    {translate("travel")["Additional Benefits(s)"]}
                                                                </div>
                                                            </Col>
                                                            <Col sm={18} md={18} lg={18} xl={18} xxl={18} xs={18}>
                                                                <Row style={{ justifyContent: 'right' }}>
                                                                    {quotesarr && quotesarr[0]?.additionalBenefits.length > 0 ?
                                                                        quotesarr[0]?.additionalBenefits.map((item, index) => {
                                                                            return (
                                                                                <Row>
                                                                                    {` ${item.BenefitDesc} `} {index === (quotesarr && quotesarr[0]?.additionalBenefits.length - 1) ? '' : ', '}
                                                                                </Row>
                                                                            )
                                                                        }) : '-'}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>}
                                                </Card>
                                                <Card>
                                                    <div className='justify-space'>
                                                        <div>
                                                            <b className='thick-font'>{translate("motor")["Insured Vehicle Information"]}</b>
                                                        </div>
                                                    </div>
                                                    <div className='justify-space'>
                                                        <div>
                                                            {translate("motor")["Vehicle Brand"]}
                                                        </div>
                                                        <div>
                                                            {quotesarr[0]?.actualVehicleBrand}
                                                        </div>
                                                    </div>
                                                    <div className='justify-space'>
                                                        <div>
                                                            {translate("motor")["Vehicle Model"]}
                                                        </div>
                                                        <div>
                                                            {quotesarr[0]?.vehicleModel}
                                                        </div>
                                                    </div>
                                                    <div className='justify-space'>
                                                        <div>
                                                            {translate("motor")["Model Year"]}
                                                        </div>
                                                        <div>
                                                            {quotesarr[0]?.actualManufactureYear}
                                                        </div>
                                                    </div>
                                                    <div className='justify-space'>
                                                        <div>
                                                            {translate("motor")["Plate Number"]}
                                                        </div>
                                                        <div>
                                                            {quotesarr[0]?.actualLicensePlatCode} {quotesarr[0]?.licensePlateNumber} {quotesarr[0]?.licensePlateNumber1}
                                                        </div>
                                                    </div>
                                                    <div className='justify-space'>
                                                        <div>
                                                            {translate("motor")["Chassis Number"]}
                                                        </div>
                                                        <div>
                                                            {quotesarr[0]?.chassisNo}
                                                        </div>
                                                    </div>

                                                    <div className='justify-space'>
                                                        <div>
                                                            {translate("motor")["Engine Number"]}
                                                        </div>
                                                        <div>
                                                            {
                                                                quotesarr[0]?.engineNo
                                                            }
                                                        </div>
                                                    </div>
                                                </Card>
                                                {imgArr && <Card>
                                                    <div className='justify-space'>
                                                        <div>
                                                            <b className='thick-font'>{translate("motor")["Insured Vehicle Information"]}</b>
                                                        </div>
                                                    </div>
                                                    <Row className='card-image-icon'>
                                                        {
                                                            imgArr && imgArr?.map((data, index) => (
                                                                <Col xl={index === (imgArr.length - 1) ? 24 : 12} lg={index === (imgArr.length - 1) ? 24 : 12} md={index === (imgArr.length - 1) ? 24 : 12} xs={index === (imgArr.length - 1) ? 24 : 12} sm={index === (imgArr.length - 1) ? 24 : 12} >
                                                                    <div>Vehicle {data?.type} Side Photo</div>
                                                                    <Card className='card-image-motor' >
                                                                        <Image width={"100%"} src={data?.images[0]} style={{
                                                                            width: "95%"
                                                                        }} preview={false} />
                                                                        <p>
                                                                            {data?.desc}
                                                                        </p>
                                                                    </Card>

                                                                </Col>

                                                            ))
                                                        }
                                                    </Row>
                                                </Card>
                                                }
                                                <Card>
                                                    <div className='justify-space'>
                                                        <div>
                                                            <b className='thick-font'>POLICY INFORMATION</b>
                                                        </div>
                                                    </div>

                                                    <div className='justify-space'>
                                                        <div>
                                                            Policy Start Date
                                                        </div>
                                                        <div>
                                                            {quotesarr[0]?.effectiveDate}              </div>
                                                    </div>
                                                    <div className='justify-space'>
                                                        <div>
                                                            Policy End Date
                                                        </div>
                                                        <div>

                                                            {moment(quotesarr[0]?.expiryDate).format('YYYY-MM-DD')}
                                                            {/* 20/02/2024 */}
                                                        </div>
                                                    </div>
                                                </Card>
                                                <Card>
                                                    <div className='justify-space'>
                                                        <div>
                                                            <b className='thick-font'>CUSTOMER INFORMATION</b>
                                                        </div>
                                                        {/* {state.cipher != null ? null : <div style={{ color: "#4AC6BB" }}>
                    {translate("travel")["Edit"]}
                  </div>} */}
                                                    </div>
                                                    <div className='justify-space'>
                                                        <div>
                                                            {translate("travel")["Name"]}
                                                        </div>
                                                        <div>
                                                            {quotesarr[0]?.insuredName}
                                                        </div>
                                                    </div>
                                                    <div className='justify-space'>
                                                        <div>
                                                            {translate("travel")["Email Address (optional)"]}
                                                        </div>
                                                        <div>
                                                            {quotesarr[0]?.insuredEmail}
                                                        </div>
                                                    </div>
                                                    <div className='justify-space'>
                                                        <div>
                                                            {translate("travel")["Mobile Number (optional)"]}
                                                        </div>
                                                        <div>
                                                            {quotesarr[0]?.insuredMobile}
                                                        </div>
                                                    </div>
                                                </Card>

                                                {/* <Card>


                        <div className='justify-space'>
                            <div>
                                <b>ADULT TRAVEL INFORMATION 2</b>
                            </div>
                            <div style={{ color: "#4AC6BB" }}>
                                Edit
                            </div>
                        </div>
                        <div className='justify-space'>
                            <div>
                                Name
                            </div>
                            <div>
                                Jennifer Santoso
                            </div>
                        </div>
                        <div className='justify-space'>
                            <div>
                                Birth Date
                            </div>
                            <div>
                                01/01/1980
                            </div>
                        </div>
                        <div className='justify-space'>
                            <div>
                                ID Number
                            </div>
                            <div>
                                X1234153A
                            </div>
                        </div>
                        <div className='justify-space'>
                            <div>
                                Email Address
                            </div>
                            <div>
                                jennifer.santoso@gmail.com
                            </div>
                        </div>
                        <div className='justify-space'>
                            <div>
                                Phone Number
                            </div>
                            <div>
                                081234567890
                            </div>
                        </div>
                    </Card> */}

                                                {childarr.length > 0 ? childarr.map((item, index) => {
                                                    return (
                                                        <Card>
                                                            <div className='justify-space'>
                                                                <div>
                                                                    <b>CHILD TRAVELLER {index + 1} INFORMATION </b>
                                                                </div>
                                                            </div>

                                                            <div className='justify-space'>
                                                                <div>
                                                                    {translate("travel")["Name"]}
                                                                </div>
                                                                <div>
                                                                    {item.fullName}
                                                                </div>
                                                            </div>
                                                            <div className='justify-space'>
                                                                <div>
                                                                    {translate("travel")["Birthdate"]}
                                                                </div>
                                                                <div>
                                                                    {moment(item.dob).format('DD/MM/YYYY')}
                                                                </div>
                                                            </div>
                                                            <div className='justify-space'>
                                                                <div>
                                                                    {translate("travel")["Name"]}
                                                                </div>
                                                                <div>
                                                                    {item.passportNo}
                                                                </div>
                                                            </div>
                                                            {/* <div className='justify-space'>
                            <div>
                                Email Address
                            </div>
                            <div>
                                jennifer.santoso@gmail.com
                            </div>
                        </div>
                        <div className='justify-space'>
                            <div>
                                Phone Number
                            </div>
                            <div>
                                081234567890
                            </div>
                        </div> */}
                                                        </Card>
                                                    )
                                                }) : null}
                                                {/* <Card>
                        <div className='justify-space'>
                            <div>
                                <b>CHILD TRAVELLER INFORMATION</b>
                            </div>
                            <div style={{ color: "#4AC6BB" }}>
                                Edit
                            </div>
                        </div>

                        <div className='justify-space'>
                            <div>
                                Name
                            </div>
                            <div>
                                Jennifer Santoso
                            </div>
                        </div>
                        <div className='justify-space'>
                            <div>
                                Birth Date
                            </div>
                            <div>
                                01/01/1980
                            </div>
                        </div>
                        <div className='justify-space'>
                            <div>
                                ID Number
                            </div>
                            <div>
                                X1234153A
                            </div>
                        </div>
                        <div className='justify-space'>
                            <div>
                                Email Address
                            </div>
                            <div>
                                jennifer.santoso@gmail.com
                            </div>
                        </div>
                        <div className='justify-space'>
                            <div>
                                Phone Number
                            </div>
                            <div>
                                081234567890
                            </div>
                        </div>
                    </Card> */}
                                                <Card>
                                                    <div className='justify-space'>
                                                        <div>
                                                            <b>{translate("motor")["Personal Information"]}</b>
                                                        </div>
                                                    </div>
                                                    <div className='justify-space'>
                                                        <div>
                                                            {quotesarr && quotesarr[0]?.planName}

                                                        </div>
                                                        <div>
                                                            {new Intl.NumberFormat('id-ID', {
                                                                style: 'currency',
                                                                currency: 'IDR',
                                                                minimumFractionDigits: 0,
                                                            }).format(parseInt(quotesarr && quotesarr[0]?.premium))}
                                                        </div>
                                                    </div>
                                                    {quotesarr && quotesarr[0]?.additionalBenefits.length > 0 ?
                                                        quotesarr[0]?.additionalBenefits.map(item => {
                                                            return (
                                                                <div className='justify-space'>
                                                                    <div>
                                                                        {item.BenefitDesc}
                                                                    </div>
                                                                    <div>
                                                                        {new Intl.NumberFormat('id-ID', {
                                                                            style: 'currency',
                                                                            currency: 'IDR',
                                                                            minimumFractionDigits: 0,
                                                                        }).format(parseInt(item.Premium))}
                                                                    </div>
                                                                </div>

                                                            )
                                                        }) : null}

                                                    {/* <div className='justify-space'>
                                <div>
                                    Visa Protection
                                </div>
                                <div>
                                    X1234153A
                                </div>
                            </div>*/}
                                                    {quotesarr && quotesarr[0]?.adminCost != undefined ?
                                                        <div className='justify-space'>
                                                            <div>
                                                                {translate("motor")["Admin Cost"]}
                                                            </div>
                                                            <div>
                                                                {new Intl.NumberFormat('id-ID', {
                                                                    style: 'currency',
                                                                    currency: 'IDR',
                                                                    minimumFractionDigits: 0,
                                                                }).format(parseInt(quotesarr && quotesarr[0]?.adminCost))}
                                                            </div>
                                                        </div>
                                                        : null}
                                                    <div className='justify-space'>
                                                        <div>
                                                            {translate("motor")["Discount"]}
                                                        </div>
                                                        <div style={{ color: "red" }}>
                                                            -{new Intl.NumberFormat('id-ID', {
                                                                style: 'currency',
                                                                currency: 'IDR',
                                                                minimumFractionDigits: 0,
                                                            }).format(parseInt(discountpercent))}
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className='justify-space'>
                                                        <div className='view-detail-font'>
                                                            TOTAL
                                                        </div>
                                                        <div className='thick-font' style={{ fontSize: 18 }}>
                                                            <b>
                                                                {new Intl.NumberFormat('id-ID', {
                                                                    style: 'currency',
                                                                    currency: 'IDR',
                                                                    minimumFractionDigits: 0,
                                                                }).format(parseInt(total))}
                                                            </b>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Col> : null}


                                            {productname === 'Home' ?
                                           <Col xl={24} md={24} lg={24} sm={24} xs={24}>
                                           <Card>
                                               <div className='justify-space'>
                                                   <div>
                                                       <b className='thick-font'>{translate("home")["Insured Property Details"]}</b>
                                                   </div>
                                               </div>
                       
                                               <div className='justify-space'>
                                                   <div>
                                                   {translate("commercial sales")["startDate"]}
                                                   </div>
                                                   <div>
                                                       {moment(quotedetails?.homeEffectiveDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                   </div>
                                               </div>
                                               <div className='justify-space'>
                                                   <div>
                                                   {translate("commercial sales")["endDate"]}
                                                   </div>
                                                   <div>
                                                       {moment(quotedetails?.homeExpiryDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                   </div>
                                               </div>
                                               <div className='justify-space'>
                                                   <div>
                                                   {translate("commercial sales")["address"]}
                                                   </div>
                                                   <div>
                                                       {quotedetails?.address}
                                                   </div>
                                               </div>
                       
                                               <div className='justify-space'>
                                                   <div>
                                                   {translate("home")["Province"]}
                                                   </div>
                                                   <div>
                                                       { findingprovinceobj?.Description}
                                                   </div>
                                               </div>
                       
                                               <div className='justify-space'>
                                                   <div>
                                                   {translate("home")["City/Kabupaten"]}
                                                   </div>
                                                   <div>
                                                       { findingCitiesobj?.RegionalLevel}
                                                   </div>
                                               </div>
                       
                                               <div className='justify-space'>
                                                   <div>
                                                   {translate("home")["District"]}
                                                   </div>
                                                   <div>
                                                       {quotedetails?.district || findingDistrictsobj?.District}
                                                   </div>
                                               </div>
                       
                                               <div className='justify-space'>
                                                   <div>
                                                   {translate("home")["Sub-District"]}
                                                   </div>
                                                   <div>
                                                       {quotedetails?.subDistrict || findingsubdistrictsobj?.SubDistrict}
                                                   </div>
                                               </div>
                       
                                               <div className='justify-space'>
                                                   <div>
                                                   {translate("home")["Postal Code"]}
                                                   </div>
                                                   <div>
                                                       {quotedetails?.postalCode}
                                                   </div>
                                               </div>
                                           </Card>
                                           <Card>
                                              
                       
                                               <div className='justify-space'>
                                                   <div>
                                                   {translate("travel")["Name"]}
                                                   </div>
                                                   <div>
                                                       {quotedetails?.insuredName}
                                                   </div>
                                               </div>
                                              
                       
                                               <div className='justify-space'>
                                                   <div>
                                                   {translate("travel")["Email Address (optional)"]}
                                                   </div>
                                                   <div>
                                                       {quotedetails?.lead[0]?.email}
                                                   </div>
                                               </div>
                                               <div className='justify-space'>
                                                   <div>
                                                   {translate("travel")["Mobile Number (optional)"]}
                                                   </div>
                                                   <div>
                                                       {quotedetails?.insuredMobile}
                                                   </div>
                                               </div>
                                           </Card>
                       
                                           <Card>
                                               <div className='justify-space'>
                                                   <div>
                                                       <b className='thick-font'>PAYMENT DETAIL INFORMATION</b>
                                                   </div>
                                               </div>
                       
                                               {
                                                   sortedProductOptions?.map((item) => {
                                                       return <div className='justify-space'>
                                                           <div>
                                                               {item?.coverageName}
                                                           </div>
                                                           <div>
                                                               {
                                                                   new Intl.NumberFormat('id-ID', {
                                                                       style: 'currency',
                                                                       currency: 'IDR',
                                                                       minimumFractionDigits: 0,
                                                                   }).format(item?.grossPremium)}
                                                           </div>
                                                       </div>
                                                   })
                                               }
                       
                       
                                           
                                               <div className='justify-space'>
                                                   <div>
                                                   {translate("motor")["Admin Cost"]}
                                                   </div>
                                                   <div>
                       
                                                       {new Intl.NumberFormat('id-ID', {
                                                           style: 'currency',
                                                           currency: 'IDR',
                                                           minimumFractionDigits: 0,
                                                       }).format(quotedetails?.adminCost)}
                       
                                                   </div>
                                               </div>
                       
                                               <div className='justify-space'>
                                                   <div>
                                                   {translate("motor")["Discount"]}
                                                   </div>
                                                   <div style={{ color: "red" }}>
                                                       -{new Intl.NumberFormat('id-ID', {
                                                           style: 'currency',
                                                           currency: 'IDR',
                                                           minimumFractionDigits: 0,
                                                       }).format(quotedetails?.discountAmount)}
                                                   </div>
                                               </div>
                                               <br />
                       
                       
                                               <div className='justify-space'>
                                                   <div className='view-detail-font'>
                                                       TOTAL
                                                   </div>
                                                   <div style={{ fontSize: 18 }}>
                                                       <b className='thick-font'>
                                                           {quotedetails?.duePremium === undefined ? new Intl.NumberFormat('id-ID', {
                                                               style: 'currency',
                                                               currency: 'IDR',
                                                               minimumFractionDigits: 0,
                                                           }).format(Number(quotedetails?.grossPremium) + Number(quotedetails?.adminCost)) : new Intl.NumberFormat('id-ID', {
                                                               style: 'currency',
                                                               currency: 'IDR',
                                                               minimumFractionDigits: 0,
                                                           }).format(Number(quotedetails?.duePremium))}
                                                       </b>
                                                   </div>
                                               </div>
                       
                                              
                                           </Card>
                                       </Col>
                                            
                                            
                                            : null}

{

    productname === "PA" ? <Col xl={24} md={24} lg={24} sm={24} xs={24}>
         <Card style={{ marginTop: 3 }}>
        <div className="justify-space ">
            
                <div className="thick-font">{translate("accident")["Product Package"]}</div>
              </div>
              <div className="accordian-inner">
                <div className="label-font">{translate("accident")["Requested Limit"]}</div>
                <div className="label-font-1">{quotesarr[0]?.productName}</div>
              </div>

             
              <div className="justify-space">
                <div className="thick-font">{translate("accident")["Policy Information"]}</div>
              </div>
              <div className="justify-space">
                <div className="label-font">{translate("accident")["Policy start date"]}</div>
                <div className="label-font-1">{moment(quotesarr[0]?.effectiveDate).format("DD/MM/YYYY")}</div>
              </div>
              <div className="justify-space">
                <div className="label-font">{translate("accident")["Policy end date"]}</div>
                <div className="label-font-1">{moment(quotesarr[0]?.expiryDate,"YYYY-MM-DD").format("DD/MM/YYYY")}</div>
              </div>


              </Card>

              {
              quotesarr[0]?.numberOfParticipants === 1 ? (
                <>
                  <Card style={{ marginTop: 1 }}>
                   
                    <div className="justify-space">
                      <div className="label-font">{translate("accident")["Name"]}</div>
                      <div className="label-font-1">{quotesarr[0]?.insuredName}</div>
                    </div>
                    <div className="justify-space">
                      <div className="label-font">{translate("accident")["Date of Birth"]}</div>
                      <div className="label-font-1">{moment(quotesarr[0]?.insuredDob).format("DD/MM/YYYY")}</div>
                    </div>
                    <div className="justify-space">
                      <div className="label-font">{translate("accident")["Address"]}</div>
                      <div className="label-font-1">
                        {quotesarr[0]?.address}
                      </div>
                    </div>
                    <div className="justify-space">
                      <div className="label-font">{translate("accident")["Phone Number"]}</div>
                      <div className="label-font-1">{quotesarr[0]?.insuredMobile}</div>
                    </div>
                    <div className="justify-space">
                      <div className="label-font">{translate("accident")["Email Address"]}</div>
                      <div className="label-font-1">{quotesarr[0]?.insuredEmail}</div>
                    </div>
                  </Card>
                  <Card style={{ marginTop: 1 }}>
                  
                    <div className="justify-space">
                      <div className="label-font">{translate("accident")["Beneficiary's Name"]}</div>
                      <div className="label-font-1">{quotesarr[0]?.requestLimit[0]?.beneficiaryName}</div>
                    </div>
                    <div className="justify-space">
                      <div className="label-font">{translate("accident")["Beneficiary's Date of Birth"]}</div>
                      <div className="label-font-1">{quotesarr[0]?.requestLimit[0]?.beneficiaryDateOfBirth !== null ? moment(quotesarr[0]?.requestLimit[0]?.beneficiaryDateOfBirth).format("DD/MM/YYYY") : ""}</div>
                    </div>
                  </Card>
                </>
              ) : (
                <Card>
                  <Col xl={24} xs={24} sm={24} lg={24} md={24}>
                    <div style={{ padding: "12px 0px" }} className='thick-font'>{translate("accident")["Personal Information"]}</div>
                  </Col>
                  {quotesarr[0]?.requestLimit?.map((item, i) => {
                    return (
                      <Card className="card-padding-customer" style={{ margin: "16px 0px 16px 0px" }}>
                        <Row>
                          <Col style={{ display: "flex", alignItems: "center", justifyContent: "space-around", marginRight: "10px", }} xl={3} lg={3} md={3} sm={4} xs={4}>
                            {/* <Image src={userImage} preview={false} /> */}
                          </Col>
                          <Col xl={17} lg={17} md={15} sm={14} xs={14}>
                            <div style={{ fontSize: 12, fontWeight: 600 }}>
                              {/* `$Insured+${i}` */}
                              {quotesarr[0]?.requestLimitType === 1 ? (<>{i === 0 ? <>{translate("accident")["Insured"]} {i + 1}</> : ""}</>) : (<>{translate("accident")["Insured"]} {i + 1}</>)}
                            </div>
                            <div style={{ fontSize: 14, fontWeight: 600, color: "#482c77", marginBottom: "10px", }}>{item.insuredName}</div>
                            <Row>
                              <Col xl={11} lg={8} md={8} sm={12} xs={12}>
                                <div style={{ fontSize: 14, fontWeight: 500, color: "#404852", }}>{translate("accident")["Requested Limit"]}</div>
                                <div style={{ fontSize: 14, fontWeight: 600, color: "#272D33", }}>{item.planName}</div>
                              </Col>
                              <Col xl={11} lg={8} md={8} sm={12} xs={12} style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-around", }}>
                                <div style={{ fontSize: 14, fontWeight: 500, color: "#404852", }}>{translate("accident")["Premium"]}</div>
                                <div style={{ fontSize: 14, fontWeight: 600, color: "#272D33", }}>{toCurrencyConvertor(Number(item.premium))}</div>
                              </Col>
                            </Row>
                          </Col>
                         
                        </Row>
                      </Card>
                    );
                  })}
                </Card>
              )
            }

<Card style={{ marginTop: 1 }}>
              <div className="justify-space">
                <div className="thick-font">{translate("accident")["Payment Details"]}</div>
              </div>
              <div className="justify-space">
                <div className="label-font">{translate("accident")["Premium"]}</div>
                <div className="label-font-1">{toCurrencyConvertor(quotesarr[0]?.premium)}</div>
              </div>
              <div className="justify-space">
                <div className="label-font">{translate("accident")["Admin Cost"]}</div>
                <div className="label-font-1">{toCurrencyConvertor(quotesarr[0]?.adminCost)}</div>
              </div>
              <div className="justify-space">
                <div className="label-font">{translate("accident")["Discount"]}</div>
                <div
                  className="info-font"
                  style={{ color: "red", fontWeight: "bold" }}
                >
                 
                  -{toCurrencyConvertor(quotesarr[0]?.premium)}
                </div>
              </div>
              <hr />
              <br />
              <div className="justify-space">
                <div>
                  <b
                    className="thick-font letter-space"
                    style={{ color: "#482c77", fontSize: 16 }}
                  >
                    TOTAL
                  </b>
                </div>
                <div className="thick-font" style={{ fontSize: 16 }}>
                {toCurrencyConvertor(Number(Math.floor(quotesarr[0]?.duePremium)))}
                </div>
              </div>

              
            
            </Card>
           
    </Col> : null
}


                                            





                                        {/* <Card className='policy-padding'>
                                            <div className='justify-space'>
                                                <div>
                                                    <b>PRODUCT PACKAGE</b>
                                                </div>
                                            </div>

                                            <div className='justify-space'>
                                                <div>
                                                    product package
                                                </div>
                                                <div>
                                                    Basic
                                                </div>
                                            </div>
                                            <div className='justify-space'>
                                                <div>
                                                    Additional Benefits
                                                </div>
                                                <div>
                                                    Limit Personal Accident for Passenger
                                                </div>
                                            </div>
                                        </Card>
                                        <Card className='policy-padding'>
                                            <div className='justify-space'>
                                                <div>
                                                    <b>INSURED VEHICLE INFORMATION</b>
                                                </div>
                                            </div>
                                            <div className='justify-space'>
                                                <div>
                                                    Vehicle Brand
                                                </div>
                                                <div>
                                                    Honda
                                                </div>
                                            </div>
                                            <div className='justify-space'>
                                                <div>
                                                    Vehicle Model
                                                </div>
                                                <div>
                                                    Accord EX
                                                </div>
                                            </div>
                                            <div className='justify-space'>
                                                <div>
                                                    Model Year
                                                </div>
                                                <div>
                                                    2022
                                                </div>
                                            </div>
                                            <div className='justify-space'>
                                                <div>
                                                    Plate Number
                                                </div>
                                                <div>
                                                    B 1234 JVA
                                                </div>
                                            </div>
                                            <div className='justify-space'>
                                                <div>
                                                    Chassis Number
                                                </div>
                                                <div>
                                                    4S3BMHB68B3286050
                                                </div>
                                            </div>

                                            <div className='justify-space'>
                                                <div>
                                                    Engine Number
                                                </div>
                                                <div>
                                                    4S3BMHB68B3286050
                                                </div>
                                            </div>
                                        </Card>
                                        <Card className='policy-padding'>
                                            <div className='justify-space'>
                                                <div>
                                                    <b>INSURED VEHICLE INFORMATION</b>
                                                </div>
                                            </div>
                                            <Row className='card-image-icon'>
                                                <Col xl={12} lg={12} md={12} xs={12} sm={12}>
                                                    <div>Vehicle Left Side Photo</div>
                                                    <Card style={{ width: "90%" }} className='card-image-motor'>
                                                        <Image width={"100%"} src={LeftImage} preview={false} />
                                                    </Card>
                                                </Col>
                                                <Col xl={12} lg={12} md={12} xs={12} sm={12}>
                                                    <div>Vehicle Right Side Photo</div>
                                                    <Card style={{ width: "90%" }} className='card-image-motor'>
                                                        <Image width={"100%"} src={RightImage} preview={false} />
                                                    </Card>
                                                </Col>
                                                <Col xl={12} lg={12} md={12} xs={12} sm={12}>
                                                    <div>Vehicle Front Side Photo</div>
                                                    <Card style={{ width: "90%" }} className='card-image-motor'>
                                                        <Image width={"100%"} src={FrontImage} preview={false} />
                                                    </Card>
                                                </Col>
                                                <Col xl={12} lg={12} md={12} xs={12} sm={12}>
                                                    <div>Vehicle Rear Side Photo</div>
                                                    <Card style={{ width: "90%" }} className='card-image-motor'>
                                                        <Image width={"100%"} src={BackImage} preview={false} />
                                                    </Card>
                                                </Col>
                                                <Col xl={24} lg={24} md={24} xs={24} sm={24}>
                                                    <div>Vehicle Interior Dashboard Photo</div>
                                                    <Card style={{ width: "95%" }} className='card-image-motor'>
                                                        <Image width={"100%"} src={InteriorImage} preview={false} />
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Card>
                                        <Card className='policy-padding'>
                                            <div className='justify-space'>
                                                <div>
                                                    <b>Policy INFORMATION</b>
                                                </div>
                                            </div>

                                            <div className='justify-space'>
                                                <div>
                                                    Policy Start Date
                                                </div>
                                                <div>
                                                    20/02/2023
                                                </div>
                                            </div>
                                            <div className='justify-space'>
                                                <div>
                                                    Policy End Date
                                                </div>
                                                <div>
                                                    20/02/2024
                                                </div>
                                            </div>
                                        </Card>
                                        <Card className='policy-padding'>
                                            <div className='justify-space'>
                                                <div>
                                                    <b>Customer Information</b>
                                                </div>
                                                <div style={{ color: "#4AC6BB" }}>
                                                    Edit
                                                </div>
                                            </div>
                                            <div className='justify-space'>
                                                <div>
                                                    Name
                                                </div>
                                                <div>
                                                    Jennifer Santoso
                                                </div>
                                            </div>
                                            <div className='justify-space'>
                                                <div>
                                                    Email Address
                                                </div>
                                                <div>
                                                    jennifer.santoso@gmail.com
                                                </div>
                                            </div>
                                            <div className='justify-space'>
                                                <div>
                                                    Phone Number
                                                </div>
                                                <div>
                                                    081234567890
                                                </div>
                                            </div>
                                        </Card> */}
                                    </>
            </div>
            <Modal className='radio-leads' visible={isModalOpen} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div>
                    <Row style={{ marginTop: 10 }}><div style={{ fontWeight: 'bold', fontSize: 18 }}>{translate("commercial sales")["filterSort"]}</div></Row> 
                    <Row style={{ marginTop: 15 }}><div style={{ fontWeight: 'bold', fontSize: 14, }}>{translate("commercial sales")["productType"]}</div></Row>
                    <Radio.Group defaultValue={filtersel} value={filtersel} buttonStyle="solid" style={{ marginTop: 10 }} onChange={(e) => setFilterSel(e.target.value)}>
                        <Radio.Button value="All">{translate("referal partner")["all"]}</Radio.Button>
                        <Radio.Button value="64aff2f3831667919245f86c">{translate("referal partner")["travelProduct"]}</Radio.Button>
                        <Radio.Button value="64c0fd38f6d4bddffcac8f71">{translate("referal partner")["motorVehicle"]}</Radio.Button>
                        <Radio.Button value="64c34807507810de0263ff63">{translate("referal partner")["propertyProduct"]}</Radio.Button>
                    </Radio.Group>

                    <Row style={{ marginTop: 10 }}><div style={{ fontWeight: 'bold', fontSize: 14, marginTop: 10 }}>{translate("referal partner")["sort"]}</div></Row>

                    <Radio.Group onChange={onChange} defaultValue={sort} value={sort} style={{ marginTop: 5 }}>
                        <Space direction="vertical">
                            <Radio value={'newest'}>{translate("referal partner")["newestToOldest"]}</Radio>
                            <Radio value={'oldest'}>{translate("referal partner")["oldestToNewest"]}</Radio>
                            <Radio value={'highestPremium'}>{translate("referal partner")["highToLowPremiumAmount"]}</Radio>
                            <Radio value={'lowestPremium'}>{translate("referal partner")["lowToHighPremiumAmount"]}</Radio>
                        </Space>
                    </Radio.Group>

                    <Row style={{ marginTop: 15 }}>
                        <Col xl={12} md={12} lg={12} xs={12} sm={12}>
                            <Button className='no-button' onClick={() => ResetFilter()}>{translate("referal partner")["reset"]}</Button>
                        </Col>
                        <Col xl={12} md={12} lg={12} xs={12} sm={12}>
                            <Button className='next-button-travel' onClick={() => handleNext()}>{translate("referal partner")["apply"]}</Button>
                        </Col>
                    </Row>


                </div>
            </Modal>

            <Modal title="To-Do" open={isModalOpen1} onOk={handleOk1} onCancel={handleCancel1}>
                <Form layout='vertical'>
                    <Form.Item label="Agents">
                        <Select mode="multiple" allowClear onChange={handleChange1} value={selectedOptions} options={list}></Select>
                    </Form.Item>

                    <Form.Item label="Task Description">
                        <Input
                            style={{ border: "1px solid lightgray" }}
                            value={formData.taskDescription}
                            onChange={e => setFormData({ ...formData, taskDescription: e.target.value })}
                        />
                    </Form.Item>

                    <Form.Item label="Task Status">
                        <Select
                            style={{ border: "1px solid lightgray" }}
                            value={formData.taskStatus}
                            options={options1} onChange={handleStatus}
                        />
                    </Form.Item>

                    <Form.Item label="Task Category">
                        <Select value={formData.taskCategory} options={options} onChange={handleChange}>
                        </Select>
                    </Form.Item>

                    <Form.Item label="Task Date">
                        <DatePicker format="YYYY-MM-DD HH:mm" showTime={{
                            format: 'HH:mm',
                        }} onChange={onDateChange} onOk={onOk} />
                    </Form.Item>


                    <Button className='next-button-travel' onClick={handleFormSubmit}>Save</Button>
                </Form>
            </Modal>

            <Modal className='radio-leads' visible={isModalOpen2} open={isModalOpen2} onOk={handleOk2} onCancel={handleCancel2}>
                <div>
                    <Row style={{ marginTop: 10 }}><div style={{ fontWeight: 'bold', fontSize: 18 }}>{translate("commercial sales")["filterSort"]}</div></Row>
                    <Row style={{ marginTop: 15 }}><div style={{ fontWeight: 'bold', fontSize: 14, }}>{translate("commercial sales")["productType"]}</div></Row> 
                    <Radio.Group defaultValue={filtersel2} value={filtersel2} buttonStyle="solid" style={{ marginTop: 10 }} onChange={(e) => setFilterSel2(e.target.value)}>
                        <Radio.Button value="all">{translate("referal partner")["all"]}</Radio.Button>
                        <Radio.Button value="64aff2f3831667919245f86c">{translate("referal partner")["travelProduct"]}</Radio.Button>
                        <Radio.Button value="64c0fd38f6d4bddffcac8f71">{translate("referal partner")["motorVehicle"]}</Radio.Button>
                        <Radio.Button value="64c34807507810de0263ff63">{translate("referal partner")["propertyProduct"]}</Radio.Button>
                    </Radio.Group>

                    <Row style={{ marginTop: 10 }}><div style={{ fontWeight: 'bold', fontSize: 14, marginTop: 10 }}>{translate("referal partner")["sort"]}</div></Row>

                    <Radio.Group onChange={onChange2} defaultValue={sort2} value={sort2} style={{ marginTop: 5 }}>
                        <Space direction="vertical">
                            <Radio value={'newest'}>{translate("referal partner")["newestToOldest"]}</Radio>
                            <Radio value={'oldest'}>{translate("referal partner")["oldestToNewest"]}</Radio>
                            <Radio value={'highest'}>{translate("referal partner")["highToLowPremiumAmount"]}</Radio>
                            <Radio value={'lowest'}>{translate("referal partner")["lowToHighPremiumAmount"]}</Radio>
                        </Space>
                    </Radio.Group>

                    <Row style={{ marginTop: 15 }}>
                        <Col xl={12} md={12} lg={12} xs={12} sm={12}>
                            <Button className='no-button' onClick={() => ResetFilter2()}>{translate("referal partner")["reset"]}</Button>
                        </Col>
                        <Col xl={12} md={12} lg={12} xs={12} sm={12}>
                            <Button className='next-button-travel' onClick={() => handlePayment()}>{translate("referal partner")["apply"]}</Button>
                        </Col>
                    </Row>
                </div>
            </Modal>
            <Footer />

        </>
    )
}

export default OonanotifyPayment