import React, { useEffect, useState } from 'react'
import { Collapse, Button, Card, Row, Col, Modal, Image, Progress, Checkbox, Radio, Spin } from "antd";

import { ArrowLeftOutlined } from "@ant-design/icons"
import { useHistory } from "react-router-dom"
import rootIndex from "../../../store/root_index";
import ArrowIcon from "../../../oonaImages/arrowIcon.png"
import { useLanguage } from '../../../config/LanguageContext';
import { toast } from 'react-toastify';
import { getLanguage } from '../../../config/LanguageGetter';
import axiosRequest from '../../../axios-request/request.methods'
import CustomerHeader from '../../../CustomerHeader/CustomerHeader';
import Singlepanel from '../../accordin/Singlepanel';
import MultiplePanel from '../../accordin/MultiplePanel'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';


const Index = (props) => {
    const { store } = rootIndex;
    const { Panel } = Collapse;
   

    const fetchingIDfromStore=useSelector((state)=>state?.cipherlinkreducer?.quote?.quoteId)
    console.log(fetchingIDfromStore,"fetchingIDfromStore")
    const checkingResumeType=useSelector((state)=>state?.cipherlinkreducer?.quote?.resumeType);
    const { translate, language } = useLanguage()

    const history = useHistory()
    const [width, setWidth] = useState(window.innerWidth);
    const breakpoint = 620;
    const [bike, setBike] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [addbnft, setAddbnft] = useState([])
    console.log(addbnft,"addbnft")
    const [calculation, setCalculation] = useState(0)
    console.log(calculation,"calculation in bs")
    const [premium, setPremium] = useState(props.price)
    const [quoteData, setQuoteData] = useState({})
    console.log(quoteData,"quoteData")
    const [planArr, setAddPlanArr] = useState();
    console.log(planArr,"planArr")
    const [planName, setPlanName] = useState('');
    const [durationfromPlan,setDuration]=useState(0)
    const [checkingmultiyear,setcheckingMultiYear]=useState(false)
    const [planCode, setPlanCode] = useState('')
    const [itemWithTp, setItemWithTp] = useState([]);
    const [itemWithoutTp, setItemWithOutTp] = useState([])
    const [loader,setLoader]=useState(false)
    const accordionColors = ["linear-gradient(260.99deg, #B18E75 -0.7%, #66462F 96.39%)", "linear-gradient(74.62deg, #1C1F20 -10.07%, #476372 99.04%)", "linear-gradient(74.62deg, #6F5000 -10.07%, #D1A737 99.04%)", "linear-gradient(260.99deg, #B18E75 -0.7%, #66462F 96.39%)", "linear-gradient(74.62deg, #1C1F20 -10.07%, #476372 99.04%)"];
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    
  // Scroll to top when the component mounts
    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }, []);

    useEffect(() => {

        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, [width]);
    useEffect(() => {
        if(fetchingIDfromStore !== ""){
        handleAdditionalBenifits(fetchingIDfromStore)
    }
        setAddbnft([])
        // setPremium(props?.price)

    }, [language,fetchingIDfromStore])



    const sendEmail = () => {
        window.open("mailto:noreply@oona-insurance.co.id?subject=SendMail&body=Description");
    };



    const handleAdditionalBenifits = (id) => {
        setLoader(true)
        try {
            let options = { secure: true, multipart: false }
            axiosRequest
                .get(
                    `user/plan-benefit-motor/${id}?LangId=${getLanguage(language)}`,
                    options
                )
                .then((data) => {
                    if (data.errCode === -1) {
                        setLoader(false)
                        if (data.errMsg.data[0].product_Id === '64c0fd38f6d4bddffcac8f71') {
                            setBike(true)
                        }
                        else {
                            setBike(false)

                        }
                        if (data.errMsg.data[0].Plans != undefined) {
                            console.log(data.errMsg.data[0].Plans[0].AdditionalBenefits, 'Travel Type API Response----->');
                            let finalArr = data.errMsg.data[0].Plans[0].AdditionalBenefits

                            finalArr.map(item => {
                                if (item.BenefitDesc === 'Covid- 19 Protection' && props.covid === true) {
                                    item.selectbtn = true
                                    let fi = [item, ...addbnft]
                                    // console.log(addplanarr, fi, 'if arr====>');
                                    handleCalc(fi)
                                    setAddbnft(fi)
                                } else {
                                    item.selectbtn = false
                                }
                            })

                            let filteredFinal = finalArr.filter(item => {
                                console.log(item.BenefitType === 'C', 'yess');
                                return item.BenefitType === 'C' && item.BenefitDesc != 'Covid- 19 Protection'
                            })
                            const tpBenefitArray = filteredFinal.filter(item => item.BenefitCode.includes('TP'));
                            const remainingBenefitArray = filteredFinal.filter(item => !item.BenefitCode.includes('TP'));
                            setItemWithTp(tpBenefitArray)
                            setItemWithOutTp(remainingBenefitArray)
                            console.log(tpBenefitArray); // Contains items with 'TP'
                            console.log(remainingBenefitArray); // Contains items without 'TP'



                            console.log(filteredFinal, 'additional benefit----->');
                            setAddPlanArr(filteredFinal)
                            // getQuotes(filteredFinal)
                            props.handleLoading(false)
                        } else {
                            props.handleLoading(false)
                            // setAddPlanArr(dummyarr.errMsg.data[0].Plans[0].AdditionalBenefits)
                            setAddPlanArr([])
                        }
                        // console.log(data.data.errMsg.data, "res");
                    }
                })
                .catch((error) => {
                    console.log(
                        typeof error,
                        error.Error,
                        error.message
                    );
                });
        } catch (error) {
            console.log(
                typeof error,
                error.Error,
                error.message
            );

        }

    }


    useEffect(()=>{
        if(quoteData?.additionalBenefits?.length > 0 && planArr?.length > 0){

             const fetchingadditionalBenefitsPlanCodes=quoteData?.additionalBenefits?.map((item)=>{
                return item?.BenefitCode
            })
            console.log(fetchingadditionalBenefitsPlanCodes,"fetchingadditionalBenefitsPlanCodes")

            const fetchingtheadd=planArr?.filter((item)=>{
                return fetchingadditionalBenefitsPlanCodes.includes(item?.BenefitCode)
            })
            console.log(fetchingtheadd,"fetchingtheadd")
            setAddbnft(fetchingtheadd)


            quoteData?.additionalBenefits?.map(item => {
                return planArr.map(datas => {
                    if (item.BenefitCode === datas.BenefitCode) {
                        return datas.selectbtn = true
                    }
                })
            })

                var finalValue = 0
                fetchingtheadd?.map(item => {
                    finalValue = finalValue + parseInt(item.Premium)
                })
                if (finalValue !== 0) {
                    console.log(finalValue, 'final calc value-------->');
                    setCalculation(finalValue)
                }
            
               
        }

    },[quoteData,planArr])

    useEffect(()=>{
        if(fetchingIDfromStore !== ""){
            getQuotes()
        }
       
    },[planArr,fetchingIDfromStore,checkingResumeType])
    const getQuotes = () => {
        setLoader(true)
        let options = { secure: true, multipart: false }
        var fromReferral = ""
        if(checkingResumeType === "referalJourney" || checkingResumeType === "quoteSearch"){
          fromReferral=`user/get-quotes?id=${fetchingIDfromStore}&logkey=${checkingResumeType}`
        }
        else{
          fromReferral=`user/get-quotes?id=${fetchingIDfromStore}`
        }

        axiosRequest
            .get(
                `${fromReferral}`,
                options
            )
            .then((data) => {
                console.log("Final response: of new lead", data.errMsg);
                if (data.errCode === -1) {
                    setLoader(false)
                    setDuration(data?.errMsg?.data[0]?.duration)
                    setQuoteData(data?.errMsg?.data[0])
                    setcheckingMultiYear(data?.errMsg?.data[0]?.isEligibleForMultiyear)
                    setPlanName(data.errMsg.data[0].planName)
                    data.errMsg.data[0]?.premium && setPremium(data.errMsg.data[0].premium)
                    setPlanCode(data.errMsg.data[0].planCode)
                    var finaladdArr = []
                    if (planArr.length > 0 && data.errMsg.data[0].additionalBenefits.length > 0) {
                        // setAddbnft(data.errMsg.data[0].additionalBenefits)
                        console.log(planArr);
                        //  data.errMsg.data[0].additionalBenefits.map(item => {
                        //     return planArr.map(datas => {
                        //         if (item.BenefitCode === datas.BenefitCode) {
                        //             return datas.selectbtn = true
                        //         }
                        //     })
                        // })
                    }
                    // if (finaladdArr.length > 0) {
                    //     console.log(finaladdArr, 'final add arr----->');
                    //     //    setAddPlanArr(finaladdArr)
                    // }

                    // if (data.errMsg.data[0].additionalBenefits.length > 0) {

                    //     var finalValue = 0
                    //     data.errMsg.data[0].additionalBenefits.map(item => {
                    //         finalValue = finalValue + parseInt(item.Premium)
                    //     })
                    //     if (finalValue != 0) {
                    //         console.log(finalValue, 'final calc value-------->');
                    //         setCalculation(finalValue)
                    //     }
                    // }

                }
            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

            });
    }


    const handleCheck = (selectbtn, obj) => {
        console.log(obj, "fghj");
        if (selectbtn === false) {
            console.log('if condition');
            planArr?.map(item => {
                if (item.BenefitCode === obj.BenefitCode) {
                    item.selectbtn = true
                }
            })
            // let selectedarr = addbnft.push(obj)
            let fi = [obj, ...addbnft]
            // console.log(addplanarr, fi, 'if arr====>');
            handleCalc(fi)
            setAddbnft(fi)
        } else {
            console.log('else condition');
            planArr?.map(item => {
                if (item.BenefitCode === obj.BenefitCode) {
                    item.selectbtn = false
                }
            })
            addbnft.map(item => {
                if (item.BenefitCode === obj.BenefitCode) {
                    addbnft.splice(addbnft.indexOf(item), 1)
                }

            })
            handleCalc(addbnft)
            console.log(addbnft, "fghj");

        }
    }
    const handleSubmit = () => {

        const req = {
            additionalBenefits: [
                ...addbnft
            ]
        }

        let options = { secure: true, multipart: false }
        axiosRequest
            .patch(
                `user/update-quote-motor/${fetchingIDfromStore}`,
                req,
                options
            )
            .then((data) => {
                if (data.errCode === -1) {
                    toast.success(`${translate('home')["Quote updated SuccessFully"]}
                    `, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
                    history.push({
                        pathname: 'motorordersummary',
                        // state: {
                        //     data:state?.data,
                        //     quoteId: data.errMsg.data._id,
                        //     cipher: state?.cipher != undefined ? state?.cipher : null,

                        // }
                    })
                    props.setDatas((prevState) => ({
                        ...prevState, additionalBenefits: [...addbnft], totalCost: (calculation + parseInt(premium))
                    }))

                    // props.setWholeProp(prev => return{...prev, ...selectedPlan});
                    // props.next()
                }
            })
            .catch((error) => {
                console.log(
                    "error:2---forgotpass-----> ",
                    typeof error,
                    error.Error,
                    error.message
                );
            });



    }
    const handleCalc = (arr) => {
        console.log(arr, 'else condition cALC');
        if (arr.length > 0) {
            var finalValue = 0
            arr.map(item => {
                finalValue = finalValue + parseInt(item.Premium)
            })
            if (finalValue != 0) {
                console.log(finalValue, 'final calc value-------->');
                setCalculation(finalValue)
            }
        } else {
            setCalculation(0)
        }
    }

    const handleRadioChange = (selectbtn, obj) => {
        if (selectbtn === false) {
            console.log('if condition');
            // Unselect all items with BenefitCode containing "TPL"
            planArr?.forEach(item => {
                if (item.BenefitCode.includes('TP')) {
                    item.selectbtn = false;
                }
            });

            // Select the current item
            planArr?.forEach(item => {
                if (item.BenefitCode === obj.BenefitCode) {
                    item.selectbtn = true;
                }
            });

            // Update the selected item in addbnft
            const updatedAddbnft = addbnft.filter(item => !item.BenefitCode.includes('TP'));
            updatedAddbnft.push(obj);

            handleCalc(updatedAddbnft);
            setAddbnft(updatedAddbnft);
        } else {
            console.log('else condition');
            // Deselect the current item
            planArr?.forEach(item => {
                if (item.BenefitCode === obj.BenefitCode) {
                    item.selectbtn = false;
                }
            });

            // Remove the current item from addbnft
            const updatedAddbnft = addbnft.filter(item => item.BenefitCode !== obj.BenefitCode);

            handleCalc(updatedAddbnft);
            setAddbnft(updatedAddbnft);
        }
    };
    const handleBack = () => {
        history.push({
            pathname: "/motorchooseproduct",
            // state: {
            //     data:state?.data,
            //     quoteId: state.quoteId,
            //     cipher: state?.cipher
            // }

        })
    }
    return (
        <>
                <CustomerHeader/>

            <div className="row-travel">
                <Row>
                    <Col xl={7} md={7} lg={7} sm={24} xs={24}>
                        {width > breakpoint ? (
                            <>
                                <h5 className="heading-content">{translate("travel")["Quick quote"]}</h5>
                                <Card className="card-progress">
                                    <Row>
                                        <Col md={3}>
                                            <Progress className="progress-content"
                                                percent={30} showInfo={false}
                                            />
                                        </Col>
                                        <Col className="col-content" md={10}>
                                            <div className='thick-font'>{translate("travel")["Create Quote"]}</div>
                                            <div className='thick-font'>{translate("travel")["Customer Details"]}</div>
                                            <div className='thick-font'>{translate("travel")["Confirmation"]}</div>
                                        </Col>
                                    </Row>
                                </Card>
                            </>
                        ) : (
                            <>
                                <Card className="card-mobile">
                                    <div className="justify-space">
                                        <div className="mob-header">
                                            {translate("travel")["Quick quote"]}
                                        </div>
                                        <div style={{ cursor: "pointer" }} onClick={sendEmail} className='need-content'>
                                            {translate("onboarding")["Need Help"]} ?
                                        </div>
                                    </div>
                                </Card>
                                <Card className="card-mobile">
                                    <Row>
                                        <Col xs={3}>
                                            <div className="number-mob-selected">
                                                1
                                            </div>
                                        </Col>
                                        <Col xs={14}>
                                            <div className='thick-font'>
                                                {translate("travel")["Create Quote"]}
                                            </div>
                                        </Col>
                                        <Col xs={3}>
                                            <div className="number-mob-non-selected">
                                                2
                                            </div>
                                        </Col>
                                        <Col xs={3}>
                                            <div className="number-mob-non-selected">
                                                3
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </>
                        )}
                    </Col>
                    <Col xl={14} md={14} lg={14} sm={24} xs={24}>
                        <Card>
                            <div className='travel-row-1 back-content'>
                                <div className='arrow-back-font' onClick={handleBack}><ArrowLeftOutlined />{translate("onboarding")["Back"]}</div>
                                <div style={{ cursor: "pointer" }} onClick={sendEmail} className='need-content'>{translate("onboarding")["Need Help"]} ?</div>
                            </div>
                             <Spin tip="Loading" spinning={loader} >
                            <div className="steps-content">
                                <div>
                                    <h3 className='header-card-content'>{translate("travel")["Additional Benefit"]}</h3>
                                    <div><b>{translate("motor")["Add some optional coverage for your vehicle. You can add more protection for your vehicle"]}</b></div>

                                    <Card className='add-benefit-card  back-img-1'>
                                        <div>{translate("motor")["Product"]}</div>
                                        <div className='thick-font'>{planName}</div>
                                        <div className='thick-font'>
                                            {new Intl.NumberFormat('id-ID', {
                                                style: 'currency',
                                                currency: 'IDR',
                                                minimumFractionDigits: 0,
                                            }).format(premium)}
                                        </div>
                                    </Card>

                                    {itemWithoutTp?.map((items, i) => {

                                        return <Card className='add-benefit-card'>
                                            <Row className='benefit-inner'>
                                            <Col md={3} xl={3} lg={3} xs={3} sm={3}>
                                                    <Image src={items.signedUrl} style={{ width: 40, height: 40 }} preview={false} />
                                                </Col>
                                              
                                                {
                                                    (checkingmultiyear && quoteData?.duration > 1) ?(<>

                                                <Col md={18} xl={18} lg={18} xs={18} sm={18}>
                                                {
                                                    items.BenefitCode === "AU01" ? (
                                                        <div className='badgeforFreetag'>
                                                        1 YEAR FREE FOR {durationfromPlan} YEAR PLAN
                                                </div>
                                                    ) : (<></>) 
                                                }
                                                
                                               
                                                    <div>
                                                        {items.BenefitDesc}
                                                    </div>

                                                    <div className='thick-font'> 
                                                        <div style={{display:"flex",justifyContent:"flex-start",alignItems:"center"}}>
                                                            {
                                                                 items.BenefitCode === "AU01" &&          <div style={{marginRight:"0.6em"}}><s>
                                                                 {new Intl.NumberFormat('id-ID', {
                                                                 style: 'currency',
                                                                 currency: 'IDR',
                                                                 minimumFractionDigits: 0,   
                                                             }).format(items.PremiumWithoutDiscount)}
                                                            </s></div>
                                                            }
                                                   <div className={items?.BenefitCode === "AU01"? "applyingdiscountColor"  : "applyingwithoutdiscountColor"}>
                                                        {new Intl.NumberFormat('id-ID', {
                                                        style: 'currency',
                                                        currency: 'IDR',
                                                        minimumFractionDigits: 0,   
                                                    }).format(items.Premium)} 
                                                    </div>
                                                    <div style={{color:"#818F99",fontWeight:"400",marginLeft:"0.4em"}}>{`/ ${durationfromPlan} years`}</div>
                                                  
                                                    </div>
                                                    <div style={{fontWeight:"500"}}>
                                                    { items.BenefitCode === "AU01" && <div> About {new Intl.NumberFormat('id-ID', {
                                                        style: 'currency',
                                                        currency: 'IDR',
                                                        minimumFractionDigits: 0,   
                                                    }).format(items.PremiumPerYear)} per year </div>} 
                                                    </div>
                                                    <div style={{color:"#482C77"}}>
                                                  <Collapse  accordion collapsible="header" className='nestedCollapse'>
                                                  <Panel header={<div className='panel__header'>See Price Detail</div>} >
                                                  <div className="accordian-inner" style={{ display: "flex", flexDirection: "column" }}>
                                                                        {["FirstYrPremium", "SecondYrPremium", "ThirdYrPremium", "FourthYrPremium", "FifthYrPremium"].map((year, index) => {
                                                                            const yearLabel = `Basic Year ${index + 1}`;
                                                                            const yearPremium = items[year];
                                                                            if (yearPremium) {
                                                                                return (
                                                                                    <div key={index} style={{ display: "flex", justifyContent: "space-between" ,padding:"0.2em 0em 0.2em 0em"}}>
                                                                                        <div style={{fontWeight:"500",color:"#4F5B66"}}>{yearLabel}</div>
                                                                                        <div style={{fontWeight:"500",color:"#4F5B66"}}>{new Intl.NumberFormat('id-ID', {
                                                                                            style: 'currency',
                                                                                            currency: 'IDR',
                                                                                            minimumFractionDigits: 0,
                                                                                        }).format(yearPremium || 0)}</div>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                            return null;
                                                                        })}
                                                                    </div>

                                                  </Panel>
                                                  </Collapse>
                                                    </div>
                                                    </div>
                                                    
                                                </Col>
                                                    </>) : (
                                                        <Col md={18} xl={18} lg={18} xs={18} sm={18}>
                                                        <div>
                                                            {items.BenefitDesc}
                                                        </div>
    
                                                        <div className='thick-font'> {new Intl.NumberFormat('id-ID', {
                                                            style: 'currency',
                                                            currency: 'IDR',
                                                            minimumFractionDigits: 0,
                                                        }).format(items.Premium)} </div>
                                                        {/* <div className='view-detail-font'>View Detail <Image style={{ width: 15 }} src={ArrowIcon} preview={false} /></div> */}
                                                    </Col>
                                                    )
                                                }
                                              
                                                <Col md={3} xl={3} lg={3} xs={3} sm={3}>
                                                    <Checkbox onChange={() => handleCheck(items.selectbtn, items)} checked={items.selectbtn === true ? true : false} defaultChecked={items.selectbtn === true ? true : false} />
                                                </Col>
                                            </Row>
                                        </Card>
                                    })

                                    }

                                    {/* {
                                        checkingmultiyear ? (<>
                                        {
                                            itemWithoutTp?.map((benefit, j)=>(
                                                <Collapse key={j} accordion collapsible="header" className='nestedCollapse'>
                                                          <Collapse key={j} accordion collapsible="header" className='nestedCollapse'>
                                                                <Panel
                                                                    header=<div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                    <div style={{fontWeight:"600",color:"#4F5B66"}}>{benefit.BenefitDesc}</div>
                                                                    <div style={{fontWeight:"600",color:"#4F5B66"}}>{new Intl.NumberFormat('id-ID', {
                                                                        style: 'currency',
                                                                        currency: 'IDR',
                                                                        minimumFractionDigits: 0,
                                                                    }).format(benefit.Premium || 0)}</div>
                                                                </div>
                                                                    key={j}
                                                                >
                                                                    <div className="accordian-inner" style={{ display: "flex", flexDirection: "column" }}>
                                                                        {["FirstYrPremium", "SecondYrPremium", "ThirdYrPremium", "FourthYrPremium", "FifthYrPremium"].map((year, index) => {
                                                                            const yearLabel = `Year ${index + 1}`;
                                                                            const yearPremium = benefit[year];
                                                                            if (yearPremium) {
                                                                                return (
                                                                                    <div key={index} style={{ display: "flex", justifyContent: "space-between" ,padding:"0.2em 0em 0.2em 0em"}}>
                                                                                        <div style={{fontWeight:"500",color:"#4F5B66"}}>{yearLabel}</div>
                                                                                        <div style={{fontWeight:"500",color:"#4F5B66"}}>{new Intl.NumberFormat('id-ID', {
                                                                                            style: 'currency',
                                                                                            currency: 'IDR',
                                                                                            minimumFractionDigits: 0,
                                                                                        }).format(yearPremium || 0)}</div>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                            return null;
                                                                        })}
                                                                    </div>
                                                                </Panel>
                                                            </Collapse>

                                                </Collapse>
                                            ))
                                        }
                                        </>):(<></>)
                                    } */}


                                                      
                                                    
                                  
                                  

                                    <Radio.Group style={{ width: "100%" }}>
                                        {/* {itemWithTp.map((items, i) => (
                                            <Card className='add-benefit-card' key={items.BenefitCode}>
                                                <Row className='benefit-inner'>
                                                    <Col md={3} xl={3} lg={3} xs={3} sm={3}>
                                                        <Image src={items.signedUrl} style={{ width: 40, height: 40 }} preview={false} />
                                                    </Col>
                                                    <Col md={18} xl={18} lg={18} xs={18} sm={18}>
                                                        <div>
                                                            {items.BenefitDesc}
                                                        </div>
                                                        <div className='thick-font'>
                                                            <div style={{display:"flex"}}>
                                                            {new Intl.NumberFormat('id-ID', {
                                                                style: 'currency',
                                                                currency: 'IDR',
                                                                minimumFractionDigits: 0,
                                                            }).format(items.Premium)} <div style={{marginRight:"0.2em",fontWeight:"400",marginLeft:"0.4em"}}>/ year</div>
                                                        </div>
                                                        </div>
                                                    </Col>
                                                    <Col md={3} xl={3} lg={3} xs={3} sm={3}>
                                                        <Checkbox
                                                            value={items.BenefitCode}
                                                            onChange={() => handleRadioChange(items.selectbtn, items)}
                                                            checked={items.selectbtn === true ? true : false}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Card>
                                        ))} */}
                                          {itemWithTp?.map((items, i) => {

return <Card className='add-benefit-card'>
    <Row className='benefit-inner'>
        <Col md={3} xl={3} lg={3} xs={3} sm={3}>
            <Image src={items.signedUrl} style={{ width: 40, height: 40 }} preview={false} />
        </Col>
        
        {
            checkingmultiyear ? (<> <Col md={18} xl={18} lg={18} xs={18} sm={18}>
                {
                    items.BenefitCode === "AU01" ? (
                        <div className='badgeforFreetag'>
                        1 YEAR FREE FOR {durationfromPlan} YEAR PLAN
                </div>
                    ) : (<></>) 
                }
                
               
                    <div>
                        {items.BenefitDesc}
                    </div>
        
                    <div className='thick-font'> 
                        <div style={{display:"flex"}}>
                        {new Intl.NumberFormat('id-ID', {
                        style: 'currency',
                        currency: 'IDR',
                        minimumFractionDigits: 0,   
                    }).format(items.Premium)}   <div style={{color:"#818F99",fontWeight:"400",marginLeft:"0.4em"}}>{`/ ${durationfromPlan} years`}</div>
                   
                    </div>
                    <div style={{color:"#482C77"}}>
                  <Collapse  accordion collapsible="header" className='nestedCollapse'>
                  <Panel header={<div className='panel__header'>See Price Detail</div>} >
                  <div className="accordian-inner" style={{ display: "flex", flexDirection: "column" }}>
                                        {["FirstYrPremium", "SecondYrPremium", "ThirdYrPremium", "FourthYrPremium", "FifthYrPremium"].map((year, index) => {
                                            const yearLabel = `Year ${index + 1}`;
                                            const yearPremium = items[year];
                                            if (yearPremium) {
                                                return (
                                                    <div key={index} style={{ display: "flex", justifyContent: "space-between" ,padding:"0.2em 0em 0.2em 0em"}}>
                                                        <div style={{fontWeight:"500",color:"#4F5B66"}}>{yearLabel}</div>
                                                        <div style={{fontWeight:"500",color:"#4F5B66"}}>{new Intl.NumberFormat('id-ID', {
                                                            style: 'currency',
                                                            currency: 'IDR',
                                                            minimumFractionDigits: 0,
                                                        }).format(yearPremium || 0)}</div>
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })}
                                    </div>
        
                  </Panel>
                  </Collapse>
                    </div>
                    </div>
                    
                </Col>
                
            </>) : (
                <Col md={18} xl={18} lg={18} xs={18} sm={18}>
                <div>
                    {items.BenefitDesc}
                </div>

                <div className='thick-font'> {new Intl.NumberFormat('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                    minimumFractionDigits: 0,
                }).format(items.Premium)} </div>
                {/* <div className='view-detail-font'>View Detail <Image style={{ width: 15 }} src={ArrowIcon} preview={false} /></div> */}
            </Col>
            )
            
        }
        <Col md={3} xl={3} lg={3} xs={3} sm={3}>
        <Checkbox
                                                            value={items.BenefitCode}
                                                            onChange={() => handleRadioChange(items.selectbtn, items)}
                                                            checked={items.selectbtn === true ? true : false}
                                                        />
                </Col>
       
    </Row>
</Card>
})

}


                                    </Radio.Group>

                                    <Row>
                                        <Col xl={20} xs={18} sm={20} lg={20} md={20}>
                                            <div style={{ fontSize: 10 }}>
                                                {translate("home")["Subtotal"]}
                                            </div>
                                            <div style={{ fontSize: 18 }}>
                                                <b>
                                                    {new Intl.NumberFormat('id-ID', {
                                                        style: 'currency',
                                                        currency: 'IDR',
                                                        minimumFractionDigits: 0,
                                                    }).format(calculation + parseInt(premium))}
                                                </b>
                                            </div>
                                        </Col>
                                        <Col md={4} xl={4} lg={4} xs={6} sm={4} onClick={() => setIsModalOpen(true)}>
                                            <div style={{ inlineSize: "max-content", cursor: "pointer" }} className='view-detail-font'>{translate("motor")["View Detail"]}<Image style={{ width: 15 }} src={ArrowIcon} preview={false} /></div>
                                        </Col>


                                        <Button className='next-button-travel' onClick={handleSubmit}>
                                            {translate("travel")["Add to Cart"]}
                                        </Button>
                                    </Row>
                                    <Modal closable={false} visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                                        <h5>{translate("motor")["Price Detail"]}</h5>
                                        <br />
                                        <p className='thick-font'>{translate("motor")["Product Package"]}</p>

{
    quoteData?.isEligibleForMultiyear ? (<>
     <Singlepanel planDetails={quoteData?.planDetails} quoteData={quoteData}/>
    </>) : (   <div className='accordian-inner'>
                                            <div>
                                                {planName}
                                            </div>
                                            <div>
                                                {new Intl.NumberFormat('id-ID', {
                                                    style: 'currency',
                                                    currency: 'IDR',
                                                    minimumFractionDigits: 0,
                                                }).format(premium)}
                                            </div>
                                        </div> )
}
                                      
                                      
                                        <br />
                                        <div className='thick-font'>{translate("motor")["Additional Coverage(s)"]}</div>
                                        <br />
                                        {
                                            quoteData?.isEligibleForMultiyear ? (<>
                                            <MultiplePanel additionalbenefitsData={addbnft} />
                                            </>
                                            ) : (addbnft?.map((benifit, i) => (
                                            <div className='accordian-inner'>
                                                <div style={{ padding: "0% 20% 2% 0%" }}>
                                                    {
                                                        benifit?.BenefitDesc
                                                    }
                                                </div>
                                                <div>
                                                    {new Intl.NumberFormat('id-ID', {
                                                        style: 'currency',
                                                        currency: 'IDR',
                                                        minimumFractionDigits: 0,
                                                    }).format(benifit.Premium)}</div>
                                            </div>
                                        )))
                                        }
                                        

                                        <hr />
                                        <div className='accordian-inner'>
                                            <div style={{ color: "#482c77", fontWeight: 600 }}>
                                                {translate("motor")["Total"]}
                                            </div>
                                        
                                        
                                            <div className='thick-font' style={{ fontSize: 18, fontWeight: "bold" }}>
                                                {new Intl.NumberFormat('id-ID', {
                                                    style: 'currency',
                                                    currency: 'IDR',
                                                    minimumFractionDigits: 0,
                                                }).format(calculation + parseInt(premium))}
                                            </div>
                                        </div>
                                        <br />
                                        <Button className='next-button-travel' onClick={() => setIsModalOpen(false)}>Ok</Button>
                                    </Modal>
                                </div >
                            </div>
                            </Spin>

                        </Card>
                    </Col>
                </Row>
            </div >
        </>)
}

export default Index