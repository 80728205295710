import React, { useEffect, useState } from 'react'
import { Switch, Space } from 'antd';
import Cookies from 'js-cookie';
import { useLanguage } from './LanguageContext';
import { ConsoleSqlOutlined } from '@ant-design/icons';


const LangSwitch = () => {
    const { switchLanguage, language } = useLanguage();

    // const [langSwitch,setlangSwitch]=useState(language)
    // console.log(langSwitch,"langSwitch")
    console.log(language, "language changed")
    const [settingLangswitch,setsettinglangswitch]=useState("")
console.log(settingLangswitch,"settingLangswitch===>")
    const handleLanguageToggle = () => {
        if (language === 'es') {
            switchLanguage('en'); 
             Cookies.set('LANG', 'en', { domain: process.env.REACT_APP_COOKIE_URL });
           
        } else {
            switchLanguage('es');
             Cookies.set('LANG', 'id', { domain: process.env.REACT_APP_COOKIE_URL });
           
        }
    };

    const getLanguageCookie = () => 
        {
            const geettingcookie=Cookies.get('LANG')
            return geettingcookie === "en" ? "en" : "es"
        }
        useEffect(()=>{
           
                let settingLangswitches=["es","en"]
                const fetchingremaininglangkey=settingLangswitches.filter((item)=>{
                    return item !== language
                })
                console.log(fetchingremaininglangkey,"fetchingremaininglangkey")
                setsettinglangswitch(fetchingremaininglangkey[0])
            

        },[language])


        const fetchinglangugaekey=()=>{

            let settingLangswitches=["es","en"]
            const fetchingremaininglangkey=settingLangswitches.filter((item)=>{
                return item !== language
            })
            console.log(fetchingremaininglangkey,"fetchingremaininglangkey")
           if(fetchingremaininglangkey[0] === "en"){
            return "IDN"
           }
           else{
            return "ENG"
           }

        }

       
      
        
        console.log(getLanguageCookie(),"bgdfyv")

    return (
        <Switch checkedChildren={language === "en" ? "ENG" : "IDN"} unCheckedChildren={fetchinglangugaekey()} defaultChecked={language}
        onChange={handleLanguageToggle} />
    )
}

export default LangSwitch