import React, { useState, useEffect } from 'react'
import Footer from '../components/Footer/Footer'
import { Row, Col, Button, Badge, Pagination, Spin } from 'antd'
import { ArrowLeftOutlined, } from "@ant-design/icons"
import { useHistory } from "react-router-dom"
import rootIndex from "../store/root_index";
import { toast } from 'react-toastify';
import { useLanguage } from "../../src/config/LanguageContext"
import axiosRequest from "../axios-request/request.methods";
import { useDispatch, useSelector } from 'react-redux'
import * as action from "../store/actions/index"



const { store } = rootIndex;

function OonaNotification() {
    const _store = store.getState();
    const fetchingWholeDatafromStore=useSelector((state)=>state?.cipherlinkreducer?.quote)
    const dispatch=useDispatch()
    const [width, setWidth] = useState(window.innerWidth);
    const [indexp, setIndex] = useState(1)
    const [total, setTotal] = useState(50)
    const [page, setPage] = useState(10)
    const [arr, setArr] = useState([])
    const [status, setStatus] = useState('')
    const [loading, setLoading] = useState(false)

    const history = useHistory();

    const breakpoint = 620;

    const pageChange = (i, num) => {
        console.log(i, num, 'on chanhge page ======.');
        setIndex(i)
        setPage(num)
        getNotification(i, num)
    }

    useEffect(() => {
        getNotification(indexp, page)
        console.log(_store, 'store----->');
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);

        return () => window.removeEventListener("resize", handleWindowResize);

    }, [width]);

    const getQuotes = (id, body) => {
        console.log(id,"TESTING WITH ID")
        console.log(body,"body with BODY")
        console.log('enter get quotes-------->');



        // var axiosConfig = {
            //     headers: {
                //         "Content-Type": "application/json",
                //         'Authorization': 'Bearer ' + _store.login.token
            //     },
        // };


        axiosRequest
            .get(
                `user/get-quotes?id=${id}`
            )
            .then((data) => {
                //console.log("Final response: of get quote lead", data.data.errMsg.data);

                if (data.errCode === -1) {
                    // getLink(id, data.data.errMsg.data[0].product_Id)
                    setStatus(data.errMsg.data[0].stage)
                    console.log(data.errMsg,"hgujgv")

                    if(data?.errMsg?.data[0]?.stage === 'Policy Generated'){
                        let Data={
                            ...fetchingWholeDatafromStore,
                            quoteId: id
                        }
                        dispatch(action.set_quote_id(Data))
                    history.push({
                        pathname: "/oonasuccess1",
                        // state: {
                        //     quoteId: id
                        // }
                    })
                }
                
                
                else{
                    console.log('mkmkmkmkmm=======>');
                    if (body.includes('pending payment')) {
                        let Data={
                            ...fetchingWholeDatafromStore,
                            quoteId: id
                        }
                        dispatch(action.set_quote_id(Data))
                        history.push({
                            pathname: '/notificationpaymentpending',
                            // state: {
                            //     quoteId: id,

                            // }
                        })
                    }
                }
            }
            })
    }

    const getNotification = (i, num) => {
        setLoading(true)
        var apiConfig = `notification/get-user-notification?perPage=${num}&pageNo=${i}`


        axiosRequest
            .get(
                apiConfig
            )
            .then((data) => {
                // console.log("Final response: of new lead", data.errMsg.result_valid_mobile[0]);
                if (data.errCode === -1) {
                    console.log(data.errMsg, 'Notification API Response----->');
                    setTotal(data.errMsg.data.total)
                    setArr(data.errMsg.data.data)
                    setLoading(false)
                    //   let arr = data.data.errMsg
                    //   setData(data.data.errMsg)
                    // setMyPerfArr(arr)

                    // if(myperfarr.length == 0){
                    //     setMyPerfArr(data.data.errMsg)
                    // }else{
                    //     setMyPerfArr([...data.data.errMsg])
                    // }


                }
            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

            });
    }

    const handleAll = () => {
        const extractedData = arr.map(item => ({
            _id: item._id,
            user_id: item.user_id,
            readStatus: item.readStatus
        }));
        // arr.map((data,i)=>{
        //     console.log(i, index, 'index----->');

        //        return data.readStatus = 1

        // })
        // setArr([...arr])
        // let axiosConfig = {
            //     headers: {
                //         "Content-Type": "application/json",
                //         'Authorization': 'Bearer ' + _store.login.token
            //     },
        // };


        var apiConfig = `notification/update-user-notification`

        axiosRequest
            .put(
                apiConfig,
                extractedData
            )
            .then((data) => {
                // console.log("Final response: of new lead", data.errMsg.result_valid_mobile[0]);
                if (data.errCode === -1) {
                    console.log(data.errMsg, 'Notification update API Response----->');
                    toast.success("Notification marked as read", { position: "top-center", hideProgressBar: true, autoClose: 1000 })
                    console.log(indexp, page, 'jhj-------->');
                    getNotification(indexp, page)
                    //   let arr = data.data.errMsg
                    //   setData(data.data.errMsg)
                    // setMyPerfArr(arr)

                    // if(myperfarr.length == 0){
                    //     setMyPerfArr(data.data.errMsg)
                    // }else{
                    //     setMyPerfArr([...data.data.errMsg])
                    // }


                }
            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

            });
    }

    const handleMark = (item, index) => {
        console.log('ss', item, index);
        if (item.readStatus === 0) {
            console.log('if psart notification----->');
            const newObj = [{
                "_id": item._id,
                "user_id": item.user_id,
                "readStatus": item.readStatus
            }]

            var apiConfig = `notification/update-user-notification`

            axiosRequest
                .put(
                    apiConfig,
                    newObj
                )
                .then((data) => {
                    if (data.errCode === -1) {
                        console.log(data.errMsg, 'Notification update API Response----->');
                        toast.success("Notification marked as read", { position: "top-center", hideProgressBar: true, autoClose: 1000 })
                        getNotification(indexp, page)
                        getQuotes(item.quoteId, item.body)
                  
                    }
                }).catch((error) => {
                    console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

                });
        } else {
            console.log('else psart notification----->');
            getQuotes(item.quoteId, item.body)
            
        }
       
    }

    const { translate } = useLanguage()
    return (
        <>
            <Spin tip="Loading" spinning={loading} >
                <div className='main-notify' style={{ marginTop: 20, padding: width > breakpoint ? '0% 20%' : '0% 5%' }}>
                    {width > breakpoint ? (
                        <>
                            <Row className='notify-header'>
                                <Col md={20} xl={20} lg={20} xs={20} sm={20}>
                                    <Row onClick={() => history.push("/oonahome")}>
                                        <ArrowLeftOutlined width={20} color='#482c77' className='arrow-left' />
                                        <div><h2 className='back-btn'>{translate("onboarding")["Back"]}</h2></div>
                                    </Row>
                                </Col>
                                <Col md={4} xl={4} lg={4} xs={4} sm={4}>
                                    <Button className='btn-read'>
                                        <h2 onClick={() => handleAll()} style={{ fontSize: 12, fontWeight: 'bold', color: '#482c77' }}>
                                        {translate("commercial sales")["markAllAsRead"]}</h2>
                                    </Button>
                                </Col>
                            </Row>

                            <Row>
                                <div >
                                    <h2 className='page-heading'>{translate("commercial sales")["notification"]}</h2>
                                </div>
                            </Row>


                        </>
                    ) : <> <Row>
                        <Col md={23} xl={23} lg={23} xs={2} sm={2}>
                            <ArrowLeftOutlined width={20} color='#482c77' className='arrow-left' style={{ marginTop: 15 }} />
                        </Col>
                        <Col md={16} xl={16} lg={16} xs={16} sm={16}>
                            <div >
                                <h2 className='page-heading' style={{ fontSize: 18 }}>{translate("commercial sales")["notification"]}</h2>
                            </div>
                        </Col>
                        <Col md={6} xl={6} lg={6} xs={6} sm={6}>
                            <Button className='btn-read' style={{ marginTop: 10 }}>
                                <h2 onClick={() => handleAll()} style={{ fontSize: 10, fontWeight: 'bold', color: '#482c77', }}>
                                {translate("commercial sales")["markAllAsRead"]}</h2>
                            </Button>
                        </Col>
                    </Row></>}

                    {arr.map((item, index) => {
                        return (
                            <Row className='notify-card' style={{ marginTop: 10 }} onClick={() => handleMark(item, index)}>
                                <Col md={23} xl={23} lg={23} xs={22} sm={22}>
                                    <div>
                                        <h5 className='notify-heading' style={{ fontWeight: item.readStatus === 0 ? 'bold' : 'normal', fontSize: 14 }}>{item.body}</h5>
                                    </div>
                                    <div >
                                        <h2 className='notify-desc'> {translate("commercial sales")["You received commission"]}!</h2>
                                    </div>
                                    <div className='notify-days'>
                                        {/* {moment(item?.created_date).format("DD MMM YYYY")}  */}
                                        {item.daysCompleted === 0 ? 'Today' : `${item.daysCompleted} day ago`}
                                    </div>
                                </Col>
                                <Col md={1} xl={1} lg={1} xs={2} sm={2}>
                                    {item.readStatus === 0 ? <Badge color='#f68b1f' style={{ float: 'right', }} /> : null}
                                </Col>
                            </Row>
                        )
                    })}


                    {/* <Row className='notify-card' style={{ marginTop: 10 }}>
                    <Col md={23} xl={23} lg={23} xs={22} sm={22}>
                        <div>
                            <h5 className='notify-heading-bold'>CPTL Sale Completed</h5>
                        </div>
                        <div >
                            <h2 className='notify-desc'>You received a commission!</h2>
                        </div>
                        <div className='notify-days'>
                            5 day ago
                        </div>
                    </Col>
                    <Col md={1} xl={1} lg={1} xs={2} sm={2}>
                        <Badge color='#f68b1f' style={{ float: 'right', }} />
                    </Col>
                </Row>

                <Row className='notify-card'>
                    <Col md={23} xl={23} lg={23} xs={22} sm={22}>
                        <div >
                            <h6 className='notify-heading-bold'>Quick Quote #999XXXXXXX has been sent</h6>
                        </div>
                        <div >
                            <h2 className='notify-desc'>You received a commission!</h2>
                        </div>
                        <div className='notify-days'>
                            10 day ago
                        </div>
                    </Col>
                    <Col md={1} xl={1} lg={1} xs={2} sm={2}>
                        <Badge color='#f68b1f' style={{ float: 'right', }} />
                    </Col>
                </Row>

                <Row className='notify-card'>
                    <Col md={23} xl={23} lg={23} xs={22} sm={22}>
                        <div >
                            <h6 className='notify-heading-bold'>CPTL Sale Completed</h6>
                        </div>
                        <div >
                            <h2 className='notify-desc'>You received a commission!</h2>
                        </div>
                        <div className='notify-days'>
                            5 day ago
                        </div>
                    </Col>
                    <Col md={1} xl={1} lg={1} xs={2} sm={2}>
                        <Badge color='#f68b1f' style={{ float: 'right', }} />
                    </Col>
                </Row> */}

                    {/* <Row className='notify-card'>
                    <Col md={23} xl={23} lg={23} xs={22} sm={22}>
                        <div className='notify-heading-normal'>
                            Formal Quote #999XXXXXX has been sent
                        </div>
                        <div >
                            <h2 className='notify-desc'>You received a commission!</h2>
                        </div>
                        <div className='notify-days'>
                            21 day ago
                        </div>
                    </Col>
                    <Col md={1} xl={1} lg={1} xs={2} sm={2}>
                    </Col>
                </Row>

                <Row className='notify-card'>
                    <Col md={23} xl={23} lg={23} xs={22} sm={22}>
                        <div className='notify-heading-normal'>
                            Formal Quote #999XXXXXX has been sent
                        </div>
                        <div>
                            <h2 className='notify-desc'>You received a commission!</h2>
                        </div>
                        <div className='notify-days'>
                            21 day ago
                        </div>
                    </Col>
                    <Col md={1} xl={1} lg={1} xs={2} sm={2}>
                    </Col>
                </Row> */}

                    <Pagination itemActiveBg='red' style={{ marginBottom: 20, justifyContent: 'center', display: 'flex', }} defaultCurrent={indexp} total={total} onChange={(i, pageNum) => pageChange(i, pageNum, 'index------>')} />

                </div>
                <Footer />
            </Spin>
        </>
    )
}

export default OonaNotification