import React, { useState, useRef, useEffect } from 'react';
import { Button, Modal, Form, Input, Row, Col, Card, Drawer, Image } from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import { useLocation } from 'react-router-dom/cjs/react-router-dom'
import { Base_URL } from '../config/BaseUrl'
import rootIndex from "../store/root_index";
import { useHistory } from "react-router-dom";
import { Header } from 'antd/lib/layout/layout';
import oonaLogo from "../images/oona-logo@2x.png"
import { useLanguage } from "../../src/config/LanguageContext"
import LangSwitch from '../config/LangSwitch';
import { useDispatch } from 'react-redux'
import * as action from '../store/actions/index'
import axiosRequest from '../axios-request/request.methods'

const { store } = rootIndex;

function HomeOtp(props) {

  const location = useLocation();

  const _store = store.getState();
  const history = useHistory();
  const [form] = Form.useForm();
  const [phone, setPhone] = useState("");
  const [otp1, setOTP1] = useState("");
  const [otp2, setOTP2] = useState("");
  const [otp3, setOTP3] = useState("");
  const [otp4, setOTP4] = useState("");
  const [otp5, setOTP5] = useState("");
  const [otp6, setOTP6] = useState("");
  const [key, setKey] = useState('')
  const [phoneErr, setPhoneErr] = useState(false)
  const [phonnumberUsed, setphonnumberUsed] = useState(false);
  const [mobilenumberreq, setmobilenumberreq] = useState(false);
  const [otpInvaludres, setotpInvaludres] = useState(false)
  const [otpreqres, setotpreqres] = useState(false)
  const [isModalPropertyOpen, setIsModalPropertyOpen] = useState(false);

  const inputOTP2 = useRef(null);
  const inputOTP3 = useRef(null);
  const inputOTP4 = useRef(null);
  const inputOTP5 = useRef(null);
  const inputOTP6 = useRef(null);
  const dispatch = useDispatch()
  // method for the otp
  const handleCancel1 = () => {
    setOTP1("")
    setOTP2("")
    setOTP3("")
    setOTP4("")
  };

  const propertyClose = () => {
    setIsModalPropertyOpen(false);

  };

  const submitOtp = () => {
  }
  const handleOTP1changeHandler = (e) => {
    setOTP1(e.target.value);
    if (e.target.attributes.maxlength.value == 1) {
      inputOTP2.current.focus();
    }
  };

  const { translate, language } = useLanguage()

  const handleOTP2changeHandler = (e) => {
    setOTP2(e.target.value);
    if (e.target.attributes.maxlength.value == 1) {
      inputOTP3.current.focus();
    }
  };

  const handleOTP3changeHandler = (e) => {
    setOTP3(e.target.value);
    if (e.target.attributes.maxlength.value == 1) {
      inputOTP4.current.focus();
    }
  };
  const handleOTP4changeHandler = (e) => {
    setOTP4(e.target.value);
    if (e.target.attributes.maxlength.value == 1) {
      inputOTP5.current.focus();
    }
  };
  const handleOTP5changeHandler = (e) => {
    setOTP5(e.target.value);
    if (e.target.attributes.maxlength.value == 1) {
      inputOTP6.current.focus();
    }
  };

  const handleOTP6changeHandler = (e) => {
    setOTP6(e.target.value);

  };

  const handleModalPropoertyOpen = () => {
    setIsModalPropertyOpen(true)
  }


  const verifyOTP = () => {

    if (otp1 === "" || otp2 === "" || otp3 === "" || otp4 === "" || otp5 === "" || otp6 === "") {
      toast.error(`${translate("home")["Otp is required"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
    }
    else {

      let allOTP = "";
      let allOTPIntegration = allOTP.concat(otp1, otp2, otp3, otp4, otp5, otp6);

      let formData = {
        key: key,
        otp: parseInt(allOTPIntegration)
      }

      console.log(props, "props====>")
      let options = { secure: false, multipart: false }
      axiosRequest
        .post(
          `auth/verifyOtp?langId=${language === "en" ? "EN" : "BH"}`,
          formData,
          options
        )
        .then((data) => {
          if(data?.errCode === -1){
          console.log("forgot passs  : ", data.errMsg.key,);
          // console.log("Final response: of new lead", data.errMsg.result_valid_mobile[0]);
          
            setotpInvaludres(false)
            handleModalPropoertyOpen()
            setotpreqres(false)
            // history.push({
            //   pathname: '/oonahomescreen',
            //   state: {
            //     phonenumber: phone,
            //     cipher: location?.state?.cipher
            //   },
            // })
            toast.success(data.errMsg?.message, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
          }
        })
        .catch((error) => {
          console.log(error?.response,"sbjgb")
          if (error?.response) {

            if (error?.response?.data?.errMsg === "otp is required") {
              setotpreqres(true)
            }
            else if (error?.response?.data?.errMsg === "OTP is invalid") {
              setotpInvaludres(true)
            }
            else {
              toast.error(error?.response?.data?.errMsg, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
            }

          }

        });

    }



  }


  const handleVerify = () => {
    if (otp1 === "" || otp2 === "" || otp3 === "" || (otp4 === "") === "") {
      toast.error("Enter OTP", { position: "top-center", hideProgressBar: true, autoClose: 1000 })
      return;
    } else {
      form.resetFields();
    }
  };



  const sendOtp = (values) => {
    console.log(values, "values")

   
console.log(phone.length,"phone Incs")
    let formData = { mobileNo: phone };
    let options = { secure: false, multipart: false }
if( phone.length > 9){
    axiosRequest
      .post(`auth/sendOtp?langId=${language === "en" ? "EN" : "BH"}&isCustomerFlow=true`, formData, options)
      .then((data) => {
        if(data?.errCode === -1){
          setphonnumberUsed(false);
          setmobilenumberreq(false);
          setSeeContent(false)
          setKey(data?.errMsg?.key)
          toast.success(data.errMsg.message, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
        }
      })
      .catch((error) => {
        setSeeContent(true)
        if (error?.response) {
          console.log(error?.response ,"geetha");

          if (
            error?.response?.data?.errMsg ===
            "Agent is already exist on given mobile number"
          ) {
            setphonnumberUsed(true);
          } else if (
            error?.response?.data?.errMsg === "mobile number is required"
          ) {
            setmobilenumberreq(true);
          } else {
            toast.error(error?.response?.data?.errMsg, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
          }
        }
      });
    }
    else{
      toast.error(`${translate("home")["Please lengthen this text to 9 characters or more"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
    }

    console.log(phone, "phone num----->");
  };


  const [seeContent, setSeeContent] = useState(true)
  const [width, setWidth] = useState(window.innerWidth);

  const breakpoint = 620;
  useEffect(() => {
    console.log(_store.login.userType, "usertype------->");

    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [width]);
  return (
    <>

      {_store?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
        <Header className="header-login" >
          <div className="oona__customer__flow newWithOUtLInk">
            <Image preview={false} className="oona-logo" src={oonaLogo}></Image>
            <LangSwitch />
          </div>

        </Header> : null}

      <div className='oona-travel-otp'>




        {width > breakpoint ? (
          <>
            <Modal
              style={{ top: "25%" }}
              closable={false}
              visible={isModalPropertyOpen}
              onOk={() => setIsModalPropertyOpen(false)}
              onCancel={() => setIsModalPropertyOpen(false)}
            >
              <div>
                <div className="heading-modal-content">
                  <h5 style={{ color: "#482c77", fontSize: 25, fontWeight: "bolder" }} className="thick-font property-font">
                    {translate("home")["Property Requirement"]}
                  </h5>
                </div>
                <br />
                <div style={{ marginBottom: 5 }}>
                  {translate("home")["Please make sure that the property that will be insured fulfills the requirements below"]}
                </div>

                <div>
                  {translate("home")["1. Risk insured is dwelling house or apartment only"]}
                </div>
                <div>{translate("home")["2. Maximum Total Sum Insured is Rp10B"]}</div>
                <div>
                  {translate("home")["3. First-class construction (need to be in layman’s term)"]}
                </div>
                <div>{translate("home")["4. Nil loss in the past 5 years"]}</div>
                <div>
                  {translate("home")["5. Road in front of the risk insured must be able to fit 2 vehicles (side by side) at minimum"]}
                </div>
                <div>
                  {translate("home")["6. Road in front of the risk insured must be able to fit 2 vehicles (side by side) at minimum"]}
                </div>
              </div>

              <Row className="row-modal">
                <Col md={12} xs={12} sm={12} lg={12} xl={12}>
                  <Button onClick={propertyClose} className="no-button">
                    {translate("onboarding")["Back"]}
                  </Button>
                </Col>
                <Col md={12} xs={12} sm={12} lg={12} xl={12}>
                  <Button
                    className="next-button-travel"
                    //   onClick={() => 
                    //     history.push({
                    //     pathname: '/oonahomescreen',
                    //     state: {
                    //       phonenumber: phone,
                    //       cipher: location?.state?.cipher
                    //     },
                    //   })
                    // }


                    onClick={() => {
                      dispatch(action.set_quote_id({
                        quoteId: "",
                        isHome: false
                      }));
                      history.push({
                        pathname: '/oonahomescreen',
                        state: {
                          phonenumber: phone,
                          //cipher: location?.state?.cipher
                        },
                      })
                    }
                    }
                  >


                    {translate("commercial sales")["confirm"]}
                  </Button>
                </Col>
              </Row>
            </Modal>
          </>
        ) : (
          <>
            <Drawer
              placement="bottom"
              className="property-drawer"
              closable={false}
              visible={isModalPropertyOpen}
            >
              <div>
                <div className="heading-modal-content">
                  <h5 style={{ color: "#482c77", fontSize: 25, fontWeight: "bolder" }} className="thick-font property-font">
                    {translate("home")["Property Requirement"]}
                  </h5>
                </div>
                <br />
                <div style={{ marginBottom: 5 }}>
                  {translate("home")["Please make sure that the property that will be insured fulfill the requirement below"]}
                </div>

                <div>
                  {translate("home")["1. Risk insured is dwelling house or apartment only"]}
                </div>
                <div>{translate("home")["2. Maximum Total Sum Insured is Rp10B"]}</div>
                <div>
                  {translate("home")["3. First-class construction (need to be in layman’s term)"]}
                </div>
                <div>{translate("home")["4. Nil loss in the past 5 years"]}</div>
                <div>
                  {translate("home")["5. Road in front of the risk insured must be able to fit 2 vehicles (side by side) at minimum"]}
                </div>
                <div>
                  {translate("home")["6. Road in front of the risk insured must be able to fit 2 vehicles (side by side) at minimum"]}
                </div>
              </div>

              <Row className="row-modal">
                <Col md={12} xs={12} sm={12} lg={12} xl={12}>
                  <Button className="no-button" onClick={propertyClose}>{translate("onboarding")["Back"]}</Button>
                </Col>
                <Col md={12} xs={12} sm={12} lg={12} xl={12}>
                  <Button
                    className="next-button-travel"
                    onClick={() => {
                      dispatch(action.set_quote_id({
                        quoteId: "",
                        isHome: false
                      }));
                      history.push({
                        pathname: '/oonahomescreen',
                        state: {
                          phonenumber: phone,
                          //cipher: location?.state?.cipher
                        },
                      })

                    }

                    }
                  >
                    {translate("commercial sales")["confirm"]}
                  </Button>
                </Col>
              </Row>
            </Drawer>
          </>
        )}


        <Card>
          {seeContent === true ?
            <>
              <div style={{ textAlign: "center" }}>
                <h5 style={{ marginBottom: 5, color: "#482c77" }}> {translate("onboarding")["You’ve received an invite to fill form from"]} {location?.state?.fullName} {translate("onboarding")["Untuk Mengisi Form Asuransi"]}</h5>
                <div style={{ margin: "2% 18%" }}>{translate("onboarding")["Enter your phone number to issue policy! You will receive an One-Time Password (OTP) for confirmation"]}</div>

                <Form layout='vertical' form={form} onFinish={sendOtp}>
                  <Form.Item
                    label={translate("onboarding")["Phone number"]}
                    validateStatus={phoneErr && 'error'}
                    help={phoneErr && `${translate("home")["Only Numbers are Allowed"]}`}
                    rules={[
                      {
                        required: true,
                        message: "phonenumber is required"
                      },
                      {
                        message: `${translate("home")["Only Number is Allowed"]}`,
                        pattern: '^[0-9]+$',

                      },

                      // {
                      //   min: 9, max: 13, message: "Please enter valid 11 to 13 digits mobile number"
                      // },
                      {
                        validator: (rule, value) => {
                          if (!/^[0-9]+$/.test(value)) {
                            return Promise.reject(`${translate("home")["Only Number is Allowed"]}`);
                          }
                          return Promise.resolve();
                        },
                      },

                    ]}
                  >
                    <Input  maxLength={13}
                      placeholder={translate("onboarding")["Enter your phone numberPlace"]}
                     
                      className="CreatingAgentAccount__phoneNumberinput"
                      // onChange={(e) => setPhone(e.target.value)}

                      onChange={(e) => {
                        if (/^[0-9]+$/.test(e.target.value)) {
                          setPhoneErr(false);
                          setPhone(e.target.value);
                        } else if (e.target.value === "") {
                          setPhoneErr(false);
                          setPhone("");
                        } else {
                          setPhoneErr(true);
                        }
                      }}
                      value={phone}></Input>
                  </Form.Item>
                  {phonnumberUsed && (
                    <div className="text__color__aob">
                      This phone number has been used. Enter a different phone
                      number.
                    </div>
                  )}
                  {mobilenumberreq && (
                    <div className="text__color__aob">
                     `${translate("home")["You might have entered an invalid phone number. Please check again."]}` 
                    </div>
                  )}

                  <Button htmlType="submit" className='next-button-travel'>{translate("onboarding")["Send OTP Cus"]}</Button>
                </Form>
              </div>
            </> :
            <>
              <div style={{ textAlign: "center" }}>
                <h5 style={{ marginBottom: 5, color: "#482c77" }}>{translate("onboarding")["confirmation"]}</h5>

                <div style={{ margin: "2% 18%" }}>{translate("onboarding")["Enter the OTP that has been sent to"]} {phone} {translate("onboarding")["to complete phone number confirmation"]} </div>

                <Form
                  form={form}
                  fields={[
                    {
                      name: ["otp1"],
                      value: otp1,
                    },
                    {
                      name: ["otp2"],
                      value: otp2,
                    },
                    {
                      name: ["otp3"],
                      value: otp3,
                    },
                    {
                      name: ["otp4"],
                      value: otp4,
                    },

                  ]}
                >
                  <Row>
                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                      <Form.Item
                        className="form-item-name label-color"
                        name="otp1"
                        rules={[
                          {
                            message: `${translate("home")["Only Number is Allowed"]}`,
                            pattern: '^[0-9]+$',
                          },
                        ]}
                        style={{ marginBottom: "0.5rem" }}
                      >
                        <Input
                          size="large"
                          maxLength="1"
                          value={otp1}
                          name="otp1"
                          rules={[
                            // {
                            //   required: false,
                            //   message: "First Name is required",
                            // },
                            {
                              message: `${translate("home")["Only Number is Allowed"]}`,
                              pattern: new RegExp(/^[0-9]+$/),
                            },
                          ]}
                          // defaultValue={storefirstNameValue}
                          onChange={(item) => handleOTP1changeHandler(item)}
                          autoComplete="off"
                          className="OTPInput"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                      <Form.Item
                        className="form-item-name label-color"
                        name="otp2"
                        rules={[
                          {
                            message: `${translate("home")["Only Number is Allowed"]}`,
                            pattern: '^[0-9]+$',
                          },
                        ]}
                        style={{ marginBottom: "0.5rem" }}
                      >
                        <Input
                          size="large"
                          maxLength="1"
                          value={otp2}
                          name="otp2"
                          ref={inputOTP2}
                          className="OTPInput"
                          // defaultValue={storefirstNameValue}
                          onChange={(item) => handleOTP2changeHandler(item)}
                          autoComplete="off"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                      <Form.Item
                        className="form-item-name label-color"
                        name="otp3"
                        rules={[
                          {
                            message: `${translate("home")["Only Number is Allowed"]}`,
                            pattern: '^[0-9]+$',
                          },
                        ]}
                        style={{ marginBottom: "0.5rem" }}
                      >
                        <Input
                          size="large"
                          maxLength="1"
                          name="otp3"
                          value={otp3}
                          ref={inputOTP3}
                          // defaultValue={storefirstNameValue}
                          onChange={(item) => handleOTP3changeHandler(item)}
                          autoComplete="off"
                          className="OTPInput"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                      style={{ marginBottom: "0.5rem" }}
                    >
                      {" "}
                      <Form.Item
                        className="form-item-name label-color"
                        name="otp4"
                        rules={[
                          {
                            message: `${translate("home")["Only Number is Allowed"]}`,
                            pattern: '^[0-9]+$',
                          },
                        ]}
                        style={{ marginBottom: "0.5rem" }}
                      >
                        <Input
                          size="large"
                          maxLength="1"
                          value={otp4}
                          name="opt4"
                          ref={inputOTP4}

                          // defaultValue={storefirstNameValue}
                          onChange={(item) => handleOTP4changeHandler(item)}
                          autoComplete="off"
                          className="OTPInput"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                      style={{ marginBottom: "0.5rem" }}
                    >
                      {" "}
                      <Form.Item
                        className="form-item-name label-color"
                        name="otp5"
                        rules={[
                          {
                            message: `${translate("home")["Only Number is Allowed"]}`,
                            pattern: '^[0-9]+$',
                          },
                        ]}
                        style={{ marginBottom: "0.5rem" }}
                      >
                        <Input
                          size="large"
                          maxLength="1"
                          value={otp5}
                          name="opt5"
                          ref={inputOTP5}

                          // defaultValue={storefirstNameValue}
                          onChange={(item) => handleOTP5changeHandler(item)}
                          autoComplete="off"
                          className="OTPInput"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                      style={{ marginBottom: "0.5rem" }}
                    >
                      {" "}
                      <Form.Item
                        className="form-item-name label-color"
                        name="otp6"
                        rules={[
                          {
                            message: `${translate("home")["Only Number is Allowed"]}`,
                            pattern: '^[0-9]+$',
                          },
                        ]}
                        style={{ marginBottom: "0.5rem" }}
                      >
                        <Input
                          size="large"
                          maxLength="1"
                          value={otp6}
                          name="opt6"
                          ref={inputOTP6}

                          // defaultValue={storefirstNameValue}
                          onChange={(item) => handleOTP6changeHandler(item)}
                          autoComplete="off"
                          className="OTPInput"
                        />
                      </Form.Item>
                    </Col>

                  </Row>
                  <Form.Item>
                    <Row>
                      <Col xl={24} md={24} lg={24} sm={24} xs={24} className="otp__error__message">
                        {otpreqres && (<div className="otp__error__message__text">{translate("home")["otp is required"]}</div>)}
                        {otpInvaludres && (<div className="otp__error__message__text">{translate("commercial sales")["You have entered an invalid OTP. Please check again."]}</div>)}
                      </Col>
                    </Row>
                    <Button
                      htmlType="submit"
                      className="next-button-travel"
                      onClick={() => {
                        verifyOTP();
                      }}
                    >
                      {translate("onboarding")["Verify OTP"]}
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </>}
        </Card>
      </div >
    </>
  )
}

export default HomeOtp