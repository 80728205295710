import React, { useState, useEffect } from 'react'
import { Row, Col, Image, Form, Input, Button } from "antd"
import SuccessLogo from "../../oonaImages/GRFX Success State.png"
import { useHistory } from "react-router-dom"
import InputImage from "../../oonaImages/Frame 1000004254.png"
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { toast } from 'react-toastify'
import { useLanguage } from '../../config/LanguageContext'
import axiosRequest from '../../axios-request/request.methods'
import { useSelector } from 'react-redux'
import CustomerHeader from '../../CustomerHeader/CustomerHeader'


const HomeQuickQuoteSuccessScreen = (props) => {
  
  const {translate}=useLanguage()
  console.log(props, "propspropsprops")
  const fetchingIDfromStore=useSelector((state)=>state?.cipherlinkreducer?.quote?.quoteId)
  console.log(fetchingIDfromStore,"fetchingIDfromStore")
  const fetchingQuotationcodefromStore=useSelector((state)=>state?.cipherlinkreducer?.quote?.quote_Id)
  console.log(fetchingQuotationcodefromStore,"fetchingQuotationcodefromStore")
  const [copyLink, setCopyLInk] = useState("")
  const location = useLocation()
  console.log(location,"location  ==>")

  useEffect(() => {
    form.setFieldsValue({
      copyLink: copyLink
    })

    setCopyLInk(copyLink)
  }, [copyLink])

  console.log(location, "location in HOme")
  const history = useHistory();
  const successHandle = () => {
   
      history.push({
        pathname: '/customerinformationhome',
      })
    

  }


  useEffect(() => {
    getLink()
  }, [fetchingIDfromStore])





  const getLink=()=>{

    axiosRequest
            .get(
                `user/generate-link?type=lead&quoteId=${fetchingIDfromStore}&isEPdfLink=true&pdfType=quickQuote`,
            )
            .then((data) => {
                if(data?.errCode === -1){
                console.log("Linknckshu", data?.errMsg?.link);
                    setCopyLInk(data?.errMsg?.link)
                }
            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

            });

  }

  const handleCopyLInk = () => {
    navigator.clipboard.writeText(copyLink)
    toast.success(`${translate("home")["link copied"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
  }


  const [form] = Form.useForm()

  return (
    <>
    <CustomerHeader/>
    <div>
      <Row className='success-content'>
        <Col xl={12} md={12} lg={12} xs={24} sm={24}>
          <Image preview={false} src={SuccessLogo} />
        </Col>
        <Col xl={12} md={12} lg={12} xs={24} sm={24}>
          <h5 className='header-card-content'>{translate('travel')["Success!"]}</h5>
          <div><b>{translate('travel')["Quote has been created"]}</b></div> 
          <div style={{ fontSize: 12, color: "lightgray" }}>{translate('travel')["Quote code"]}: {fetchingQuotationcodefromStore }</div>
          <br />
            <>
              <b>
              {translate('travel')["Send this quote link to the client"]}
              </b>
              <br />
              <br />
         
              <Form form={form}>
                <Form.Item
                  name="copyLink">
                  <Input disabled value={copyLink} suffix={<><Image className="input-img" style={{ cursor: 'pointer' }} src={InputImage} preview={false} onClick={() => handleCopyLInk()} /></>} onClick={() => handleCopyLInk()} className='link-input' />
                </Form.Item>
              </Form>
            </>
          
          <Row>
            <Col md={24} xl={24} sm={24} xs={24} lg={24}>
              <Button className='next-button-travel' onClick={() => successHandle()}>{translate('home')["Create Policy"]}</Button>
            </Col>
            <Col md={24} xl={24} sm={24} xs={24} lg={24}>
              <div style={{ fontWeight: "bold", marginTop: "5%", textAlign: "center", cursor: "pointer" }} onClick={() => history.push('/oonahome')}>{translate('travel')["Back to Dashboard"]}</div>
            </Col>

          </Row>
        </Col>
      </Row>
    </div>
    </>
  )
}

export default HomeQuickQuoteSuccessScreen
