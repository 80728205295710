import React, { forwardRef, useEffect, useState } from 'react'
import { Card, Checkbox, Row, Col, Image, Modal, Button } from 'antd'
import ArrowIcon from "../../oonaImages/arrowIcon.png"
import rootIndex from '../../store/root_index'
import { useLanguage } from '../../config/LanguageContext'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import axiosRequest from '../../axios-request/request.methods'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

const { store } = rootIndex;


const AdditionalBenefit = forwardRef((props, ref) => {
    
    const history=useHistory()
    const fetchingIDfromStore=useSelector((state)=>state?.cipherlinkreducer?.quote?.quoteId)
    console.log(fetchingIDfromStore,"fetchingIDfromStore")
    const checkingResumeType=useSelector((state)=>state?.cipherlinkreducer?.quote?.resumeType);
   
    const _store = store.getState();
 
    const [homeadditionalBenefits, setHomeAdditionalBenefits] = useState("")
    console.log(homeadditionalBenefits?.data?.[0]?.Plans?.[0]?.AdditionalBenefits, "homeadditionalBenefits")
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filteredAdditionalHomeBenefits, setFilteredHomeAdditionalBenefits] = useState([])
    console.log(filteredAdditionalHomeBenefits, "filteredAdditionalHomeBenefits")
    const [additionalBenefitCheckbox, setadditionalBenefitCheckbox] = useState({})
    console.log(additionalBenefitCheckbox, "additionalBenefitCheckbox")
    const [calculatingupdatedarray,setCaluculatingupdatedarrayofObjects]=useState([])
    console.log(calculatingupdatedarray,"calculatingupdatedarray")
   
    const [subtotalamount, setSubtotalAmount] = useState("")
    const [viewDetailTS, setViewDetailTotalSum] = useState("")

    const [getQuoteDetails, setgetQuoteDetails] = useState({})
    console.log(getQuoteDetails, "getQuoteDetailsInAdditionalBenefits")


    useEffect(()=>{

        const updatedarrayofObjects = filteredAdditionalHomeBenefits?.map((obj) => {
            // console.log(obj?.BenefitDesc,"dshte67efBenefitDescccc")
        if (additionalBenefitCheckbox?.isEQCoverOpted === true) {
            if (obj?.BenefitCode === "C0001028") {
                return { ...obj}
            }
        }
        if (additionalBenefitCheckbox?.isRSMDCCCoverOpted === true) {
            if (obj?.BenefitCode === "C0002004" ) {
                return { ...obj }
            }

        }
        if (additionalBenefitCheckbox?.isFloodCoverOpted === true) {
            if (obj?.BenefitCode === "C0002003") {
                return { ...obj }
            }
        }

    }).filter((value) => {
        return value !== undefined
    })

setCaluculatingupdatedarrayofObjects(updatedarrayofObjects)

    },[filteredAdditionalHomeBenefits,additionalBenefitCheckbox])
 

    const { translate ,language} = useLanguage()
    console.log(language,"Language in additional")
    const location=useLocation()
    // console.log(location?.state?.quoteId,"location in Additional Benefitss")

    console.log(calculatingupdatedarray, "updatedarrayofObjects in xbhjgdy")
  
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };


    useEffect(() => {
        console.log(getQuoteDetails?.isEQCoverOpted, "getQuoteDetails?.isEQCoverOpted")
        if (getQuoteDetails?.isEQCoverOpted !== undefined || getQuoteDetails?.isFloodCoverOpted !== undefined || getQuoteDetails?.isRSMDCCCoverOpted !== undefined) {


            const needtocopyvalues = ['isEQCoverOpted', 'isFloodCoverOpted', 'isRSMDCCCoverOpted']
            const copiedValues = {}
            needtocopyvalues?.forEach((key) => {
                copiedValues[key] = getQuoteDetails[key]
            })
            copiedValues["isEQCoverOpted"] === '' ? copiedValues["isEQCoverOpted"] = false : copiedValues["isEQCoverOpted"] = true
            copiedValues["isFloodCoverOpted"] === '' ? copiedValues["isFloodCoverOpted"] = false : copiedValues["isFloodCoverOpted"] = true
            copiedValues["isRSMDCCCoverOpted"] === '' ? copiedValues["isRSMDCCCoverOpted"] = false : copiedValues["isRSMDCCCoverOpted"] = true

            setadditionalBenefitCheckbox(copiedValues)
        }

    }, [getQuoteDetails])

    // const changingLanguage=()=>{

    // }


    useEffect(() => {
       
// if(language === "en"){
//     handleLanguageChanging() 
// }
// else{
    
// }

if(fetchingIDfromStore !== ""){
if(language === "en"){
    handleLanguageChanging(language) 
}
else{
    handleLanguageChanging(language) 
}
}
    }, [language,fetchingIDfromStore])



   const handleLanguageChanging=(lang)=>{
    console.log(lang," geetha  gudg")

  

    axiosRequest
    .get(
        `user/get-home-benefits/${fetchingIDfromStore}?langId=${lang === "en" ? "EN" : "BH"}`)
    .then((data) => {
        if(data?.errCode === -1){
        console.log(data?.errMsg?.data,"dattt")
            setHomeAdditionalBenefits(data?.errMsg)
        }
    }).catch((error) => {
        console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

    });
   }

   useEffect(() => {
    if(fetchingIDfromStore !== ""){

    var fromReferral = ""
    if(checkingResumeType === "referalJourney" || checkingResumeType === "quoteSearch"){
      fromReferral=`home/getQuote/${fetchingIDfromStore}?logkey=${checkingResumeType}`
    }
    else{
      fromReferral=`home/getQuote/${fetchingIDfromStore}`
    }


        axiosRequest
            .get(`${fromReferral}`)
            .then((data) => {
                if(data?.errCode === -1){
                    setgetQuoteDetails(data?.errMsg?.data)
            }
            })
            .catch((error) => {
                console.log(
                    "error:2---forgotpass-----> ",
                    typeof error,
                    error.Error,
                    error.message
                );
            });
        }

    }, [fetchingIDfromStore,checkingResumeType])


    const handleAdditionalBenefit = () => {

        let formData = {
            "isFloodCoverOpted": additionalBenefitCheckbox["isFloodCoverOpted"] ? "Y" : "",
            "isRSMDCCCoverOpted": additionalBenefitCheckbox["isRSMDCCCoverOpted"] ? "Y" : "",
            "isEQCoverOpted": additionalBenefitCheckbox["isEQCoverOpted"] ? "Y" : "",
            additionalBenefits:calculatingupdatedarray
        }
       

        axiosRequest
            .patch(
                `home/updateQuote/${fetchingIDfromStore}`,
                formData,
            )
            .then((data) => {
                if(data?.errCode === -1){
 history.push({
            pathname: "/home-order-summary",
        })
                    toast.success(`${translate("home")["Quote Updated Successfully"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
                }    
            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
                toast.error(`${translate("home")["Network Request Failed"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
            });
    }

 

    useEffect(() => {

        const totalSum = calculatingupdatedarray?.reduce(function (acc, obj) { return parseInt(acc) + parseInt(obj.Premium); }, 0);
        //console.log(totalSum, "totalSum")
        setSubtotalAmount(totalSum)
        const viewDetailTS = Number(getQuoteDetails?.premium) + Number(subtotalamount)
        setViewDetailTotalSum(viewDetailTS)

    }, [calculatingupdatedarray,getQuoteDetails,subtotalamount])



    useEffect(() => {
        const filtresponse = homeadditionalBenefits?.data?.[0]?.Plans?.[0]?.AdditionalBenefits?.filter((item) => {
            return item.BenefitType === "C"
        })

        setFilteredHomeAdditionalBenefits(filtresponse)
    }, [homeadditionalBenefits])

    return (
        <div>
            <h3 className='header-card-content'>{translate("travel")["Additional Benefit"]}</h3>
            <div>{translate("home")["Add some optional coverage for your home."]}</div>

            <Card className='add-benefit-card  back-img-1'>
                <>  <div>{translate("home")["product"]}</div>
                    <div><b>{getQuoteDetails?.typeofProduct === "PAR" ? "PROPERTY ALL RISK" : getQuoteDetails?.typeofProduct}</b></div>
                </>
            </Card>

            {
                filteredAdditionalHomeBenefits?.map((item) => {
                    return item
                }).map((item1) => {
                    let opted = {
                        "C0002003": "isFloodCoverOpted",
                        "C0002004": "isRSMDCCCoverOpted",
                        "C0001028": "isEQCoverOpted"
                    }
                    return <Card className='add-benefit-card'>
                        <Row className='benefit-inner'>
                            <Col md={3} xl={3} lg={3} xs={3} sm={3}>
                                <Image src={item1?.signedUrl} preview={false} style={{ width: "60%" }} />
                            </Col>
                            <Col md={18} xl={18} lg={18} xs={18} sm={18}>
                                <div>{item1.BenefitDesc}</div>
                                <div className="thick-font">{new Intl.NumberFormat('id-ID', {
                                    style: 'currency',
                                    currency: 'IDR',
                                    minimumFractionDigits: 0,
                                }).format(Math.floor(item1?.Premium))}</div>
                            </Col>
                            <Col md={3} xl={3} lg={3} xs={3} sm={3}>
                                {
                                     ( <Checkbox name={opted[item1.BenefitCode]} 
                                        checked={additionalBenefitCheckbox[opted[item1.BenefitCode]]} 
                                        value={additionalBenefitCheckbox[opted[item1.BenefitCode]]} 
                                        onChange={(e) => {
                                        setadditionalBenefitCheckbox({
                                            ...additionalBenefitCheckbox,
                                            [opted[item1.BenefitCode]]: e.target.checked
                                        })
    
                                    }} />) 
                                  
                                }
                            </Col>
                        </Row>
                    </Card>
                })
            }

            <Row>
                <Col xl={19} xs={17} sm={19} lg={19} md={19}>
                    <div style={{ fontSize: 10 }}>
                    {translate("home")["Subtotal"]}
                    </div>
                    <div>
                        <b>
                            {new Intl.NumberFormat('id-ID', {
                                style: 'currency',
                                currency: 'IDR',
                                minimumFractionDigits: 0,
                            }).format(Math.floor(Number(getQuoteDetails?.premium) + Number(subtotalamount)))}
                        </b>
                    </div>
                </Col>
                <Col md={5} xl={5} lg={5} xs={7} sm={5} onClick={() => setIsModalOpen(true)}>
                    <div className='view-detail-font' style={{
                        cursor: "pointer"
                    }}>{translate("motor")["View Detail"]}<Image style={{ width: 15 }} src={ArrowIcon} preview={false} /></div>
                </Col>

                <Button type="primary" className='next-button-travel' onClick={handleAdditionalBenefit}>
                {translate("home")["next"]}
                </Button>
            </Row>
            <Modal closable={false} visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <h5>{translate("motor")["Price Detail"]}</h5>
                <p style={{ marginBottom: 10, marginTop: 10 }} className='thick-font'>{translate("motor")["Product Package"]}</p>

                <div className='accordian-inner'>
                    <div>
                        {getQuoteDetails?.typeofProduct === "PAR" ? "PROPERTY ALL RISK" : getQuoteDetails?.typeofProduct}
                    </div>
                    <div>
                        {/* {props?.planPremium} */}
                        {new Intl.NumberFormat('id-ID', {
                            style: 'currency',
                            currency: 'IDR',
                            minimumFractionDigits: 0,
                        }).format(Math.floor(getQuoteDetails?.premium))}
                    </div>
                </div>
                <div style={{ marginBottom: 10, marginTop: 10 }} className='thick-font'>{translate("motor")["Additional Coverage(s)"]}</div>


                {
                    calculatingupdatedarray?.map((item) => {
                        return <div className='accordian-inner'>
                            <div>{item?.BenefitDesc}</div>
                            <div>{new Intl.NumberFormat('id-ID', {
                                style: 'currency',
                                currency: 'IDR',
                                minimumFractionDigits: 0,
                            }).format(Math.floor(item?.Premium))}</div>
                        </div>
                    })
                }
                <hr />
                <div className='accordian-inner'>
                    <div style={{ fontSize: 12 }}>
                    Total
                    </div>
                    <div className='thick-font' style={{ fontSize: 18 }}>
                        {new Intl.NumberFormat('id-ID', {
                            style: 'currency',
                            currency: 'IDR',
                            minimumFractionDigits: 0,
                        }).format(Math.floor(viewDetailTS))}
                    </div>
                </div>
                <br />
                <Button className='next-button-travel' onClick={() => setIsModalOpen(false)}>Ok</Button>
            </Modal>
        </div >
    )
})

export default AdditionalBenefit