const initialState = {
    encryptedData: ""
   };

   export const encryptedDataofAEMreducer=(state=initialState,action)=>{
    switch(action?.type){
        case "FETCHING_ENCRYPTED_DATA":return {
            ...state,
            encryptedData:action?.payload
        }
        default:
            return state
    }

   }