import React, { useEffect } from "react";
import { Layout, Image, Row, Col, Button, Input } from 'antd';
import oonaLogo1 from "../../images/oona-logo1@2x.png"
import vector6 from "../../images/vector6.svg"
import vector12 from "../../images/vector12.svg"
import vector2 from "../../images/vector2.svg"
import ionIcons from "../../oonaImages/Ionicons 8.png"
import emailIcon from "../../oonaImages/email Icon.png"
import { useLocation } from "react-router-dom";
import { useLanguage } from "../../config/LanguageContext";


const Footer = () => {

  const { translate } = useLanguage()


  const { Footer } = Layout;


  let location = useLocation()
  useEffect(() => {
    console.log("location", location)

  }, [])
  const { pathname } = useLocation();

  // you can check a more conditions here
  // if (pathname === "/login") return null;

  return (
    <>
      <Layout>
        <Footer className="footer-main-content">
          <div style={{ margin: "1% 0%" }}>
            <Image preview={false} style={{ width: "60%" }} src={oonaLogo1} />
          </div>

          <Row style={{ padding: "0% 0% 3% 0%", alignItems: "end" }}>
            <Col xl={5} xs={5} lg={5} md={5} sm={8}>
              <div className="footer-white-font">
                Do you have any concerns?
              </div>
              <div>
                
                <div>
                  <Button className="footer-button-1" onClick={()=>window.open("mailto:contactus@oona-insurance.co.id?subject=ContactUS&body=Description")}> {translate("commercial sales")["contactUs"]}</Button>
                </div>
              </div>
            </Col>
            <Col xl={10} xs={10} lg={10} md={10} sm={8}>
              <div className="footer-white-font">Follow us on social</div>
              <Row>
                <div style={{ gap: 20, display: "flex" }}>
                  <div>
                  <a href="https://www.facebook.com/oonainsuranceind/" target="blank" >
                    <Image preview={false} alt="" src={vector6} />
                    </a>
                  </div>
                  <div>
                  <a href="https://twitter.com/oonainsurance" target="blank" >
                    <Image preview={false}
                      alt=""
                      src={vector12}
                    />
                    </a>
                  </div>
                  <div>
                  <a href="https://www.tiktok.com/@oonainsurance_id" target="blank" >
                    <Image preview={false} alt="" src={vector2} />
                    </a>
                  </div>
                  <div>
                    <a href="https://www.instagram.com/oonainsurance_id/" target="blank" >
                    <Image preview={false} alt="" src={ionIcons} />
                    </a>
                   
                  </div>
                </div>
              </Row>
            </Col>
            <Col xl={9} xs={9} lg={9} md={9} sm={8}>
              {/* <Row>
                <Col xl={18} xs={18} lg={18} md={18} sm={18}>
                  <Input placeholder="Enter Email Address" prefix={<><Image src={emailIcon} preview={false} /></>} style={{ width: "96%" }}></Input>
                </Col>
                <Col xl={6} xs={6} lg={6} md={6} sm={6}>
                  <Button className="footer-button-2">Submit</Button>
                </Col>
              </Row> */}
            </Col>
          </Row>
          <Row>
            <Col xl={16} sm={12} lg={16} xs={16} md={16}>
              <Row style={{ alignItems: "center" }}>
                <Col className="col-footer-line" xl={3} xs={3} lg={3} md={3} sm={6}>
                <a href="https://www.myoona.id/privacy-policy-2/" target="blank" >
                  <div className="footer-white-font-1" >
                 
                    Privacy Policy
                   
                  </div>
                  </a>
                </Col>
                <Col className="col-footer-line1" xl={5} xs={5} lg={5} md={5} sm={10}>
                <a href="https://www.myoona.id/terms-of-use/" target="blank" >
                  <div className="footer-white-font-1" >
                  
                    Terms of Use & Service
                    
                  </div>
                  </a>
                </Col>
                {/* <Col className="col-footer-line1" xl={4} xs={4} lg={4} md={4} sm={8}>
                  <div className="footer-white-font-1">
                    Report a Problem
                  </div>
                </Col> */}
              </Row>
            </Col>
            <Col xl={8} xs={8} lg={8} md={8} sm={12}>
            <div style={{marginTop:10}} className="footer-white-font">PT Asuransi Bina Dana Arta,Tbk Berizin Dan diawasi oleh Otoritas Jasa Keuangan</div>
              <div className="footer-white-font-1">
                Copyright © 2023 OONA Insurance. All Rights Reserved.
              </div>
            </Col>
          </Row>
        </Footer>
      </Layout >
    </>
  )
};
export default Footer;