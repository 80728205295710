import { Collapse } from 'antd';
import React, { useState } from 'react'

const Singlepanel = ({planDetails,quoteData}) => {
    console.log(planDetails,"planDetails")
    const { Panel } = Collapse;

    const [expandedProducts, setExpandedProducts] = useState([]);

    const [activeNestedKeys, setActiveNestedKeys] = useState([]);

    const handleNestedCollapseChange = (key) => {
        setActiveNestedKeys(prevActiveKeys => {
            if (prevActiveKeys.includes(key)) {
                return prevActiveKeys.filter(k => k !== key);
            } else {
                return [key];
            }
        });
    };

    // const toggleProductExpand = (benefit) => {
    //     if (expandedProducts.includes(benefit?.BenefitCode)) {
    //       setExpandedProducts(expandedProducts.filter((name) => name !== benefit?.BenefitCode));
    //     } else {
    //       setExpandedProducts([...expandedProducts, benefit?.BenefitCode]);
    //     }
    //   };
    
    //   const callback = (key, item) => {
    //     console.log(key, "jkehkjh");
    //     toggleProductExpand(key);
    //   };

  return (
    <div>
     {
        quoteData?.isEligibleForMultiyear ? (<>
        <Collapse accordion collapsible="header" className='nestedCollapse'>
        <Panel header={
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{fontWeight:"600",color:"#4F5B66"}}>{planDetails.PlanName}</div>
                    <div style={{fontWeight:"600",color:"#4F5B66"}}>{new Intl.NumberFormat('id-ID', {
                                                                        style: 'currency',
                                                                        currency: 'IDR',
                                                                        minimumFractionDigits: 0,
                                                                    }).format(planDetails.Premium || 0)}</div>
                </div>
            }
            >
        {/* {["FirstYrSumInsured", "SecondYrSumInsured", "ThirdYrSumInsured", "FourthYrSumInsured", "FifthYrSumInsured"].map((year, index) => {
                                                                    const yearLabel = `Year ${index + 1}`;
                                                                    const yearPremium = planDetails[year];
                                                                    return yearPremium ? (
                                                                        <div key={index} style={{ display: "flex", justifyContent: "space-between", padding: "0.2em 0em 0.2em 0em" }}>
                                                                            <div style={{ fontWeight: "500", color: "#4F5B66" }}>{yearLabel}</div>
                                                                            <div style={{ fontWeight: "500", color: "#4F5B66" }}>{new Intl.NumberFormat('id-ID', {
                                                                                style: 'currency',
                                                                                currency: 'IDR',
                                                                                minimumFractionDigits: 0,
                                                                            }).format(yearPremium)}</div>
                                                                        </div>
                                                                    ) : null;
                                                                })} */}

<>
                                                        {/* <div style={{ marginBottom: 10 }} className="accordian-inner">
                                                            <div className="thick-font">BASIC BENEFITS</div>
                                                            <div className="thick-font">TOTAL PREMIUM</div>
                                                        </div> */}
                                                      
                                                      {
                                                        quoteData?.isEligibleForMultiyear ? (quoteData?.planDetails?.MainBenefits.filter(benefit => benefit.BenefitType === "C").map((benefit, j) => (
                                                            <Collapse key={j} accordion collapsible="header" className='nestedCollapse'
                                                            activeKey={activeNestedKeys.includes(benefit.BenefitCode) ? benefit.BenefitCode : undefined}
                                                            onChange={() => handleNestedCollapseChange(benefit.BenefitCode)}
                                                           >
                                                                <Panel
                                                                
                                                                    header=<div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                    <div style={{fontWeight:"600",color:"#4F5B66"}}>{benefit.BenefitDesc}</div>
                                                                    <div style={{fontWeight:"600",color:"#4F5B66"}}>{new Intl.NumberFormat('id-ID', {
                                                                        style: 'currency',
                                                                        currency: 'IDR',
                                                                        minimumFractionDigits: 0,
                                                                    }).format(benefit.Premium || 0)}</div>
                                                                </div>
                                                                    key={benefit.BenefitCode}
                                                                >
                                                                    <div className="accordian-inner" style={{ display: "flex", flexDirection: "column" }}>
                                                                        {["FirstYrPremium", "SecondYrPremium", "ThirdYrPremium", "FourthYrPremium", "FifthYrPremium"].map((year, index) => {
                                                                            const yearLabel = `Year ${index + 1}`;
                                                                            const yearPremium = benefit[year];
                                                                            if (yearPremium) {
                                                                                return (
                                                                                    <div key={index} style={{ display: "flex", justifyContent: "space-between" ,padding:"0.2em 0em 0.2em 0em"}}>
                                                                                        <div style={{fontWeight:"500",color:"#4F5B66",width:"70%"}}>{yearLabel}</div>
                                                                                        <div style={{fontWeight:"500",color:"#4F5B66"}}>{new Intl.NumberFormat('id-ID', {
                                                                                            style: 'currency',
                                                                                            currency: 'IDR',
                                                                                            minimumFractionDigits: 0,
                                                                                        }).format(yearPremium || 0)}</div>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                            return null;
                                                                        })}
                                                                    </div>
                                                                </Panel>
                                                            </Collapse>
                                                        ))):(
                                                            // item?.MainBenefits.map((data) => (
                                                            //     <div className="accordian-inner">
                                                            //         <div>{data.BenefitDesc}</div>
                                                            //         <div>{new Intl.NumberFormat('id-ID', {
                                                            //             style: 'currency',
                                                            //             currency: 'IDR',
                                                            //             minimumFractionDigits: 0,
                                                            //         }).format(data?.Premium || 0)}</div>
                                                            //     </div>
                                                            // ))<>
                                                            <>
                                                            </>
                                                        )
                                                      }
                                                      

                                                        

                                                    </>                                                   

        </Panel>
        </Collapse>

        </>):(<>
        </>)
     }
      
    </div>
  )
}

export default Singlepanel
