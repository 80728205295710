import rootIndex from "../store/root_index";
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import * as action from '../store/actions/index'
import axiosRequest from '../axios-request/request.methods'
import { aemAUth } from "../store/actions/ciProduct/aemAUth";
import { useLocation } from "react-router-dom";
import Cookies from 'js-cookie';
const { store } = rootIndex;

const CIDynamicRoute = (props) => {
    const _store = store.getState();
    const history = useHistory();
    const dispatch = useDispatch()
    const [cipherCiData, setCipherCiData] = useState("")
    console.log(cipherCiData,"cipherCiData")
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const langParam = searchParams.get('lang');
    console.log(langParam,"langParam")
    useEffect(() => {
        if (_store.cipherlinkreducer.formdata?.cipherkaye !== undefined) {
            axiosRequest.get(`user/getcipherdetails/${_store.cipherlinkreducer.formdata?.cipherkaye}`).then(res =>
                setCipherCiData(res?.errMsg?.data)
            )
        }
    }, [_store.cipherlinkreducer.formdata?.cipherkaye])

    useEffect(() => {
        if (props?.match?.params?.id?.length === 6) {
            let Data = {
                cipherkaye: props.match.params.id,
                cipherkeyavilable: true
            }
            dispatch(action.fetch_cipher_data(Data))
            let options = { secure: false, multipart: false }
            axiosRequest
                .get(
                    `auth/decrypt-link-ci`, options
                )
                .then((data) => {
                    if (data?.errCode === -1) {
                        dispatch(action.set_global_language(langParam));
                        Cookies.set('LANG', langParam === 'en' ? 'en' : 'id', { domain: process.env.REACT_APP_COOKIE_URL });
                        console.log(data.errMsg, 'HomeDynamicAPIResponse');
                        if (data?.errMsg?.data?.hasOwnProperty("stage") === false) {
                            if (cipherCiData?.hasOwnProperty("discountPercent")) {
                                const ciPayload = {
                                    refId: "",
                                    ref: "quote",
                                    product: "CI",
                                    workflow: {
                                        role: "Customer",
                                        createdBy: "Agent",
                                        agentProfileId: data.errMsg?.data?.agent_id,
                                    },
                                    "discountPct": cipherCiData?.discountPercent,
                                    "promo": cipherCiData?.promoCode
                                };
                                setTimeout(() => {
                                    axiosRequest
                                        .post("auth/aem/encrypt", ciPayload, options)
                                        .then((res) => {
                                            console.log(res, "resssssforCI===>");
                                            if (res?.errCode === -1) {
                                                const redirectingURLdata = res?.errMsg?.encryptedData;
                                                const customerredirectionURlfromenv=process.env.REACT_APP_CUSTOMER_CI_REDIRECTIONURL
                                                const redirectingURl = `${customerredirectionURlfromenv}=${redirectingURLdata}`;
                                                window.location.replace(redirectingURl);
                                            }
                                        });
                                }, 1000);
                            }
                        }
                        else if (data?.errMsg?.data?.stage === "Full Quote Created") {
                            if (cipherCiData?.hasOwnProperty("discountPercent")) {
                                const ciPayload = {
                                    deepLinkStage: data.errMsg?.data?.quoteId?.otherData?.deepLinkStage,
                                    refId: data?.errMsg?.data?.quoteId?._id,
                                    ref: "quote",
                                    product: "CI",
                                    workflow: {
                                        role: "Customer",
                                        createdBy: "Agent",
                                        agentProfileId: data.errMsg?.data?.agent_id,
                                    },
                                    "discountPct": data?.errMsg?.data?.quoteId?.discountPercent,
                                    "promo": data?.errMsg?.data?.quoteId?.promoCode
                                };
                                console.log(ciPayload, "ciPayload")
                                setTimeout(() => {
                                    axiosRequest
                                        .post("auth/aem/encrypt", ciPayload, options)
                                        .then((res) => {
                                            console.log(res, "resssssforCI===>");
                                            if (res?.errCode === -1) {
                                                const redirectingURLdata = res?.errMsg?.encryptedData;
                                                const customerredirectionURlfromenv=process.env.REACT_APP_CUSTOMER_CI_REDIRECTIONURL
                                                
                                                const redirectingURl = `${customerredirectionURlfromenv}=${redirectingURLdata}`;
                                                window.location.replace(redirectingURl);
                                            }
                                        });
                                }, 1000);
                            }
                        }

                    }
                }).catch((error) => {
                    console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
                });
        } else {
        }
    }, [cipherCiData])

    return (
        <div>
        </div>
    )
}

export default CIDynamicRoute
