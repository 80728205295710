import React, { useState, useEffect } from 'react'
import { Row, Col, Progress, Card } from "antd"
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom"
import ChooseProduct from '../screens/ChooseProduct';
import { useLanguage } from '../../config/LanguageContext';
import CustomerHeader from '../../CustomerHeader/CustomerHeader';




function ChooseProductBrowserBack() {
   
    const history = useHistory()
    const [width, setWidth] = useState(window.innerWidth);
    const breakpoint = 620;

    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);

        return () => window.removeEventListener("resize", handleWindowResize);
    }, [width]);

    const sendEmail = () => {
        window.open("mailto:noreply@oona-insurance.co.id?subject=SendMail&body=Description");
    };


    const { translate } = useLanguage()
    return (
        <>
<CustomerHeader/>

                
            <div className="row-travel">
                <Row>
                    <Col xl={7} md={7} lg={7} sm={24} xs={24}>
                        {width > breakpoint ? (
                            <>
                                <h5 className="heading-content">{translate("travel")["Quick quote"]}</h5>
                                <Card className="card-progress">
                                    <Row>
                                        <Col md={3}>
                                            <Progress className="progress-content"
                                                percent={30} showInfo={false}
                                            />
                                        </Col>
                                        <Col className="col-content" md={10}>
                                            <div className="thick-font">{translate("travel")["Create Quote"]}</div>
                                            <div className="thick-font">{translate("travel")["Customer Details"]}</div>
                                            <div className="thick-font">{translate("travel")["Confirmation"]}</div>
                                        </Col>
                                    </Row>
                                </Card>
                            </>
                        ) : (
                            <>
                                <Card className="card-mobile">
                                    <div className="justify-space">
                                        <div className="mob-header">
                                            {translate("travel")["Quick quote"]}
                                        </div>
                                        <div style={{ cursor: "pointer" }} onClick={sendEmail} className='need-content'>
                                            {translate("onboarding")["Need Help"]} ?
                                        </div>
                                    </div>
                                </Card>
                                <Card className="card-mobile">
                                    <Row>
                                        <Col xs={3}>
                                            <div className="number-mob-selected">
                                                1
                                            </div>
                                        </Col>
                                        <Col xs={14}>
                                            <div className='thick-font'>
                                                {translate("travel")["Create Quote"]}
                                            </div>
                                        </Col>
                                        <Col xs={3}>
                                            <div className="number-mob-non-selected">
                                                2
                                            </div>
                                        </Col>
                                        <Col xs={3}>
                                            <div className="number-mob-non-selected">
                                                3
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </>
                        )}
                    </Col>
                    <Col xl={14} md={14} lg={14} sm={24} xs={24}>
                        <Card>
                           
                                <div className='travel-row-1 back-content'>
                                  <><div className='arrow-back-font' onClick={() => history.push({
                                        pathname: "/oonahomescreen",
                                        
                                    })}><ArrowLeftOutlined />{translate("onboarding")["Back"]}</div></>

                               
                                    <div style={{ cursor: "pointer" }} onClick={sendEmail} className='need-content'>{translate("onboarding")["Need Help"]} ?</div>
                                </div>
                            <div className="steps-content"><ChooseProduct /></div>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>

    )
}

export default ChooseProductBrowserBack