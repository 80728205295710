import React, { useState, useEffect } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import { Row, Col,Button,Input } from "antd"

function MapContainer(props) {
  console.log(props?.mapsaddress, "mapssaddresss");
  console.log(props, "propsssss");
  const [map, setMap] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [searchLocation, setSearchLocation] = useState("");
  console.log(searchLocation, "searchLocation");
  const [searchResults, setSearchResults] = useState([]);
  const [shownElement, setShownElement] = useState(true);

  const [initialLoad, setInitialLoad] = useState(true);
  useEffect(() => {
    if (initialLoad) {
      // Get the user's current location
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            const initialPosition = new props.google.maps.LatLng(
              latitude,
              longitude
            );
            setMarkerPosition(initialPosition);
            setInitialLoad(false);
          },
          (error) => {
            console.error("Error getting current location:", error);
            setInitialLoad(false);
          }
        );
      } else {
        console.error("Geolocation is not available in this browser.");
        setInitialLoad(false);
      }
    }
  }, [initialLoad, props.google.maps.LatLng]);

  useEffect(() => {
    // Use the Google Places AutocompleteService to fetch suggestions
    const autocompleteService =
      new props.google.maps.places.AutocompleteService();
    autocompleteService.getPlacePredictions(
      { input: searchLocation },
      (results, status) => {
        if (status === "OK") {
          setSearchResults(results);
        } else {
          console.error("Autocomplete error:", status);
        }
      }
    );
  }, [searchLocation, props.google.maps.places.AutocompleteService]);

  useEffect(() => {
    if (searchLocation === "") {
      setShownElement(true);
    }
  }, [searchLocation]);

  const onMapReady = (mapProps, map) => {
    setMap(map);
  };

  const onMapClick = (mapProps, map, clickEvent) => {
    const { latLng } = clickEvent;
    setMarkerPosition(latLng);
    console.log("Clicked at:", latLng.lat(), latLng.lng());
    const data = {
      lat: latLng.lat(),
      long: latLng.lng(),
    };
    props?.handleInsuredAddress(data);
  };

  const handleSearchChange = (e) => {
    const location = e.target.value;
    setSearchLocation(location);
  };

  const handleSearch = () => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        searchLocation
      )}&key=AIzaSyAZfVPEGAfjlr_TA9ZjBN6SKZpWT2lx1HM`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "OK" && data.results.length > 0) {
          const { lat, lng } = data.results[0].geometry.location;
          const newPosition = new props.google.maps.LatLng(lat, lng);
          setMarkerPosition(newPosition);
          map.panTo(newPosition);

          const mapsdata = {
            lat: lat,
            long: lng,
          };
          props?.handleInsuredAddress(mapsdata);
          setSearchResults([])
        } else {
          console.log("Location not found");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleSuggestionClick = async (suggestion) => {
    const location_description = suggestion.description
    setSearchLocation(location_description, () => {
      setShownElement(false);
      handleSearch();
    }); // Set the input field value to the suggestion/ Perform the search when a suggestion is clicked
  };

  return (
    <div>
      <div>
        <Row>
          <Col xl={20} xs={20} md={20} lg={20} sm={20}>
            <Input
              style={{ width: "100%" }}
              type="text"
              placeholder="Search for a location"
              value={searchLocation}
              onChange={handleSearchChange}
            />
          </Col>
          <Col xl={4} xs={4} md={4} lg={4} sm={4}>
            <Button className="button-map" onClick={handleSearch}>Search</Button>
          </Col>
        </Row>

        {/* <div className="mapresultbox">
          {props?.mapsaddress !== undefined || props?.mapsaddress !== "" ? (
            <div>{props?.mapsaddress?.data}</div>
          ) : (
            ""
          )}
        </div> */}
      </div>

      {/* Display search suggestions */}
      <div>
        <div>
          <ul>
            {searchResults.map((result) => (
              <div
                key={result.place_id}
                onClick={() => handleSuggestionClick(result)}
                style={{ cursor: "pointer" }}
                className="mapspointer"
              >
                {searchLocation !== "" &&
                  result.description &&
                  shownElement === true && <div>{result.description}</div>}
              </div>
            ))}
          </ul>
        </div>
      </div>
      <Map
        google={props.google}
        onReady={onMapReady}
        onClick={onMapClick}
        center={markerPosition || { lat: 0, lng: 0 }}
        zoom={14}
        style={{ width: "95%", height: "300px" }}
      >
        {markerPosition && <Marker position={markerPosition} />}
      </Map>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyAZfVPEGAfjlr_TA9ZjBN6SKZpWT2lx1HM", // Replace with your Google Maps API key
})(MapContainer);
