import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Collapse, Button, Card, Row, Col } from "antd";
import rootIndex from "../../store/root_index"; 
import axios from "axios";
import { Base_URL } from "../../config/BaseUrl";
import { toast } from "react-toastify";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useLanguage } from "../../config/LanguageContext";
import { useHistory,useLocation } from "react-router-dom";
import axiosRequest from '../../axios-request/request.methods'
import { useSelector } from "react-redux";

const { store } = rootIndex;

const { Panel } = Collapse;

const ChooseProduct = (props, ref) => {
  const quoteId = useSelector((state)=>state?.cipherlinkreducer?.quote?.quoteId);
  const checkingResumeType=useSelector((state)=>state?.cipherlinkreducer?.quote?.resumeType);
  const [selectButton, setSelectButton] = useState(true);
  const [productOptions, setproductOptions] = useState("");
  const [quotedetails, setquoteDetails] = useState("");
  const [showSelectedProducts, setshowSelectedProducts] = useState([]);
  const history=useHistory()
  const [planCode, setPlanCode] = useState("");
  const [planName, setPlanName] = useState("");
  const [planPremium, setplanPremium] = useState("");
  const [parColor, setparColor] = useState("");
  const { translate,language } = useLanguage()



  useEffect(() => {
    if(quoteId !== ""){
    /*get-home-plans*/
    axiosRequest
      .get(`user/get-home-plans/${quoteId}?langId=${language === "en" ? "EN" : "BH"}`)
      .then((data) => {
        if(data?.errCode === -1){
      
          setproductOptions(data.errMsg);
        }
      })

    /*getQuotedetails*/
    var fromReferral = ""
    if(checkingResumeType === "referalJourney" || checkingResumeType === "quoteSearch"){
      fromReferral=`home/getQuote/${quoteId}?logkey=${checkingResumeType}`
    }
    else{
      fromReferral=`home/getQuote/${quoteId}`
    }

    axiosRequest
      .get(`${fromReferral}`)
      .then((data) => {
        if(data?.errCode === -1){
          setquoteDetails(data.errMsg?.data);
        }
      })
      .catch((error) => {
        console.log(
          "error:2---forgotpass-----> ",
          typeof error,
          error.Error,
          error.message
        );
      })
    }
  }, [quoteId,checkingResumeType]);

  const sortedProductOptions = productOptions?.data?.sort((planA, planB) => {
    let planFlexas = planA?.PlanName.toLowerCase();
    let planPAR = planB?.PlanName.toLowerCase();

    if (planFlexas < planPAR) {
      return -1;
    }
    if (planFlexas > planPAR) {
      return 1;
    }
    return 0;
  });

  useEffect(() => {
const applyGradiant = sortedProductOptions?.find((item) => {
      if (item?.PlanName === "PAR") {
        setparColor("accordian-image__Par");
      }
    });

    const selectingProducts = sortedProductOptions?.map((item, i) => {
      return { ...item, name: "Select" };
    });

  
    setshowSelectedProducts(selectingProducts);
  }, [productOptions,quoteId]);

  useEffect(() => {
    const selectingProducts = sortedProductOptions?.map((item, i) => {
      return { ...item, name: "Select" };
    });

    if (quotedetails?.typeofProduct !== undefined) {
      const response = selectingProducts?.map((item) => {
        if (item?.PlanName === quotedetails?.typeofProduct) {
          setButtonEnabled(false)
          return { ...item, name: "Selected" };
        }
        return item;
      });
   
     
      setPlanCode(quotedetails?.planCode);
      setPlanName(quotedetails?.typeofProduct);
      setplanPremium(quotedetails?.premium)
    
      

      setshowSelectedProducts(response);
    }
  }, [productOptions, quotedetails,quoteId]);

  const [buttonEnabled, setButtonEnabled] = useState(true)

  const handleSelect = (plancode, planname, planPremium, i) => {
  
    setshowSelectedProducts((prevState) => {
      const nextState = prevState.map((sbtn) => ({
        ...sbtn,
        name: "Select",
      }));
      nextState[i].name = "Selected";
      return nextState;
    });
    setSelectButton(true);
    setButtonEnabled(false)
    setPlanCode(plancode);
    setPlanName(planname);
    setplanPremium(planPremium);
  };

  const [expandedProducts, setExpandedProducts] = useState([]);

  const toggleProductExpand = (planName) => {
    if (expandedProducts.includes(planName)) {
      setExpandedProducts(expandedProducts.filter((name) => name !== planName));
    } else {
      setExpandedProducts([...expandedProducts, planName]);
    }
  };

  const callback = (key, item) => {
  
    toggleProductExpand(key);
  };


  const handleChoosingProduct=()=>{

    if (planCode === "") {
      toast.error(`${translate("home")["Please Select Plan"]}`, {
        position: "top-center",
        hideProgressBar: true,
        autoClose: 1000,
      });
    } else if (planName === "") {
      toast.error(`${translate("home")["Please Select Plan"]}`, {
        position: "top-center",
        hideProgressBar: true,
        autoClose: 1000,
      });
    } else if (selectButton === false) {
      toast.error(`${translate("home")["Please Select Plan"]}`, {
        position: "top-center",
        hideProgressBar: true,
        autoClose: 1000,
      });
    } else {


      let formData = {
        typeofProduct: planName,
        planCode: planCode,
        planName: planName,
        premium:planPremium
      };

   
      try {
          axiosRequest
              .patch(
                  `home/updateQuote/${quoteId}`,
                  formData,
                  
              )
              .then((data) => {
                if(data?.errCode === -1){
                  toast.success(`${translate("home")["Quote Updated Successfully"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
              history.push({
          pathname: "/home-additional-benefit",
          })
        }
              }).catch((error) => {
                  console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
                  toast.error(`${translate("home")["Network Request Failed"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
              });

      } catch (error) {
          console.log(error)
      }

    }

  }


  return (
    <div>
      <h3 className="header-card-content">{translate("travel")["Choose Product"]}</h3>
      <div style={{ fontWeight: 600 }}>
        {translate("home")["Our best selection of product for your property."]}
      </div>

      <div className="destination-heading">
        {translate("home")["occupation"]}:{" "}
        {quotedetails?.occupationType === "A" ? `${translate("home")["Apartment"]}` : `${translate("home")["HomeInd"]}`}
      </div>

      {showSelectedProducts &&
        showSelectedProducts?.map((item, i) => {
          return (
            <div key={item.PlanCode}>
              <Collapse
                className="home-accord travel-header-accord accordian-overcontent"
                accordion
                collapsible="header"
                defaultActiveKey={[quotedetails?.typeofProduct]}
                onChange={callback}
                activeKey={expandedProducts}
              >
                <Panel
                  key={item?.PlanName}
                  showArrow={false}

                  header={
                    <div>
                      <Card
                        className={
                          item?.PlanName === "PAR"
                            ? parColor
                            : "accordian-image"
                        }
                      >
                        <Row>
                          <Col md={20} xl={20} lg={20} sm={20} xs={16}>
                            <div>
                              <b>
                                {item.PlanName === "PAR"
                                  ? "PROPERTY ALL RISK"
                                  : item?.PlanName}
                              </b>
                            </div>
                            <div>
                              <b style={{ fontSize: 21 }}>{new Intl.NumberFormat('id-ID', {
                                style: 'currency',
                                currency: 'IDR',
                                minimumFractionDigits: 0,
                              }).format(item?.Premium)}</b>
                              /{translate("home")["year"]}
                            </div>
                          </Col>
                          <Col md={4} xl={4} lg={4} sm={4} xs={8}>
                            <>
                              <>
                                <Button
                                  className="accordian-button"
                                  onClick={(e) =>
                                    handleSelect(
                                      item.PlanCode,
                                      item.PlanName,
                                      item.Premium,
                                      i
                                    )
                                  }
                                >
                                  {item.name === "Select" ? translate("home")["Select"] : translate("home")["Selected"]}


                                </Button>
                              </>


                            </>
                          </Col>
                        </Row>
                      </Card>

                    </div>
                  }
                >
                  <>
                    <div
                      style={{ marginBottom: 10 }}
                      className="accordian-inner"
                    >


                      <div className="thick-font">
                        {item.PlanName === "PAR"
                          ? "PROPERTY ALL RISK"
                          : item?.PlanName}{" "}
                        BENEFITS
                      </div>
                    </div>
                    {item?.MainBenefits?.filter((itembenefits) => {
                      return itembenefits?.ParentBenefit === "NA";
                    }).map((item) => {
                      return (
                        <>
                          <div className="accordian-inner">
                            <div>{item?.BenefitDesc}</div>
                          </div>
                          <hr />
                        </>
                      );
                    })}

                  </>
                </Panel>
                <div
                  className="accord-bottom-font view-detail-font"
                  onClick={() => toggleProductExpand(item?.PlanName)}
                  style={{ cursor: "pointer" }}
                >
                  {expandedProducts.includes(item?.PlanName) ? (
                    <>
                      {translate("home")["Hide Detail"]}
                      <UpOutlined style={{ color: "#4AC6BB", strokeWidth: 30 }} />
                    </>
                  ) : (
                    <>
                      {translate("travel")["See Details"]}
                      <DownOutlined style={{ color: "#4AC6BB", strokeWidth: 30 }} />
                    </>
                  )}
                </div>
              </Collapse>
            </div>
          );
        })}
      <Button type="primary" disabled={buttonEnabled} className="next-button-travel" onClick={handleChoosingProduct}>
      {translate("home")["next"]}
      </Button>
    </div>
  );
};

export default ChooseProduct;
