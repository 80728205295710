import React, { useEffect } from 'react'
import { Base_URL } from '../config/BaseUrl'
import rootIndex from "../store/root_index"
import { useHistory } from 'react-router-dom/'
import { message } from 'antd'
import { useDispatch } from "react-redux";
import * as action from '../store/actions/index'


import axiosRequest from './../axios-request/request.methods'

const AobDynamicRoute = (props) => {
    const history=useHistory()
    const dispatch = useDispatch()

    useEffect(()=>{
        if (props?.match?.params?.id?.length === 6) {
        let Data ={
            cipherkaye:props.match.params.id,
            cipherkeyavilable:true
        }

        dispatch(action.fetch_cipher_data(Data))
       
            // let axiosConfig = {
            //     headers: {
            //         "Content-Type": "application/json",
            //         // 'Authorization': 'Bearer ' + _store.login.token
            //         "cipher": props.match.params.id
            //     },
            // };

// const decryptedresponse=async()=>{


//         // let options = { secure: false, multipart: false }

//         // const response = await axiosRequest
//         //     .get(`auth/decrypt-link`,options)
              
//         //                 history.push({
//         //                     pathname: "/withoutReferralLinkRegistration",
//         //                     state: {
//         //                         agentName:response?.errMsg?.data?.fullName
//         //                     }
//         //                 })
                
               
        
      

// }

// decryptedresponse()

let options = { secure: false, multipart: false }

axiosRequest.get(`auth/decrypt-link`,options).then((res)=>{
   console.log(res,"ressss==>")
   console.log(res?.errMsg?.data?.fullName,"FullName")
   if(res?.errCode === -1){
    if(res?.errMsg?.data?.hasOwnProperty('fullName')){
    setTimeout(() => {
  history.push({
        pathname: "/withoutReferralLinkRegistration",
        state:{
            agentName:res?.errMsg?.data?.fullName
        }
    })

    },1000)
  
   }
}
})
        }

    },[])
  return (
    <div>
      
    </div>
  )
}

export default AobDynamicRoute
