import React, { useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import FullPageLoader from "./components/FullPageLoader/FullPageLoader";
import CustomerInformationHome from "./oona-home/screens/CustomerInformationHome";
import OonaNotification from "./oona-home/OonaNotification";
import TravelDynamicRoute from "./oona-travel/TravelDynamicRoute";

import AobDynamicRoute from "./oona-aob/AobDynamicRoute";
import CommercialLeads from "./oonaLeads/CommercialLeads";
import { useSelector } from "react-redux";
import HomeQuickQuoteSuccessScreen from "./oona-home/screens/HomeQuickQuoteSuccessScreen";
import HomePaymentScreen from "./oona-home/HomePaymentScreen";
import HomesuccessPrintPolicy from "./oona-home/HomesuccessPrintPolicy";
import HomeprintPolicyError from "./oona-home/screens/HomeprintPolicyError";
import HomeDynamicRoute from "./oona-home/HomeDynamicRoute";
import MotorDynamicRoute from "./oona-motor/MotorDynamicRoute";
import HomeOtp from "./oona-home/HomeOtp";
import MotorOtp from "./oona-motor/motorOtp";
import oonaPaymentResp from "./components/success/oonaPaymentResp";
import AOBsuucessScreenOCR from "./oona-aob/AOBsuucessScreenOCR";
import AOBOCRerror from "./oona-aob/AOBOCRerror";
import AOBCORError from "./oona-aob/AOBCORError";
import AOBCorOCRSuucess from "./oona-aob/AOBCorOCRSuucess";
import AccidentOtp from "./oona-personal/screens/AccidentOtp.js";
import { LanguageProvider } from "./config/LanguageContext";
import OOnaPaylaterSuccess from "./oona-home/screens/OOnaPaylaterSuccess";
import OONApaylaterError from "./oona-home/screens/OONApaylaterError";
import ProfilePartIDTypeError from "./oona-aob/ProfilePartIDTypeError";
import ProfilePartIDTypeSuccess from "./oona-aob/ProfilePartIDTypeSuccess";
import ProfilePartTaxSuucessScreen from "./oona-aob/ProfilePartTaxSuucessScreen";
import OonanotifyPayment from "./oona-home/OonanotifyPayment";
import ChooseProduct from "./oona-motor/createQuote/ChooseProduct";
import AdditionalBenefit from "./oona-motor/createQuote/AdditionalBenifit";
import OrderSummary from "./oona-motor/createQuote/OrderSummary";
import ChooseProductTravel from "./oona-travel/create-quote/chooseProductTravel";
import MultipleCustomerInformationInd from './oona-personal/screens/MultipleCustomerInformationInd.js'
import AdditionalBenefitTravel from "./oona-travel/create-quote/additionalBenefitTravel";
import OrderSummaryTravel from "./oona-travel/create-quote/orderSummaryTravel";
import ChooseProductBrowserBack from "./oona-home/Homebrowserback/ChooseProductBrowserBack";
import AdditionalBenefitBrowserBenefit from "./oona-home/Homebrowserback/AdditionalBenefitBrowserBenefit";
import OrderSummaryBrowserBack from "./oona-home/Homebrowserback/OrderSummaryBrowserBack";
import AccidentDynamicRoute from "./oona-personal/screens/AccidentDynamicRoute.js";
import keycloak from './keyclocklogincomponent/keycloak.js'
import { ReactKeycloakProvider } from "@react-keycloak/web";
import CIDynamicRoute from "./oona-ci/CIDynamicRoute.js";
import CiOTP from "./oona-ci/CiOTP.js";

import Cookies from 'js-cookie';
import { stoageSetter } from './helpers.js'
import dotenv from "dotenv"
import IdleSessionTimeOut from "./keyclocklogincomponent/IdleSessionTimeOut.js";
import axios from "axios";
import useIdleTimer from "./utils/useIdle.js";
import { toast } from "react-toastify";

dotenv.config()

const Login = React.lazy(() => import("./components/Login/Login"));

const Sidebar = React.lazy(() => import("./components/SideBar/SideBar"));

const Tree = React.lazy(() =>
  import("./TreeChart")
)
const OonaHome = React.lazy(() =>
  import("./oona-home/OonaHome")
)

console.log(OonaHome,"OonaHome")
const OonaMotor = React.lazy(() =>
  import("./oona-motor/oonaMotor")
)
const LearningCenter = React.lazy(() =>
  import("./LearningCenter/LearningCenter")
)
const HelpingCenter = React.lazy(() =>
  import("./HelpingCenter/HelpingCenter")
)
const HomeScreen = React.lazy(() =>
  import("../src/oona-home/HomeScreen")
)
const CreateQuote = React.lazy(() =>
  import("./oona-travel/create-quote/createQuote")
)
const CreateHomeQuote = React.lazy(() =>
  import("./oona-home/screens/CreateQuote")
)

const CreateMotorQuote = React.lazy(() =>
  import("./oona-motor/screens/CreateQuote")
)

const OonaCustomerInformationHome = React.lazy(() =>
  import("./oona-home/screens/CustomerInformationHome")
)

const Oonacustomerinformationmotor = React.lazy(() =>
  import("./oona-motor/screens/CustomerInformationMotor")
)

const OonaCustomerConfirmationHome = React.lazy(() =>
  import("./oona-home/screens/CustomerConfirmationHome")
)

// const customerInformationAccident = React.lazy(() =>
//   import("./oona-personal/customerInformationAccident")
// )

// const customerConfirmationAccident = React.lazy(() =>
//   import("./oona-personal/customerConfirmationAccident")
// )


// const customerConfirmationAccident = React.lazy(() =>
//   import("./oona-personal/screens/cust")
// )
const OonaCustomerConfirmationMotor = React.lazy(() =>
  import("./oona-motor/screens/CustomerConfirmationMotor")
)

const OonaConfirmation = React.lazy(() =>
  import("./oona-travel/create-quote/screens/confirmationDetails")
)


const commercialHome = React.lazy(() =>
  import("./commercial-home/commercialHome")
)


const Recruit = React.lazy(() =>
  import("./Recruit/Recruit")
)


const OonaCustomerInformation = React.lazy(() =>
  import("./oona-travel/create-quote/screens/customerInformation")
)

const OonaSiBuildingReference = React.lazy(() =>
  import("./oona-home/screens/BuildingSiReference")
)
const AccidentScreen = React.lazy(() =>
  import("../src/oona-personal/AccidentScreen")
)
const AccidentOrderSummaryScreen = React.lazy(() =>
  import("./oona-personal/screens/AccidentOrderSummary")
)
const AccidentQuoteSuccess = React.lazy(() =>
  import("./oona-personal/screens/AccidentQuickQuotesSuccessScreen")
)

const LinkNotValid = React.lazy(() =>
  import("./oona-personal/screens/LinkNotValid.js")
)
const LinkNotMatch = React.lazy(() =>
  import("./oona-personal/screens/LinkNotMatch.js")
)
const inactiveUser = React.lazy(() =>
  import("./components/Inactivepage.js")
)
const AccidentSeperateRequestLimits = React.lazy(() =>
  import("./oona-personal/screens/SeperateRequestLimit")
)
const AccidentCustomerInformation = React.lazy(() =>
  import("./oona-personal/screens/CustomerInformation")
)
const AccidentMultipleCustomerInformation = React.lazy(() =>
  import("./oona-personal/screens/MutlipleCustomerInformation")
)
const AccidentCustomerDetails = React.lazy(() =>
  import("./oona-personal/screens/CustomerDetails")
)
const AccidentPaymentConfirmation = React.lazy(() =>
  import("./oona-personal/screens/PaymentConfirmation")
)
const AccidentConfirmationSuccess= React.lazy(() =>
  import("./oona-personal/screens/AccidentSuccess.js")
)
const AccidentPayLater= React.lazy(() =>
  import("./oona-personal/screens/PayLater")
)



// const OonaAob = React.lazy(() =>
//   import("./oonaAob/oonaAob")
// )

// const AobWithreferralCodeRoute = React.lazy(() =>
//   import("./oonaAob/aobWithReferralcode/AobWithreferralCode"))

// const AobWithReferralCodeOTPAuthRoute = React.lazy(() =>
//   import("./oonaAob/aobWithReferralcode/AobWithReferralCodeOTPAuth")
// )



// const AobWithreferralCodeRoute=React.lazy(()=>
// import("./oonaAob/aobWithReferralcode/AobWithreferralCode"))

// const AobWithReferralCodeOTPAuthRoute=React.lazy(()=>
// import("./oonaAob/aobWithReferralcode/AobWithReferralCodeOTPAuth"))

// const AobWithreferralCodeRoute = React.lazy(() =>
//   import("./oonaAob/AOB/AOBWithReferralCode/AOBWithReferralCode"))

// const AobWithReferralCodeOTPAuthRoute = React.lazy(() =>
//   import("./oonaAob/AOB/AOBWithReferralCode/AOBWithReferralCode"))



// const OonaAobSuccessAuthentication = React.lazy(() =>
//   import('./oonaAob/otpConfirmation/OTPConfirmation')
// )

// const AobMitraRegistrationForm = React.lazy(() =>
//   import('./oonaAob/LinkRegistration/IndividualmitraRegistrationform')
// )
// const IndividualIDCarddetails = React.lazy(() =>
//   import('./oonaAob/LinkRegistration/IndividualIDCarddetails')
// )

// const IndividualmitraTermsandConditions = React.lazy(() =>
//   import('./oonaAob/LinkRegistration/IndividualmitraTermsandConditions'))
// const TaxPayerandBankAccountDetails = React.lazy(() =>
//   import('./oonaAob/LinkRegistration/TaxPayerandBankAccountDetails')
// )

// const TaxPayersTermsandConditions = React.lazy(() =>
//   import('./oonaAob/LinkRegistration/TaxPayersTermsandConditions'))


// /*corporate*/
// const CorporateTermsandConditions = React.lazy(() =>
//   import('./oonaAob/LinkRegistration/CorporateMitraRegistration/CorporateTermsandRegistration'))

// const CorporateIDCardDetails = React.lazy(() =>
//   import('./oonaAob/LinkRegistration/CorporateMitraRegistration/CorporateIDCardDetails'))

// const CorporateIdCardtermsandConditions = React.lazy(() =>
//   import('./oonaAob/LinkRegistration/CorporateMitraRegistration/CorporateIdCardtermsandConditions'))

// const reuploadphotos = React.lazy(() =>
//   import('./oonaAob/LinkRegistration/ReuploadPhotos'))


const OonaSuccess = React.lazy(() =>
  import("./components/success/oonaSuccess")
)


const OonaError1 = React.lazy(() =>
  import("./components/error/oonaError1")
)


const OonaSuccess1 = React.lazy(() =>
  import("./components/success/oonaSuccess1")
)

// const KahoonaSuccess = React.lazy(() =>
//   import("./components/success/KahoonaSuccess")
// )

const OonaError = React.lazy(() =>
  import("./components/error/oonaError")
)

const KahoonaError = React.lazy(() =>
  import("./components/error/KahoonaError"))


const OonaPayment = React.lazy(() =>
  import("./oona-payment/oonaPayment")
)


const MotorPayment = React.lazy(() =>
  import("./oona-payment/MotorPayment")
)

const OonaTravel = React.lazy(() =>
  import("./oona-travel/oonaTravel")
)

const oonaLeads = React.lazy(() =>
  import("./oonaLeads/oonaLeads")
);

const AboutUs = React.lazy(() =>
  import("../src/AboutUs/AboutUs")
)

const FAQContent = React.lazy(() =>
  import("../src/AboutUs/FAQContent")
)

const CommercialSupport = React.lazy(() =>
  import("./commercialSupport/CommercialSupport")
);

const oonaWithOutReferralLinkRegistration = React.lazy(() =>
  import('./oona-aob/linkRegistration/WithOutReferralLinkRegistration'))

const oonaAOBMitraRegistrationForm = React.lazy(() =>
  import('./oona-aob/registrationForm/MitraRegistrationForm'))
// const oonaMitraIndividualIdCardDetails = React.lazy(() =>
//   import('./oona-aob/idCarddetails/MitraIndividualIdCardDetails'))
const oonaMitraCorporateIdCardDetails = React.lazy(() =>
  import('./oona-aob/idCarddetails/MitraCorporateIdCardDetails'))

const oonaIndividualTaxPayerandBankAccountDetails = React.lazy(() =>
  import('./oona-aob/taxPayerandBankAccountDetails/IndividualTaxPayerandBankAccountDetails'))
const oonaCorporateTaxPayerandBankAccountDetails = React.lazy(() =>
  import('./oona-aob/taxPayerandBankAccountDetails/CorporateTaxPayerandBankAccountDetails'))

const oonaReUploadPhotos = React.lazy(() =>
  import("./oona-aob/reupload-photos/ReUploadPhotos"))

const TravelOtp = React.lazy(() =>
  import("./oona-travel/travelOtp.js")
);


const oonaPreview = React.lazy(() =>
  import("./oona-motor/screens/oonaPreview"))


const OonaSorryScreen = React.lazy(() =>
  import("./oona-motor/screens/OonaSorryScreen"))


const OonaTodo = React.lazy(() =>
  import("./OonaTodo/OonaTodo"))


const CommercialTodo = React.lazy(() =>
  import("./OonaTodo/CommercialTodo"))


const MotorReuploadPhotos = React.lazy(() =>
  import("./oona-motor/screens/uploadPhotos"))


const oonaIndividualTermsAndConditions = React.lazy(() =>
  import("./oona-aob/termsAndConditions/IndividualTermsAndConditions"))
const oonaCorporateTermsAndConditions = React.lazy(() =>
  import("./oona-aob/termsAndConditions/CorporateTermsAndConditions"))

const oonaITaxPayerTaxandConditions = React.lazy(() =>
  import('./oona-aob/termsAndConditions/TaxPayerTermsandConditions/ITaxPayerTaxandConditions'))

const oonaCTaxPayerTaxandConditions = React.lazy(() =>
  import('./oona-aob/termsAndConditions/TaxPayerTermsandConditions/CTaxPayerTaxandConditions'))

const oonaOConfirmation = React.lazy(() =>
  import('./oona-aob/linkRegistration/OTPConfirmation'))


const oonaRiskInspectionMotor = React.lazy(() =>
  import('./components/OonaRiskInspection/OonaRiskInspection'))


const oonaRiskInspectionBike = React.lazy(() =>
  import('./components/OonaRiskInspection/OonaRiskInspectionBike'))


const oonaIndividualIdCardTermsandConditions = React.lazy(() => import("./oona-aob/termsAndConditions/IDCardTermsandConditions/IndividualIdCardTermsandConditions"))
const oonaCorporateIdCardTermsandConditions = React.lazy(() => import("./oona-aob/termsAndConditions/IDCardTermsandConditions/CorporateIdCardTermsandConditions"))
const oonaAObSuccess = React.lazy(() => import('./oona-aob/alert-templates/oonaSuccess/OonaSuccess'))
const oonaAoboonaReferralSuccess = React.lazy(() => import('./oona-aob/alert-templates/oonaSuccess/OonaReferralSuccesstemplate'))
const oonaErrorTemplate = React.lazy(() => import("./oona-aob/alert-templates/oonaError/OonaErrortemplate"))
const oonaWithReferralCodeAOB = React.lazy(() => import("./oona-aob/linkRegistration/withReferralCode/WithReferralCodeAOB"))
const oonasdk = React.lazy(() => import('./oona-aob/idCarddetails/sdk/SDK'))


/*customer details*/

function App() {
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false
      },
      "google_translate_element"
    );
  };
  const storestate = useSelector(state => state)


// const onIdle = () => {
//   // Perform logout action
//   if(localStorage.getItem('keyclock__refreshToken__parsed') !== null){
//     const fetchingrefreshToken=localStorage?.getItem("keyclock__refreshToken__parsed")
//       console.log(fetchingrefreshToken,"fetchingrefreshToken")
//       const convertingepochTimestamp=new Date(fetchingrefreshToken * 1000)
//       console.log(convertingepochTimestamp,"convertingepochTimestamp")
//       const currentTime = new Date();

//       if(currentTime >= convertingepochTimestamp  ){
//           const keycloakBaseUrl = process.env.REACT_APP_KEYCLOCK_BASE_URL;
//           const realm = process.env.REACT_APP_REALM_ID;
//           const clientId = process.env.REACT_APP_CLIENT_ID;
//           toast.error("Session Expired", { position: "top-center", hideProgressBar: true, autoClose: 1000 })
//           const logoutUrl = `${keycloakBaseUrl}/realms/${realm}/protocol/openid-connect/logout`;
//           const formData = new URLSearchParams();
//    formData.append('refresh_token', localStorage.getItem('keyclock__refreshToken'));
//    formData.append('client_id', clientId);

//   // Make the POST request with application/x-www-form-urlencoded content type
//   axios.post(logoutUrl,formData, {
//     headers: {
//       'Accept':"*",
//       'Content-Type': 'application/x-www-form-urlencoded',
//       'Authorization': `Bearer ${localStorage.getItem('keyclock__accessToken')}`
//     },
//     withCredentials: true
//   }).then(response => {
//     console.log('Logout successful:', response);
//     window.location.href = process.env.REACT_APP_REDIRECINGURL;
//     localStorage.clear()
  
//   })
//   .catch(error => {
//     console.error('Logout failed:', error);
//   });
// }
//       }
// };
//   const fetchingrefreshToken=localStorage?.getItem("keyclock__refreshToken__parsed")
//   console.log(fetchingrefreshToken,"fetchingrefreshToken")
//   const convertingepochTimestamp=new Date(fetchingrefreshToken * 1000)
//   console.log(convertingepochTimestamp,"convertingepochTimestamp")
//   const currentTime = new Date();
//   const findingdifferencesbetweenTwotimings=convertingepochTimestamp-currentTime
//   // const differenceInSeconds = Math.floor(findingdifferencesbetweenTwotimings / 1000);
//   // console.log(differenceInSeconds,"differenceInSeconds")
//   useIdleTimer(onIdle, findingdifferencesbetweenTwotimings); 
  


  return (

 
    <ReactKeycloakProvider authClient={keycloak}  >
    <React.Suspense fallback={<FullPageLoader fromapploader={true} />}>
      <LanguageProvider>
        <Router>
          <div className="box-size">
            <Switch>
              <Route exact path="/">
                <Redirect to="/login" />
              </Route>
              <Route path="/login" component={Login}></Route>
              <Route path="/registerhere" component={oonaWithOutReferralLinkRegistration}/>
              <Route
                path="/travel/:id"
                component={TravelDynamicRoute}
              />
              <Route
                path="/motor/:id"
                component={MotorDynamicRoute}
              />
              <Route path="/home/:id" component={HomeDynamicRoute} />
              <Route path="/pa/:id" component={AccidentDynamicRoute}/>
              <Route path="/ci/:id" component={CIDynamicRoute}/>
              <Route path="/withoutReferralLinkRegistration/:id" component={AobDynamicRoute} />
			  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? <Route path="/linknotvalid" component={LinkNotValid}/> : null}
        {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? 
        <Route path="/linknotmatch" component={LinkNotMatch}/> 
        : null}
               <Route path="/inactiveuser" component={inactiveUser}/> 
              {/* {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/oonatravel"
                    component={OonaTravel}
                  />
                  : null
              }
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/createquote"
                    component={CreateQuote}
                  />
                  : null
              }
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/oonasuccess"
                    component={OonaSuccess}
                  />
                  : null
              }
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/oonacustomerinformation"
                    component={OonaCustomerInformation}
                  />
                  : null
              }
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/chooseproducttravel"
                    component={ChooseProductTravel}
                  />
                  : null
              }
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/additionalbenefittravel"
                    component={AdditionalBenefitTravel}
                  />
                  : null
              }
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/ordersummarytravel"
                    component={OrderSummaryTravel}
                  />
                  : null
              }
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/oonaconfirmation"
                    component={OonaConfirmation}
                  />
                  : null
              } */}
              {/* {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/oonapayment"
                    component={OonaPayment}
                  />
                  : null
              } */}
               {/* <Route
                    path="/oonapayment"
                    component={OonaPayment}
                  />

              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/oonasuccess1"
                    component={OonaSuccess1}
                  />
                  : null
              }

              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/oonaerror"
                    component={OonaError}
                  />
                  : null
              }
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/oonaerror1"
                    component={OonaError1}
                  />
                  : null
              } */}

{/* travel routes */}
<Route path="/oonatravel" component={OonaTravel}/>
 <Route path="/createquote" component={CreateQuote}/>
 <Route path="/oonasuccess" component={OonaSuccess}/>
 <Route path="/oonacustomerinformation" component={OonaCustomerInformation}/>
  <Route path="/chooseproducttravel" component={ChooseProductTravel}/>
 <Route path="/additionalbenefittravel" component={AdditionalBenefitTravel}/>
 <Route path="/ordersummarytravel" component={OrderSummaryTravel}/>
 <Route path="/oonaconfirmation" component={OonaConfirmation}/>
<Route path="/oonapayment" component={OonaPayment}/>
<Route path="/oonasuccess1" component={OonaSuccess1}/>
  <Route path="/oonaerror" component={OonaError}/>
  <Route path="/oonaerror1" component={OonaError1}/>


             {/* home Routes */}

<Route path="/oonahomescreen" component={HomeScreen} />
<Route path="/homepaymentScreen" component={HomePaymentScreen} />
<Route path="/home-success-print-policy" component={HomesuccessPrintPolicy} />
<Route path="/home-print-policy-error" component={HomeprintPolicyError} />
<Route path="/createhomequote" component={CreateHomeQuote} />
<Route path="/home-quick-quote-success-screen" component={HomeQuickQuoteSuccessScreen} />
<Route path="/customerinformationhome" component={OonaCustomerInformationHome} />
<Route path="/customerconfirmationhome" component={OonaCustomerConfirmationHome} />
<Route path="/homeOtp" component={HomeOtp} />
<Route path="/paylater-success" component={OOnaPaylaterSuccess} />
<Route path="/paylater-error" component={OONApaylaterError} />
<Route path="/home-choose-product" component={ChooseProductBrowserBack} />
<Route path="/home-additional-benefit" component={AdditionalBenefitBrowserBenefit} />
<Route path="/home-order-summary" component={OrderSummaryBrowserBack} />


{/* mvRoutes */}

<Route path="/motorscreen" component={OonaMotor}/>
 <Route path="/motorordersummary" component={OrderSummary}/>
 <Route path="/createmotorquote" component={CreateMotorQuote}/>
 <Route path="/motorchooseproduct" component={ChooseProduct}/>
 <Route path="/motoradditionalbenefits" component={AdditionalBenefit}/>
 <Route path="/customerinformationmotor" component={Oonacustomerinformationmotor}/>
 <Route path="/customerconfirmationmotor"component={OonaCustomerConfirmationMotor}/>
 <Route path="/oonaconfirmationMotor"component={OonaCustomerConfirmationMotor}/>
 <Route path="/motorpayment" component={MotorPayment}/>
 <Route path="/oonasuccess1" component={OonaSuccess1}/>
 <Route path="/oonaerror" component={OonaError}/>
 <Route path="/oonaerror1" component={OonaError1}/>
  <Route path="/oonariskinspectionbike"component={oonaRiskInspectionBike}/>
  <Route path="/oonariskinspectionmotor" component={oonaRiskInspectionMotor}/>
  <Route path="/paymentresponse" component={oonaPaymentResp}/>
  <Route path="/oonamotorPreview" component={oonaPreview}/>
<Route path="/motorreupload" component={MotorReuploadPhotos}/>





              {/* {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/oonahomescreen" component={HomeScreen} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/homepaymentScreen" component={HomePaymentScreen} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/home-success-print-policy" component={HomesuccessPrintPolicy} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/home-print-policy-error" component={HomeprintPolicyError} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/createhomequote" component={CreateHomeQuote} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/home-quick-quote-success-screen" component={HomeQuickQuoteSuccessScreen} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/customerinformationhome" component={OonaCustomerInformationHome} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/customerconfirmationhome" component={OonaCustomerConfirmationHome} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/homeOtp" component={HomeOtp} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/paylater-success" component={OOnaPaylaterSuccess} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/paylater-error" component={OONApaylaterError} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/home-choose-product" component={ChooseProductBrowserBack} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/home-additional-benefit" component={AdditionalBenefitBrowserBenefit} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/home-order-summary" component={OrderSummaryBrowserBack} />) : null} */}






              {/* {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/motorscreen"
                    component={OonaMotor}
                  />
                  : null
              }
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/motorordersummary"
                    component={OrderSummary}
                  />
                  : null
              }
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/createmotorquote"
                    component={CreateMotorQuote}
                  />
                  : null
              }
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/motorchooseproduct"
                    component={ChooseProduct}
                  />
                  : null
              }
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/motoradditionalbenefits"
                    component={AdditionalBenefit}
                  />
                  : null
              }

              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/customerinformationmotor"
                    component={Oonacustomerinformationmotor}
                  />
                  : null
              }
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/customerconfirmationmotor"
                    component={OonaCustomerConfirmationMotor}
                  />
                  : null
              }
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/oonaconfirmationMotor"
                    component={OonaCustomerConfirmationMotor}
                  />
                  : null
              }
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/motorpayment"
                    component={MotorPayment}
                  />
                  : null
              }
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/oonasuccess1"
                    component={OonaSuccess1}
                  />
                  : null
              }

              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/oonaerror"
                    component={OonaError}
                  />
                  : null
              }
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/oonaerror1"
                    component={OonaError1}
                  />
                  : null
              }

              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/oonariskinspectionbike"
                    component={oonaRiskInspectionBike}
                  />

                  : null
              }

              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?

                  <Route
                    path="/oonariskinspectionmotor"
                    component={oonaRiskInspectionMotor}
                  />
                  : null
              }
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?

                  <Route
                    path="/paymentresponse"
                    component={oonaPaymentResp}
                  />
                  : null
              } */}


              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/withoutReferralLinkRegistration" component={oonaWithOutReferralLinkRegistration} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/aobOTPConfirmation" component={oonaOConfirmation} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/AOBmitraRegistrationForm" component={oonaAOBMitraRegistrationForm} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/AOBIndividualIdCardTermsandConditions" component={oonaIndividualIdCardTermsandConditions} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/AOBCorporateIdCardTermsandConditions" component={oonaCorporateIdCardTermsandConditions} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/individualTaxPayerandBankAccountDetails" component={oonaIndividualTaxPayerandBankAccountDetails} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/corporateTaxPayerandBankAccountDetails" component={oonaCorporateTaxPayerandBankAccountDetails} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/AOBITaxTermsandConditions" component={oonaITaxPayerTaxandConditions} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/AOBCTaxPayerTaxandConditions" component={oonaCTaxPayerTaxandConditions} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/aob-success" component={oonaAObSuccess} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/aob-error" component={oonaErrorTemplate} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/sdk" component={oonasdk} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/AOBIndividualTermsAndConditions" component={oonaIndividualTermsAndConditions} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/AOBCorporateTermsAndConditions" component={oonaCorporateTermsAndConditions} />) : null}
              {/* {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/AOBIndividualIdCardTermsandConditions" component={oonaIndividualIdCardTermsandConditions} />) : null} */}
              {/* {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/AOBCorporateIdCardTermsandConditions" component={oonaCorporateIdCardTermsandConditions} />) : null} */}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/aob-ocr-success-screen" component={AOBsuucessScreenOCR} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/aob-ocr-error" component={AOBOCRerror} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/aob-ocr-cor-error" component={AOBCORError} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/aob-ocr-cor-success" component={AOBCorOCRSuucess} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/id-card-data-error" component={ProfilePartIDTypeError} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/id-card-data-success" component={ProfilePartIDTypeSuccess} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/tax-id-data-success" component={ProfilePartTaxSuucessScreen} />) : null}


              {/* {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route
                path="/oonamotorPreview"
                component={oonaPreview}
              />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? <Route
                path="/motorreupload"
                component={MotorReuploadPhotos}
              /> : null} */}
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? <Route
                  path="/oonafailure"
                  component={OonaSorryScreen}
                /> : null
              }
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? <Route
                  path="/motor/:id"
                  component={MotorDynamicRoute}

                /> : null
              }
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? 
                <Route path="/home/:id" component={HomeDynamicRoute} />
                  : null
              }
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? <Route path="/withoutReferralLinkRegistration/:id" component={AobDynamicRoute} />

                  : null
              }

              {/* {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/oonatravel"
                    component={OonaTravel}
                  />
                  : null
              }
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/createquote"
                    component={CreateQuote}
                  />
                  : null
              }
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/oonasuccess"
                    component={OonaSuccess}
                  />
                  : null
              }
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/oonacustomerinformation"
                    component={OonaCustomerInformation}
                  />
                  : null
              }
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/oonaconfirmation"
                    component={OonaConfirmation}
                  />
                  : null
              }
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/oonapayment"
                    component={OonaPayment}
                  />
                  : null
              }
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/oonasuccess1"
                    component={OonaSuccess1}
                  />
                  : null
              }

              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/oonaerror"
                    component={OonaError}
                  />
                  : null
              }
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/oonaerror1"
                    component={OonaError1}
                  />
                  : null
              }

              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/travelotp"
                    component={TravelOtp}
                  />
                  : null
              }

              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/motorotp"
                    component={MotorOtp}
                  />
                  : null
              } */}





              {/* {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/oonahomescreen" component={HomeScreen} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/homepaymentScreen" component={HomePaymentScreen} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/home-success-print-policy" component={HomesuccessPrintPolicy} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/home-print-policy-error" component={HomeprintPolicyError} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/createhomequote" component={CreateHomeQuote} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/home-quick-quote-success-screen" component={HomeQuickQuoteSuccessScreen} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/customerinformationhome" component={OonaCustomerInformationHome} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/customerconfirmationhome" component={OonaCustomerConfirmationHome} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/homeOtp" component={HomeOtp} />) : null} */}


              <Route
                path="/tree"
                component={Tree}
              />

              {/* {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/motorscreen"
                    component={OonaMotor}
                  />
                  : null
              }
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/createmotorquote"
                    component={CreateMotorQuote}
                  />
                  : null
              }

              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/customerinformationmotor"
                    component={Oonacustomerinformationmotor}
                  />
                  : null
              }
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/customerconfirmationmotor"
                    component={OonaCustomerInformation}
                  />
                  : null
              }
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/oonaconfirmationMotor"
                    component={OonaCustomerConfirmationMotor}
                  />
                  : null
              }
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/oonapayment"
                    component={OonaPayment}
                  />
                  : null
              }
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/oonasuccess1"
                    component={OonaSuccess1}
                  />
                  : null
              }

              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/oonaerror"
                    component={OonaError}
                  />
                  : null
              }
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/oonaerror1"
                    component={OonaError1}
                  />
                  : null
              }

              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                  <Route
                    path="/oonariskinspectionbike"
                    component={oonaRiskInspectionBike}
                  />

                  : null
              }

              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?

                  <Route
                    path="/oonariskinspectionmotor"
                    component={oonaRiskInspectionMotor}
                  />
                  : null
              }
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?

                  <Route
                    path="/paymentresponse"
                    component={oonaPaymentResp}
                  />
                  : null
              } */}
              {/* {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/withoutReferralLinkRegistration" component={oonaWithOutReferralLinkRegistration} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/aobOTPConfirmation" component={oonaOConfirmation} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/AOBmitraRegistrationForm" component={oonaAOBMitraRegistrationForm} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/AOBIndividualIdCardTermsandConditions" component={oonaIndividualIdCardTermsandConditions} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/AOBCorporateIdCardTermsandConditions" component={oonaCorporateIdCardTermsandConditions} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/individualTaxPayerandBankAccountDetails" component={oonaIndividualTaxPayerandBankAccountDetails} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/corporateTaxPayerandBankAccountDetails" component={oonaCorporateTaxPayerandBankAccountDetails} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/AOBITaxTermsandConditions" component={oonaITaxPayerTaxandConditions} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/AOBCTaxPayerTaxandConditions" component={oonaCTaxPayerTaxandConditions} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/aob-success" component={oonaAObSuccess} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/aob-error" component={oonaErrorTemplate} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/sdk" component={oonasdk} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/AOBIndividualTermsAndConditions" component={oonaIndividualTermsAndConditions} />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/AOBCorporateTermsAndConditions" component={oonaCorporateTermsAndConditions} />) : null} */}



              {/* {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route
                path="/oonamotorPreview"
                component={oonaPreview}
              />) : null}
              {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? <Route
                path="/motorreupload"
                component={MotorReuploadPhotos}
              /> : null} */}
              {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? <Route
                  path="/oonafailure"
                  component={OonaSorryScreen}
                />
                  : null
              }
              {/* <Route path="/Master/:masterType" component={Master}></Route> */}

               {/* accident policy cutomer flow routes */}

               {/*  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? <Route path="/pa/:id" component={AccidentDynamicRoute} /> : null}
               {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/accidentOtp" component={AccidentOtp} />) : null}
               {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? <Route path="/oonaaccidentscreen" component={AccidentScreen} />  : null}
               {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? <Route path="/accidentordersummary" component={AccidentOrderSummaryScreen} />  : null}
               {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? <Route path="/accidentcustomerinformation" component={AccidentCustomerInformation} /> : null}
               {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? <Route path="/accidentcustomerdetails" component={AccidentCustomerDetails} /> : null}
               {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? <Route path="/accidentpaymentconfirmation" component={AccidentPaymentConfirmation}/> : null}
               {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?<Route path="/accidentquotesuccess" component={AccidentQuoteSuccess} /> : null}
               {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? <Route path="/accidentmultiplecustomerinformation" component={AccidentMultipleCustomerInformation} /> : null}
               {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? <Route path="/accidential-insurance-policy-insured-details" component={MultipleCustomerInformationInd} /> : null}
               {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? <Route path="/accidentseperaterequestlimits" component={AccidentSeperateRequestLimits} /> : null}
               {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? <Route path="/accidentpaylater" component={AccidentPayLater} /> : null}*/}
                
                <Route path="/pa/:id" component={AccidentDynamicRoute} />
<Route path="/accidentOtp" component={AccidentOtp} />
<Route path="/oonaaccidentscreen" component={AccidentScreen} />
<Route path="/accidentordersummary" component={AccidentOrderSummaryScreen} />
<Route path="/accidentcustomerinformation" component={AccidentCustomerInformation} />
 <Route path="/accidentcustomerdetails" component={AccidentCustomerDetails} />
<Route path="/accidentpaymentconfirmation" component={AccidentPaymentConfirmation}/>
<Route path="/accidentquotesuccess" component={AccidentQuoteSuccess} />
<Route path="/accidentmultiplecustomerinformation" component={AccidentMultipleCustomerInformation} />
 <Route path="/accidential-insurance-policy-insured-details" component={MultipleCustomerInformationInd} />
<Route path="/accidentseperaterequestlimits" component={AccidentSeperateRequestLimits} />
<Route path="/accidentpaylater" component={AccidentPayLater} />

                {/* ci */}

                {
                storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? <Route path="/ci/:id" component={CIDynamicRoute} />
                  : null
                }
                 {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (<Route path="/ciOtp" component={CiOTP} />) : null}
                
              <div>
                <Sidebar />

                <Route
                  path="/recruit"
                  component={Recruit}
                />
                <div style={{ marginTop: "60px" }}>
                  <Route
                    path="/oonahome"
                    component={OonaHome}
                  />
                  <Route
                    path="/oonalearningcenter"
                    component={LearningCenter}
                  />
                  <Route
                    path="/commercialtodo"
                    component={CommercialTodo}
                  />
                  <Route
                    path="/oonafaq"
                    component={FAQContent}
                  />
                  <Route
                    path="/oonahelpingcenter"
                    component={HelpingCenter}
                  />
                  <Route
                    path="/oonafailure"
                    component={OonaSorryScreen}
                  />

                  <Route
                    path="/commercialhome"
                    component={commercialHome}
                  />

                  <Route
                    path="/motorscreen"
                    component={OonaMotor}
                  />

                  <Route
                    path="/oonanotification"
                    component={OonaNotification}
                  />

                  <Route
                    path="/commercialsupport"
                    component={CommercialSupport}
                  />

                  {/* <Route path="/kahoonaSuccess" component={KahoonaSuccess} /> */}
                  {/* <Route
                  path="/oonaerror"
                  component={OonaError}
                />
                <Route
                  path="/oonaerror1"
                  component={OonaError1}
                /> */}
                  <Route
                    path="/buildingreference"
                    component={OonaSiBuildingReference}
                  />

                  <Route
                    path="/aboutus"
                    component={AboutUs}
                  />


                  {/* <Route path="/reuploadphotos" component={reuploadphotos} />

                  <Route path="/kahoonaError" component={KahoonaError} /> */}
                  {/* <Route
                  path="/oonatravel"
                  component={OonaTravel}
                /> */}
                  {/* {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ?
              <Route
                  path="/dynaicrouting/:id"
                  component={TravelDynamicRoute}
                />
               : null
               } */}
                  {/* <Route
                  path="/oonatravel"
                  component={OonaTravel}
                /> */}


                  {/* <Route path="/informationaccident" component={customerInformationAccident} /> */}

                  {/* <Route path="/confirmationpersonalaccident" component={customerConfirmationAccident} /> */}


                  {
                    storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ?
                      <Route
                        path="/oonatravel"
                        component={OonaTravel}
                      />
                      : null
                  }
                  {
                    storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ?
                      <Route
                        path="/createquote"
                        component={CreateQuote}
                      />
                      : null
                  }

                  {
                    storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ?
                      <Route
                        path="/oonaconfirmation"
                        component={OonaConfirmation}
                      />
                      : null
                  }
                  {
                    storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? <Route
                      path="/oonafailure"
                      component={OonaSorryScreen}
                    />
                      : null
                  }
                  {
                    storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ?
                      <Route
                        path="/oonacustomerinformation"
                        component={OonaCustomerInformation}
                      />
                      : null
                  }
                  {
                    storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ?
                      <Route
                        path="/chooseproducttravel"
                        component={ChooseProductTravel}
                      />
                      : null
                  }
                  {
                    storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ?
                      <Route
                        path="/additionalbenefittravel"
                        component={AdditionalBenefitTravel}
                      />
                      : null
                  }
                  {
                    storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ?
                      <Route
                        path="/ordersummarytravel"
                        component={OrderSummaryTravel}
                      />
                      : null
                  }
                  {
                    storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ?
                      <Route
                        path="/oonapayment"
                        component={OonaPayment}
                      />
                      : null
                  }
                  {
                    storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ?

                      <Route
                        path="/paymentresponse"
                        component={oonaPaymentResp}
                      />
                      : null}

                  {
                    storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ?
                      <Route
                        path="/oonamotorPreview"
                        component={oonaPreview}
                      />
                      : null
                  }
                  {
                    storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ?
                      <Route
                        path="/motorotp"
                        component={MotorOtp}
                      />
                      : null
                  }

                  {
                    storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ?
                      <Route
                        path="/oonasuccess"
                        component={OonaSuccess}
                      />
                      : null
                  }
                  {
                    storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ?
                      <Route
                        path="/motorordersummary"
                        component={OrderSummary}
                      />
                      : null
                  }
                  {
                    storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ?
                      <Route
                        path="/oonasuccess1"
                        component={OonaSuccess1}
                      />
                      : null
                  }

                  {
                    storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ?
                      <Route
                        path="/oonaerror"
                        component={OonaError}
                      />
                      : null
                  }


                  {
                    storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ?
                      <Route
                        path="/travelotp"
                        component={TravelOtp}
                      />
                      : null
                  }

                  {
                    storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ?
                      <Route
                        path="/oonaerror1"
                        component={OonaError1}
                      />
                      : null
                  }

                  {
                    storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ?
                      <Route
                        path="/motorchooseproduct"
                        component={ChooseProduct}
                      />
                      : null
                  }
                  {
                    storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ?
                      <Route
                        path="/motoradditionalbenefits"
                        component={AdditionalBenefit}
                      />
                      : null
                  }



                  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/oonahomescreen" component={HomeScreen} />) : null}
                  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/homepaymentScreen" component={HomePaymentScreen} />) : null}
                  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/home-success-print-policy" component={HomesuccessPrintPolicy} />) : null}
                  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/home-print-policy-error" component={HomeprintPolicyError} />) : null}
                  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/createhomequote" component={CreateHomeQuote} />) : null}
                  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/home-quick-quote-success-screen" component={HomeQuickQuoteSuccessScreen} />) : null}
                  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/customerinformationhome" component={OonaCustomerInformationHome} />) : null}
                  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/customerconfirmationhome" component={OonaCustomerConfirmationHome} />) : null}
                  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/homeOtp" component={HomeOtp} />) : null}
                  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/paylater-success" component={OOnaPaylaterSuccess} />) : null}
                  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/paylater-error" component={OONApaylaterError} />) : null}
                  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/home-choose-product" component={ChooseProductBrowserBack} />) : null}
                  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/home-additional-benefit" component={AdditionalBenefitBrowserBenefit} />) : null}
                  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/home-order-summary" component={OrderSummaryBrowserBack} />) : null}

                  <Route path="/oonatodo" component={OonaTodo} />

                  <Route path="/notificationpaymentpending" component={OonanotifyPayment} />


                  {/* <Route
                  path="/oonahomescreen"
                  component={HomeScreen}
                />
                <Route path="/homepaymentScreen" component={HomePaymentScreen} />
                <Route path="/home-success-print-policy" component={HomesuccessPrintPolicy} />
                <Route path="/home-print-policy-error" component={HomeprintPolicyError} /> */}

                  {/* <Route
                  path="/createquote"
                  component={CreateQuote}
                /> */}


                  {
                    storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ?

                      <Route
                        path="/oonariskinspectionmotor"
                        component={oonaRiskInspectionMotor}
                      /> : null
                  }
                  {
                    storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ?

                      <Route
                        path="/oonariskinspectionbike"
                        component={oonaRiskInspectionBike}
                      /> : null
                  }
                  {
                    storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ?
                      <Route
                        path="/createmotorquote"
                        component={CreateMotorQuote}
                      />
                      : null
                  }
                  {
                    storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ?
                      <Route
                        path="/motorreupload"
                        component={MotorReuploadPhotos}
                      />
                      : null
                  }

                  {/* {
                  storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ?
                    <Route
                      path="/createmotorquote"
                      component={CreateMotorQuote}
                    />
                    : null
                } */}
                  {/* {
                  storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ?
                    <Route
                      path="/"
                      component={CreateMotorQuote}
                    />
                    : null
                } */}

                  {/* <Route
                  path="/createhomequote"
                  component={CreateHomeQuote}
                /> */}

                  {/* <Route path="/home-quick-quote-success-screen" component={HomeQuickQuoteSuccessScreen} /> */}

                  <Route
                    path="/oonacomleads"
                    component={CommercialLeads}
                  />


                  {
                    storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ?
                      <Route
                        path="/customerinformationmotor"
                        component={Oonacustomerinformationmotor}
                      /> : null
                  }
                  {
                    storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ?
                      <Route
                        path="/customerconfirmationmotor"
                        component={OonaCustomerConfirmationMotor}
                      />
                      : null}
                  <Route
                    path="/oonaleads"
                    component={oonaLeads}
                  />
                  {/* <Route
                    path="/oonaAob"
                    component={OonaAob}
                  />

                  <Route path="/oonaAobsucessauthentication"
                    component={OonaAobSuccessAuthentication}
                  />

                  <Route path="/aobWithreferralCode"
                    component={AobWithreferralCodeRoute}
                  />

                  <Route path="/aobWithReferralCodeOTPAuth"
                    component={AobWithReferralCodeOTPAuthRoute}
                  />

                  <Route path="/mitraRegistrationForm" component={AobMitraRegistrationForm} />
                  <Route path="/mitraIndividualIDCarddetails" component={IndividualIDCarddetails} />
                  <Route path="/IndividualmitraTermsandConditions" component={IndividualmitraTermsandConditions} />
                  <Route path="/taxPayerandBankAccountDetails" component={TaxPayerandBankAccountDetails} />
                  <Route path="/taxPayersTermsandConditions" component={TaxPayersTermsandConditions} />
                  <Route path="/CorporateTermsandConditions" component={CorporateTermsandConditions} />
                  <Route path="/CorporateIDCardDetails" component={CorporateIDCardDetails} />
                  <Route path="/CorporateIdCardtermsandConditions" component={CorporateIdCardtermsandConditions} /> */}

                  {/* AOBRouteStartsHere */}

                  {/* AOB false scenario */}

                  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/withoutReferralLinkRegistration" component={oonaWithOutReferralLinkRegistration} />) : null}
                  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/aobOTPConfirmation" component={oonaOConfirmation} />) : null}
                  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/AOBmitraRegistrationForm" component={oonaAOBMitraRegistrationForm} />) : null}
                  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/AOBIndividualIdCardTermsandConditions" component={oonaIndividualIdCardTermsandConditions} />) : null}
                  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/AOBCorporateIdCardTermsandConditions" component={oonaCorporateIdCardTermsandConditions} />) : null}
                  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/individualTaxPayerandBankAccountDetails" component={oonaIndividualTaxPayerandBankAccountDetails} />) : null}
                  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/corporateTaxPayerandBankAccountDetails" component={oonaCorporateTaxPayerandBankAccountDetails} />) : null}
                  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/AOBITaxTermsandConditions" component={oonaITaxPayerTaxandConditions} />) : null}
                  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/AOBCTaxPayerTaxandConditions" component={oonaCTaxPayerTaxandConditions} />) : null}
                  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/AOBIndividualTermsAndConditions" component={oonaIndividualTermsAndConditions} />) : null}
                  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/AOBCorporateTermsAndConditions" component={oonaCorporateTermsAndConditions} />) : null}
                  {/* { storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/AOBIndividualIdCardTermsandConditions" component={oonaIndividualIdCardTermsandConditions} />) : null } */}
                  {/* { storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/AOBCorporateIdCardTermsandConditions" component={oonaCorporateIdCardTermsandConditions} />) : null } */}
                  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/aob-success" component={oonaAObSuccess} />) : null}
                  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/aob-error" component={oonaErrorTemplate} />) : null}
                  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/sdk" component={oonasdk} />) : null}
                  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/aob-ocr-success-screen" component={AOBsuucessScreenOCR} />) : null}
                  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/aob-ocr-error" component={AOBOCRerror} />) : null}
                  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/aob-ocr-cor-error" component={AOBCORError} />) : null}
                  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/aob-ocr-cor-success" component={AOBCorOCRSuucess} />) : null}
                  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/id-card-data-error" component={ProfilePartIDTypeError} />) : null}
                  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/id-card-data-success" component={ProfilePartIDTypeSuccess} />) : null}
                  {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/tax-id-data-success" component={ProfilePartTaxSuucessScreen} />) : null}

                  {/* accident policy agent flow */}
                   {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? <Route path="/pa/:id" component={AccidentDynamicRoute} /> : null}
                   {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? (<Route path="/accidentOtp" component={AccidentOtp} />) : null}
                   {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? <Route path="/oonaaccidentscreen" component={AccidentScreen} />  : null}
                   {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? <Route path="/accidentordersummary" component={AccidentOrderSummaryScreen} />  : null}
                   {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? <Route path="/accidentcustomerinformation" component={AccidentCustomerInformation} /> : null}
                   {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? <Route path="/accidentcustomerdetails" component={AccidentCustomerDetails} /> : null}
                   {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? <Route path="/accidentpaymentconfirmation" component={AccidentPaymentConfirmation}/> : null}
                   {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? <Route path="/accidentquotesuccess" component={AccidentQuoteSuccess} /> : null}
                   {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? <Route path="/accidentmultiplecustomerinformation" component={AccidentMultipleCustomerInformation} /> : null}
                   {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? <Route path="/accidential-insurance-policy-insured-details" component={MultipleCustomerInformationInd} /> : null}
                   {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? <Route path="/accidentseperaterequestlimits" component={AccidentSeperateRequestLimits} /> : null}
                   {storestate?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ? <Route path="/accidentpaylater" component={AccidentPayLater} /> : null}
                    
                   
                 


                    
                    
                    <Route
                      path="/accidentconfirmationsuccess"
                      component={AccidentConfirmationSuccess}
                    />
					

                    
                    

                   

                    
                    
                    
                    
                    
                  
                    

                  
                    
                   
                 



                  {/* <Route path="/withoutReferralLinkRegistration" component={oonaWithOutReferralLinkRegistration} />
                <Route path="/aobOTPConfirmation" component={oonaOConfirmation} />
                <Route path="/AOBmitraRegistrationForm" component={oonaAOBMitraRegistrationForm} />
                <Route path="/individualTaxPayerandBankAccountDetails" component={oonaIndividualTaxPayerandBankAccountDetails} />
                <Route path="/corporateTaxPayerandBankAccountDetails" component={oonaCorporateTaxPayerandBankAccountDetails} />
                <Route path="/AOBreUploadPhotos" component={oonaReUploadPhotos} />
                <Route path="/AOBIndividualTermsAndConditions" component={oonaIndividualTermsAndConditions} />
                <Route path="/AOBCorporateTermsAndConditions" component={oonaCorporateTermsAndConditions} />
                <Route path="/AOBITaxTermsandConditions" component={oonaITaxPayerTaxandConditions} />
                <Route path="/AOBCTaxPayerTaxandConditions" component={oonaCTaxPayerTaxandConditions} />
                <Route path="/AOBIndividualIdCardTermsandConditions" component={oonaIndividualIdCardTermsandConditions} />
                <Route path="/AOBCorporateIdCardTermsandConditions" component={oonaCorporateIdCardTermsandConditions} />
                <Route path="/aob-success" component={oonaAObSuccess} />
                <Route path="/oonaReferralSuccess" component={oonaAoboonaReferralSuccess} />
                <Route path="/aob-error" component={oonaErrorTemplate} />
                <Route path="/WithReferralCodeAOB" component={oonaWithReferralCodeAOB} />
                <Route path="/sdk" component={oonasdk} /> */}

                  {/* AOB true scenario */}



                  {/* Customer details tab */}

                  {/* <Route
                  path="/rewardscorner/contests/activecontest"
                  component={ActiveContest}
                />
                <Route
                  path="/rewardscorner/contests/activecontestdetails"
                  component={ActiveContestDetails}
                />
                <Route path="/calendar" component={Calendar1} />
                <Route path="/calendar/create-event" component={Calendar} />
                <Route path="/create-event-mobile" component={CalendarMobile} />

                <Route path="/todo" component={TodoMobile} />


                <Route path="/kpi-dashboard" component={KpiDashboard} />
                <Route path="/daily-bussienss" component={DailyBussiness} />

                <Route path="/notifypage" component={NotifyPage} />

                <Route
                  path="/masterpresales/advisordetail/advisorpitch"
                  component={AdvisorPitch}
                ></Route>
                <Route
                  path="/master/benefitillustrator"
                  component={BenefitIllustrator}
                ></Route>
                <Route
                  path="/master/proposalfulfilment"
                  component={ProposalFulfilment}
                ></Route>
                <Route
                  path="/master/prepaymentreview"
                  component={PrePaymentReview}
                ></Route>
                <Route
                  path="/master/paymentoptions"
                  component={PaymentOptions}
                ></Route>
                <Route path="/forcustomer" component={ForCustomer}></Route>
                <Route path="/forself" component={ForSelf}></Route> */}
                  {/* <Route
                  path="/renewalreport"
                  component={RenewalReport}
                ></Route>
                <Route
                  path="/masterpresales/customerdetails/salespitch"
                  component={SalesPitch}
                ></Route>
                <Route
                  path="/resourcecenter"
                  component={ResourceCenter}
                ></Route>
                <Route
                  path="/servicecorner/all"
                  component={ServiceCorner}
                ></Route>
                <Route
                  path="/servicecorner/self"
                  component={ServiceCornerSelf}
                ></Route> */}
                  {/* <Route
                  path="/servicecorner/customers"
                  component={ServiceCornerCustomers}
                ></Route>
                <Route
                  path="/renewalMaster/allRenewals"
                  component={Renewals}
                />
                <Route path="/renewalMaster/all" component={RenewalAll} />
                <Route
                  path="/renewalMaster/paidRenewals"
                  component={RenewalPaid}
                />
                <Route
                  path="/renewalMaster/unpaidRenewals"
                  component={RenewalUnPaid}
                />
                <Route
                  path="/renewalMaster/lapsedRenewals"
                  component={RenewalLapsed}
                /> */}
                  {/* <Route
                  path="/renewalMaster/Details"
                  component={RenewalMasterDetails}
                />
                <Route
                  path="/salespendency"
                  component={SalesPendency}
                ></Route>
                <Route path="/existingpartner" component={ExistingPartner} />
                <Route
                  path="/existingpartnerdetails"
                  component={ExistingPartnerDetails}
                /> */}
                  {/* <Route
                  path="/mappedbranches"
                  component={MappedBranches}
                ></Route>
                <Route
                  path="/master/uploaddocuments"
                  component={UploadDocuments}
                ></Route>
                <Route
                  path="/master/proposalhistory"
                  component={ProposalHistory}
                ></Route>
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/createtask" component={CreateTask} />
                <Route path="/createnewtask" component={CreateNewTask} />
                <Route
                  path="/leadMaster/:leadType"
                  component={LeadMaster}
                ></Route> */}
                  {/* <Route
                  path="/advisormaster/:advisortype"
                  component={AdvisorMaster}
                ></Route>
                <Route path="/multichannel" component={Multichannel}></Route>
                <Route
                  path="/defaultchannel"
                  component={DefaultChannel}
                ></Route>
                <Route path="/team" component={MyTeams}></Route>

                <Route
                  path="/leadmasterpage/statuslead"
                  component={StatusLead}
                ></Route> */}
                  {/* <Route
                  path="/leadmasterpage/leaddetails/personallead"
                  component={PersonalDetails}
                ></Route>
                <Route
                  path="/leadmasterpage/leaddetails/contactlead"
                  component={ContactDetails}
                ></Route>
                <Route
                  path="/leadmasterpage/leaddetails/professionallead"
                  component={ProfessionalDetails}
                ></Route>
                <Route
                  path="/leadmasterpage/leaddetails/existingLead"
                  component={ExistingInsurance}
                ></Route> */}
                  {/* <Route
                  path="/leadmasterpage/leaddetails/productLead"
                  component={ProposedProduct}
                ></Route>
                <Route
                  path="/leadmasterpage/proposal"
                  component={ProposalDetails}
                ></Route>
                <Route
                  path="/leadmasterpage/leadmasterdoc/leaddoc"
                  component={DocumentsUpload}
                ></Route>
                <Route
                  path="/leadmasterpage/leadhistory"
                  component={History}
                ></Route> */}
                  {/* <Route
                  path="/advisorOnboarding/:type"
                  component={AdvisorList}
                ></Route> */}

                  {/* <Route path="/master/proposalTabs" component={ProposalTabs}></Route> */}
                  {/* <Route path="/master/benefitillustrator" component={ProposalTabs}></Route> */}
                  {/* <Route path="/applications" component={Applications}></Route> */}
                  {/* <Route
                  path="/PropsalFulfilment"
                  component={ProposalFulfilment}
                ></Route> */}
                  {/* <Route path="/leads-report" component={LeadReport}></Route> */}
                  {/* <Route path="/leads-report2" component={LeadReport2}></Route> */}
                  {/* <Route path="/Applications" component={Applications}></Route> */}
                  {/* <Route path="/BenefitIllustrator" component={BenefitIllustrator}></Route> */}

                </div >
              </div >


              
            </Switch >
          </div >
        </Router >
      </LanguageProvider >
    </React.Suspense >
     </ReactKeycloakProvider>

     
  



  );

 


}

export default App;
