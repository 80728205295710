import { combineReducers } from 'redux';
import leadsReducer from './leads';
import createLeadReducer from './newLead'
import addressReducer from './address'
import loginReducer from './auth';
import homeReducer from './home';
import activitiesReducer from './home';
import kpiDashboardReducer from './kpiDashboard'
import renewalReducer from './renewals'
import productReducer from './product'
import agentReducer from './agentMicroSite'
import advisorReducer from './advisor'
import applicationReducer from './applicationreducer'
import {paQuoteFetchedDataReducer,updateQuoteDataReducer,policyInceptionDateReducer,copyOptionReducer} from './paProduct/paProductfetchDataReducer'
import {paQuoteReducer,seperateRequestLimitDataReducer,multiplePaInsuredDetailsReducer, showingdataUsingSharableLinkInitialStateReducer} from './paProduct/paProductCreateQuoteReducer'
// import BICardReducer from './BICardReducer';
// import activitiesReducer from './home'
import historyReducer from './history'

import configureStore from '../CreateStore'
import cipherlinkreducer from '../reducers/cipherlink'
import languageReducer from './languageReducer'
// import pendencyReducer from './penc'
import * as actionTypes from "../actions/actionTypes";
import { encryptedDataofAEMreducer } from './ciProduct/aemAuth';


export default () => {
  const rootReducer = combineReducers({
    leads: leadsReducer,
    newLead: createLeadReducer,
    address: addressReducer,
    login: loginReducer,
    home: homeReducer,
    activities: activitiesReducer,
    kpiDashboard: kpiDashboardReducer,
    renewals: renewalReducer,
    history: historyReducer,
    product: productReducer,
    agent: agentReducer,
    advisor: advisorReducer,
    paQuoteObject:paQuoteReducer,
    paQuotefetchedData:paQuoteFetchedDataReducer,
    paSeperateRequestLimitData:seperateRequestLimitDataReducer,
    updateQuoteData:updateQuoteDataReducer,
    multiplePaInsuredDetailsReducer:multiplePaInsuredDetailsReducer,
    showingdataUsingSharableLinkInitialStateReducer:showingdataUsingSharableLinkInitialStateReducer,
    encryptedDataReducer:encryptedDataofAEMreducer,
    applicationReducer,
    cipherlinkreducer,
    languageReducer,
    setPolicyInceptionDate:policyInceptionDateReducer,
    setCopyOption:copyOptionReducer,


    // BICardReducer,
    // pendencies:pendencyReducer
  });

  const reducerProxy = (state, action) => {
    if (action.type === actionTypes.AUTH_LOGOUT_SUCCESS) {
      return rootReducer(undefined, action);
    }
    return rootReducer(state, action);
  }

  return configureStore(reducerProxy)
}

// export default rootReducer;