import React from 'react'
import LangSwitch from '../config/LangSwitch'
import styled from "styled-components";
import { BellOutlined } from "@ant-design/icons";
// import sales_logo_img from "../../images/oona-logo@2x.png";
import sales_logo_img from "../images/oona-logo@2x.png"
import { Image } from 'antd';
import oonaLogo from '../images/oona-logo@2x.png'
import { IconContext } from "react-icons/lib";
import { Header } from 'antd/lib/layout/layout';
import rootIndex from './../store/root_index'
import { Button, Row, Col } from "antd";
import { Link, useHistory } from "react-router-dom";
import { useLanguage } from '../config/LanguageContext';

const {store}=rootIndex
const CustomerHeader = ({pageType}) => {
  console.log(pageType,"pageType")
    const _store=store.getState()
    console.log(_store,"sgc==>")
    const history=useHistory()
    const { translate } = useLanguage()

    const Nav = styled.div`
    border-width: 0px 0px 6px 0px;
    border-style: solid;
    border-image: linear-gradient(90deg, #4ac6bb, #7a4cb7 93.75%) 1;
    background: #482c77;
    height: 70px;
    display: flex;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
  
    @media (max-width: 400px) {
      height: auto;
      flex-direction: column;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  `;
  const NavIcon = styled(Link)`
    font-size: 1.5rem;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  
    @media (max-width: 400px) {
      justify-content: center;
    }
  `;

  const path = () => {
    if (_store.login.userType === "Mitra") {
      history.push("/oonahome", { data: true })
    }
    else if (_store.login.userType === "Commercial") {
      history.push("/commercialhome", { data: true })
    }
    else if (_store.login.userType === "Support") {
      history.push("/commercialsupport", { data: true })
    }
    else {
      history.push("/oonahome", { data: true })
    }
  }

  return (
    <>
    {_store?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? (
        <Header className="header-login" style={{position:"fixed",top:"0",width:"100%",zIndex:"1000"}}>
                          <div style={{display:"flex" ,alignItems:"center"}}>
                            <img  alt= "kahoonalogo"style={{width: "100px", cursor: "pointer" ,marginRight:"0.2em"}} src={oonaLogo}/>
                            <div style={{ color: "white", fontSize: "0.6em", marginTop: "0.9em" }}>{`v.${process.env.REACT_APP_KAHOONA_VERSION}`}</div>
                            </div>
                            <div>
                            <LangSwitch />
                            </div>
                    </Header>
        
    ) : 
    <div>
    
    <IconContext.Provider value={{ color: "#fff" }}>

    <Header style={{ height: "70px",display:"flex",alignItems:"center",position:"fixed",top:"0",width:"100%",zIndex:"1000"}}className='agentHeader'>
      <Row style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <Col xl={12} md={12} lg={12} sm={9} xs={9} style={{ display: "flex", alignItems: "center" }}>
          <img
            onClick={path}
            alt="logo"
            src={sales_logo_img}
            style={{ width: "100px", cursor: "pointer",marginRight:"0.2em" }}
          />
          <div style={{ color: "white", fontSize: "0.6em", marginTop: "0.9em" }}>{`v.${process.env.REACT_APP_KAHOONA_VERSION}`}</div>
        </Col>
        <Col xl={6} md={8} lg={6} sm={13} xs={15}>
          <Row style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            {
              pageType === "alert" ? (<>
               <Col xl={10} md={11} lg={12} sm={14} xs={14}></Col>
               <Col xl={3} md={3} lg={3} sm={4} xs={5}></Col>
              </>) : (<>
                <Col xl={10} md={11} lg={12} sm={14} xs={14} style={{ display: "flex", justifyContent: "center" }}>
              
              {_store.login.userType === "Support" ? (
                <></>
              ) : (
                <Button
                  onClick={() => history.push("/recruit")}
                  style={{
                    padding: "0px 5px",
                    height: 25,
                    color: "white",
                    backgroundColor: "transparent",
                    borderRadius: 25,
                    border: "1px solid white"
                  }}
                >
                  {translate("commercial sales")["inviteFriends"]}
                </Button>
              )}
            </Col>
            <Col xl={3} md={3} lg={3} sm={4} xs={5} style={{ display: "flex", justifyContent: "center" }}>
              <NavIcon style={{ color: "white", fontSize: "1rem" }} to="#">
                <BellOutlined onClick={() => history.push("/oonanotification")} />
              </NavIcon>
            </Col>
              </>)
            }
           
            <Col xl={5} md={5} lg={5} sm={6} xs={5} style={{ display: "flex", justifyContent: "center" }}>
            <LangSwitch />
            </Col>
          </Row>
        </Col>
      </Row>
    </Header>
    
  
        
      </IconContext.Provider>
    
    </div>
    
    }
            </>
 
  )
}

export default CustomerHeader
