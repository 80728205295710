import React, { useState, useEffect } from 'react'
import { Row, Col, Image, Form, Input, Button, message, Spin } from "antd"
import SuccessLogo from "../oonaImages/GRFX Success State.png"
import uploadLogo from '../oonaImages/GRFX Upload.png'
import { useHistory } from "react-router-dom"
//import InputImage from "../../oonaImages/Frame 1000004254.png"
import InputImage from '../oonaImages/Frame 1000004254.png'
import axios from "axios"
//import rootIndex from "../../store/root_index";
import rootIndex from '../store/root_index'
import { Base_URL } from '../config/BaseUrl'
import { ToastContainer, toast } from 'react-toastify';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import './oona-aob.css'
import { useLanguage } from '../config/LanguageContext'
import axiosRequest from './../axios-request/request.methods'
import CustomerHeader from '../CustomerHeader/CustomerHeader'

const ProfilePartTaxSuucessScreen = () => {
const location=useLocation()
console.log(location,"location")
const [loading,setLoading]=useState(false)
console.log(location?.state?.taxresult?.transactionId,"transactionIIDFD")
const[idurldata,setIdData]=useState({})
const history=useHistory()
  const fetchingOCRDetails=()=>{
    let axiosConfig = {
      headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + location?.state?.token
      },
    };
    let options = { secure: false, multipart: false }

    axiosRequest
    .get(
        `auth/hyperverge/ocr-details?transactionId=${location?.state?.taxresult?.transactionId}`,
        options
    )
    .then((data) => {
          if(data?.errCode === -1){
            //console.log(data?.data?.errMsg,"yuteyfuv") 
            setIdData(data?.errMsg?.details?.ocrDetails)
          }
    }).catch((error) => {
      // toast.error("Verification service is not responding as expected. Please try after sometime")
        console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
        // setLoading(true)
    });
  
  }

  useEffect(()=>{
setTimeout(fetchingOCRDetails,5000)
  },[])
  


  useEffect(()=>{

    if(idurldata?.hasOwnProperty('s3Url')){
      setLoading(false)
    }
    else{
      setLoading(true)
    }

  },[idurldata])
    
  const handleSuccessScreen= async()=>{
  const updatePayload={
    taxid:location?.state?.taxresult?.details?.idNumber,
    taxIdUrl:idurldata?.s3Url,
  }
  
  let axiosConfig = {
    headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + location?.state?.token
    },
  };
    try {
      const response = await axiosRequest
        .patch(
          `agent/update-profile`,
          updatePayload,
          
  
        )
     
        //setIndividualResponse(response?.data?.errMsg?.data?._id
        //)
        
        if(response?.errCode === -1){
        toast.success(response?.errMsg?.message, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
        //showDrawer();showModal()
        history.push({
          pathname: "/oonahome",
          state: {
          data:"redirectingtoprofile"
  }
      })
  
    }
  
    } catch (error) {
      if (error?.response) {
        console.log(error?.response?.data, "geetha")
        if (error?.response?.data?.errCode === 1) {
          toast.error(error?.response?.data?.errMsg, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
        }
        else {
          toast.error(error?.response?.data?.errCode, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
        }
  
      }
    }
  }

    return (
        <div className="row__aob oona_aob_font">
    <Spin tip="Loading" spinning={loading} >
        <Row>
          <Col xl={12} md={12} lg={12}>
            <Image preview={false} src={uploadLogo} />
          </Col>
          <Col xl={12} md={12} lg={12}>
            <div className="oonaSuccess__ocrkahoona">
              <div className="oonaSuccess__ocrkahoona__header">We’ve Got Your Tax ID Data</div>
              <div className="oonaSuccess__kahoona__header__cenetered oona_aob_font">
                <div className="oona_aob_font">
                Thank you for completing tax ID to your profile.
                </div>
    
                <div className="oonna_success_buuton">
                  <Col xl={24} md={24} lg={24} >
                    {
                      idurldata?.hasOwnProperty('s3Url') ? (<Button className="oona_aob_font continue__button" onClick={handleSuccessScreen}>Back to Profile</Button>) :(<Button className="oona_aob_font continue__button" disabled>Back to Profile</Button>)
                    }
                    
                  </Col>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        </Spin>
      </div>
      )
}

export default ProfilePartTaxSuucessScreen
