import React, { useEffect, useState } from "react";
import "./oona-aob.css";
import { Row, Col, Image, Form, Input, Button } from "antd";
//import ErrorLogo from "../../aobImages/ErrorLogo.png";
//import badgePlaystore from "../../aobImages/badgePlaystore.png";
//import DownloadAppStore from "../../aobImages/DownloadAppStore.png";
import { useHistory } from "react-router-dom";
import  errorLogo from '../oonaImages/GRFX Error.png'
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import rootIndex from '../store/root_index'
import { Base_URL } from '../config/BaseUrl'
import { toast } from "react-toastify";
import axiosRequest from './../axios-request/request.methods'
import CustomerHeader from "../CustomerHeader/CustomerHeader";

const ProfilePartIDTypeError = () => {
  const { store } = rootIndex;
  const _store = store.getState();
    const history=useHistory();
    const location=useLocation()

console.log(location,"locationlocationlocation")
    const onBoardHandler = async (KYCResult) => {
      let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + _store.login.token
        },
    };
    let options = { secure: false, multipart: false }

    axiosRequest.get(`auth/hyperverge/access-token&logkey=idCardUpload&phoneNumber=${location?.state?.phonenumber}`,options)
        .then((res, error) => {
          console.log('Response', res)
        
            let token = res.errMsg.accessToken
            let transactionId = res.errMsg.transactionId
            LaunchSDK(KYCResult, token, transactionId)
          
        });
    };
    const LaunchSDK = async (KYCResult, Token, transactionId) => {
      console.log(Token, "Token")
      console.log(transactionId, "transactionId")
      const hyperKycConfigs = new window.HyperKycConfig(Token, "agent_onboarding", transactionId);
      window.HyperKYCModule.launch(hyperKycConfigs, ResultsHandler);
    }
    const ResultsHandler = (KYCResult) => {
      console.log("Resul Hadler ===>>>>>", KYCResult?.status)
      switch (KYCResult.status) {
        case "user_cancelled":
          toast.error("user Cancelled", { position: "top-center", hideProgressBar: true, autoClose: 1000 })
        
              history.push({
                  pathname: '/id-card-data-error',
                  state: {
                    data: "autodeclined",
                    phonenumber: location?.state?.phonenumber,
                    userId: location?.state?.userId,
                    token:location?.state?.token,
                    agentpath:location?.state?.agentpath
                    
                  },
                })
         
          break;
        case "error":
          toast.error("Failed Transection", { position: "top-center", hideProgressBar: true, autoClose: 1000 })
         
        
          history.push({
              pathname: '/id-card-data-error',
              state: {
                data: "autodeclined",
                phonenumber: location?.state?.phonenumber,
                    // cipher: location?.state?.cipher,
                    userId: location?.state?.userId,
                    token:location?.state?.token,
                    agentpath:location?.state?.agentpath
              },
            })
          break;
        case "auto_approved":
          toast.success("Approved", { position: "top-center", hideProgressBar: true, autoClose: 1000 })
          // history.push({
          //   pathname: '/id-card-data-success',
          //   state: {
          //     data: "taxpayer",
          //     phonenumber: location?.state?.phonenumber,
          //           // cipher: location?.state?.cipher,
          //           userId: location?.state?.userId,
          //           token:location?.state?.token,
          //           iddata:KYCResult,

          //   },
          // })

          if(location?.state?.user_type === "Individual"){
            history.push({
                         pathname: '/AOBIndividualIdCardTermsandConditions',
                         state: {
                           data: "redirectingtocardTermsandconditions",
                           phonenumber: location?.state?.phonenumber,
                           // cipher: location?.state?.cipher,
                           userId: location?.state?.userId,
                           token:location?.state?.token,
                           iddata:location?.state?.KYCResult
                         },
                       })
                       }
                       else{
           
                            history.push({
                         pathname: '/AOBCorporateIdCardTermsandConditions',
                         state: {
                          data: "redirectingtocardTermsandconditions",
                          phonenumber: location?.state?.phonenumber,
                          // cipher: location?.state?.cipher,
                          userId: location?.state?.userId,
                          token:location?.state?.token,
                          iddata:location?.state?.KYCResult
                        },
                       })
           
                       }
     
          break;
        case "auto_declined":
          toast.error("Auto Declined", { position: "top-center", hideProgressBar: true, autoClose: 1000 })
          
              history.push({
                  pathname: '/id-card-data-error',
                  state: {
                    data: "autodeclined",
                    phonenumber: location?.state?.phonenumber,
                    // cipher: location?.state?.cipher,
                    userId: location?.state?.userId,
                    token:location?.state?.token,
                  },
                })
           
          break;
        case "needs_review":
          // workflow success
          if(location?.state?.user_type === "Individual"){
            history.push({
                         pathname: '/AOBIndividualIdCardTermsandConditions',
                         state: {
                           data: "redirectingtocardTermsandconditions",
                           phonenumber: location?.state?.phonenumber,
                           // cipher: location?.state?.cipher,
                           userId: location?.state?.userId,
                           token:location?.state?.token,
                           iddata:location?.state?.KYCResult
                         },
                       })
                       }
                       else{
                            history.push({
                         pathname: '/AOBCorporateIdCardTermsandConditions',
                         state: {
                          data: "redirectingtocardTermsandconditions",
                          phonenumber: location?.state?.phonenumber,
                          // cipher: location?.state?.cipher,
                          userId: location?.state?.userId,
                          token:location?.state?.token,
                          iddata:location?.state?.KYCResult
                        },
                       })
           
                       }
      
          //alert("Successs");
          break;
      }
    }


  return (
    <div className="row__aob oona_aob_font">
    <Row>
                <Col xl={12} md={12} lg={12}>
                  <Image preview={false} src={errorLogo} />
                </Col>
                <Col xl={12} md={12} lg={12}>
                  <div className="oonaError__kahoona">
                    <div className="oonaSuccess__kahoona__header oona_aob_font">
                    We’re Sorry
                    </div>
                    <div className="oonaError__kahoona__header__cenetered oona_aob_font">
                      <div className="oona_aob_font">
                      Your registration cannot be proceeded.
    
                      </div>
                      <div className="oona_aob_font">
                      Your ID card photo is not in the correct format.
    
                      </div>
                      <div className="oona_aob_font">  
                      Please retake the photo or proceed without 
    an ID card photo.
                      </div>
                    </div>
    
                   
                    <div className="oonna_success_buuton">
                      <Button className="oonna_success_buuton__home oona_aob_font" onClick={()=>history.push({
        pathname: "/oonahome",
        state: {
        data:"redirectingtoprofile"
}
    })}>
                      Back to Profile
                      </Button>
                      <Button className="oonna_success_buuton__Invite oona_aob_font" onClick={onBoardHandler}>
                        Retake Photo
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
          
        </div>
  )
}

export default ProfilePartIDTypeError
