import * as actionTypes from '../actions/actionTypes'
// import { set_quote_id } from '../actions/cipherlink';
import { updateObject } from "../utility";
const initialstate = { formdata: {}, quote: {
  quoteId:"",
  isOld:false,
  isTravel:false,
  isHome:false,
  isCI:false,
  leadId:"",
  resumeType:"",
  ProposerNumberdata:"",
  tca:null,
  triptype:null,
  tcaid:null,
  age:"",
  covid:false,
  qNo:"",
  redirectingtohome:false
} }
export const fetch_cipher_data = (state, action) => {
  console.log("Reducer Console ====>>>", state, action)
  return updateObject(state, { formdata: action.linkdata })
}
export const set_quote_id = (state, action) => {
  console.log("Reducer Console ====>>>", state, action)
  return updateObject(state, {
    quote: {
      quoteId: action.id, 
      isOld: action.isOld,
      isTravel : action.isTravel,
      isHome:action.isHome,
      isCI:action?.isCI,
      leadId:action.leadId,
      resumeType:action.resumeType,
      quote_Id:action?.quote_Id,
      ProposerNumberdata:action?.ProposerNumberdata,
      isMvdurationChanged:action?.isMvdurationChanged,
      mvduration:action?.mvduration,
      tca:action?.tca,
      triptype:action?.triptype,
      tcaid:action?.tcaid,
      age:action?.age,
      covid:action?.covid,
       qNo:action?.qNo,
       redirectingtohome:action?.redirectingtohome
    }
  })
}


const reducer = (state = initialstate, action) => {
  switch (action.type) {
    //state
    case actionTypes.FETCH_CIPHER_LINK:
      return fetch_cipher_data(state, action);

    case actionTypes.SET_QUOTE_ID:
      return set_quote_id(state, action);
    default:
      return state;
  }
};

export default reducer