import React, { forwardRef, useState, useEffect, useImperativeHandle } from 'react'
import { Collapse, Button, Card, Row, Col } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import rootIndex from "../../../store/root_index";
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { useLanguage } from "../../../config/LanguageContext"
import axiosRequest from '../../../axios-request/request.methods'
import { useSelector } from 'react-redux';

const { store } = rootIndex;
const { Panel } = Collapse;

const ChooseProduct = forwardRef((props, ref) => {
    const { translate, language } = useLanguage()
    const fetchingWholeDatafromStore=useSelector((state)=>state?.cipherlinkreducer?.quote)
    console.log(fetchingWholeDatafromStore,"fetchingWholeDatafromStore")
    console.log(fetchingWholeDatafromStore?.quoteId,"cxbgcyuv==>")
    const [selectButton, setSelectButton] = useState(false)
    const [seedetail, setSeeDetail] = useState(false)
    const [seedetail1, setSeeDetail1] = useState(false)
    const [seedetail2, setSeeDetail2] = useState(false)
    const [seedetail3, setSeeDetail3] = useState(false)
    const [planarr, setPlanArr] = useState([])
    const [goldplanarr, setGoldPlanArr] = useState([])
    const [bronzeplanarr, setBronzePlanArr] = useState([])
    const [specialarr, setSpecialArr] = useState([])
    const [planCode, setPlanCode] = useState('')
    const [planName, setPlanName] = useState('')
    const [premium, setPremium] = useState('')
    const [tvca, settvca] = useState('')
    const location = useLocation()

    useEffect(()=>{
        console.log(planName,"planName==>")
        if(planName === ""){
            props.setSelectButton(true)
        }

    },[planName])

    useImperativeHandle(ref, () => ({
        log() {
            if (planCode === '') {
                toast.error(`${translate("home")["Please Select Plan"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
            } else if (planName === '') {
                toast.error(`${translate("home")["Please Select Plan"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
            }
            else if (premium === '') {
                toast.error(`${translate("home")["Please Select Plan"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
            } else {
                console.log(planCode, 'code values', planName, 'name values', premium, 'values');

                props.planSubmit(planCode, planName, premium);
            }
        }
    }));

    const handletvca = (id) => {
        axiosRequest
            .get(
                `user/travel-coveragearea`,
            )
            .then((data) => {
                data.errMsg.data.map(item => {
                    if (item.id === id || item.id === Number(id)) {
                        settvca(item.description)
                    }
                })


            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

            });
    }


    useEffect(() => {
        if(fetchingWholeDatafromStore?.quoteId !== ""){
        if (fetchingWholeDatafromStore.tca !== undefined) {
            settvca(fetchingWholeDatafromStore.tca)
        }
        if (props.index === 0) {
            props.handleLoading(true)


            axiosRequest
                .get(
                    `user/get-plan/${fetchingWholeDatafromStore?.quoteId}?langId=${language === "en" ? "EN" : "BH"}`,

                )
                .then((data) => {

                    props.handleLoading(false)
                    

                    let arr = data?.errMsg?.data[0]?.Plans

                    let filteredPlatinum = arr.filter(item => {
                        return item.PlanName.includes('Platinum') === true
                    })

                    let filteredGold = arr.filter(item => {
                        return item.PlanName.includes('Gold') === true
                    })

                    let filteredBronze = arr.filter(item => {
                        return item.PlanName.includes('Bronze') === true
                    })

                    let filteredSpecial = arr.filter(item => {
                        return item.PlanName.includes('Bronze') != true && item.PlanName.includes('Gold') != true && item.PlanName.includes('Platinum') != true
                    })

                    if (filteredPlatinum.length != 0) {
                        filteredPlatinum[0].selectbtn = false
                        filteredPlatinum[0].detail = false
                        console.log(filteredPlatinum, 'final platinum arr------->');
                        setPlanArr(filteredPlatinum)
                    }

                    if (filteredGold.length != 0) {
                        filteredGold[0].selectbtn = false
                        filteredGold[0].detail = false
                        console.log(filteredGold, 'final gold arr------->');
                        setGoldPlanArr(filteredGold)
                    }

                    if (filteredBronze.length != 0) {
                        filteredBronze[0].selectbtn = false
                        filteredBronze[0].detail = false
                        console.log(filteredBronze, 'final bronze arr------->');
                        setBronzePlanArr(filteredBronze)
                    }

                    if (filteredSpecial.length != 0) {
                        filteredSpecial.map(item => {
                            item.selectbtn = false
                            item.detail = false
                        })
                        // filteredSpecial[0].selectbtn = false
                        console.log(filteredSpecial, 'final Special arr------->');
                        setSpecialArr(filteredSpecial)
                    }
                    if(fetchingWholeDatafromStore?.quoteId !== ""){
                        getQuotes(filteredPlatinum, filteredGold, filteredBronze, filteredSpecial)
                    }

                }).catch((error) => {
                    console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
                    props.handleLoading(false)
                });

        }
    }

    }, [fetchingWholeDatafromStore])

    const handleSelect = (planname, selectbtn, plancode, premium) => {
        console.log(planname, typeof (planname));
        if (selectbtn === false) {
            if (planname.includes('Platinum') === true) {
                props.scheganPlan(true)
                planarr.map(item => {
                    item.selectbtn = true
                })
                goldplanarr.map(item => {
                    item.selectbtn = false
                })
                bronzeplanarr.map(item => {
                    item.selectbtn = false
                })
                if (specialarr.length > 0) {
                    specialarr.map(item => {
                        item.selectbtn = false
                    })
                }
            } else if (planname.includes('Gold') === true) {
                props.scheganPlan(true)
                planarr.map(item => {
                    item.selectbtn = false
                })
                goldplanarr.map(item => {
                    item.selectbtn = true
                })
                bronzeplanarr.map(item => {
                    item.selectbtn = false
                })
                if (specialarr.length > 0) {
                    specialarr.map(item => {
                        item.selectbtn = false
                    })
                }
            } else if (planname.includes('Bronze') === true) {
                props.scheganPlan(true)
                planarr.map(item => {
                    item.selectbtn = false
                })
                goldplanarr.map(item => {
                    item.selectbtn = false
                })
                bronzeplanarr.map(item => {
                    item.selectbtn = true
                })
                if (specialarr.length > 0) {
                    specialarr.map(item => {
                        item.selectbtn = false
                    })
                }
            } else {
                props.scheganPlan(false)
                planarr.map(item => {
                    item.selectbtn = false
                })
                goldplanarr.map(item => {
                    item.selectbtn = false
                })
                bronzeplanarr.map(item => {
                    item.selectbtn = false
                })
                if (specialarr.length > 0) {
                    specialarr.map(item => {
                        if (item.PlanCode === plancode) {
                            item.selectbtn = true
                        } else {
                            item.selectbtn = false
                        }
                    })
                }
            }
            setSelectButton(true)
            setPlanCode(plancode)
            setPlanName(planname)
            setPremium(premium)
        } else {
            if (planname.includes('Platinum') === true) {
                props.scheganPlan(true)
                planarr.map(item => {
                    item.selectbtn = false
                })
                goldplanarr.map(item => {
                    item.selectbtn = false
                })
                bronzeplanarr.map(item => {
                    item.selectbtn = false
                })
                if (specialarr.length > 0) {
                    specialarr.map(item => {
                        item.selectbtn = false
                    })
                }

            } else if (planname.includes('Gold') === true) {
                props.scheganPlan(true)
                planarr.map(item => {
                    item.selectbtn = false
                })
                goldplanarr.map(item => {
                    item.selectbtn = false
                })
                bronzeplanarr.map(item => {
                    item.selectbtn = false
                })
                if (specialarr.length > 0) {
                    specialarr.map(item => {
                        item.selectbtn = false
                    })
                }
            } else if (planname.includes('Bronze') === true) {
                props.scheganPlan(true)
                planarr.map(item => {
                    item.selectbtn = false
                })
                goldplanarr.map(item => {
                    item.selectbtn = false
                })
                bronzeplanarr.map(item => {
                    item.selectbtn = false
                })
                if (specialarr.length > 0) {
                    specialarr.map(item => {
                        item.selectbtn = false
                    })
                }
            } else {
                props.scheganPlan(false)
                planarr.map(item => {
                    item.selectbtn = false
                })
                goldplanarr.map(item => {
                    item.selectbtn = false
                })
                bronzeplanarr.map(item => {
                    item.selectbtn = false
                })
                if (specialarr.length > 0) {
                    specialarr.map(item => {
                        item.selectbtn = false
                    })
                }
            }
            setSelectButton(false)
            setPlanCode('')
            setPlanName('')
            setPremium('')
        }
    }

    const getQuotes = (platinum, gold, bronze, spl) => {

        var fromReferral = ""
        if (fetchingWholeDatafromStore?.resumeType === "referalJourney" ||fetchingWholeDatafromStore?.resumeType === "quoteSearch") {
            fromReferral = `user/get-quotes?id=${fetchingWholeDatafromStore?.quoteId}&logkey=${fetchingWholeDatafromStore?.resumeType}`
        }
        else {
            fromReferral = `user/get-quotes?id=${fetchingWholeDatafromStore?.quoteId}`
        }


        axiosRequest
            .get(
                `${fromReferral}`,
            )
            .then((data) => {


                console.log(data.errMsg.data[0].planName, data.errMsg.data[0].premium, data.errMsg.data[0].planCode, 'planname------->');
                setPlanName(data.errMsg.data[0].planName !== undefined ? data.errMsg.data[0].planName : '')
                // setPremium(data.errMsg.data[0].premium != undefined ? data.errMsg.data[0].premium : '')
                setPremium(data.errMsg.data[0].premium !== undefined ? data.errMsg.data[0].premium : '')
                setPlanCode(data.errMsg.data[0].planCode !== undefined ? data.errMsg.data[0].planCode : '')
                settvca(data.errMsg.data[0].travelCoverageAreaDesc)
                handletvca(data.errMsg.data[0].travelCoverageArea)
                if (platinum.length > 0) {
                    platinum.map(item => {
                        if (item.PlanName === data.errMsg.data[0].planName) {
                            props.scheganPlan(true)
                            setPremium(Number(item.Premium))
                            setPlanCode(item?.PlanCode)
                            setPlanName(item?.PlanName)
                            return item.selectbtn = true
                        }
                    })
                    setPlanArr([...platinum])
                }

                if (gold.length > 0) {
                    gold.map(item => {
                        if (item.PlanName === data.errMsg.data[0].planName) {
                            props.scheganPlan(true)
                            setPremium(Number(item.Premium))
                            setPlanCode(item?.PlanCode)
                            setPlanName(item?.PlanName)
                            return item.selectbtn = true
                        }
                    })
                    setGoldPlanArr([...gold])
                }

                if (bronze.length > 0) {
                    bronze.map(item => {
                        if (item.PlanName === data.errMsg.data[0].planName) {
                            props.scheganPlan(true)
                            setPremium(Number(item.Premium))
                            setPlanCode(item?.PlanCode)
                            setPlanName(item?.PlanName)
                            return item.selectbtn = true
                        }
                    })
                    setBronzePlanArr([...bronze])
                }

                if (spl.length > 0) {
                    spl.map(item => {
                        if (item.PlanName === data.errMsg.data[0].planName) {
                            if (data.errMsg.data[0].planName === 'Schengen Essential') {
                                props.scheganPlan(true)
                            }
                            setPremium(Number(item.Premium))
                            setPlanCode(item?.PlanCode)
                            setPlanName(item?.PlanName)
                            return item.selectbtn = true
                        }
                    })
                    setSpecialArr([...spl])
                }


                console.log(platinum, gold, bronze, spl, 'final get prd arr--------->');


            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

            });
    }


    const [activeKeys, setActiveKeys] = useState([]);

    const handleCollapse = (value) => {
        setActiveKeys(value);
        console.log(value[0], 'collapse val --------->');
        if (value[0] == 1) {
            setSeeDetail(true)
            setSeeDetail1(false)
            setSeeDetail2(false)
            setSeeDetail3(false)
        }
        else if (value[0] == 2) {
            setSeeDetail(false)
            setSeeDetail1(true)
            setSeeDetail2(false)
            setSeeDetail3(false)
        }
        else if (value[0] == 3) {
            setSeeDetail(false)
            setSeeDetail1(false)
            setSeeDetail2(true)
            setSeeDetail3(false)
        }
        else if (value[0] == 4) {
            setSeeDetail(false)
            setSeeDetail1(false)
            setSeeDetail2(false)
            setSeeDetail3(true)
        }
        else {
            setSeeDetail(false)
            setSeeDetail1(false)
            setSeeDetail2(false)
            setSeeDetail3(false)
        }
    }



    return (
        <div>
            <h3 className='header-card-content'>{translate("travel")["Choose Product"]}</h3>
            <div><b>{translate("travel")["Our best selection of products for your safe travel."]}</b></div>

            <div className='destination-heading'>{translate("travel")["Travel Destination"]}: {tvca === "Domestic" ? `${translate("travel")["Domestic"]}` : tvca}</div>
            {console.log(planarr, goldplanarr, bronzeplanarr, specialarr, 'ooooooo')}
            <Collapse activeKey={activeKeys} className='travel-header-accord travel-accord' accordion={false} isactive={false} onChange={handleCollapse} collapsible="header">
                {console.log(planarr, 'planarr------>')}
                {planarr.length != 0 ? planarr.map(item => {
                    return (
                        <Panel showArrow={false} header={
                            <div>
                                <Card className='accordian-image'>
                                    <Row>
                                        <Col md={20} xl={20} lg={20} sm={20} xs={16}>
                                            <div style={{ textTransform: "uppercase" }}><b>{item.PlanName}</b></div>
                                            <div style={{ display: "flex" }}><b style={{ fontSize: 21 }}>{new Intl.NumberFormat('id-ID', {
                                                style: 'currency',
                                                currency: 'IDR',
                                                minimumFractionDigits: 0,
                                            }).format(item.Premium)}</b></div>
                                        </Col>
                                        <Col md={2} xl={2} lg={2} sm={2} xs={8}>
                                            <><Button className='accordian-button' onClick={() => handleSelect(item.PlanName, item.selectbtn, item.PlanCode, item.Premium)}>{item.selectbtn === false ? `${translate("travel")["Select"]}` : `${translate("home")["Selected"]}`}</Button></>
                                        </Col>
                                    </Row>
                                </Card>
                                {seedetail === true ?
                                    <></>
                                    : <div className='accord-bottom-font view-detail-font'>{translate("travel")["See Details"]}<DownOutlined style={{ color: "#4AC6BB", strokeWidth: 30 }} /></div>}
                            </div>} key="1">
                            <>
                                <div style={{ marginBottom: 10 }} className='accordian-inner'>
                                    <div style={{ textTransform: "uppercase" }} className='thick-font'>{`${item.PlanName} Benefits`}</div>
                                    <div className='thick-font'>(RP)</div>
                                </div>
                                {item.MainBenefits.map(item => {
                                    return (
                                        <div>
                                            {item.benefitType != 'C' ?
                                                <div className='accordian-inner'>
                                                    <div>{item.BenefitDesc}</div>
                                                    <div> {tvca != 'Domestic' ? (item.InternationalSI != undefined ? (/\d/.test(item.InternationalSI) === true ? new Intl.NumberFormat('id-ID', {
                                                        style: 'currency',
                                                        currency: 'IDR',
                                                        minimumFractionDigits: 0,
                                                    }).format(item.InternationalSI) : item.InternationalSI) : null) : (item.DomesticSI != undefined ? (/\d/.test(item.DomesticSI) === true ? new Intl.NumberFormat('id-ID', {
                                                        style: 'currency',
                                                        currency: 'IDR',
                                                        minimumFractionDigits: 0,
                                                    }).format(item.DomesticSI) : item.DomesticSI) : null)} </div>
                                                </div> : null}
                                            <hr />
                                        </div>
                                    )
                                })}
                                <div className='thick-font accord-font' style={{ cursor: 'pointer' }} onClick={() => handleCollapse([])}>
                                    {translate("home")["Hide Detail"]}
                                    <UpOutlined style={{ color: "#4AC6BB", paddingLeft: 5 }} /></div>
                            </>
                        </Panel>
                    )
                }) : null}

                {goldplanarr.length != 0 ? goldplanarr.map(item => {
                    return (
                        <Panel showArrow={false} header={
                            <div>
                                <Card className='accordian-image2'>
                                    <Row>
                                        <Col md={20} xl={20} lg={20} sm={20} xs={16}>
                                            <div style={{ textTransform: "uppercase" }}><b>{item.PlanName}</b></div>
                                            <div style={{ display: "flex" }}><b style={{ fontSize: 21 }}>{new Intl.NumberFormat('id-ID', {
                                                style: 'currency',
                                                currency: 'IDR',
                                                minimumFractionDigits: 0,
                                            }).format(item.Premium)}</b></div>
                                        </Col>
                                        <Col md={2} xl={2} lg={2} sm={2} xs={8}>
                                            <><Button className='accordian-button' onClick={() => handleSelect(item.PlanName, item.selectbtn, item.PlanCode, item.Premium)}>{item.selectbtn === false ? `${translate("travel")["Select"]}` : `${translate("home")["Selected"]}`}</Button></>
                                        </Col>
                                    </Row>
                                </Card>
                                {seedetail1 === true ?
                                    <></>
                                    : <div className='accord-bottom-font view-detail-font'>{translate("travel")["See Details"]}<DownOutlined style={{ color: "#4AC6BB" }} /></div>}
                            </div>} key="2">
                            <>
                                <div style={{ marginBottom: 10 }} className='accordian-inner'>
                                    <div style={{ textTransform: "uppercase" }} className='thick-font'>{`${item.PlanName} Benefits`}</div>
                                    <div className='thick-font'>(RP)</div>
                                </div>
                                {item.MainBenefits.map(item => {

                                    return (

                                        <div>
                                            {item.benefitType != 'C' ?
                                                <div className='accordian-inner'>
                                                    <div>{item.BenefitDesc}</div>
                                                    <div> {tvca != 'Domestic' ? (item.InternationalSI != undefined ? (/\d/.test(item.InternationalSI) === true ? new Intl.NumberFormat('id-ID', {
                                                        style: 'currency',
                                                        currency: 'IDR',
                                                        minimumFractionDigits: 0,
                                                    }).format(item.InternationalSI) : item.InternationalSI) : null) : (item.DomesticSI != undefined ? (/\d/.test(item.DomesticSI) === true ? new Intl.NumberFormat('id-ID', {
                                                        style: 'currency',
                                                        currency: 'IDR',
                                                        minimumFractionDigits: 0,
                                                    }).format(item.DomesticSI) : item.DomesticSI) : null)} </div>
                                                </div> : null}
                                            <hr />
                                        </div>


                                    )
                                })}
                                <div className='thick-font accord-font' style={{ cursor: 'pointer' }} onClick={() => handleCollapse([])}>{translate("home")["Hide Detail"]}<UpOutlined style={{ color: "#4AC6BB", paddingLeft: 5 }} /></div>

                            </>
                        </Panel>
                    )
                }) : null}

                {bronzeplanarr.length != 0 ? bronzeplanarr.map(item => {
                    return (
                        <Panel showArrow={false} header={
                            <div>
                                <Card className='accordian-image1'>
                                    <Row>
                                        <Col md={20} xl={20} lg={20} sm={20} xs={16}>
                                            <div style={{ textTransform: "uppercase" }}><b>{item.PlanName}</b></div>
                                            <div style={{ display: "flex" }}><b style={{ fontSize: 21 }}>{new Intl.NumberFormat('id-ID', {
                                                style: 'currency',
                                                currency: 'IDR',
                                                minimumFractionDigits: 0,
                                            }).format(item.Premium)}</b></div>
                                        </Col>
                                        <Col md={2} xl={2} lg={2} sm={2} xs={8}>
                                            <><Button className='accordian-button' onClick={() => handleSelect(item.PlanName, item.selectbtn, item.PlanCode, item.Premium)}>{item.selectbtn === false ? `${translate("travel")["Select"]}` : `${translate("home")["Selected"]}`}</Button></>
                                        </Col>
                                    </Row>
                                </Card>
                                {seedetail2 === true ?
                                    <></>
                                    : <div className='accord-bottom-font view-detail-font'>{translate("travel")["See Details"]}<DownOutlined style={{ color: "#4AC6BB" }} /></div>}
                            </div>} key="3">
                            <>
                                <div style={{ marginBottom: 10 }} className='accordian-inner'>
                                    <div style={{ textTransform: "uppercase" }} className='thick-font'>{`${item.PlanName} Benefits`}</div>
                                    <div className='thick-font'>(RP)</div>
                                </div>
                                {item.MainBenefits.map(item => {

                                    return (

                                        <div>
                                            {item.benefitType != 'C' ?
                                                <div className='accordian-inner'>
                                                    <div>{item.BenefitDesc}</div>
                                                    <div> {tvca != 'Domestic' ? (item.InternationalSI != undefined ? (/\d/.test(item.InternationalSI) === true ? new Intl.NumberFormat('id-ID', {
                                                        style: 'currency',
                                                        currency: 'IDR',
                                                        minimumFractionDigits: 0,
                                                    }).format(item.InternationalSI) : item.InternationalSI) : null) : (item.DomesticSI != undefined ? (/\d/.test(item.DomesticSI) === true ? new Intl.NumberFormat('id-ID', {
                                                        style: 'currency',
                                                        currency: 'IDR',
                                                        minimumFractionDigits: 0,
                                                    }).format(item.DomesticSI) : item.DomesticSI) : null)} </div>
                                                </div> : null}
                                            <hr />
                                        </div>


                                    )
                                })}
                                <div className='thick-font accord-font' style={{ cursor: 'pointer' }} onClick={() => handleCollapse([])}>{translate("home")["Hide Detail"]}<UpOutlined style={{ color: "#4AC6BB", paddingLeft: 5 }} /></div>
                            </>
                        </Panel>
                    )
                }) : null}

                {specialarr.length != 0 ? specialarr.map(item => {
                    return (
                        <Panel showArrow={false} header={
                            <div>
                                <Card className={item.PlanName.includes('Garuda') ? 'accordian-image' : item.PlanName.includes('Perisai') ? 'accordian-image2' : 'accordian-image1'}>
                                    <Row>
                                        <Col md={20} xl={20} lg={20} sm={20} xs={16}>
                                            <div style={{ textTransform: "uppercase" }}><b>{item.PlanName}</b></div>
                                            <div style={{ display: "flex" }}><b style={{ fontSize: 21 }}>{new Intl.NumberFormat('id-ID', {
                                                style: 'currency',
                                                currency: 'IDR',
                                                minimumFractionDigits: 0,
                                            }).format(item.Premium)}</b></div>
                                        </Col>
                                        <Col md={2} xl={2} lg={2} sm={2} xs={8}>
                                            <><Button className='accordian-button' onClick={() => handleSelect(item.PlanName, item.selectbtn, item.PlanCode, item.Premium)}>{item.selectbtn === false ? `${translate("travel")["Select"]}` : `${translate("home")["Selected"]}`}</Button></>
                                        </Col>
                                    </Row>
                                </Card>
                                {seedetail3 === true ?
                                    <></>
                                    : <div className='accord-bottom-font view-detail-font'>{translate("travel")["See Details"]}<DownOutlined style={{ color: "#4AC6BB" }} /></div>}
                            </div>} key="4">
                            <>
                                <div style={{ marginBottom: 10 }} className='accordian-inner'>
                                    <div style={{ textTransform: "uppercase" }} className='thick-font'>{`${item.PlanName} Benefits`}</div>
                                    <div className='thick-font'>(RP)</div>
                                </div>
                                {item.MainBenefits.map(item => {

                                    return (

                                        <div>
                                            {item.benefitType != 'C' ?
                                                <div className='accordian-inner'>
                                                    <div>{item.BenefitDesc}</div>
                                                    <div> {tvca != 'Domestic' ? (item.InternationalSI != undefined ? (/\d/.test(item.InternationalSI) === true ? new Intl.NumberFormat('id-ID', {
                                                        style: 'currency',
                                                        currency: 'IDR',
                                                        minimumFractionDigits: 0,
                                                    }).format(item.InternationalSI) : item.InternationalSI) : null) : (item.DomesticSI != undefined ? (/\d/.test(item.DomesticSI) === true ? new Intl.NumberFormat('id-ID', {
                                                        style: 'currency',
                                                        currency: 'IDR',
                                                        minimumFractionDigits: 0,
                                                    }).format(item.DomesticSI) : item.DomesticSI) : null)} </div>
                                                </div> : null}
                                            <hr />
                                        </div>


                                    )
                                })}
                                <div className='thick-font accord-font' style={{ cursor: 'pointer' }} onClick={() => handleCollapse([])}>{translate("home")["Hide Detail"]}<UpOutlined style={{ color: "#4AC6BB", paddingLeft: 5 }} /></div>
                            </>
                        </Panel>
                    )
                }) : null}
            </Collapse>


            {/* <Button className='next-button-travel' onClick={() => props.next()}>
                Proceed
            </Button> */}
        </div>
    )
})

export default ChooseProduct