import React, {useState, useEffect} from 'react'
import { Row, Col, Image, Form, Input, Button, message, Spin } from "antd"
import SuccessLogo from "../oonaImages/GRFX Success State.png"
import  uploadLogo from '../oonaImages/GRFX Upload.png'
import { useHistory } from "react-router-dom"
//import InputImage from "../../oonaImages/Frame 1000004254.png"
import InputImage from '../oonaImages/Frame 1000004254.png'
//import rootIndex from "../../store/root_index";
import rootIndex from '../store/root_index'
import { Base_URL } from '../config/BaseUrl'
import { ToastContainer, toast } from 'react-toastify';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import './oona-aob.css'
import { Header } from 'antd/lib/layout/layout'
import LangSwitch from '../config/LangSwitch'
import oonaLogo from "../images/oona-logo@2x.png"
import { useLanguage } from '../config/LanguageContext'
import axiosRequest from './../axios-request/request.methods'
import CustomerHeader from './../CustomerHeader/CustomerHeader'

const AOBCorOCRSuucess = (props) => {
  console.log(props,"prospssss")
  const history=useHistory()
  const [idURldata,setIdData]=useState("")
  const [loading,setLoading]=useState(false)
  console.log(idURldata,"idURldata")
  const location=useLocation()
console.log(props?.location?.state?.KYCResult?.details?.idNumber,"propsss")

useEffect(()=>{
  if(props?.location?.state?.details?.idNumber !== "" || props?.location?.state?.details?.idNumber !== undefined){
    setTimeout(fetchingOCRDetails, 5000);
  }
 
},[])

useEffect(()=>{
  if(idURldata.hasOwnProperty('idNumber')){
    setLoading(false)
  }
  else{
    setLoading(true)
  }

},[idURldata])


const fetchingOCRDetails=()=>{
  

  let axiosConfig = {
    headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + props?.location?.state?.cipher
    },
  };
  let options = { secure: false, multipart: false }

  axiosRequest
  .get(
      `auth/hyperverge/ocr-details?transactionId=${props?.location?.state?.KYCResult?.transactionId}`,
      options
  )
  .then((data) => {
      
          //console.log(data?.data?.errMsg,"yuteyfuv") 
          setIdData(data?.errMsg?.details?.ocrDetails)
      
  }).catch((error) => {
    // toast.error("Verification service is not responding as expected. Please try after sometime")
      console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
      // setLoading(true)
  });

}

  const handleupdateprofile=()=>{
    
    setLoading(true)
        if (props?.location?.state?.cipher != undefined || props?.location?.state?.cipher != null) {
            var axiosConfig = {
                headers: {
                    "Content-Type": "application/json",
                    'cipher': props?.location?.state?.cipher
                },
            };
        } 
let payloadforIdCard={
  idNumber:props?.location?.state?.KYCResult?.details?.idNumber,
  idUrl:idURldata?.s3Url,
  idType:idURldata?.idType

}
        axiosRequest
        .patch(
          `agent/update-profile`,
          payloadforIdCard,
          
        ).then((data) => {
          //console.log("forgot passs  : ", data.data.errMsg);
          // console.log("Final response: of new lead", data.errMsg.result_valid_mobile[0]);
          if(data?.errCode === -1){
            setLoading(false)
            history.push({
              pathname: '/AOBCorporateIdCardTermsandConditions',
              state: {
                data: props?.location?.state?.data,
                //cipher:props?.location?.state?.cipher,
                phonenumber:props?.location?.state?.phonenumber,
                userId:props?.location?.state?.userId,
                token:props?.location?.state?.token,
                termsandconditionsData:{
                  corporateCreatePassword:props?.location?.state?.termsandconditionsData?.corporateCreatePassword,
                  corporatecomapanyName:props?.location?.state?.termsandconditionsData?.corporatecomapanyName,
                  corporatecompanydirectorname:props?.location?.state?.termsandconditionsData?.corporatecompanydirectorname,
                  corporatepiccompanyemailaddress:props?.location?.state?.termsandconditionsData?.corporatepiccompanyemailaddress
                }
              },
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.errCode === 1) {
            // history.push('/aob-error')
            toast.error(error?.response?.data?.errMsg?.message, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
          }
          console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
        });

  }
  const {translate}=useLanguage()
  return (
    <>
      <CustomerHeader/>
    <div className="row__aob oona_aob_font">

    <Spin tip="Loading" spinning={loading} >
<Row>
            <Col xl={12} md={12} lg={12}>
              <Image preview={false} src={uploadLogo} />
            </Col>
            <Col xl={12} md={12} lg={12}>
              <div className="oonaSuccess__ocrkahoona">
                <div className="oonaSuccess__ocrkahoona__header">
                {translate("onboarding")["We’ve Got Your ID Card Data"]}
                  </div>
                <div className="oonaSuccess__kahoona__header__cenetered oona_aob_font">
                  <div className="oona_aob_font">
                  {translate("onboarding")["Just a few more steps to complete your Kahoona account registration."]}
                  </div>
                 
                <div className="oonna_success_buuton">
                <Col xl={24} md={24} lg={24} >

                {
  idURldata.hasOwnProperty('idNumber') ? ( <Button className="oona_aob_font continue__button" 
  onClick={handleupdateprofile}
  >
    
    {translate("onboarding")["Continue"]}
    </Button>) : (<Button className="oona_aob_font continue__button" 
  disabled
  >
   {translate("onboarding")["Continue"]}
    </Button>)
}

                  </Col>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
    </Spin>
</div>
</>
  )
}

export default AOBCorOCRSuucess
