import React, { useState, useEffect, forwardRef } from 'react'
import { Button, Card, Row, Col,Modal, Image, Drawer, Spin } from 'antd';
import CovidImage from '../../../oonaImages/covid.png';
import benefitIcon from "../../../oonaImages/benefit icon.png"
import { useLanguage } from "../../../config/LanguageContext"
import axiosRequest from '../../../axios-request/request.methods'
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as action from '../../../store/actions/index'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Covid = forwardRef((props, ref) => {
    const { translate, language } = useLanguage()
    const fetchingWholeDatafromStore=useSelector((state)=>state?.cipherlinkreducer?.quote)
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [width, setWidth] = useState(window.innerWidth);
    const [cov, setCov] = useState(false)
    const [price, setPrice] = useState('')
    const [loading, setLoading] = useState(false)
    const location=useLocation()
    const history=useHistory()
    const dispatch=useDispatch()
    const breakpoint = 620;
    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, [width]);

    useEffect(()=>{
        if(fetchingWholeDatafromStore?.quoteId !== ""){
            getQuotes()
            addbenefit()
        }
       

    },[fetchingWholeDatafromStore])

    const getQuotes = () => {
        var fromReferral = ""
        if(fetchingWholeDatafromStore?.resumeType === "referalJourney" || fetchingWholeDatafromStore?.resumeType === "quoteSearch"){
          fromReferral=`user/get-quotes?id=${fetchingWholeDatafromStore?.quoteId}&logkey=${fetchingWholeDatafromStore?.resumeType}`
        }
        else{
          fromReferral=`user/get-quotes?id=${fetchingWholeDatafromStore?.quoteId}`
        }

        
        axiosRequest
        .get(
          `${fromReferral}`,
                )
            .then((data) => {
                
            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

            });
    }

    const [filteredData, setFilteredData] = useState([])

    const addbenefit = () => {
        setLoading(true)
        axiosRequest
            .get(
                `user/plan-benefit/${fetchingWholeDatafromStore?.quoteId}?langId=${language === "en" ? "EN" : "BH"}`,
                
            )
            .then((data) => {
                setLoading(false)
                if(data?.errMsg?.data[0]?.Plans != undefined){
                const filteredBenefits = data?.errMsg?.data[0]?.Plans[0]?.AdditionalBenefits.filter(
                    (benefit) => benefit.BenefitType === 'B' && benefit.ParentBenefit === 'AC000CV01'
                );
                setFilteredData(filteredBenefits)
                data?.errMsg?.data[0]?.Plans[0]?.AdditionalBenefits?.map(item => {
                    if (item.BenefitCode === 'AC000CV01') {
                        console.log(item.Premium, ' covid premium------->');
                        setPrice(item.Premium)
                    }
                })
            }

            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
                setLoading(false)
            });
    }

    const handlecvbnft = (value) => {
        console.log(value, 'cv----->');
        if (value === false) {
            let Data={
               ...fetchingWholeDatafromStore,
               covid:false
            }
            dispatch(action.set_quote_id(Data))
          history.push("/additionalbenefittravel")
            
        } else {
            let Data={
                ...fetchingWholeDatafromStore,
                covid:true
             }
             dispatch(action.set_quote_id(Data))
             history.push("/additionalbenefittravel")
          
        }
    }


    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <div>

            {width > breakpoint ? (
                <>
                    <Modal className='covid-popup' maskClosable={false} closable={false} visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                        <Spin tip="Loading" spinning={loading} >
                            <Card className='covid-card'>
                                <Image preview={false} src={CovidImage} />
                            </Card>
                            <h4 style={{ fontSize: 25 }} className='thick-font modal-font'>{translate("travel")["Would you like to add COVID-19 Protection?"]}</h4>
                            <div className='modal-font covid-font'>
                                {translate("travel")["We offer you reimbursement for travel cancellation costs and hospital fees due to Covid-19"]}
                            </div>
                            {filteredData.map((benefit, index) => (
                                <div key={index} style={{ alignItems: "center", display: "flex", margin: "2% 0%" }}>
                                    <div><Image src={benefitIcon} preview={false} /></div>
                                    <div style={{ fontSize: "12px" }}>{benefit.BenefitDesc}</div>
                                </div>
                            ))}
                            <br />
                            <div className='home-small-font'>{translate("travel")["Price"]}</div>
                            <h5 className='thick-font'>{new Intl.NumberFormat('id-ID', {
                                style: 'currency',
                                currency: 'IDR',
                                minimumFractionDigits: 0,
                            }).format(price === 0 ? 0 : price)}</h5>
                            <Row>
                                <Col md={12} xl={12} lg={12} xs={12} sm={12}>
                                    <Button className='no-button' onClick={() => handlecvbnft(false)}>
                                        {translate("travel")["No"]}
                                    </Button>
                                </Col>
                                <Col md={12} xl={12} lg={12} xs={12} sm={12}>
                                    <Button onClick={() => handlecvbnft(true)} className='next-button-travel'>
                                        {translate("travel")["Add Protection"]}
                                    </Button>
                                </Col>
                            </Row>
                        </Spin>
                    </Modal>
                </>) : (<>
                    <Drawer
                        className='covid-drawer'
                        placement="bottom"
                        closable={false}
                        visible={isModalOpen}
                    >
                        <>
                            <Spin tip="Loading" spinning={loading} >
                                <Card className='covid-card'>
                                    <Image preview={false} src={CovidImage} />
                                </Card>
                                <h4 style={{ fontSize: 22 }} className='modal-font'>{translate("travel")["Would you like to add COVID-19 Protection?"]}</h4>
                                <div className='modal-font'>
                                    {translate("travel")["We offer you reimbursement for travel cancellation costs and hospital fees due to Covid-19"]}
                                </div>
                                {filteredData.map((benefit, index) => (
                                <div key={index} style={{ alignItems: "center", display: "flex", margin: "2% 0%" }}>
                                    <div><Image src={benefitIcon} preview={false} /></div>
                                    <div style={{ fontSize: "12px" }}>{benefit.BenefitDesc}</div>
                                </div>
                            ))}
                                <div>Price</div>
                                <h5><b>{new Intl.NumberFormat('id-ID', {
                                    style: 'currency',
                                    currency: 'IDR',
                                    minimumFractionDigits: 0,
                                }).format(price === 0 ? 0 : price)}</b></h5>
                                <Row>
                                    <Col md={12} xl={12} lg={12} xs={12} sm={12}>
                                        <Button className='no-button' onClick={() => handlecvbnft(false)}>
                                            {translate("travel")["No"]}
                                        </Button>
                                    </Col>
                                    <Col md={12} xl={12} lg={12} xs={12} sm={12}>
                                        <Button onClick={() => handlecvbnft(true)} className='next-button-travel'>
                                            {translate("travel")["Add Protection"]}
                                        </Button>
                                    </Col>
                                </Row>
                            </Spin>
                        </>
                    </Drawer>
                </>)
            }

        </div>
    )
})

export default Covid