
export const PIDList={
    travel : "64aff2f3831667919245f86c",
    motorFourwheeler : "64c0fd4af6d4bddffcac8f72",
    motorTwowheeler: "64c0fd38f6d4bddffcac8f71",
    home : "64c34807507810de0263ff63",
    pA: "657044193c379101206fd9c6",
    CI: "660f9fee0999cd537fc73926"
}


export const PNamesList={
    travel:"Travel Insurance",
    motorFourwheeler:"Motor Insurance 4W",
    motorTwowheeler:"Motor Insurance 2W",
    home:"Home Insurance",
    pA:"Personal Accident Insurance",
    CI:"Critical Illness Insurance"
}