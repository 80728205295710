import React, { createContext, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as action from '../store/actions/index';
import Cookies from 'js-cookie';
import { set_global_language } from '../store/actions';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const dispatch = useDispatch();

    // Use useSelector to get the language from the store
    const language = useSelector((state) => state.languageReducer.lang);
console.log(language,"language in Langcon==>")
    const switchLanguage = (newLanguage) => {
        // Dispatch the action to update the language in the store
        dispatch(set_global_language(newLanguage));
        Cookies.set('LANG', newLanguage === 'en' ? 'en' : 'id', { domain: process.env.REACT_APP_COOKIE_URL });
       
    };

    const translate = (key) => {
        // Load the translation based on the current language
        const translations = require(`./locales/${language}.json`);
        const translation = translations[key];

        if (translation !== undefined) {
            return translation;
        } else {
            // Fallback to a default translation or the key itself
            return key;
        }
    };
    return (
        <LanguageContext.Provider value={{ language, switchLanguage, translate }}>
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguage = () => useContext(LanguageContext);
