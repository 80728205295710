import React, { useState, useEffect } from 'react'
import { Collapse, Button, Card, Row, Col, Progress, Input } from "antd";
import {  ArrowLeftOutlined } from "@ant-design/icons"
import { useHistory } from "react-router-dom"
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import rootIndex from "../../../store/root_index";
import { useLanguage } from '../../../config/LanguageContext';
import { toast } from 'react-toastify';
import axiosRequest from '../../../axios-request/request.methods'
import CustomerHeader from '../../../CustomerHeader/CustomerHeader';
import Singlepanel from '../../accordin/Singlepanel';
import MultiplePanel from '../../accordin/MultiplePanel';
import { useSelector } from 'react-redux';


export default function Index(props) {
    const { store } = rootIndex;
    const _store = store.getState();
    const { translate } = useLanguage()
    const fetchingIDfromStore=useSelector((state)=>state?.cipherlinkreducer?.quote?.quoteId)
    console.log(fetchingIDfromStore,"fetchingIDfromStore")
    const checkingResumeType=useSelector((state)=>state?.cipherlinkreducer?.quote?.resumeType);

    const history = useHistory()

    const [width, setWidth] = useState(window.innerWidth);
    const breakpoint = 620;
    const location = useLocation();
    const [bike, setBike] = useState(false)
    useEffect(() => {

        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, [width]);
    
    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }, []);

    useEffect(() => {
        if (_store?.cipherlinkreducer?.formdata?.cipherkeyavilable === true) {
            setShow(false)
        } else {
            setShow(true)
        }
        if(fetchingIDfromStore !== ""){
            getQuote()
        }
        
    }, [fetchingIDfromStore,_store?.cipherlinkreducer?.formdata?.cipherkeyavilable])
    const [num, setNum] = useState(0);
    const [show, setShow] = useState(true);
    const [discountpercent, setDiscountPercent] = useState(0)
    console.log(discountpercent,"discountpercent in Mv")
    const [total, setTotal] = useState(0)
    console.log(total,"total in MV")
    const [diserr, setDisErr] = useState(false)
    const [disnumerr, setdisNumErr] = useState(false)
    const [bnftcalc, setBnftCalc] = useState(0)
    const [premium, setPremium] = useState('')
    const { state } = useLocation();
    const [quoteData, setQuoteData] = useState();
    console.log(quoteData,"quoteData")
    const [additionBenifit, setAdditionBenifit] = useState([])
    const [planName, setPlanName] = useState('');
    const [promo, setPromo] = useState('')
    const [disable, setDisable] = useState(false)
    const [promoarr, setPromoArr] = useState(false)
    const [checkingAuthWorkshop,setcheckingAuthWorkshop]=useState([])
    console.log(checkingAuthWorkshop,"checkingAuthWorkshop")
    const [loader,setLoader]=useState(false)

    const handlePromo = (value) => {
        setPromo(value)
        if (value.length === 18) {
            promocodeValidate(value)
        }
    }
    const promocodeSubmit = () => {
        if (promo.length === 18) {

            let formData = {
                promoCodeList: JSON.parse(JSON.stringify([promo]))
            }
            let options = { multipart: false, secure: true }
            axiosRequest
                .patch(
                    `user/update-quote-motor/${fetchingIDfromStore}`,
                    formData,
                    options
                )
                .then((data) => {
                    console.log("Final response: of Update lead in plan selection", data.errMsg);
                    if (data.errCode === -1) {
                        axiosRequest.get(
                            `user/quick-quote-motor/${fetchingIDfromStore}?type=${quoteData?.product_Id === '64c0fd4af6d4bddffcac8f72' ? 'fourWheeler' : 'twoWheeler'}`, options
                        ).then((response) => {
                            if (response.errCode === -1) {
                                toast.success(`${translate('home')["Quote updated SuccessFully"]}
                                `, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
                                if (_store?.cipherlinkreducer?.formdata?.cipherkeyavilable === true) {
                                    history.push({
                                        pathname: '/customerinformationmotor',
                                        // state: {
                                        //     quoteId: state?.quoteId,
                                        //     data:location.state.data,
                                        //     quoteCode: data.errMsg.data.quote_Id,
                                        //     // cipher: state.cipher
                                        // }
                                    })
                                } else {

                                    history.push({
                                        pathname: '/oonasuccess',
                                        // state: {
                                        //     quoteId: state?.quoteId,
                                        //     data:location.state.data === "referalJourney" ? `motor${location?.state?.data}`  : location?.state?.data === "quoteSearch" ? `motor${location?.state?.data}` : "motor",
                                        //     quoteCode: data.errMsg.data.quote_Id
                                        // }
                                    })
                                }
                            }
                        })
                       

                        toast.success(`${translate("home")["Quote Updated Successfully"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
                    }
                })
        }
        else if ((promo.length > 0 && promo.length < 18) || promo.length > 18) {
            toast.error('Enter a valid promo code', { position: "top-center", hideProgressBar: true, autoClose: 1000 })
        }
        else {
            let formData = {
                discountPercent: 0
            }

            let options = { secure: true, multipart: false }
            axiosRequest
                .patch(
                    `user/update-quote-motor/${fetchingIDfromStore}`,
                    formData,
                    options
                )
                .then((data) => {
                    if (data.errCode === -1) {
                        axiosRequest.get(
                            `user/quick-quote-motor/${fetchingIDfromStore}?type=${quoteData?.product_Id === '64c0fd4af6d4bddffcac8f72' ? 'fourWheeler' : 'twoWheeler'}`, options
                        ).then((response) => {
                            if (response.errCode === -1) {
                                toast.success(`${translate('home')["Quote updated SuccessFully"]
                            }`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
                                if (_store?.cipherlinkreducer?.formdata?.cipherkeyavilable === true) {
                                    history.push({
                                        pathname: '/customerinformationmotor',
                                        // state: {
                                        //     quoteId: state?.quoteId,
                                        //     data: location.state.data,
                                        //     quoteCode: data.errMsg.data.quote_Id,
                                        //     // cipher: state.cipher
                                        // }
                                    })
                                } else {
                                    history.push({
                                        pathname: '/oonasuccess',
                                        // state: {
                                        //     quoteId: state?.quoteId,
                                        //     data:location.state.data === "referalJourney" ? `motor${location?.state?.data}`  : location?.state?.data === "quoteSearch" ? `motor${location?.state?.data}` : "motor",
                                        //     quoteCode: data.errMsg.data.quote_Id
                                        // }
                                    })
                                }
                            }
                        })

                        toast.success(`${translate("home")["Quote Updated Successfully"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
                    }
                })
              
        }

    }

    const getQuote = () => {
        let options = { secure: true, multipart: false }

        var fromReferral = ""
        if(checkingResumeType === "referalJourney" || checkingResumeType === "quoteSearch"){
          fromReferral=`user/get-quotes?id=${fetchingIDfromStore}&logkey=${checkingResumeType}`
        }
        else{
          fromReferral=`user/get-quotes?id=${fetchingIDfromStore}`
        }


        axiosRequest
            .get(
                `${fromReferral}`,
                options
            )
            .then((data) => {
                if (data.errCode === -1) {
                    setLoader(false)
                    const getQuoteData=data?.errMsg?.data[0]
                    setQuoteData(getQuoteData)
                    setDisErr(false)
                    setdisNumErr(false)
                    setPlanName(getQuoteData?.planName)
                    getQuoteData.premium && setPremium(getQuoteData?.premium)
                    getQuoteData?.discountPercent && setNum(getQuoteData?.discountPercent)
                    setAdditionBenifit(getQuoteData?.additionalBenefits)
                    if(getQuoteData?.isEligibleForMultiyear){
                        const findingauthorizedworkshop=getQuoteData?.additionalBenefits?.filter((item)=>{
                            return item?.BenefitCode === "AU01"
                        })
                        setcheckingAuthWorkshop(findingauthorizedworkshop)
                        console.log(findingauthorizedworkshop,"findingauthorizedworkshop")

                    }
                    if (getQuoteData.product_Id === "64c0fd38f6d4bddffcac8f71") {
                        setBike(true)
                    } else {
                        setBike(false)
                    }
                    if (getQuoteData.additionalBenefits.length > 0) {
                        var finalValue = 0
                        getQuoteData.additionalBenefits.map(item => {
                            finalValue = finalValue + parseInt(item.Premium)
                        })
                        if (finalValue !== 0) {
                            setBnftCalc(finalValue)
                            if (_store?.cipherlinkreducer?.formdata?.cipherkeyavilable === true) {
                                if (getQuoteData.promoCodeList && getQuoteData.promoCodeList.length > 0) {
                                    setPromo(getQuoteData.promoCodeList[0].promoCode)
                                    setDisable(true)
                                    setPromoArr(true)

                                    const calculatingDiscountAmount=  (Math.round((finalValue + getQuoteData.premium) * (Number(getQuoteData.promoCodeList[0].discountPercent)) / 100))
                                    console.log(calculatingDiscountAmount,"calculatingDiscountAmount")
                          
                                    const calculatingTotalAmount=Math.floor((finalValue + getQuoteData.premium) - (calculatingDiscountAmount))
                                      console.log(calculatingTotalAmount,"calculatingTotalAmount")
                          
                                      getQuoteData?.premium &&   setDiscountPercent(parseInt(calculatingDiscountAmount))
                                      getQuoteData?.premium &&   setTotal((parseInt(calculatingTotalAmount)))



                                    // getQuoteData?.premium && setDiscountPercent(Math.round(((parseInt(finalValue) + parseInt(getQuoteData.premium)) * (getQuoteData.promoCodeList[0].discountPercent / 100))))
                                    // getQuoteData?.premium && setTotal(Math.round(((parseInt(finalValue) + parseInt(getQuoteData.premium)) - ((parseInt(finalValue) + parseInt(getQuoteData.premium)) * (getQuoteData.promoCodeList[0].discountPercent / 100)))))
                                } else {


                                    const calculatingDiscountAmount=  (Math.round((finalValue + getQuoteData.premium) * (Number(getQuoteData.discountPercent || 0)) / 100))
                                    console.log(calculatingDiscountAmount,"calculatingDiscountAmount")
                          
                                    const calculatingTotalAmount=Math.floor((finalValue + getQuoteData.premium) - (calculatingDiscountAmount))
                                      console.log(calculatingTotalAmount,"calculatingTotalAmount")
                          
                                      getQuoteData?.premium &&   setDiscountPercent(parseInt(calculatingDiscountAmount))
                                      getQuoteData?.premium &&   setTotal((parseInt(calculatingTotalAmount)))


                                   
                                      
                                    // getQuoteData.premium && setDiscountPercent(Math.round(((parseInt(finalValue) + parseInt(getQuoteData.premium)) * ((getQuoteData.discountPercent || 0) / 100))))
                                    // getQuoteData.premium && setTotal(Math.round(((parseInt(finalValue) + parseInt(getQuoteData.premium)) - ((parseInt(finalValue) + parseInt(getQuoteData.premium)) * ((getQuoteData.discountPercent || 0) / 100)))))
                                }
                            } else {
                                if (getQuoteData.discountPercent !== undefined) {

                                    const calculatingDiscountAmount=  (Math.round((finalValue + getQuoteData.premium) * (Number(getQuoteData.discountPercent ? getQuoteData.discountPercent : 0)) / 100))
                                    console.log(calculatingDiscountAmount,"calculatingDiscountAmount")
                          
                                    const calculatingTotalAmount=Math.floor((finalValue + getQuoteData.premium) - (calculatingDiscountAmount))
                                      console.log(calculatingTotalAmount,"calculatingTotalAmount")
                          
                                      getQuoteData?.premium &&   setDiscountPercent(parseInt(calculatingDiscountAmount))
                                      getQuoteData?.premium &&   setTotal((parseInt(calculatingTotalAmount)))

                                    // setDiscountPercent(Math.round(((parseInt(finalValue) + parseInt(getQuoteData.premium)) * ((getQuoteData.discountPercent ? getQuoteData.discountPercent : 0) / 100))))
                                    // setTotal(Math.round(((parseInt(finalValue) + parseInt(getQuoteData.premium)) - ((parseInt(finalValue) + parseInt(getQuoteData.premium)) * ((getQuoteData.discountPercent ? getQuoteData.discountPercent : 0) / 100)))))
                                } else {
                                   

                                    const calculatingDiscountAmount= (Math.round((finalValue + getQuoteData.premium) * ( getQuoteData.discountPercent ? Number(getQuoteData.discountPercent) : 0) / 100))
          console.log(calculatingDiscountAmount,"calculatingDiscountAmount")

          const calculatingTotalAmount=Math.floor((finalValue + getQuoteData.premium) - (calculatingDiscountAmount))
            console.log(calculatingTotalAmount,"calculatingTotalAmount")

          setDiscountPercent(parseInt(calculatingDiscountAmount))
          setTotal((parseInt(calculatingTotalAmount)))
                                    

                                    // getQuoteData.premium && setDiscountPercent(Math.round(((parseInt(finalValue) + parseInt(getQuoteData.premium)) * ((getQuoteData.discountPercent ? getQuoteData.discountPercent : 0) / 100))))
                                    // getQuoteData.premium && setTotal(Math.round(((parseInt(finalValue) + parseInt(getQuoteData.premium)) - ((parseInt(finalValue) + parseInt(getQuoteData.premium)) * ((getQuoteData.discountPercent || 0) / 100)))))
                                }
                            }
                        } else {
                            if (_store?.cipherlinkreducer?.formdata?.cipherkeyavilable === true) {
                                if (getQuoteData.promoCodeList && getQuoteData.promoCodeList.length > 0) {
                                    setPromo(getQuoteData.promoCodeList[0].promoCode != undefined ? getQuoteData.promoCodeList[0].promoCode : '')
                                    setDisable(true)
                                    setPromoArr(true)


                                    const calculatingDiscountAmount=  (Math.round((finalValue + getQuoteData.premium) * ( Number(getQuoteData.promoCodeList[0].discountPercent)) / 100))
                                    console.log(calculatingDiscountAmount,"calculatingDiscountAmount")
                          
                                    const calculatingTotalAmount=Math.floor((finalValue + getQuoteData.premium) - (calculatingDiscountAmount))
                                      console.log(calculatingTotalAmount,"calculatingTotalAmount")
                          
                                      getQuoteData.premium &&   setDiscountPercent(parseInt(calculatingDiscountAmount))
                                      getQuoteData.premium &&  setTotal((parseInt(calculatingTotalAmount)))

                                    // getQuoteData.premium && setDiscountPercent(Math.round(((parseInt(finalValue) + parseInt(getQuoteData.premium)) * (getQuoteData.promoCodeList[0].discountPercent / 100))))
                                    // getQuoteData.premium && setTotal(Math.round(((parseInt(finalValue) + parseInt(getQuoteData.premium)) - ((parseInt(finalValue) + parseInt(getQuoteData.premium)) * (getQuoteData.promoCodeList[0].discountPercent / 100)))))
                                } else {
                                    

                                    const calculatingDiscountAmount=  (Math.round((finalValue + getQuoteData.premium) * ( Number(getQuoteData.discountPercent)) / 100))
                                    console.log(calculatingDiscountAmount,"calculatingDiscountAmount")
                          
                                    const calculatingTotalAmount=Math.floor((finalValue + getQuoteData.premium) - (calculatingDiscountAmount))
                                      console.log(calculatingTotalAmount,"calculatingTotalAmount")
                          
                                      getQuoteData.premium &&   setDiscountPercent(parseInt(calculatingDiscountAmount))
                                      getQuoteData.premium &&  setTotal((parseInt(calculatingTotalAmount)))


                                    // getQuoteData.premium && setDiscountPercent(Math.round(((parseInt(finalValue) + parseInt(getQuoteData.premium)) * (getQuoteData.discountPercent / 100))))
                                    // getQuoteData.premium && setTotal(Math.round(((parseInt(finalValue) + parseInt(getQuoteData.premium)) - ((parseInt(finalValue) + parseInt(getQuoteData.premium)) * (getQuoteData.discountPercent / 100)))))
                                }
                            } else {
                                if (getQuoteData.discountPercent != undefined) {

                                    const calculatingDiscountAmount=  (Math.round((finalValue + getQuoteData.premium) * ( Number(getQuoteData.discountPercent)) / 100))
                                    console.log(calculatingDiscountAmount,"calculatingDiscountAmount")
                          
                                    const calculatingTotalAmount=Math.floor((finalValue + getQuoteData.premium) - (calculatingDiscountAmount))
                                      console.log(calculatingTotalAmount,"calculatingTotalAmount")
                          
                                      getQuoteData.premium &&   setDiscountPercent(parseInt(calculatingDiscountAmount))
                                      getQuoteData.premium &&  setTotal((parseInt(calculatingTotalAmount)))

                                    // getQuoteData.premium && setDiscountPercent(Math.round(((parseInt(finalValue) + parseInt(getQuoteData.premium)) * (getQuoteData.discountPercent / 100))))
                                    // getQuoteData.premium && setTotal(Math.round(((parseInt(finalValue) + parseInt(getQuoteData.premium)) - ((parseInt(finalValue) + parseInt(getQuoteData.premium)) * (getQuoteData.discountPercent / 100)))))
                                } else {
                                    
                                    const calculatingDiscountAmount=  (Math.round((finalValue + getQuoteData.premium) * ( Number(getQuoteData.discountPercent)) / 100))
                                    console.log(calculatingDiscountAmount,"calculatingDiscountAmount")
                          
                                    const calculatingTotalAmount=Math.floor((finalValue + getQuoteData.premium) - (calculatingDiscountAmount))
                                      console.log(calculatingTotalAmount,"calculatingTotalAmount")
                          
                                      getQuoteData.premium &&   setDiscountPercent(parseInt(calculatingDiscountAmount))
                                      getQuoteData.premium &&  setTotal((parseInt(calculatingTotalAmount)))

                                    // getQuoteData.premium && setDiscountPercent(Math.round(((parseInt(finalValue) + parseInt(getQuoteData.premium)) * (getQuoteData.discountPercent / 100))))
                                    // getQuoteData.premium && setTotal(Math.round(((parseInt(finalValue) + parseInt(getQuoteData?.premium)) - ((parseInt(finalValue) + parseInt(getQuoteData?.premium)) * (getQuoteData.discountPercent / 100)))))
                                }
                            }
                        }
                        setPlanName(getQuoteData?.planName)
                    }
                    else {
                        if (getQuoteData?.discountPercent !== undefined) {
                           
                            const calculatingDiscountAmount=  (Math.round(( getQuoteData.premium) * ( Number(getQuoteData.discountPercent)) / 100))
                            console.log(calculatingDiscountAmount,"calculatingDiscountAmount")
                  
                            const calculatingTotalAmount=Math.floor(( getQuoteData.premium) - (calculatingDiscountAmount))
                              console.log(calculatingTotalAmount,"calculatingTotalAmount")
                  
                              getQuoteData.premium &&   setDiscountPercent(parseInt(calculatingDiscountAmount))
                              getQuoteData.premium &&  setTotal((parseInt(calculatingTotalAmount)))

                            // getQuoteData.premium && setDiscountPercent(Math.round(((parseInt(getQuoteData.premium)) * (getQuoteData.discountPercent / 100))))
                            // getQuoteData.premium && setTotal(Math.round(((parseInt(getQuoteData.premium)) - ((parseInt(getQuoteData.premium)) * (getQuoteData?.discountPercent / 100)))))
                        } else {

                           
                            const calculatingDiscountAmount=  (Math.round((getQuoteData?.premium) * ( Number(0)) / 100))
                            console.log(calculatingDiscountAmount,"calculatingDiscountAmount")
                  
                            const calculatingTotalAmount=Math.floor((getQuoteData?.premium) - (calculatingDiscountAmount))
                              console.log(calculatingTotalAmount,"calculatingTotalAmount")
                  
                              getQuoteData.premium &&   setDiscountPercent(parseInt(calculatingDiscountAmount))
                              getQuoteData.premium &&  setTotal((parseInt(calculatingTotalAmount)))

                            // getQuoteData.premium && setDiscountPercent(Math.round(((parseInt(getQuoteData?.premium)) * (0 / 100))))
                            // getQuoteData.premium && setTotal(Math.round(((parseInt(getQuoteData.premium)) - ((parseInt(getQuoteData.premium)) * (0 / 100)))))
                        }
                    }
                }
            })
    }
    const promocodeValidate = (value) => {
        let options = { secure: true, multipart: false }
        let formData = {
            promoCode: value
        }
        axiosRequest
            .post(
                `user/validate-promocode`,
                formData,
                options
            )
            .then((data) => {
                console.log("Final response: of Update lead in plan selection", data.errMsg);
                if (data.errCode === -1) {
                    setDisable(true)

                    const calculatingDiscountAmount=  (Math.round((bnftcalc + premium) * ( Number(data.errMsg.discount)) / 100))
                    console.log(calculatingDiscountAmount,"calculatingDiscountAmount")
          
                    const calculatingTotalAmount=Math.floor((bnftcalc + premium) - (calculatingDiscountAmount))
                      console.log(calculatingTotalAmount,"calculatingTotalAmount")
          
                         setDiscountPercent(parseInt(calculatingDiscountAmount))
                       setTotal((parseInt(calculatingTotalAmount)))



                    // setDiscountPercent(Math.round((parseInt(bnftcalc) + parseInt(premium)) * (data.errMsg.discount / 100)))
                    // setTotal(Math.round(((parseInt(bnftcalc) + parseInt(premium)) - ((parseInt(bnftcalc) + parseInt(premium)) * (data.errMsg.discount / 100)))))
                    toast.success("PromoCode validated Successfully", { position: "top-center", hideProgressBar: true, autoClose: 1000 })
                }
            })
           
    }

    let incNum = () => {
        console.log(num, 'numm------>');
        if (diserr === false && disnumerr === false) {
            if (num < 25) {
                if ((Number(num) + 0.5) <= 25) {
                    console.log(total, num, bnftcalc, "cgh")
                    setNum(Number(num) + 0.5);
                    // setDiscountPercent(Math.round((parseInt(bnftcalc) + parseInt(premium)) * ((Number(num) + 0.5) / 100)))
                    // setTotal(Math.round(((parseInt(bnftcalc) + parseInt(premium)) - ((parseInt(bnftcalc) + parseInt(premium)) * ((Number(num) + 0.5) / 100)))))
                    const calculatingDiscountAmount=  (Math.round((bnftcalc + premium) * (Number(num)+ 0.5)  / 100))
                    console.log(calculatingDiscountAmount,"calculatingDiscountAmount")
          
                    const calculatingTotalAmount=Math.floor((bnftcalc + premium) - (calculatingDiscountAmount))
                      console.log(calculatingTotalAmount,"calculatingTotalAmount")
          
                    setDiscountPercent(parseInt(calculatingDiscountAmount))
                    setTotal((parseInt(calculatingTotalAmount)))


                } else {
                    toast.error("Discount will be increse in the interval of 0.5, maximum discount is 30% only", { position: "top-center", hideProgressBar: true, autoClose: 1000 })
                }
            } else {
                toast.error(`${translate("home")["Discount is upto 25% only"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
            }
        } else {
            if (diserr === true) {
                toast.error(`${translate("home")["Please Enter Only Numbers"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
            }
            if (disnumerr === true) {
                toast.error(`${translate("home")["Please enter numbers between 0 to 25"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
            }
        }
    };
    let decNum = () => {
        if (diserr === false && disnumerr === false) {
            if (num >= 0.5) {
                if ((Number(num) - 0.5) >= 0) {
                    setNum(num - 0.5);
                    const calculatingDiscountAmount=  (Math.round((bnftcalc + premium) * (Number(num) - 0.5) / 100))
                    console.log(calculatingDiscountAmount,"calculatingDiscountAmount")
          
                    const calculatingTotalAmount=Math.floor((bnftcalc + premium) - (calculatingDiscountAmount))
                      console.log(calculatingTotalAmount,"calculatingTotalAmount")
          
                    setDiscountPercent(parseInt(calculatingDiscountAmount))
                    setTotal((parseInt(calculatingTotalAmount)))

                } else {
                    toast.error("Discount will be decrease in the interval of 0.5, discount cannot be negative", { position: "top-center", hideProgressBar: true, autoClose: 1000 })
                }
            } else {
                toast.error(`${translate("home")["Discount cannot be negative"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
            }
        } else {
            if (diserr === true) {
                toast.error(`${translate("home")["Please Enter Only Numbers"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
            }
            if (disnumerr === true) {
                toast.error(`${translate("home")["Please enter numbers between 0 to 25"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
            }
        }
    }
    let handleChange = (val) => {
        if (val >= 0 && val <= 1) {
            setNum(val);
            setDisErr(false)
            setdisNumErr(false)
            const calculatingDiscountAmount=  (Math.round((bnftcalc + premium) * (Number(num)) / 100))
                    console.log(calculatingDiscountAmount,"calculatingDiscountAmount")
          
                    const calculatingTotalAmount=Math.floor((bnftcalc + premium) - (calculatingDiscountAmount))
                      console.log(calculatingTotalAmount,"calculatingTotalAmount")
          
                    setDiscountPercent(parseInt(calculatingDiscountAmount))
                    setTotal((parseInt(calculatingTotalAmount)))


        }
        const reg = new RegExp('^([+-]?([0-9]*[.])?[0-9]+)');
        console.log(reg.test(val), typeof (val));
        if (reg.test(val) === true) {
            if (val.length <= 4) {
                if (Number(val) <= 25 && Number(val) >= 0) {
                    console.log(Number(val),  "fgh")
                    setNum(Number(val));
                    setDisErr(false)
                    setdisNumErr(false)
                    // setDiscountPercent(Math.round((parseInt(bnftcalc) + parseInt(premium)) * ((Number(val)) / 100)))
                    // setTotal(Math.round(((parseInt(bnftcalc) + parseInt(premium)) - ((parseInt(bnftcalc) + parseInt(premium)) * ((Number(val)) / 100)))))

                    const calculatingDiscountAmount=  (Math.round((bnftcalc + premium) * (Number(val)) / 100))
                    console.log(calculatingDiscountAmount,"calculatingDiscountAmount")
          
                    const calculatingTotalAmount=Math.floor((bnftcalc + premium) - (calculatingDiscountAmount))
                      console.log(calculatingTotalAmount,"calculatingTotalAmount")
          
                    setDiscountPercent(parseInt(calculatingDiscountAmount))
                    setTotal((parseInt(calculatingTotalAmount)))
                } else {
                    if (num === 25) {
                        setNum(25);
                        const calculatingDiscountAmount=  (Math.round((bnftcalc + premium) * (Number(25)) / 100))
                        console.log(calculatingDiscountAmount,"calculatingDiscountAmount")
              
                        const calculatingTotalAmount=Math.floor((bnftcalc + premium) - (calculatingDiscountAmount))
                          console.log(calculatingTotalAmount,"calculatingTotalAmount")
              
                        setDiscountPercent(parseInt(calculatingDiscountAmount))
                        setTotal((parseInt(calculatingTotalAmount)))
                        setDisErr(false)

                    } else {
                         setNum(25);
                         const calculatingDiscountAmount=  (Math.round((bnftcalc + premium) * (Number(25)) / 100))
                         console.log(calculatingDiscountAmount,"calculatingDiscountAmount")
               
                         const calculatingTotalAmount=Math.floor((bnftcalc + premium) - (calculatingDiscountAmount))
                           console.log(calculatingTotalAmount,"calculatingTotalAmount")
               
                         setDiscountPercent(parseInt(calculatingDiscountAmount))
                         setTotal((parseInt(calculatingTotalAmount)))

                     
                        setDisErr(false)
                         toast.error(`${translate("home")["Please enter numbers between 0 to 25"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
                    }
                }
            } else {
                toast.error(`${translate("motor")["Enter only numbers between 0 to 25"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
            }
        } else {
            if (val.length <= 4 && val.length > 0) {
                setNum(val);
                toast.error(`${translate("home")["Enter only numbers or Number Should Not start with 0"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
                setDisErr(true)
            } else if (val.length === 0) {
                setNum(val);
                setDisErr(true)
                toast.error(`${translate("home")["Enter any number, minimum is 0"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
                const calculatingDiscountAmount=  (Math.round((bnftcalc + premium) * (Number(0)) / 100))
                console.log(calculatingDiscountAmount,"calculatingDiscountAmount")
      
                const calculatingTotalAmount=Math.floor((bnftcalc + premium) - (calculatingDiscountAmount))
                  console.log(calculatingTotalAmount,"calculatingTotalAmount")
      
                setDiscountPercent(parseInt(calculatingDiscountAmount))
                setTotal((parseInt(calculatingTotalAmount)))
            } else {
                toast.error(`${translate("motor")["Enter only numbers between 0 to 25"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
            }
        }
    }

    const handleSubmit = () => {
    
        const reg = new RegExp('/^(0(\.\d{1,2})?|([1-9]|1\d|2[0-9]|30)(\.\d{1,2})?)$/');
        if (reg.test(num) === true || (num => 0 && num <= 1)) {
            if (num => 0 && num <= 30) {
                const req = {
                    "discountPercent": num,
                }
                let options = { multipart: false, secure: true }
                axiosRequest
                    .patch(
                        `user/update-quote-motor/${fetchingIDfromStore}`,
                        req,
                        options
                    )
                    .then((data) => {
                        if (data.errCode === -1) {
                            toast.success(data.errMsg.message, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
                            axiosRequest.get(
                                `user/quick-quote-motor/${fetchingIDfromStore}?type=${quoteData?.product_Id === '64c0fd4af6d4bddffcac8f72' ? 'fourWheeler' : 'twoWheeler'}`, options
                            ).then((response) => {
                                if (response.errCode === -1) {
                                    toast.success(`${translate('home')["Quote updated SuccessFully"]}
                                    `, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
                                    if (_store?.cipherlinkreducer?.formdata?.cipherkeyavilable === true) {
                                        history.push({
                                            pathname: '/customerinformationmotor',
                                            // state: {
                                            //     quoteId: state?.quoteId,
                                            //     data: location?.state?.data,
                                            //     quoteCode: data.errMsg.data.quote_Id,
                                            //     // cipher: state.cipher
                                            // }
                                        })
                                    } else {
                                        history.push({
                                            pathname: '/oonasuccess',
                                            // state: {
                                            //     quoteId: state?.quoteId,
                                            //     data:location.state.data === "referalJourney" ? `motor${location?.state?.data}`  : location?.state?.data === "quoteSearch" ? `motor${location?.state?.data}` : "motor",
                                            //     quoteCode: data.errMsg.data.quote_Id
                                            // }
                                        })
                                    }
                                }
                            })
                        }
                    })
            } else {

                toast.error("Discount Should be between 0 to 30", { position: "top-center", hideProgressBar: true, autoClose: 1000 })

            }
        } else {
            toast.error("Discount Should Start from 0", { position: "top-center", hideProgressBar: true, autoClose: 1000 })

        }
    }
    const nextBtn = () => {
        if (_store?.cipherlinkreducer?.formdata?.cipherkeyavilable === true) {

            promocodeSubmit()
        } else {
            handleSubmit()
        }

    }


    const sendEmail = () => {
        window.open("mailto:noreply@oona-insurance.co.id?subject=SendMail&body=Description");
    };



    return (
        <>
          
                <CustomerHeader/>

            <div className="row-travel">
                <Row>
                    <Col xl={7} md={7} lg={7} sm={24} xs={24}>
                        {width > breakpoint ? (
                            <>
                                <h5 className="heading-content">{translate("travel")["Quick quote"]}</h5>
                                <Card className="card-progress">
                                    <Row>
                                        <Col md={3}>
                                            <Progress className="progress-content"
                                                percent={30} showInfo={false}
                                            />
                                        </Col>
                                        <Col className="col-content" md={10}>
                                            <div className='thick-font'>{translate("travel")["Create Quote"]}</div>
                                            <div className='thick-font'>{translate("travel")["Customer Details"]}</div>
                                            <div className='thick-font'>{translate("travel")["Confirmation"]}</div>
                                        </Col>
                                    </Row>
                                </Card>
                            </>
                        ) : (
                            <>
                                <Card className="card-mobile">
                                    <div className="justify-space">
                                        <div className="mob-header">
                                            {translate("travel")["Quick quote"]}
                                        </div>
                                        <div style={{ cursor: "pointer" }} onClick={sendEmail} className='need-content'>
                                            {translate("onboarding")["Need Help"]} ?
                                        </div>
                                    </div>
                                </Card>
                                <Card className="card-mobile">
                                    <Row>
                                        <Col xs={3}>
                                            <div className="number-mob-selected">
                                                1
                                            </div>
                                        </Col>
                                        <Col xs={14}>
                                            <div className='thick-font'>
                                                {translate("travel")["Create Quote"]}
                                            </div>
                                        </Col>
                                        <Col xs={3}>
                                            <div className="number-mob-non-selected">
                                                2
                                            </div>
                                        </Col>
                                        <Col xs={3}>
                                            <div className="number-mob-non-selected">
                                                3
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </>
                        )}
                    </Col>
                    <Col xl={16} md={16} lg={16} sm={24} xs={24}>
                        <Card>
                            {state?.quote != true && <div className='travel-row-1 back-content'>

                                <div className='arrow-back-font' onClick={() => {
                                    bike ?

                                        history.push({
                                            pathname: "/motorchooseproduct",
                                            // state: {
                                            //     data:location.state.data,
                                            //     quoteId: state.quoteId,
                                            //     // cipher: state.cipher,

                                            // }

                                        }) :
                                        history.push({
                                            pathname: "/motoradditionalbenefits",
                                            // state: {
                                            //     data:location.state.data,
                                            //     quoteId: state.quoteId,
                                            //     // cipher: state.cipher
                                            // }

                                        })



                                }}><ArrowLeftOutlined />{translate("onboarding")["Back"]}</div>

                                <div style={{ cursor: "pointer" }} onClick={sendEmail} className='need-content'>{translate("onboarding")["Need Help"]} ?</div>
                            </div>}
                            <div className="steps-content">
                                <div>
                                    <h3 className='header-card-content'>
                                        {translate("travel")["Order Summary"]}
                                    </h3>
                                    <div>{translate("travel")["Please check again your quote summary"]}</div>
                                    <br />


                                    {width > breakpoint ? (
                                        <>
                                            <Row style={{ gap: "15px" }}>
                                                <Col md={12} lg={12} xl={12} xs={24} sm={12}>
                                                    <div style={{ margin: "10px 0px" }} className='accordian-inner'>
                                                        {props.quote != undefined && props.quote === true ? null :
                                                            <div className='additional-font' style={{width:"100%"}} >
                                                               {
                                                                quoteData?.isEligibleForMultiyear ? (<>
                                                              <Singlepanel planDetails={quoteData?.planDetails} quoteData={quoteData}/>
                                                                </>) : (planName)
                                                               } 
                                                            </div>
                                                            }

                                                            {
                                                                !quoteData?.isEligibleForMultiyear && (<>
                                                                    <div className='additional-font'>
                                                                    {new Intl.NumberFormat('id-ID', {
                                                                        style: 'currency',
                                                                        currency: 'IDR',
                                                                        minimumFractionDigits: 0,
                                                                    }).format(premium)}
        
                                                                    
                                                                </div>
                                                                </>
                                                                )
                                                            }
                                                       
                                                    </div>
                                                    {
                                                        quoteData?.isEligibleForMultiyear ? (<>  
                                                        <MultiplePanel additionalbenefitsData={additionBenifit} duration={quoteData?.duration} stage="ordersummary" /> 
                                                        </>)
                                                        :( additionBenifit && additionBenifit?.map((addBenifit) => (
                                                            <div style={{ margin: "10px 0px" }} className='accordian-inner additional-font'>
                                                                <div style={{ padding: "0% 15% 2% 0%" }}>
                                                                    {addBenifit.BenefitDesc}
                                                                </div>
                                                                <div className='additional-font'>
                                                                    {new Intl.NumberFormat('id-ID', {
                                                                        style: 'currency',
                                                                        currency: 'IDR',
                                                                        minimumFractionDigits: 0,
                                                                    }).format(parseInt(addBenifit?.Premium))}
                                                                </div>
                                                            </div>
                                                        )))
                                                    }

{
    (checkingAuthWorkshop?.length > 0 && quoteData?.duration > 1) && (
        <div className='accordian-inner'>
                                                        <div style={{fontSize:"0.9em"}}>
                                                        First year discount for Authorized Workshop
                                                        </div>
                                                        <div style={{ color: "red" }}>
                                                            - {new Intl.NumberFormat('id-ID', {
                                                                style: 'currency',
                                                                currency: 'IDR',
                                                                minimumFractionDigits: 0,
                                                            }).format(checkingAuthWorkshop[0]?.FirstYearDiscount)}
                                                        </div>
                                                    </div>
    )
}


                                                   
                                                    <div className='accordian-inner'>
                                                        <div>
                                                            {translate("motor")["Discount"]}
                                                        </div>
                                                        <div style={{ color: "red" }}>
                                                            - {(discountpercent === 0 || isNaN(discountpercent)) ? 0 : new Intl.NumberFormat('id-ID', {
                                                                style: 'currency',
                                                                currency: 'IDR',
                                                                minimumFractionDigits: 0,
                                                            }).format(discountpercent)}
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className='accordian-inner'>
                                                        <div style={{ color: "#482c77" }}>
                                                            {translate("motor")["Total"]}
                                                            <p style={{ fontSize: 12, fontStyle: "italic" }}>
                                                                *{translate("travel")["Premium amount shown excludes admin cost"]}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <b>
                                                                { new Intl.NumberFormat('id-ID', {
                                                                    style: 'currency',
                                                                    currency: 'IDR',
                                                                    minimumFractionDigits: 0,
                                                                }).format(total)
                                                                }
                                                            </b>

                                                            {/* {props?.datas?.totalCost}              </b> */}
                                                        </div>
                                                    </div>

                                                </Col>
                                                {state?.quote != true && <Col md={11} sm={11} xs={24} lg={11} xl={11}>
                                                    {props.quote != undefined && props.quote === true ? null :
                                                        <Card className='card-background'>
                                                            {
                                                                show === true ?
                                                                    <>
                                                                        <b style={{ margin: "10px 0px" }}>{translate("travel")["Discount (Optional)"]}</b>
                                                                        <div style={{ margin: "10px 0px" }}>{translate("travel")["Enter discount you will give to the customer"]}
                                                                        </div>
                                                                        <Input style={{ borderColor: diserr === true || disnumerr === true ? 'red' : '#818F99', color: diserr === true || disnumerr === true ? 'red' : 'black' }} value={num} onChange={(e) => handleChange(e.target.value)} prefix={<><div onClick={decNum} style={{ cursor: 'pointer', color: "#4AC6BB", fontSize: 20 }}>-</div></>} suffix={<><div onClick={incNum} style={{ cursor: 'pointer', color: "#4AC6BB", fontSize: 20 }}>+</div></>} />
                                                                        {/* <Input value={`${num}%`} onChange={handleChange} prefix={<><div onClick={decNum}>-</div></>} suffix={<><div onClick={incNum}>+</div></>} /> */}
                                                                        <div className='justify-space'>
                                                                            <div className='font-inner'>
                                                                               {translate("home")["Min."]} 0%
                                                                            </div>
                                                                            <div className='font-inner'>
                                                                              {translate("home")["Max."]} 25%
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <b style={{ margin: "10px 0px" }}>{translate("travel")["Promo code (if any)"]}</b>
                                                                        <div style={{ margin: "10px 0px" }}>
                                                                            {translate("travel")["Apply to get the discounted price"]}
                                                                        </div>
                                                                        <br />
                                                                        <Input defaultValue={promo} value={promo} disabled={disable} placeholder={translate("travel")["Enter Promo code"]} onChange={(e) => handlePromo(e.target.value)} />
                                                                    </>
                                                            }
                                                        </Card>}
                                                </Col>}

                                            </Row>

                                            {state?.quote != true && <Button disabled={diserr === true || disnumerr === true} onClick={nextBtn} className='next-button-travel'>{translate("home")["Next"]}</Button>}
                                        </>
                                    ) : (
                                        <>
                                            <Row style={{ gap: "15px" }}>
                                                {state?.quote != true &&
                                                    <Col md={11} sm={11} xs={24} lg={11} xl={11}>
                                                        {props.quote != undefined && props.quote === true ? null :
                                                            <Card className='card-background'>
                                                                {
                                                                    show === true ?
                                                                        <>
                                                                            <b style={{ margin: "10px 0px" }}>{translate("travel")["Discount (Optional)"]}</b>
                                                                            <div style={{ margin: "10px 0px" }}>{translate("travel")["Enter discount you will give to the customer"]}
                                                                            </div>
                                                                            <Input style={{ borderColor: diserr === true || disnumerr === true ? 'red' : '#818F99', color: diserr === true || disnumerr === true ? 'red' : 'black' }} value={num} onChange={(e) => handleChange(e.target.value)} prefix={<><div onClick={decNum} style={{ cursor: 'pointer', color: "#4AC6BB", fontSize: 20 }}>-</div></>} suffix={<><div onClick={incNum} style={{ cursor: 'pointer', color: "#4AC6BB", fontSize: 20 }}>+</div></>} />
                                                                            {/* <Input value={`${num}%`} onChange={handleChange} prefix={<><div onClick={decNum}>-</div></>} suffix={<><div onClick={incNum}>+</div></>} /> */}
                                                                            <div className='justify-space'>
                                                                                <div className='font-inner'>
                                                                                   {translate("home")["Min."]}0%
                                                                                </div>
                                                                                <div className='font-inner'>
                                                                                  {translate("home")["Max."]}25%
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <b style={{ margin: "10px 0px" }}>{translate("travel")["Promo code (if any)"]}</b>
                                                                            <div style={{ margin: "10px 0px" }}>
                                                                                {translate("travel")["Apply to get the discounted price"]}
                                                                            </div>
                                                                            <br />
                                                                            <Input defaultValue={promo} value={promo} disabled={disable} placeholder={translate("travel")["Enter Promo code"]} onChange={(e) => handlePromo(e.target.value)} />
                                                                        </>
                                                                }
                                                            </Card>}
                                                    </Col>}

                                                <Col md={12} lg={12} xl={12} xs={24} sm={12}>
                                                    <div style={{ margin: "10px 0px" }} className='accordian-inner'>
                                                        {/* {props.quote != undefined && props.quote === true ? null :
                                                            <div className='additional-font'>
                                                                {planName}
                                                            </div> } */}
                                                        {/* <div className='additional-font'>
                                                            {new Intl.NumberFormat('id-ID', {
                                                                style: 'currency',
                                                                currency: 'IDR',
                                                                minimumFractionDigits: 0,
                                                            }).format(premium)}

                                                        </div> */}

{props.quote != undefined && props.quote === true ? null :
                                                            <div className='additional-font' style={{width:"100%"}} >
                                                               {
                                                                quoteData?.isEligibleForMultiyear ? (<>
                                                              <Singlepanel planDetails={quoteData?.planDetails} quoteData={quoteData}/>
                                                                </>) : (planName)
                                                               } 
                                                            </div>
                                                            }

                                                            {
                                                                !quoteData?.isEligibleForMultiyear && (<>
                                                                    <div className='additional-font'>
                                                                    {new Intl.NumberFormat('id-ID', {
                                                                        style: 'currency',
                                                                        currency: 'IDR',
                                                                        minimumFractionDigits: 0,
                                                                    }).format(premium)}
        
                                                                    
                                                                </div>
                                                                </>
                                                                )
                                                            }
                                                    </div>
                                                    {/* {additionBenifit && additionBenifit?.map((addBenifit) => (
                                                        <div style={{ margin: "10px 0px" }} className='accordian-inner additional-font'>
                                                            <div style={{ padding: "0% 15% 2% 0%" }}>
                                                                {addBenifit.BenefitDesc}
                                                            </div>
                                                            <div className='additional-font'>
                                                                {new Intl.NumberFormat('id-ID', {
                                                                    style: 'currency',
                                                                    currency: 'IDR',
                                                                    minimumFractionDigits: 0,
                                                                }).format(parseInt(addBenifit?.Premium))}
                                                            </div>
                                                        </div>
                                                    ))} */}

{
                                                        quoteData?.isEligibleForMultiyear ? (<>  
                                                        <MultiplePanel additionalbenefitsData={additionBenifit} duration={quoteData?.duration}  stage="ordersummary" /> 
                                                        </>)
                                                        :( additionBenifit && additionBenifit?.map((addBenifit) => (
                                                            <div style={{ margin: "10px 0px" }} className='accordian-inner additional-font'>
                                                                <div style={{ padding: "0% 15% 2% 0%" }}>
                                                                    {addBenifit.BenefitDesc}
                                                                </div>
                                                                <div className='additional-font'>
                                                                    {new Intl.NumberFormat('id-ID', {
                                                                        style: 'currency',
                                                                        currency: 'IDR',
                                                                        minimumFractionDigits: 0,
                                                                    }).format(parseInt(addBenifit?.Premium))}
                                                                </div>
                                                            </div>
                                                        )))
                                                    }
                                                    {
    (checkingAuthWorkshop?.length > 0 && quoteData?.duration > 1) && (
        <div className='accordian-inner'>
                                                        <div style={{fontSize:"0.9em",width: "70%"}}>
                                                        First year discount for Authorized Workshop
                                                        </div>
                                                        <div style={{ color: "red" }}>
                                                            - {new Intl.NumberFormat('id-ID', {
                                                                style: 'currency',
                                                                currency: 'IDR',
                                                                minimumFractionDigits: 0,
                                                            }).format(checkingAuthWorkshop[0]?.FirstYearDiscount)}
                                                        </div>
                                                    </div>
    )
}
                                                    <div className='accordian-inner'>
                                                        <div>
                                                            {translate("motor")["Discount"]}
                                                        </div>
                                                        <div style={{ color: "red" }}>
                                                            - {(discountpercent === 0 || isNaN(discountpercent)) ? 0 : new Intl.NumberFormat('id-ID', {
                                                                style: 'currency',
                                                                currency: 'IDR',
                                                                minimumFractionDigits: 0,
                                                            }).format(discountpercent)}
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className='accordian-inner'>
                                                        <div style={{ color: "#482c77" }}>
                                                            {translate("motor")["Total"]}
                                                            <p style={{ fontSize: 12, fontStyle: "italic" }}>
                                                                *{translate("travel")["Premium amount shown excludes admin cost"]}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <b>
                                                                {  new Intl.NumberFormat('id-ID', {
                                                                    style: 'currency',
                                                                    currency: 'IDR',
                                                                    minimumFractionDigits: 0,
                                                                }).format(total)
                                                                }
                                                            </b>

                                                            {/* {props?.datas?.totalCost}              </b> */}
                                                        </div>
                                                    </div>

                                                </Col>
                                                {/* <Button disabled=diserr == true || disnumerr == true onClick={nextBtn} className='next-button-travel'>{translate("home")["Next"]}</Button> */}
                                                {state?.quote != true && <Button disabled={diserr === true || disnumerr === true} onClick={nextBtn} className='next-button-travel'>{translate("home")["Next"]}</Button>}
                                            </Row>
                                        </>
                                    )}
                                </div>

                            </div>

                        </Card>
                    </Col>
                </Row>
            </div >
        </>
    )
}

