import { PA_RESET_DATA ,PA_SEPERATE_RESET_DATA} from "../../actions/actionTypes";

 const initialState = {
    paQuoteObject: ""
   };

export const paQuoteReducer=(state=initialState,action)=>{
    switch(action.type){
        case 'CREATE_QUOTE':
            return {
                ...state,
                paQuoteObject:action?.payload
            }
            case 'SENDING_QUOTE_ID': return {
                ...state,
                paQuoteObject:action?.payload  
            }
            case PA_RESET_DATA:
                return {
                  ...initialState,
                };
            default:
                return state
    }
}



const seperateRequestLimitData={
        seperateRequestData:{
            name:"",
            numberofparticipants:"",
            selectedLimitTYpe:"",
            seperateLimitObject:""
        }
}


export const seperateRequestLimitDataReducer=(state=seperateRequestLimitData,action)=>{
            switch(action.type){
        case 'SEPERATE_REQUEST_LIMIT':
            return {
                ...state,
                seperateRequestData:{
                    ...state.seperateRequestData,
                    seperateLimitObject:action.payload
                }
            }

            case "UPDATE_NAME":{
                return {
                    ...state,
                    seperateRequestData:{
                        ...state.seperateRequestData,
                        name:action.payload
                    }
                    
                }
            }

            case "UPDATE_SELECTED_LIMIT_TYPE":{
                return {
                    ...state,
                    seperateRequestData:{
                        ...state.seperateRequestData,
                        selectedLimitTYpe:action.payload
                    }
                    
                }
            }

            case "UPDATE_NUBEROF_PARTICIPANTS":{
                return {
                    ...state,
                    seperateRequestData:{
                        ...state.seperateRequestData,
                        numberofparticipants:action.payload
                    }  
                }
            }

            case PA_SEPERATE_RESET_DATA:
      return {
        ...seperateRequestLimitData,
      };
            default:
                return state
    }
}


// multiplePAInsureddetailsEntering


const multiplePaInsuredDetails = {
requestLimit:[]   
};

export const multiplePaInsuredDetailsReducer=(state=multiplePaInsuredDetails,action)=>{
    switch(action.type){
        case 'MULTIPLE_CUSTOMER_REQUEST_LIMIT_DATA':
            return {
                ...state,
                requestLimit:action?.payload
            }
            case PA_RESET_DATA:
                return {
                  ...initialState,
                };
            default:
                return state
    }
}



//reducer for showingordersummary and payment screen when the copy and paste the sharable link


const showingdataUisngSharableLinkInitialState = {
    quoteState: false
   };

export const showingdataUsingSharableLinkInitialStateReducer=(state=showingdataUisngSharableLinkInitialState,action)=>{
    switch(action.type){
        case 'DISPLAYING_DATA_USING_SHARABLE_LINK':
            return {
                ...state,
                quoteState:action?.payload
            }
           
            case PA_RESET_DATA:
                return {
                  ...showingdataUisngSharableLinkInitialState,
                };
            default:
                return state
    }
}




