import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from "react-router-dom"
import { Row, Col, Card, Progress, Image, Form, Input, Button, message, Spin, Drawer, Modal } from "antd"

import InputImage from "../oonaImages/Frame 1000004254.png"
import {  ArrowLeftOutlined } from "@ant-design/icons"
import CopyIcon from "../oonaImages/Ionicons 5.png"

import rootIndex from "../store/root_index";
import {  toast } from 'react-toastify';
import { useLanguage } from "../config/LanguageContext"
import axiosRequest from '../axios-request/request.methods'
import CustomerHeader from '../CustomerHeader/CustomerHeader'
import * as action from '../store/actions/index'
import { useSelector,useDispatch } from 'react-redux'


const { store } = rootIndex;

const HomePaymentScreen = (props) => {
    const _store = store.getState();
    console.log("checkingcgu",_store?.cipherlinkreducer?.formdata?.cipherkeyavilable)
    const language = useSelector((state) => state.languageReducer.lang);
    const fetchingIDfromStore=useSelector((state)=>state?.cipherlinkreducer?.quote?.quoteId)
    
    console.log(fetchingIDfromStore,"fetchingIDfromStore")
    const checkingResumeType=useSelector((state)=>state?.cipherlinkreducer?.quote?.resumeType);
console.log(checkingResumeType,"checkingResumeType")
const dispatch=useDispatch()

const fetchingtheolddata=useSelector((state)=>state?.cipherlinkreducer?.quote)
    const history = useHistory();
    const location = useLocation()
    const [show, setShow] = useState(true)
    const [travellink, setTravelLink] = useState('')
    const [loading, setLoading] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMargin, setModalMargin] = useState("modal-margin");
    const [drawerNotification, setDrawerNotification] = useState("drawer-margin");
    const [proposalNumber, setProposalNumber] = useState("")
    console.log(proposalNumber,"proposalNumber")
    const [shownpaymentInformation, setShowpaymentInformtion] = useState("")
    const [checkingforagentPaylaterButton,setcheckingforagentPaylaterButton]=useState(false)
  

    const [width, setWidth] = useState(window.innerWidth);

    const breakpoint = 620;

    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);

        return () => window.removeEventListener("resize", handleWindowResize);
    }, [width]);



    useEffect(() => {
        if(fetchingIDfromStore !== ""){
            getQuotes()
        }
       
    }, [fetchingIDfromStore,checkingResumeType])

    const getLink = () => {
        axiosRequest
            .get(
                `home/generate-link-home?type=lead&quoteId=${fetchingIDfromStore}`,
            )
            .then((data) => {
                if (data.errCode === -1) {
                    setTravelLink(data.errMsg.link)
                }
            }).catch((error) => {
                //setLoading(false)
            });
    }
    useEffect(() => {
        if (_store?.cipherlinkreducer?.formdata?.cipherkeyavilable === false) {
            getLink()
        }
    }, [_store?.cipherlinkreducer?.formdata?.cipherkeyavilable,fetchingIDfromStore])

    useEffect(()=>{
        console.log(((_store?.cipherlinkreducer?.formdata?.cipherkeyavilable === false) && (checkingResumeType !== "homefromTodo")),"bchjxghjdg==>")
        if(((_store?.cipherlinkreducer?.formdata?.cipherkeyavilable === false) && (checkingResumeType !== "homefromTodo"))){
            setcheckingforagentPaylaterButton(true)
        }

    },[_store?.cipherlinkreducer?.formdata?.cipherkeyavilable,checkingResumeType])




    const getQuotes = () => {

        var fromReferral = ""
        if (checkingResumeType === "referalJourney" || checkingResumeType === "quoteSearch") {
            fromReferral = `home/getQuote/${fetchingIDfromStore}?logkey=${checkingResumeType}`
        }
        else {
            fromReferral = `home/getQuote/${fetchingIDfromStore}`
        }


        axiosRequest
            .get(
                `${fromReferral}`,
            )
            .then((data) => {
                if (data?.errCode === -1) {
                    setShowpaymentInformtion(data?.errMsg?.data)
                    // getDoku(data?.errMsg?.data?.proposalNo)
                    setProposalNumber(data?.errMsg?.data?.proposalNo)
                }
            }).catch((error) => {
            });
    }


    const getDoku = () => {
        let options = { secure: false, multipart: false }

        axiosRequest
            .get(
                `auth/payment-route/doku/checkout?proposalNo=${proposalNumber}`,
                options
            )
            .then((data) => {
                if (data.errCode === -1) {
                    window.location.replace(data.errMsg.response.payment.url)
                    setLoading(false)
                    
                }
            }).catch((error) => {
            });
    }



    const getPaylater = () => {

        if (shownpaymentInformation?.paymentStatus !== "PayLater") {

            let options = { secure: false, multipart: false }

            axiosRequest
                .get(
                    `auth/payment-route/pay-later?proposalNo=${proposalNumber}`, options
                )
                .then((data) => {
                    if (data?.errCode === -1) {
                        // alert("entered success")
                        let Data={
                            ...fetchingtheolddata,
                            ProposerNumberdata:data.errMsg
                        }
                        dispatch(action.set_quote_id(Data))
                        setLoading(false)
                        history.push({
                            pathname: '/home-success-print-policy',
                            // state: {
                            //     data: location?.state?.data,
                            //     quoteId: props?.location?.state?.quoteId,
                            //     policyNo: data?.errMsg?.data?.SessionId,
                            //     ProposerNumberdata: data.errMsg
                            // }
                        })

                        if (data?.errCode === 422) {
                            setLoading(false)
                            history.push("/home-print-policy-error")
                            toast.success(data?.errMsg?.ValidateResult?.message, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
                        }
                    }
                }).catch((error) => {
                    history.push("/home-print-policy-error")
                });
        }
        else {
            toast.error("Policy already created with this proposal Number", { position: "top-center", hideProgressBar: true, autoClose: 1000 })
        }

    }

    const { translate } = useLanguage()

    const showModal1 = () => {
        setIsModalOpen(false);
    }


    const modalDrawer = () => {
        setDrawerNotification("notification-before");
        history.push('/oonahome')
    };


    const sendEmail = () => {
        window.open("mailto:noreply@oona-insurance.co.id?subject=SendMail&body=Description");
    };

    const getPdf = () => {
        let options = { secure: true, multipart: true }
        axiosRequest
            .get(
                `user/e-quotation?quoteId=${fetchingIDfromStore}`,
                options
            )
            .then((response) => {
                const blob = response;
                const fileURL = window.URL.createObjectURL(blob);
                const alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = `QS_Home_Insurance_${proposalNumber}.pdf`;
                alink.click();
                window.URL.revokeObjectURL(fileURL);
            })
            .catch((error) => {
                toast.error(error, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
            })
    }

    const handleShare = () => {
        const homePaymentLinkwithLanguage=`${travellink}?lang=${language}`
        navigator.clipboard.writeText(homePaymentLinkwithLanguage)
        toast.success(`${translate("home")["link copied"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
    }

    const modalContent = () => {
        setModalMargin("modal-margin-after");
        history.push('/oonahome')
    };

    const toCurrencyConvertor = (value) => {
        const currencyVal = new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 0,
        }).format(value)

        return currencyVal
    }

    var flexasamount = ""
    var paramount = ""

    if (shownpaymentInformation?.typeofProduct === "FLEXAS") {
        flexasamount = shownpaymentInformation?.policyLobList?.[0]?.policyRiskList?.[0]?.flexasCoverPremium
    }

    if (shownpaymentInformation?.typeofProduct === "PAR") {
        paramount = parseInt(shownpaymentInformation?.grossPremium) - (parseInt(shownpaymentInformation?.policyLobList?.[0]?.policyRiskList?.[0]?.eQCoverPremium || 0))
    }

    console.log(((_store?.cipherlinkreducer?.formdata?.cipherkeyavilable === false) && (checkingResumeType !== "homefromTodo")),"bchjxghjdg==>")


    return (
        <>
            <CustomerHeader />


            <div className="row-travel">


                <Spin tip="Loading" spinning={loading} >
                    <Row>
                        <Col xl={6} md={6} lg={6} sm={24} xs={24}>
                            {width > breakpoint ? (
                                <>
                                    <h5 className="heading-content">Almost done!
                                    </h5>
                                    <Card className="card-progress">
                                        <Row>
                                            <Col md={3}>
                                                <Progress className="progress-content"
                                                    percent={100} showInfo={false}
                                                />
                                            </Col>
                                            <Col className="col-content" md={10}>
                                                <div className='thick-font'>{translate("travel")["Create Quote"]}</div>
                                                <div className='thick-font'>{translate("travel")["Customer Details"]}</div>
                                                <div className='thick-font'>{translate("travel")["Confirmation"]}</div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </>
                            ) : (
                                <>
                                    <Card className="card-mobile">
                                        <div className="justify-space">
                                            <div className="mob-header">
                                                {translate("home")["Almost done!"]}
                                            </div>
                                            <div style={{ cursor: "pointer" }} onClick={sendEmail} className='need-content'>
                                                {translate("onboarding")["Need Help"]} ?
                                            </div>
                                        </div>
                                    </Card>
                                    <Card className="card-mobile">
                                        <Row>
                                            <Col xs={3}>
                                                <div className="number-mob-selected">
                                                    1
                                                </div>
                                            </Col>
                                            <Col xs={3}>
                                                <div className="number-mob-selected">
                                                    2
                                                </div>
                                            </Col>
                                            <Col xs={3}>
                                                <div className="number-mob-selected">
                                                    3
                                                </div>
                                            </Col>
                                            <Col xs={14}>
                                                <div className='thick-font'>
                                                    Confirmation
                                                </div>
                                            </Col>

                                        </Row>
                                    </Card>
                                </>
                            )}
                        </Col>

                        <Col xl={17} md={17} lg={17} sm={24} xs={24}>
                            <Card className='travel-back-card-mob'>

                                {show === true ?
                                    <>
                                        <div className='travel-row-1 back-content payment-back-content'>
                                            {_store?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ?
                                                <div className='arrow-back-font'>
                                                    <Button onClick={getPdf} style={{ backgroundColor: "#482c77", color: "white", borderRadius: 10 }}>{translate("home")["Download Quote"]} (PDF)</Button>
                                                </div> :
                                                <>
                                                {
                                                    (checkingResumeType !== "homefromTodo" &&  checkingResumeType !== "referalJourney" &&  checkingResumeType !== "quoteSearch") ?   <div className='arrow-back-font' onClick={() => history.push(
                                                        {
                                                            pathname: "/customerconfirmationhome",
                                                            // state: {
                                                            //     quoteId: props?.location?.state?.quoteId,
                                                            // }
                                                        }
                                                    )}>
                                                        
                                                        <ArrowLeftOutlined />
                                                    {translate("onboarding")["Back"]}
    
                                                    </div>  :
                                                    <div>
                                                        
                                                    </div>


                                                }
                                               
                                                </>
                                               
                                            }
                                            <div style={{ cursor: "pointer" }} onClick={sendEmail} className='need-content'>{translate("onboarding")["Need Help"]} ?</div>
                                        </div>
                                        {
                                          width > breakpoint ? (<></>) :(<>{_store?.cipherlinkreducer?.formdata?.cipherkeyavilable === true ? <Button onClick={getPdf} style={{ backgroundColor: "#482c77", color: "white", borderRadius: 10 }}>{translate("home")["Download Quote"]} (PDF)</Button> : null}</>) 
                                        }

                                        <h3 className='header-card-content'>
                                            {translate("home")["Payment Confirmation"]}
                                        </h3>
                                        <div><b>{translate("home")["Please check again your payment summary"]}</b></div>

                                        <div className='justify-space'>
                                            <div className='thick-font'>{translate("home")["Payment Detail"]}</div>
                                            <div>{translate("home")["Waiting for Payment"]}</div>
                                        </div>


                                        <div className='justify-space'>
                                            <div className='additional-font'>{shownpaymentInformation?.typeofProduct === "FLEXAS" ? shownpaymentInformation?.typeofProduct : shownpaymentInformation?.typeofProduct}</div>
                                            <div className='additional-font'>{shownpaymentInformation?.typeofProduct === "FLEXAS" ? toCurrencyConvertor(Math.floor(flexasamount)) : toCurrencyConvertor(Math.floor(paramount))}</div>
                                        </div>

                                       

                                        {shownpaymentInformation?.additionalBenefits !== undefined ? shownpaymentInformation?.additionalBenefits.map(item => {
                                            return (
                                                <>
                                                    <div className='accordian-inner'>
                                                        <div className='additional-font'>
                                                            {item.BenefitDesc}
                                                        </div>
                                                        <div className='additional-font'>
                                                            {toCurrencyConvertor(Math.floor(Number(item.Premium)))}
                                                        </div>
                                                    </div>
                                                    <br />
                                                </>
                                            )
                                        }) : null}




                                        <div className='justify-space'>
                                            <div className='additional-font'>{translate("home")["Admin Cost in payment"]}</div>
                                            <div className='additional-font'>{toCurrencyConvertor(Math.floor(shownpaymentInformation?.adminCost))}</div>
                                        </div>

                                        <div className='justify-space'>
                                            <div className='additional-font'>{translate("home")["Discount"]}</div>
                                            <div style={{ color: "red" }}>-{toCurrencyConvertor(Math.floor(shownpaymentInformation?.discountAmount))}</div>
                                        </div>


                                        <div className='justify-space'>
                                            <div className='additional-font'>{translate("home")["Total"]}</div>
                                            <div>{toCurrencyConvertor(Number(Math.floor(shownpaymentInformation?.duePremium)))}</div>
                                        </div>


                                        {
                                            travellink === "" && (<Col md={24} xl={24} xs={24} sm={24} lg={24}>
                                                <Button onClick={() => getDoku()} className='next-button-travel'>{translate("home")["Proceed to Payment"]}</Button>
                                            </Col>)
                                        }



                                        {travellink !== '' && _store?.cipherlinkreducer?.formdata?.cipherkeyavilable === false ?
                                            <>
                                                <div style={{ textAlign: "center" }}>
                                                    <b>{translate("home")["Send this payment link to the Insured"]}</b>
                                                </div>
                                                <Form.Item
                                                    name="City"
                                                >
                                                    <Input disabled suffix={<><Image className="input-img" style={{ cursor: 'pointer' }} src={InputImage} preview={false} onClick={() => handleShare()} /></>} defaultValue={travellink} className='link-input'></Input>
                                                </Form.Item>

                                                <Row>
                                                    <Col md={12} xl={12} xs={12} sm={12} lg={12}>
                                                        {
                                                            ((_store?.cipherlinkreducer?.formdata?.cipherkeyavilable === false) && (checkingResumeType !== "homefromTodo")) && (<Button onClick={() => getPaylater()} className='no-button' >{translate("home")["pay Later"]}</Button>)
                                                        }

                                                    </Col>


                                                    {
                                                        ((_store?.cipherlinkreducer?.formdata?.cipherkeyavilable === true)) ? (
                                                            <Col md={24} xl={24} xs={12} sm={12} lg={24}>
                                                                <Button onClick={() => getPaylater()} className='next-button-travel'>{translate("home")["pay Later"]}</Button>
                                                            </Col>) : checkingResumeType === "homefromTodo" ?
                                                            (<Col md={24} xl={24} xs={12} sm={12} lg={24}>
                                                                <Button onClick={() => getDoku()} className='next-button-travel'>{translate("home")["Proceed to Payment"]}</Button>
                                                            </Col>) :
                                                            (<Col md={12} xl={12} xs={12} sm={12} lg={12}>
                                                                <Button onClick={() => getDoku()} className='next-button-travel'>{translate("home")["Proceed to Payment"]}</Button>
                                                            </Col>)
                                                    }
                                                </Row>
                                            </>
                                            : null}
                                    </>
                                    :
                                    <>

                                    </>
                                    }
                            </Card>
                        </Col>
                    </Row>
                    {width > breakpoint ? (
                        <>
                            {" "}
                            <Modal
                                className={modalMargin}
                                closable={false}
                                visible={isModalOpen}
                                onOk={() => setIsModalOpen(false)}
                                onCancel={() => setIsModalOpen(false)}
                            >

                                <>
                                    <div className="heading-modal-content-1">
                                        <b>VA is copied successfully, Please pay through ATMs.</b>
                                    </div>
                                </>

                                <Row className="row-modal">
                                    <Col md={12} xs={12} sm={12} lg={12} xl={12}>
                                        <Button
                                            className="popup-button popup-inner-button"
                                            onClick={showModal1}
                                            style={{ justifyContent: 'center' }}
                                        >

                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col md={12} xs={12} sm={12} lg={12} xl={12}>
                                        <Button
                                            className="popup-button popup-inner-button"
                                            onClick={modalContent}
                                            style={{ justifyContent: 'center' }}
                                        >
                                            Back To Dashboard
                                        </Button>
                                    </Col>
                                </Row>
                            </Modal>
                        </>
                    ) : (
                        <>
                            <Drawer
                                className={drawerNotification}
                                placement="bottom"
                                closable={false}
                                visible={isModalOpen}
                            >

                                <>
                                    <div style={{ fontSize: 18 }}>
                                        <b>VA is copied successfully, Please pay through ATMs.</b>
                                    </div>
                                </>

                                <Row className="row-modal">
                                    <Col md={12} xs={12} sm={12} lg={12} xl={12}>
                                        <Button
                                            className="popup-button popup-inner-button"
                                            onClick={showModal1}
                                            style={{ justifyContent: 'center' }}
                                        >

                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col md={12} xs={12} sm={12} lg={12} xl={12}>
                                        <Button
                                            className="popup-button popup-inner-button"
                                            onClick={modalDrawer}
                                            style={{ justifyContent: 'center' }}
                                        >
                                            Back To Dashboard
                                        </Button>
                                    </Col>
                                </Row>
                            </Drawer>
                        </>
                    )}
                </Spin>
            </div >
        </>
    )
}

export default HomePaymentScreen
