import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { Card, Checkbox, Row, Col, Image, Modal, Button,Drawer } from 'antd'
import ArrowIcon from "../../../oonaImages/arrowIcon.png"
import { useLanguage } from "../../../config/LanguageContext"
import axiosRequest from '../../../axios-request/request.methods'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

const AdditionalBenefit = forwardRef((props, ref) => {
    const { translate,language } = useLanguage()
    const fetchingWholeDatafromStore=useSelector((state)=>state?.cipherlinkreducer?.quote)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [addplanarr, setAddPlanArr] = useState([])
    const [planCode, setPlanCode] = useState('')
    const [planName, setPlanName] = useState('')
    const [premium, setPremium] = useState(0)
    const [addbnft, setAddbnft] = useState([])
    const [calculation, setCalculation] = useState(0)
    const [selectedIndices, setSelectedIndices] = useState([]);
    const [covid, setCovid] = useState(false)
  const location=useLocation()

    useImperativeHandle(ref, () => ({
        log2() {
            // if (addbnft.length == 0) {
            //     message.error('Please select any additional benefit')
            // } else {
            props.addplanSubmit(addbnft, calculation, planName, premium, planCode);
            // }
        }
    }));


    useEffect(() => {
        // if (props.planName != undefined && props.planName != '') {
        //     setPlanName(props.planName)
        // }
        // if (props.premium != undefined && props.premium != '') {
        //     setPremium(props.premium)
        // }
        // if (props.covid != undefined) {
        //     console.log(props.covid, 'covid=========>');
        //     setCovid(props.covid)
        // }
        // if (props.planCode != undefined && props.planCode != '') {
        //     console.log(props.planCode, 'planCode=========>');
        //     setPlanCode(props.planCode)
        // }
        if(fetchingWholeDatafromStore.quoteId !== ""){
        props.handleLoading(true)
        axiosRequest
            .get(
                `user/plan-benefit/${fetchingWholeDatafromStore.quoteId}?langId=${language === "en" ? "EN" : "BH"}`,
               
            )
            .then((data) => {
                
                    if (data.errMsg.data[0].Plans != undefined) {
                       
                        let finalArr = data.errMsg.data[0].Plans[0].AdditionalBenefits
                        finalArr.map(item => {
                            if (item.BenefitCode === 'AC000CV01' && fetchingWholeDatafromStore?.covid) {
                                item.selectbtn = true
                                let fi = [item, ...addbnft]
                                console.log(addplanarr, fi, 'if arr====>');
                                handleCalc(fi)
                                setAddbnft(fi)
                            } else {
                                item.selectbtn = false
                            }
                        })
                        console.log(finalArr,'after initial condition----->');

                        let filteredFinal = finalArr.filter(item => {
                            console.log(item.BenefitType === 'C', 'yess');
                            return item.BenefitType === 'C' && item.BenefitCode != 'AC000CV01'
                        })


                        console.log(filteredFinal, 'additional benefit----->');
                        setAddPlanArr(filteredFinal)
                        getQuotes(filteredFinal)
                        props.handleLoading(false)
                    } else {
                        props.handleLoading(false)
                        // setAddPlanArr(dummyarr.errMsg.data[0].Plans[0].AdditionalBenefits)
                        setAddPlanArr([])
                        getQuotes([])
                    }
                
            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
                props.handleLoading(false)
            });
        }

    }, [fetchingWholeDatafromStore])


    const getQuotes = (arr) => {
        var fromReferral = ""
        if(fetchingWholeDatafromStore?.resumeType === "referalJourney" || fetchingWholeDatafromStore?.resumeType === "quoteSearch"){
          fromReferral=`user/get-quotes?id=${fetchingWholeDatafromStore.quoteId}&logkey=${fetchingWholeDatafromStore?.resumeType}`
        }
        else{
          fromReferral=`user/get-quotes?id=${fetchingWholeDatafromStore.quoteId}`
        }


        axiosRequest
        .get(
          `${fromReferral}`,
                )
            .then((data) => {
                    setPlanName(data.errMsg.data[0].planName)
                    setPremium(data.errMsg.data[0].premium)
                    setPlanCode(data.errMsg.data[0].planCode)
                    var finaladdArr = []
                    if (arr.length > 0 && data.errMsg.data[0].additionalBenefits.length > 0) {
                        setAddbnft(data.errMsg.data[0].additionalBenefits)
                        
                        console.log(arr);
                        finaladdArr = data.errMsg.data[0].additionalBenefits.map(item => {
                            return arr.map(datas => {
                                if (item.BenefitCode === datas.BenefitCode) {
                                    return datas.selectbtn = true
                                }
                            })
                        })
                    }
                    if (finaladdArr.length > 0) {
                        console.log(finaladdArr, 'final add arr----->');
                        //    setAddPlanArr(finaladdArr)
                    }

                    if (data.errMsg.data[0].additionalBenefits.length > 0) {

                        var finalValue = 0
                        data.errMsg.data[0].additionalBenefits.map(item => {
                            finalValue = finalValue + parseInt(item.Premium)
                        })
                        if (finalValue != 0) {
                            console.log(finalValue, 'final calc value-------->');
                            setCalculation(finalValue)
                        }
                    }
                
            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

            });
    }


    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleCheck = (selectbtn, obj) => {
        console.log(obj);
        if (selectbtn === false) {
            console.log('if condition');
            addplanarr.map(item => {
                if (item.BenefitCode === obj.BenefitCode) {
                    item.selectbtn = true
                }
            })
            // let selectedarr = addbnft.push(obj)
            let fi = [obj, ...addbnft]
            console.log(addplanarr, fi, 'if arr====>');
            handleCalc(fi)
            setAddbnft(fi)
        } else {
            console.log('else condition');
            addplanarr.map(item => {
                if (item.BenefitCode === obj.BenefitCode) {
                    item.selectbtn = false
                }
            })
            addbnft.map(item => {
                if (item.BenefitCode === obj.BenefitCode) {
                    addbnft.splice(addbnft.indexOf(item), 1)
                }

            })
            handleCalc(addbnft)
            console.log(addbnft);

        }
    }

    const handleCalc = (arr) => {
        console.log(arr, 'else condition cALC');
        if (arr.length > 0) {
            var finalValue = 0
            arr.map(item => {
                finalValue = finalValue + parseInt(item.Premium)
            })
            if (finalValue != 0) {
                console.log(finalValue, 'final calc value-------->');
                setCalculation(finalValue)
            }
        } else {
            setCalculation(0)
        }
    }

    const breakpoint = 620;

    const [width, setWidth] = useState(window.innerWidth)

    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);

        return () => window.removeEventListener("resize", handleWindowResize);
    }, [width]);

    const handletoggleExpand = (index) => {

        if (selectedIndices.includes(index)) {
            setSelectedIndices(selectedIndices.filter(i => i !== index)); 
          } else {
            setSelectedIndices([...selectedIndices, index]); 
          }

      };
    
    

    return (
        <div>
            <h3 className='header-card-content'>{translate("travel")["Additional Benefit"]}</h3>
            <div><b>{translate("travel")["Add some optional benefits for your travel. You can add more protection for your travel."]}</b></div>

            <Card className='add-benefit-card  back-img-1'>
                <div>{translate("motor")["Product"]}</div>
                <div className='thick-font'><b>{planName}</b> 
                {/* {translate("travel")["Add Ons"]} */}
                </div>
            </Card>
            {console.log(addplanarr, 'final arr of add beme======>')}
            {addplanarr && addplanarr.map((item,index) => {
                return (
                    <Card className='add-benefit-card'>
                        <Row className='benefit-inner'>
                            <Col md={3} xl={3} lg={3} xs={5} sm={3} style={{alignSelf : 'flex-start'}}>
                                <Image src={item.signedUrl} preview={false} width={50} height={50} />
                            </Col>
                            <Col md={18} xl={18} lg={18} xs={17} sm={18}>
                                <div className='additional-font thick-font' style={{fontSize:"1.1em"}}>{item.BenefitDesc}</div>
                                {selectedIndices.includes(index) ? (
           <div>
           {item.Note &&
             item.Note.split('\n').map((line, index) => (
               <div key={index} className='additional-font' style={{ textAlign: "justify", textJustify: "inter-word"}}>
                 {line}
               </div>
             ))}
           <div className='additional-font' onClick={() => handletoggleExpand(index)} style={{ cursor: 'pointer' ,color:"blue"}}>less...</div>
         </div>
          ) : (
            <div className='additional-font'>
            {`${item?.Note?.slice(0, 100)}`}
            {item.Note && item.Note.length > 100 && <div onClick={() => handletoggleExpand(index)} style={{ cursor: 'pointer' ,color:"blue"}}>more...</div>}
          </div>
          )}
                                
                                <div className="thick-font"> {new Intl.NumberFormat('id-ID', {
                                    style: 'currency',
                                    currency: 'IDR',
                                    minimumFractionDigits: 0,
                                }).format(parseInt(item.Premium))}</div>
                            </Col>
                            <Col md={3} xl={3} lg={3} xs={2} sm={3} className="benefit-inner-notes">
                                <Checkbox onChange={() => handleCheck(item.selectbtn, item)} checked={item.selectbtn === true ? true : false} defaultChecked={item.selectbtn == true ? true : false} />
                            </Col>
                        </Row>
                    </Card>
                )
            })}

            {addplanarr.length === 0 ?
                <div style={{ textAlign: 'center', marginTop: 50, marginBottom: 50, fontWeight: 'bold' }}>No Additional Benefits for particular product</div>
                : null}

            <Row>
                <Col xl={19} xs={18} sm={19} lg={19} md={19}>
                    <div className='home-small-font'>
                    {translate("home")["Subtotal"]}
                    </div>
                    {console.log(calculation + parseInt(premium),calculation,premium, 'tot val-------->')}
                    <div style={{ fontSize: 18, fontWeight: "bold" }} className='thick-font'>
                        {new Intl.NumberFormat('id-ID', {
                            style: 'currency',
                            currency: 'IDR',
                            minimumFractionDigits: 0,
                        }).format(calculation + parseInt(premium))}
                    </div>
                </Col>
                <Col style={{ display: "flex", justifyContent: "flex-end" }} md={5} xl={5} lg={5} xs={6} sm={5} onClick={() => setIsModalOpen(true)}>
                    <div style={{ inlineSize: "max-content" }} className='view-detail-font'>{translate("motor")["View Detail"]}<Image style={{ width: 15 }} src={ArrowIcon} preview={false} /></div>
                </Col>
            </Row>

            {width > breakpoint ? (
                <>
                    <Modal closable={false} visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                        <h5>{translate("motor")["Price Detail"]}</h5>
                        <br />
                        <p className='thick-font'>{translate("motor")["Product Package"]}</p>

                        <div className='accordian-inner'>
                            <div>
                                {planName}
                            </div>
                            <div>
                                {new Intl.NumberFormat('id-ID', {
                                    style: 'currency',
                                    currency: 'IDR',
                                    minimumFractionDigits: 0,
                                }).format(parseInt(premium))}
                            </div>
                        </div>
                        <br />
                        {addbnft.length > 0 ? <div className='thick-font'>{translate("motor")["Additional Coverage(s)"]}</div> : null}
                        <br />
                        {addbnft.map(item => {
                            return (
                                <>
                                    <div className='accordian-inner'>
                                        <div>
                                            {item.BenefitDesc}
                                        </div>
                                        <div>
                                            {new Intl.NumberFormat('id-ID', {
                                                style: 'currency',
                                                currency: 'IDR',
                                                minimumFractionDigits: 0,
                                            }).format(item.Premium)}
                                        </div>
                                    </div>
                                    <br />
                                </>
                            )
                        })}
                        <hr />
                        <div className='accordian-inner'>
                            <div>
                            {translate("motor")["Total"]}
                            </div>
                            <div className='thick-font' style={{ fontSize: 18, fontWeight: "bold" }}>
                                {new Intl.NumberFormat('id-ID', {
                                    style: 'currency',
                                    currency: 'IDR',
                                    minimumFractionDigits: 0,
                                }).format(calculation + parseInt(premium))}
                            </div>
                        </div>
                        <br />
                        <Button className='next-button-travel' onClick={() => setIsModalOpen(false)}>Ok</Button>
                    </Modal>
                </>) : (<>
                    <Drawer placement='bottom' closable={false} visible={isModalOpen}>
                        <h5>{translate("motor")["Price Detail"]}</h5>
                        <br />
                        <p className='thick-font'>{translate("motor")["Product Package"]}</p>

                        <div className='accordian-inner'>
                            <div>
                                {planName}
                            </div>
                            <div>
                                {new Intl.NumberFormat('id-ID', {
                                    style: 'currency',
                                    currency: 'IDR',
                                    minimumFractionDigits: 0,
                                }).format(parseInt(premium))}

                            </div>
                        </div>
                        <br />
                        {addbnft.length > 0 ? <div className='thick-font'>{translate("motor")["Additional Coverage(s)"]}</div> : null}
                        <br />
                        {addbnft.map(item => {
                            return (
                                <>
                                    <div className='accordian-inner'>
                                        <div>
                                            {item.BenefitDesc}
                                        </div>
                                        <div>
                                            {new Intl.NumberFormat('id-ID', {
                                                style: 'currency',
                                                currency: 'IDR',
                                                minimumFractionDigits: 0,
                                            }).format(item.Premium)}
                                        </div>
                                    </div>
                                    <br />
                                </>
                            )
                        })}

                        <hr />
                        <div className='accordian-inner'>
                            <div>
                            {translate("motor")["Total"]}
                            </div>
                            <div className='thick-font' style={{ fontSize: 18, fontWeight: "bold" }}>
                                {new Intl.NumberFormat('id-ID', {
                                    style: 'currency',
                                    currency: 'IDR',
                                    minimumFractionDigits: 0,
                                }).format(calculation + parseInt(premium))}
                            </div>
                        </div>
                        <br />
                        <Button className='next-button-travel' onClick={() => setIsModalOpen(false)}>Ok</Button>
                    </Drawer>
                </>)}

        </div >
    )
})

export default AdditionalBenefit