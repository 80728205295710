import React from 'react'
import { Row, Col, Image, Form, Input, Button } from "antd"
import ErrorLogo from "../../oonaImages/Frame 1000004259.png"
import { useHistory } from "react-router-dom"
import { useLocation } from 'react-router-dom';
import { useLanguage } from "../../config/LanguageContext"
import CustomerHeader from '../../CustomerHeader/CustomerHeader';

const HomeprintPolicyError = () => {
    const history = useHistory();
    const { state } = useLocation();

    const { translate } = useLanguage()


    console.log(state, "ghjk")
    const handleRetry = () => {
        history.push("/homepaymentScreen")
        // if (state?.data === 'motor') {
        //     history.push({
        //         pathname: '/oonapayment',
        //         // state: {
        //         //     quoteId: state?.quoteId,
        //         //     cipher: state?.cipher != null ? state?.cipher : null,
        //         //     data: 'motor'

        //         // }

        //     })

        // } else {
        //     history.push({
        //         pathname: '/homepaymentScreen',
        //         // state: {
        //         //     quoteId: props.location.state.quoteId,
        //         //     cipher: props.location.state.cipher != null ? props.location.state.cipher : null,
        //         // }
        //     })
        // }
    }

    return (
        <>
        <CustomerHeader/>
        <div>
            <Row className='success-content'>
                <Col xl={12} md={12} lg={12} xs={24} sm={24}>
                    <Image preview={false} src={ErrorLogo} />
                </Col>
                <Col xl={12} md={12} lg={12} xs={24} sm={24}>
                    <h5 className='header-card-content'>{translate("home")["errorinprintPolicy"]}</h5>

                    <p className='error-inner-margin'>{translate("home")["unabletodoRegistration"]}</p>
                    <div className='error-inner-margin'>
                        
                    </div>
                    <div className='error-inner-margin'>
                    {translate("home")["contactOurtechnical"]}
                    </div>
                    <br />
                    <Row>
                        <Col md={12} xl={12} xs={12} sm={12} lg={12}>
                            <Button onClick={() => history.push("/oonahome")} className='no-button'>{translate("motor")["Back to Dashboard"]}</Button>
                        </Col>
                        <Col md={12} xl={12} xs={12} sm={12} lg={12}>
                            <Button onClick={() => handleRetry()} className='next-button-travel'>{translate("motor")["Retry"]}</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
        </>
    )
}

export default HomeprintPolicyError
