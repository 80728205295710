import {PA_FETCH_DATA_SUCCESS,PA_UPDATE_DATA_SUCCESS,PA_RESET_DATA,POLICY_INCEPTION_DATE} from '../../actions/actionTypes.js'

const initialState = {
    paQuotefetchedData: {},
  };

  export const paQuoteFetchedDataReducer = (state = initialState, action) => {
    switch (action.type) {
      case PA_FETCH_DATA_SUCCESS:
        return {
          ...state,
          paQuotefetchedData: action.payload,
        };

        case PA_RESET_DATA:
      return {
        ...initialState,
      };
      default:
        return state;
    }
  };






  // updateApi

const updateQuoteDataInitialState={
  updateQuote:"",
  updateQuoteData:{}
}


export const updateQuoteDataReducer=(state=updateQuoteDataInitialState,action)=>{
  console.log(action,"action")
  console.log(state,"state")
switch(action.type){
  case PA_UPDATE_DATA_SUCCESS:
      return {
          ...state,
          updateQuote:action?.payload?.errMsg?.message,
          updateQuoteData:action?.payload
      }
      case PA_RESET_DATA:
        return {
          ...updateQuoteDataInitialState,
        };
      default:
          return state
}
}


const policyInceptionDatesState={
   effectiveDate:null,
   expiryDate:null
}

export const policyInceptionDateReducer=(state=policyInceptionDatesState,action)=>{
   switch(action.type){
     case POLICY_INCEPTION_DATE:{
         return{
			...state,
			effectiveDate:action.payload.effectiveDate,
			expiryDate:action.payload.expiryDate
		 }
	 };
	 default:
          return state
   }
}


const copyOptionInitialState = {
  copyOption: '2',
  address:""
};

export const copyOptionReducer = (state = copyOptionInitialState, action) => {
  switch (action.type) {
    case 'SET_COPY_OPTION':
      return {
        ...state,
        copyOption: action.payload
      };
      case 'SET_ADDRESS':
      return {
        ...state,
        address: action.payload
      };
    default:
      return state;
  }
};

  // export default paQuoteFetchedDataReducer;